export const dashPage = {
  title: "Dashboard",
  doneOrders: "Pedidos Realizados",
  totalValue: "Valor Total",
  soldProducts: "Produtos Vendidos",
  distinctUsers: "Cliente(s)",
  inProgressOrders: "Pedidos em Andamento",
  takeaway: "TakeAway",
  totalAverage: "Ticket Médio",
  rejectedOrders: "Pedidos Rejeitados",

  specificDateFilter: "Filtre a data",
  initDateFilter: "Data inicio",
  endDateFilter: "Data fim",
  cleanFilters: "Limpar filtros",
  searchFilters: "Pesquisar",
};
