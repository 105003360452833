import {
  PROFILE_API_BASE,
  PROFILE_LOCATION,
  ME_LOCATION,
  QUERY_ACCESS
} from "../static/URL";
import api from "../utils/API";

/**
 *
 * @param {String} userId Id do usuário na aplicação
 * @param {*} token token de autenticação do usuário.
 */
export const fetchUserProfiles = async (userId, token) => {
  try {
    // Buscar perfis do usuário.
    const URL = `${PROFILE_API_BASE +
      PROFILE_LOCATION +
      ME_LOCATION}?${QUERY_ACCESS + token}`;
    const { data: profiles } = await api.get(URL);
    return profiles;
  } catch (error) {
    console.log({ ...error });
    return [];
  }
};
