/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  TableCell,
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  Typography,
  LinearProgress,
} from "@material-ui/core";

import CreatableSelect from "react-select/creatable";
import "firebase/auth";
import api from "../../utils/API";
// Funções e dados.
import {
  PAYMENT_API_BASE,
  BANK_LOCATION,
  ACCOUNT_LOCATION,
  SETTINGSTORE_LOCATION,
  QUERY_ACCESS,
  PROFILE_API_BASE,
  PROFILE_LOCATION,
} from "../../static/URL";
import {
  USER_SELECTED_PROFILE,
  USER_AUTH_TOKEN,
} from "../../static/LocalStorageTags";
import { getItem } from "../../utils/localStorage";
import { loadAccounts } from "../../functions/accounts";

// Componentes.
import CustomNumberMaskedInput from "../../components/CustomNumberMaskedInput";
import CustomTooltip from "../../components/CustomTooltip";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
import CustomSideModal from "../../components/CustomSideModal";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";
import CustomModal from "../../components/CustomModal";

// Recursos visuais.
import "./style.css";

class Settings extends Component {
  state = {
    selectedOption: [],
    isValidNewOptionValue: undefined,
    openLoadingModal: false,
    openErrorModalItem: false,
    openErrorModal: false,
    openSuccessModal: false,
    openDeleteErrorModal: false,
    accounts: [],
    // Table
    open: false,
    selected: [],
    order: "asc",
    orderBy: "name",
    page: 0,
    rowsPerPage: 10,
    // SideModal
    sideModalOpen: false,
    id: undefined,
    owner: "",
    ownerError: false,
    ownerShowTooltip: false,
    ownerTooltipMessage: "",
    documentOwner: "",
    documentOwnerError: false,
    documentOwnerShowTooltip: false,
    documentOwnerTooltipMessage: "",
    accountsError: false,
    accountsShowTooltip: false,
    accountsTooltipMessage: "",
    physicalProduct: true,
    autoWithdraw: false,
    autoAdvance: false,
    emailNotify: false,
    description: "",
    profileId: undefined,
    bankSlipactive: false,
    bankSlip: {},
    creditCard: {},
    extraDue: 0,
    reprintExtraDue: 0,
    creditCardactive: true,
    softDescriptor: "FuraFillaApp",
    account: {},
    installments: false,
    maxInstallments: 0,
    maxInstallmentsWithoutInterest: 0,
    twoStepTransaction: false,
    installmentsPassInterest: false,
    feeCard: 0,
    isEnable: true,
    selectOptions: [],
    value: "",
    inputValue: "",
    selectedID: "",
    visibleValue: "",
    mensagemError: "",
    checkedB: true,
    checked: [],
    isLoading: false,
    accountId: undefined,
    configAccount: [],
    accountsSelected: {},
    config: {},
    email: "",
  };

  componentDidMount() {
    this.loadAccounts();
    this.loadConfig();
  }

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user.selectedProfile) {
      return this.setState({ configAccount: [] });
    }
    if (
      (!this.props.user.selectedProfile && nextProps.user.selectedProfile) ||
      (nextProps.user.selectedProfile &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id !==
          nextProps.user.selectedProfile.id)
    ) {
      this.loadAccounts();
      this.loadConfig();
    }
  }

  // Carrega os produtos
  loadConfig = async (id) => {
    const userToken = getItem(USER_AUTH_TOKEN);
    if (id) {
      const { data: config } = await api.get(
        `${
          PAYMENT_API_BASE +
          BANK_LOCATION +
          ACCOUNT_LOCATION +
          SETTINGSTORE_LOCATION
        }/${id}?${QUERY_ACCESS + userToken}`
      );
      console.log("config", config);
      await this.setState({
        configAccount: [
          {
            id: config.id,
            owner: config.owner,
            documentOwner: config.documentOwner,
            physicalProduct: config.physicalProduct,
            autoWithdraw: config.autoWithdraw,
            autoAdvance: config.autoAdvance,
            bankSlip: config.bankSlip,
            creditCard: config.creditCard,
            emailNotify: config.emailNotify,
            account: config.account,
            dtCreated: config.dtCreated,
            description: config.description,
            profileId: config.profileId,
            email: config.email,
          },
        ],
      });
    }
  };

  /*
   * Método que carega todas as contas cadastradas.
   */
  loadAccounts = async () => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const accounts = await loadAccounts(profileId, userToken);
      this.setState({
        accounts,
        description: this.props.user.selectedProfile.display,
        email: this.props.user.selectedProfile.email,
      });
    } catch (error) {
      console.log({ ...error });
      this.setState({ accounts: [] });
    }
  };

  /*
   * Método que verifica a mudança de statos dos items.
   * @param {newValue} novos valores selecionado.
   * @param {actionMeta} retorna ação de adicionar e deletar.
   */
  onChange = async (newValue) => {
    this.setState({ isLoading: true });
    await this.setState({ accountsSelected: newValue });
    await this.setState({
      owner: newValue.ownerName,
      documentOwner: newValue.ownerDocument,
      accountId: newValue.id,
    });
  };

  // Retorna o UserProfile
  getUserProfile = async () => {
    const storageProfile = getItem(USER_SELECTED_PROFILE);
    if (storageProfile) {
      return JSON.parse(storageProfile).id;
    }
    const { user } = this.props;
    if (user && user.selectedProfile) {
      return user.selectedProfile.id;
    }
    const userToken = getItem(USER_AUTH_TOKEN);
    const { data: me } = await api.get(
      `${PROFILE_API_BASE + PROFILE_LOCATION}/me?${QUERY_ACCESS + userToken}`
    );
    const lastDataMe = me.sort(
      (a, b) => new Date(b.dtCreated) - new Date(a.dtCreated)
    );
    return lastDataMe[0].id;
  };

  // Renderiza a tabela de produtos
  renderTable = () => {
    const { lang } = this.props;
    const { configAccount } = this.state;
    const rows = [
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: lang.name,
      },
      {
        id: "cpf",
        numeric: false,
        disablePadding: false,
        label: lang.documentOwner,
      },
      {
        id: "descricao",
        numeric: false,
        disablePadding: false,
        label: lang.description,
      },
      {
        id: "dtCreated",
        numeric: false,
        disablePadding: false,
        label: lang.createdAt,
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        showAddButton
        onAddEvent={() => this.setState({ sideModalOpen: true })}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        onClick={this.onClickConfigEdition}
        handleSelectAllClick={(e) => {
          if (e.target.checked) {
            this.setState((state) => ({
              selected: state.configAccount.map((n) => n.id),
            }));
            return;
          }
          this.setState({ selected: [] });
        }}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={configAccount.map((a) => ({
          id: a.id,
          owner: a.owner,
          documentOwner: a.documentOwner,
          physicalProduct: a.physicalProduct,
          autoWithdraw: a.autoWithdraw,
          autoAdvance: a.autoAdvance,
          bankSlip: a.bankSlip,
          creditCard: a.creditCard,
          emailNotify: a.emailNotify,
          account: a.account,
          dtCreated: new Date(a.dtCreated)
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("/"),
          description: a.description,
          profileId: a.profileId,
          accountsSelected: a.accounts,
          email: a.email,
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell component="th" scope="row" padding="none">
                {n.owner}
              </TableCell>
              <TableCell>{n.documentOwner}</TableCell>
              <TableCell>
                {n.description.length > 50
                  ? `${n.description.substr(0, 50)}...`
                  : n.description}
              </TableCell>
              <TableCell>{n.dtCreated}</TableCell>
            </>
          );
        }}
      />
    );
  };

  /**
   *
   * @param {Object} product Objeto de produto.
   */
  onClickConfigEdition = async (config) => {
    const { selected } = this.state;
    // Percorre o array de produtos para encontrar o produto selecionado
    await this.state.accounts.forEach((accountsSel) => {
      if (accountsSel.id === config.account.id) {
        // Muda o estado do produto para o selecionado.
        this.setState({ accountsSelected: accountsSel });
      }
    });
    await this.setState({
      selected: selected.filter((s) => s !== config.id),
      id: config.id,
      owner: config.owner,
      documentOwner: config.documentOwner,
      physicalProduct: config.physicalProduct,
      autoWithdraw: config.autoWithdraw,
      autoAdvance: config.autoAdvance,
      bankSlip: config.bankSlip,
      creditCard: config.creditCard,
      emailNotify: config.emailNotify,
      account: config.account,
      dtCreated: config.dtCreated,
      description: config.description,
      profileId: config.profileId,
      bankSlipactive: config.bankSlip.active,
      extraDue: config.bankSlip.extraDue,
      reprintExtraDue: config.bankSlip.reprintExtraDue,
      creditCardactive: config.creditCard.active,
      installments: config.creditCard.installments,
      softDescriptor: config.creditCard.softDescriptor,
      maxInstallments: config.creditCard.maxInstallments,
      maxInstallmentsWithoutInterest:
        config.creditCard.maxInstallmentsWithoutInterest,
      twoStepTransaction: config.creditCard.twoStepTransaction,
      installmentsPassInterest: config.creditCard.installmentsPassInterest,
      feeCard: config.creditCard.feeCard,
      accountId: config.account.id,
      sideModalOpen: true,
      email: config.email,
    });
  };

  // Renderiza a modal de criar produtos
  renderSideModal = () => {
    const { lang } = this.props;
    const { sideModalOpen } = this.state;
    return (
      <CustomSideModal
        open={sideModalOpen}
        handleClose={this.handleCloseModal}
        title={!this.state.id ? lang.sideModalTitle : lang.sideModalTitleEdit}
        confirmationAction={this.validateFormValues}
        confirmationText={lang.save}
        cancelationAction={this.handleCloseModal}
        cancelationText={lang.cancel}
        activeChecked={this.state.isEnable}
      >
        <Grid container>
          <Grid item xs={12}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  accountsShowTooltip: false,
                  accountsError: false,
                })
              }
              open={this.state.accountsShowTooltip}
              title={this.state.accountsTooltipMessage}
            >
              <CreatableSelect
                value={this.state.accountsSelected}
                deleteRemoves={false}
                isClearable
                placeholder="Contas"
                required
                options={this.state.accounts}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.name}
                getNewOptionData={(inputValue, optionLabel) => ({
                  id: inputValue,
                  name: optionLabel,
                })}
                onChange={this.onChange}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  nameShowTooltip: false,
                  nameError: false,
                })
              }
              open={this.state.nameShowTooltip}
              title={this.state.nameTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                disabled
                label={lang.name}
                error={this.state.ownerError}
                value={this.state.owner}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  documentOwnerShowTooltip: false,
                  documentOwnerError: false,
                })
              }
              open={this.state.documentOwnerShowTooltip}
              title={this.state.documentOwnerTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                disabled
                label={lang.documentOwner}
                error={this.state.documentOwnerError}
                value={this.state.documentOwner}
                onChange={(e) =>
                  this.setState({ documentOwner: e.target.value })
                }
              />
            </CustomTooltip>
          </Grid>
          <Grid container className="input-margin">
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.physicalProduct}
                    onChange={(e) =>
                      this.setState({ physicalProduct: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label={lang.physicalProduct}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.autoWithdraw}
                    onChange={(e) =>
                      this.setState({ autoWithdraw: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label={lang.autoWithdraw}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.autoAdvance}
                    onChange={(e) =>
                      this.setState({ autoAdvance: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label={lang.autoAdvance}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.emailNotify}
                    onChange={(e) =>
                      this.setState({ emailNotify: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label={lang.email}
              />
            </Grid>
          </Grid>
          <Grid container className="input-margin">
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.bankSlipactive}
                    onChange={(e) =>
                      this.setState({ bankSlipactive: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label={lang.bankSlip}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomNumberMaskedInput
              className="input-margin width-95"
              value={this.state.extraDue}
              onChange={(e) => this.setState({ extraDue: e })}
              id="number"
              label={lang.extraDue}
            />
          </Grid>
          <Grid item xs={3} sm={6}>
            <CustomNumberMaskedInput
              className="input-margin width-95"
              required
              value={this.state.reprintExtraDue}
              onChange={(e) => this.setState({ reprintExtraDue: e })}
              id="number"
              label={lang.reprintExtraDue}
            />
          </Grid>
          <Grid container className="input-margin">
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.creditCardactive}
                    onChange={(e) =>
                      this.setState({ creditCardactive: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label={lang.creditCard}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.installments}
                    onChange={(e) =>
                      this.setState({ installments: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label={lang.installments}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomNumberMaskedInput
                className="input-margin width-95"
                required
                value={this.state.maxInstallments}
                onChange={(e) => this.setState({ maxInstallments: e })}
                id="number"
                label={lang.maxInstallments}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomNumberMaskedInput
                className="input-margin width-95"
                required
                value={this.state.maxInstallmentsWithoutInterest}
                onChange={(e) =>
                  this.setState({ maxInstallmentsWithoutInterest: e })
                }
                id="number"
                label={lang.maxInstallmentsWithoutInterest}
              />
            </Grid>
            <FormControlLabel
              control={
                <Switch
                  checked={this.state.twoStepTransaction}
                  onChange={(e) =>
                    this.setState({ twoStepTransaction: e.target.checked })
                  }
                  color="primary"
                />
              }
              label={lang.twoStepTransaction}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={this.state.installmentsPassInterest}
                  onChange={(e) =>
                    this.setState({
                      installmentsPassInterest: e.target.checked,
                    })
                  }
                  color="primary"
                />
              }
              label={lang.installmentsPassInterest}
            />
            <Grid item xs={12} sm={6}>
              <CustomNumberMaskedInput
                className="input-margin width-95"
                required
                value={this.state.feeCard}
                onChange={(e) => this.setState({ feeCard: e })}
                id="number"
                label={lang.feeCard}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                className="input-margin width-95"
                required
                label={lang.softDescriptor}
                error={this.state.descError}
                value={this.state.softDescriptor}
                onChange={(e) =>
                  this.setState({ softDescriptor: e.target.value })
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            className="input-margin width-95"
            required
            disabled
            label={lang.description}
            error={this.state.descError}
            value={this.state.description}
            onChange={(e) => this.setState({ description: e.target.value })}
          />
        </Grid>
      </CustomSideModal>
    );
  };

  // Método realizado ao fechar a modal de adição de produtos.
  handleCloseModal = () => {
    this.setState({
      sideModalOpen: false,
      id: undefined,
      owner: "",
      ownerError: false,
      ownerShowTooltip: false,
      ownerTooltipMessage: "",
      documentOwner: "",
      documentOwnerError: false,
      documentOwnerShowTooltip: false,
      documentOwnerTooltipMessage: "",
      accountsError: false,
      accountsShowTooltip: false,
      accountsTooltipMessage: "",
      physicalProduct: true,
      autoWithdraw: false,
      autoAdvance: false,
      emailNotify: false,
      description: "",
      profileId: undefined,
      bankSlipactive: false,
      bankSlip: {},
      creditCard: {},
      extraDue: 0,
      reprintExtraDue: 0,
      creditCardactive: true,
      softDescriptor: "FuraFillaApp",
      account: {},
      installments: false,
      maxInstallments: 0,
      maxInstallmentsWithoutInterest: 0,
      twoStepTransaction: false,
      installmentsPassInterest: false,
      feeCard: 0,
      isEnable: true,
      selectOptions: [],
      value: "",
      inputValue: "",
      selectedID: "",
      visibleValue: "",
      mensagemError: "",
      checkedB: true,
      checked: [],
      isLoading: false,
      accountId: undefined,
      configAccount: [],
      accountsSelected: {},
    });
  };

  // Método realizado para envio dos dados do formulário para o back
  handleModalSubmit = async () => {
    this.setState({ openLoadingModal: true });

    const productRes = await this.registerConfig();
    if (productRes) {
      this.setState({
        openLoadingModal: false,
        openSuccessModal: true,
        sideModalOpen: false,
      });
      this.handleCloseModal();
    } else {
      console.log("erro ao cadastrar a configurações");
      this.setState({ openLoadingModal: false, openErrorModal: true });
    }
  };

  /**
   * Método para cadastrar produto na base de dados.
   */
  registerConfig = async () => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileIds = await this.getUserProfile();
      await this.setState({
        bankSlip: {
          active: this.state.bankSlipactive,
          extraDue: this.state.extraDue ? this.state.extraDue : 0,
          reprintExtraDue: this.state.reprintExtraDue
            ? this.state.reprintExtraDue
            : 0,
        },
        creditCard: {
          active: this.state.creditCardactive,
          installments: this.state.installments,
          softDescriptor: this.state.softDescriptor
            ? this.state.softDescriptor
            : "FuraFillaApp",
          maxInstallments: this.state.maxInstallments
            ? this.state.maxInstallments
            : 0,
          maxInstallmentsWithoutInterest: this.state
            .maxInstallmentsWithoutInterest
            ? this.state.maxInstallmentsWithoutInterest
            : 0,
          twoStepTransaction: this.state.twoStepTransaction,
          installmentsPassInterest: this.state.installmentsPassInterest,
          feeCard: this.state.feeCard ? this.state.feeCard : 0,
        },
        account: {
          id: this.state.accountId,
        },
        profileId: profileIds,
      });

      const {
        owner,
        documentOwner,
        physicalProduct,
        autoWithdraw,
        autoAdvance,
        emailNotify,
        description,
        id,
        bankSlip,
        creditCard,
        account,
        profileId,
        email,
      } = this.state;

      if (id) {
        const response = await api.put(
          `${
            PAYMENT_API_BASE +
            BANK_LOCATION +
            ACCOUNT_LOCATION +
            SETTINGSTORE_LOCATION
          }/${id}?${QUERY_ACCESS + userToken}`,
          {
            id,
            owner,
            documentOwner,
            physicalProduct,
            autoWithdraw,
            autoAdvance,
            emailNotify,
            description,
            bankSlip,
            creditCard,
            account,
            profileId,
            email,
          }
        );
        return response;
      }
      const response = await api.post(
        `${
          PAYMENT_API_BASE +
          BANK_LOCATION +
          ACCOUNT_LOCATION +
          SETTINGSTORE_LOCATION
        }?${QUERY_ACCESS + userToken}`,
        {
          owner,
          documentOwner,
          physicalProduct,
          autoWithdraw,
          autoAdvance,
          emailNotify,
          description,
          bankSlip,
          creditCard,
          account,
          profileId,
          email,
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      const errorObj = { ...error };
      console.log({ ...error });
      let message = "Não foi possível cadastrar as configuraçõe!";
      if (errorObj.isAxiosError) {
        switch (errorObj.response.status) {
          case 400:
            message = "Requisição inválida.";
            break;
          case 401:
            message = "Acesso não autorizado!";
            break;
          case 422:
            message = errorObj.response.data.content;
            break;
          case 500:
            message = "Erro inesperado. Tente novamente em alguns minutos.";
            break;
          default:
            message = "Erro ao cadastrar configurações";
            break;
        }
      }
      this.setState({
        mensagemError: message,
      });
      return false;
    }
  };

  // Método para validar os campos do formulário
  validateFormValues = () => {
    const { owner, documentOwner, accountsSelected } = this.state;
    const { lang } = this.props;
    let data = {};

    if (!accountsSelected || accountsSelected.length === 0) {
      data = {
        ...data,
        accountsShowTooltip: true,
        accountsTooltipMessage: !accountsSelected
          ? lang.accountsRequiredError
          : lang.accountsInvalidError,
        accountsError: true,
      };
    }
    if (!owner || owner.trim().length === 0) {
      data = {
        ...data,
        ownerShowTooltip: true,
        ownerTooltipMessage: !owner
          ? lang.ownerRequiredError
          : lang.ownerInvalidError,
        ownerError: true,
      };
    }
    if (!documentOwner || documentOwner.trim().length === 0) {
      data = {
        ...data,
        documentOwnerShowTooltip: true,
        documentOwnerTooltipMessage: !documentOwner
          ? lang.documentOwnerRequiredError
          : lang.documentOwnerInvalidError,
        documentOwnerError: true,
      };
    }

    if (Object.keys(data).length > 0) {
      this.setState(data);
    } else {
      this.handleModalSubmit();
    }
  };

  render() {
    const { lang } = this.props;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <CustomModal
          open={this.state.openLoadingModal}
          onClose={() => this.setState({ openLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">
              {!this.state.id
                ? lang.registerConfigLoading
                : lang.editConfigLoading}
            </Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomConfirmationModal
          title={
            !this.state.id
              ? lang.registerSuccessTitle
              : lang.registerSuccessTitle
          }
          open={this.state.openSuccessModal}
          subtitle={
            !this.state.id
              ? lang.registerSuccessSubTitle
              : lang.editSuccessSubTitle
          }
          description={
            !this.state.id
              ? lang.registerSuccessDescription
              : lang.editSuccessDescription
          }
          confirmationText={lang.registerSuccessConfirmationText}
          confirmationAction={() => this.setState({ openSuccessModal: false })}
          onClose={() => this.setState({ openSuccessModal: false })}
        />

        <CustomConfirmationModal
          title={!this.state.id ? lang.registerErrorTitle : lang.editErrorTitle}
          open={this.state.openErrorModal}
          subtitle={
            !this.state.id ? lang.registerErrorSubTitle : lang.editErrorSubTitle
          }
          description={
            !this.state.id
              ? lang.registerErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
              : lang.editErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
          }
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() => this.setState({ openErrorModal: false })}
          onClose={() => this.setState({ openErrorModal: false })}
        />

        {this.renderTable()}
        {this.renderSideModal()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(Settings);
