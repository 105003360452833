import crypto from "crypto";

/**
 * Função para criar uma hash que não realiza a volta dos dados.
 */
export const createHash = (value, salt) => {
  if (!value) {
    return false;
  }
  return crypto
    .createHash("RSA-SHA256")
    .update(value)
    .update(salt)
    .digest("hex");
};
/**
 * Função para gera strings que podem ser descriptografadas.
 * @param {String} key Chave para criptografia | Valor default deve possuir 32 bytes.
 * @param {String} iv iv para o cipher | Valor default deve possuir 16 bytes.
 * @param {String} text Valor que será criptografado.
 * @returns {String} Texto criptografado.
 */
export const cypher = ({
  key = crypto.randomBytes(32).toString("hex"),
  iv = crypto.randomBytes(16).toString("hex"),
  text,
}) => {
  if (!text) {
    return false;
  }
  const cipher = crypto.createCipheriv(
    "aes-256-cbc",
    Buffer.from(key, "hex"),
    Buffer.from(iv, "hex")
  );
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return encrypted.toString("hex");
};
/**
 * Função para realizar a descriptografia da string enviada, a partir dos valores enviados
 * ou os que são padrão.
 * @param {String} key Chave para descriptografia | Valor default deve possuir 32 bytes.
 * @param {Object} textObject Valor que será descriptografado. {encryptedData, iv}
 */
export const decypher = ({
  key = crypto.randomBytes(32).toString("hex"),
  iv = crypto.randomBytes(16).toString("hex"),
  text,
}) => {
  if (!text) {
    return false;
  }
  const encryptedText = Buffer.from(text, "hex");
  const decipher = crypto.createDecipheriv(
    "aes-256-cbc",
    Buffer.from(key, "hex"),
    Buffer.from(iv, "hex")
  );
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};
