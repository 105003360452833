import styled from "styled-components";

export const ContentContainer = styled.div`
  padding: 20px;
`;

export const ButtonContainer = styled.div`
  margin-top: 50px;
`;

export const DescriptionContainer = styled.div`
  margin-top: 20px;
`;

export const HeaderContainer = styled.div`
  background: rgba(103, 58, 183, 0.7);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px 20px;

  .title {
    color: #fff;
  }
`;
