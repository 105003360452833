import {
  ViewColumn,
  Fastfood,
  Tv,
  Equalizer,
  Home,
  Store,
  LocalOffer,
  Apps,
  AccountBalance,
  PersonAdd,
  List,
  Settings,
  Schedule,
  SettingsInputSvideo,
  InsertLink,
  Group,
  ListAlt,
  ViewList,
  Message,
} from "@material-ui/icons";
import lang from "../config/lang";

export const DASHBOARD_SCREEN = {
  title: lang.dashPage.title,
  icon: Equalizer,
  url: "/dashboard",
  name: "dashboard",
};

export const ORDER_SCREEN = {
  title: lang.orderPage.title,
  icon: ViewColumn,
  url: "/",
  name: "order",
};

export const ORDERPANEL_SCREEN = {
  title: lang.orderPanelPage.title,
  icon: Tv,
  url: "/orderPanel",
  name: "orderPanel",
};

export const ORDERLIST_SCREEN = {
  title: lang.orderListPage.title,
  icon: List,
  url: "/orderList",
  name: "orderList",
};

export const COMPANIES_SCREEN = {
  title: lang.companiesPage.title,
  icon: Home,
  url: "/companies",
  name: "companies",
};

export const CATEGORY_SCREEN = {
  title: lang.categoryPage.title,
  icon: LocalOffer,
  url: "/category",
  name: "category",
};

export const PRODUCTS_SCREEN = {
  title: lang.productsPage.title,
  icon: Fastfood,
  url: "/products",
  name: "products",
};

export const ITEMPRODUCTS_SCREEN = {
  title: lang.itemProductPage.title,
  icon: Apps,
  url: "/items",
  name: "items",
};

export const BANKACCOUNT_SCREEN = {
  title: lang.bankAccountPage.title,
  icon: AccountBalance,
  url: "/accounts",
  name: "accounts",
};

export const USERPDV_SCREEN = {
  title: lang.userPDVPage.title,
  icon: PersonAdd,
  url: "/users",
  name: "users",
};

export const PLACES_SCREEN = {
  title: lang.placesPage.title,
  icon: Store,
  url: "/places",
  name: "places",
};

export const STORE_ORDER = {
  title: lang.storeOrderPage.title,
  icon: Schedule,
  url: "/storeOrder",
  name: "storeOrder",
};
export const SETTINGS_SCREEN = {
  title: lang.settingsPage.title,
  icon: Settings,
  url: "/settings",
  name: "settings",
};
export const SETTINGSITEMS_SCREEN = {
  title: lang.settingsItemsPage.title,
  icon: SettingsInputSvideo,
  url: "/settingsItems",
  name: "settingsItems",
};

export const LISTUSER_SCREEN = {
  title: lang.userListPage.title,
  icon: Group,
  url: "/listUser",
  name: "listUser",
};

export const LINKPLACESCOMPANIESCREEN = {
  title: lang.linkPlacesCompaniesPage.title,
  icon: InsertLink,
  url: "/linkPlacesCompanies",
  name: "linkPlacesCompanies",
};

export const ALLORDERCREEN = {
  title: lang.allOrderPage.title,
  icon: ListAlt,
  url: "/allOrder",
  name: "allOrder",
};

export const LISTPRODUCTSINPREPARATIONCREEN = {
  title: lang.listProductsInPreparationPage.title,
  icon: ViewList,
  url: "/listProductsInPreparation",
  name: "listProductsInPreparation",
};

export const MESSAGECREEN = {
  title: lang.messagePage.title,
  icon: Message,
  url: "/message",
  name: "message",
};
