/**
 *
 * @param {String} userId Id do usuário na aplicação
 * @param {*} token token de autenticação do usuário.
 */
export const fetchErrors = (status, response, msg) => {
  let message = `Não foi possível ${msg}`;

  switch (status) {
    case 400:
      message = "Requisição inválida.";
      break;
    case 401:
      message = "Acesso não autorizado!";
      break;
    case 422:
      message = response;
      break;
    case 500:
      message = "Erro inesperado. Tente novamente em alguns minutos.";
      break;
    default:
      message = `Erro ao ${msg}`;
      break;
  }

  return message;
};
