import styled from "styled-components";

export const OrderInfoItemContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  .total-value-label {
    text-align: right;
    margin-top: 30px;
    margin-right: 20px;
  }
`;

export const ContentContainer = styled.div`
  padding: 20px;
`;

export const HeaderContainer = styled.div`
  background: rgba(103, 58, 183, 0.7);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px 20px;

  .title {
    color: #fff;
  }

  .print-icon {
    color: #fff;
  }
`;

export const TakehomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center !important;
  margin: 20px;
  padding: 5px;

  border: 2px solid rgba(103, 58, 183);
  border-radius: 5px;
`;
