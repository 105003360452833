/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableCell, Typography, LinearProgress } from "@material-ui/core";
import "firebase/auth";
import api from "../../utils/API";
// Funções e dados.
import {
  PROFILE_API_BASE,
  PROFILE_LOCATION,
  QUERY_ACCESS,
  USER_API_BASE,
  USERS_LOCATION,
} from "../../static/URL";
import {
  USER_SELECTED_PROFILE,
  USER_AUTH_TOKEN,
} from "../../static/LocalStorageTags";
import { getItem } from "../../utils/localStorage";
import { checkEmail } from "../../utils/regex";

// Componentes.
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";
import CustomModal from "../../components/CustomModal";

// Recursos visuais.
// import "./style.css.js";

class UserList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLoadingModal: false,
      openErrorModal: false,
      openSuccessModal: false,
      // Table
      users: [],
      open: false,
      selected: [],
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: 30,
      // SideModal
      sideModalOpen: false,
      id: undefined,
      name: "",
      login: "",
      email: "",
      role: "",
      profileId: undefined,
      profileName: "",
      isEnable: true,
      providerId: "",
      user_id: "",
      mensagemError: "",
      openLoadUser: false,
      inputValue: "",
      selectedID: "",
      visibleValue: "",
      checked: [],
    };
  }

  componentDidMount() {
    this.loadUsers();
  }

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user.selectedProfile) {
      return this.setState({ users: [] });
    }
    if (
      (!this.props.user.selectedProfile && nextProps.user.selectedProfile) ||
      (nextProps.user.selectedProfile &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id !==
          nextProps.user.selectedProfile.id)
    ) {
      this.loadUsers();
    }
  }

  // Carrega os usuários
  loadUsers = async () => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const { data: users } = await api.get(
        `${USER_API_BASE + USERS_LOCATION}?${QUERY_ACCESS + userToken}`
      );
      this.setState({ users });
    } catch (error) {
      console.log({ ...error });
      this.setState({ users: [] });
    }
  };

  // Retorna o UserProfile
  getUserProfile = async () => {
    const storageProfile = getItem(USER_SELECTED_PROFILE);
    if (storageProfile) {
      return JSON.parse(storageProfile).id;
    }
    const { user } = this.props;
    if (user && user.selectedProfile) {
      return user.selectedProfile.id;
    }
    const userToken = getItem(USER_AUTH_TOKEN);
    const { data: me } = await api.get(
      `${PROFILE_API_BASE + PROFILE_LOCATION}/me?${QUERY_ACCESS + userToken}`
    );
    const lastDataMe = me.sort(
      (a, b) => new Date(b.dtCreated) - new Date(a.dtCreated)
    );
    return lastDataMe[0].id;
  };

  handleChange = async (event) => {
    await this.setState({
      selectedID: event.currentTarget.id,
      visibleValue: event.target.value,
    });
    await this.setState({
      role: this.state.selectedID ? this.state.selectedID : "",
    });
  };

  // Renderiza a tabela de usuário
  renderTable = () => {
    const { lang } = this.props;
    const { users } = this.state;
    const rows = [
      {
        id: "foto",
        numeric: false,
        disablePadding: false,
        label: lang.photo,
      },
      {
        id: "hash",
        numeric: false,
        disablePadding: false,
        label: lang.id,
      },
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: lang.name,
      },
      {
        id: "dateOfBirth",
        numeric: false,
        disablePadding: false,
        label: lang.dateOfBirth,
      },
      {
        id: "phone",
        numeric: false,
        disablePadding: false,
        label: lang.phone,
      },
      {
        id: "dtCreated",
        numeric: false,
        disablePadding: false,
        label: lang.createdAt,
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        onAddEvent={() => this.setState({ sideModalOpen: false })}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        onClick={() => console.log("")}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={users.map((u) => ({
          id: u.id,
          name: u.name,
          email: u.email,
          dateOfBirth: u.dateOfBirth,
          phoneNumber: u.phoneNumber,
          fbUrlImage: u.fbUrlImage,
          registration: new Date(u.registration)
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("/"),
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell>
                <img src={n.fbUrlImage} alt="" height="30" className="fb-img" />
              </TableCell>
              <TableCell>{n.id}</TableCell>
              <TableCell component="th" scope="row" padding="none">
                {n.name.length > 20 ? `${n.name.substr(0, 20)}...` : n.name}
              </TableCell>
              <TableCell>{n.dateOfBirth}</TableCell>
              <TableCell>{n.phoneNumber}</TableCell>
              <TableCell>{n.registration}</TableCell>
            </>
          );
        }}
      />
    );
  };

  // Método para validar os campos do formulário
  validateFormValues = () => {
    const { name, login, email, role, providerId } = this.state;
    const { lang } = this.props;
    let data = {};

    if (!name || name.trim().length === 0) {
      data = {
        ...data,
        nameShowTooltip: true,
        nameTooltipMessage: !name
          ? lang.nameRequiredError
          : lang.nameInvalidError,
        nameError: true,
      };
    }
    if (!login || login.trim().length === 0) {
      data = {
        ...data,
        loginShowTooltip: true,
        loginTooltipMessage: !name
          ? lang.loginRequiredError
          : lang.loginInvalidError,
        loginError: true,
      };
    }

    if (
      !email ||
      email.trim().length === 0 ||
      (email.trim().length > 0 && !checkEmail(email))
    ) {
      data = {
        ...data,
        emailShowTooltip: true,
        emailTooltipMessage: !name
          ? lang.emailRequiredError
          : lang.emailInvalidError,
        emailError: true,
      };
    }
    if (!providerId || providerId.trim().length === 0) {
      data = {
        ...data,
        providerIdShowTooltip: true,
        providerIdTooltipMessage: !name
          ? lang.providerIdRequiredError
          : lang.providerIdInvalidError,
        providerIdError: true,
      };
    }
    if (!role || role.trim().length === 0) {
      data = {
        ...data,
        roleShowTooltip: true,
        roleTooltipMessage: !name
          ? lang.roleRequiredError
          : lang.roleInvalidError,
        roleError: true,
      };
    }
    if (Object.keys(data).length > 0) {
      this.setState(data);
    } else {
      this.handleModalSubmit();
    }
  };

  render() {
    const { lang } = this.props;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <CustomModal
          open={this.state.openLoadingModal}
          onClose={() => this.setState({ openLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">
              {!this.state.id ? lang.registerUserLoading : lang.editUserLoading}
            </Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomConfirmationModal
          title={
            !this.state.id
              ? lang.registerSuccessTitle
              : lang.registerSuccessTitle
          }
          open={this.state.openSuccessModal}
          subtitle={
            !this.state.id
              ? lang.registerSuccessSubTitle
              : lang.editSuccessSubTitle
          }
          description={
            !this.state.id
              ? lang.registerSuccessDescription
              : lang.registerSuccessDescription
          }
          confirmationText={lang.registerSuccessConfirmationText}
          confirmationAction={() => this.setState({ openSuccessModal: false })}
          onClose={() => this.setState({ openSuccessModal: false })}
        />

        <CustomConfirmationModal
          title={!this.state.id ? lang.registerErrorTitle : lang.editErrorTitle}
          open={this.state.openErrorModal}
          subtitle={
            !this.state.id ? lang.registerErrorSubTitle : lang.editErrorSubTitle
          }
          description={
            !this.state.id
              ? lang.registerErrorDescription
              : lang.editErrorDescription
          }
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() => this.setState({ openErrorModal: false })}
          onClose={() => this.setState({ openErrorModal: false })}
        />

        <CustomModal
          open={this.state.openLoadUser}
          onClose={() => this.setState({ openLoadUser: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">{lang.usuarioLoading}</Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        {this.renderTable()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(UserList);
