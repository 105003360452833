export const loginPage = {
	welcomeText: 'Bem vindo ao',
	emailLabel: 'Email',
	emailInputPlaceholder: 'Insira seu email',
	passwordLabel: 'Senha',
	passwordInputPlaceholder: 'Insira sua senha',
	emailRequired: 'Email  é obrigatório!',
	emailInvalid: 'Email inválido!',
	passRequired: 'Senha é obrigatório!',
	btnLogin: 'Entrar',
	btnFbLogin: 'Entrar com Facebook',
	btnGmailLogin: 'Entrar com Gmail',
	noAccountError: 'Cadastre-se para utilizar nossos serviços!',
	passwordEmailError: 'E-mail ou senha inválidos!',
	signUpText: 'Não possui uma conta? cadastre-se ',
	signUpLink: 'aqui',
	passwordEmailTitle: 'Erro',
	passwordEmailButton: 'ok'
};
