import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export const StyleModalInnerContent = styled.div`
  display: flex;
  flex-direction: column;

  border: 2px solid rgba(103, 58, 183, 0.7);
  width: 100%;
  background: #fff;
  height: 100%;

  transition: opacity 0.5s ease-in-out;

  z-index: 3;
`;

export const StyleModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #3b2e7d; 
  color: #fff
  font-size: 2em;
  font-weight: bold;
  padding: 10px 0px;
`;

export const StyleModalBody = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  display: flex;
  flex: 1;
  padding: 20px;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
`;

export const StyledModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #eee;
  padding: 10px 0px;
`;

export const FlexButtonAdd = styled.div`
  display: flex;
  align-items: center;
`;

export const ModalWrapper = styled.div`
  display: flex;
  z-index: 1;
  width: 100%;
  height: 70%;
`;

export const StyledModalShadownMask = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  cursor: pointer;
`;

export const PrimaryGrid = styled.div`
  border-color: #999;
  padding: 5px;
  margin: 10px;
  width: 100%;
`;

export const SecondGrid = styled.div`
  border-color: #ccc;
  padding: 5px;
  width: 60%;
`;

export const CustomHeaderContainer = styled.div`
  padding: 20px;
`;

export const OrderInfoItemContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;

  .total-value-label {
    text-align: right;
    margin-top: 30px;
    margin-right: 20px;
  }
`;

export const ContentContainer = styled.div`
  flex-direction: "row";
  overflow-y: auto;
`;

export const HeaderContainer = styled.div`
  background: rgba(103, 58, 183, 0.7);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 5px 20px;

  .title {
    color: #fff;
  }

  .print-icon {
    color: #fff;
  }
`;

export const TakehomeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center !important;
  justify-content: center !important;
  margin: 20px;
  padding: 5px;

  border: 2px solid rgba(103, 58, 183);
  border-radius: 5px;
`;

export const ListColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  /* border-right: 1px solid rgba(0, 0, 0, 0.05); */
  /* padding: 0px 10px 0px 0px; */
  align-items: flex-start !important;

  .list-item {
    padding: 0;
    margin-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    margin-left: 5px;

    div:nth-child(odd) {
      background: rgba(103, 58, 183, 0.1);
    }

    div {
      width: 100%;
    }
  }
`;

export const Divider = styled.div`
  height: 100%;
  width: 5px;
`;
