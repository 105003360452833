/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableCell } from "@material-ui/core";
import "firebase/auth";

import api from "../../utils/API";
// Funções e dados estaticos.
import { fetchProfileOrders } from "../../redux/actions";
// Componentes.
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
// Funções e dados.
import { QUERY_ACCESS, ORDER_API_BASE, ORDER_LOCATION } from "../../static/URL";
import OrderInfoModal from "./OrderInfoModal";
import { getItem } from "../../utils/localStorage";

import { USER_AUTH_TOKEN } from "../../static/LocalStorageTags";

class AllOrder extends Component {
  state = {
    openLoadingModal: false,
    openErrorModal: false,
    openSuccessModal: false,
    // Table

    open: false,
    selected: [],
    order: "desc",
    orderBy: "id",
    page: 0,
    rowsPerPage: 10,
    // SideModal
    sideModalOpen: false,
    openLoadCategory: false,
    orders: [],
    newOrders: [],
    // SideModal
    originalOrders: [],
    dateInitFilter: null,
    dateEndFilter: null,
    checkOrderInfoModalOpen: false,
    selectedOrder: {},
  };

  async componentDidMount() {
    const profile = this.props.user.selectedProfile;
    if (profile) {
      this.setState({ orders: [] });
      // Pegar empresas do usuário.
      this.props.fetchProfileOrders(profile.id);
    }
    this.loadOrders();
  }

  // Carrega os usuários
  // eslint-disable-next-line react/sort-comp
  loadOrders = async () => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const { data: orders } = await api.get(
        `${ORDER_API_BASE + ORDER_LOCATION}?${QUERY_ACCESS + userToken}`
      );
      this.setState({ orders });
    } catch (error) {
      console.log({ ...error });
      this.setState({ orders: [] });
    }
  };

  // eslint-disable-next-line consistent-return
  async UNSAFE_componentWillReceiveProps(nextProps) {
    try {
      // Valida se o id do perfil selecionado no redux esta vazio.
      if (
        !nextProps.user.selectedProfile ||
        !nextProps.user.selectedProfile.id ||
        !this.props.user.selectedProfile ||
        !this.props.user.selectedProfile.id
      ) {
        this.setState({ orders: [], originalOrders: [] });
      }
      // Caso nao esteja, valida a seleção.
      else if (
        nextProps.user.selectedProfile &&
        nextProps.user.selectedProfile.id &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id &&
        this.props.user.selectedProfile.id !== nextProps.user.selectedProfile.id
      ) {
        this.setState({
          orders: [],
          originalOrders: [],
        });
        this.props.fetchProfileOrders(nextProps.user.selectedProfile.id);
      }
    } catch (error) {
      console.log(`Order - componentWillReceiveProps${error}`);
    }
  }

  // Renderiza a tabela de categorias
  renderTable = () => {
    const { lang } = this.props;
    const { orders } = this.state;
    const rows = [
      {
        id: "pedido",
        numeric: false,
        disablePadding: false,
        label: lang.order,
      },
      {
        id: "profileName",
        numeric: false,
        disablePadding: false,
        label: lang.profileName,
      },
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: lang.name,
      },
      {
        id: "pedidoAtual",
        numeric: false,
        disablePadding: false,
        label: lang.currentOrder,
      },
      {
        id: "valor",
        numeric: false,
        disablePadding: false,
        label: lang.totalValue,
      },
      {
        id: "dtPagamento",
        numeric: false,
        disablePadding: false,
        label: lang.dtSendForPay,
      },
      {
        id: "idFatura",
        numeric: false,
        disablePadding: false,
        label: lang.orderInvoiceId,
      },
      {
        id: "erroPagamento",
        numeric: false,
        disablePadding: false,
        label: lang.orderPaymentError,
      },
      {
        id: "dataHora",
        numeric: false,
        disablePadding: false,
        label: lang.dateTime,
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        onClick={this.onClickOrderEdition}
        handleSelectAllClick={(e) => {
          if (e.target.checked) {
            this.setState((state) => ({
              selected: state.products.map((n) => n.id),
            }));
            return;
          }
          this.setState({ selected: [] });
        }}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={orders.map((o) => ({
          id: o.id,
          userName: o.userName,
          userId: o.userId,
          statesOrder:
            o.orderStatus && o.orderStatus.value
              ? o.orderStatus.value
              : "Estado do pedido não identificado",
          price: (o.price ? o.price : 0)
            .toFixed(2)
            .replace(".", ",")
            .replace("/d(?=(d{3})+.)g", "$&."),
          dtCreated: new Date(o.dtCreated).toLocaleString(),
          discount: o.discount,
          discountPercentage: o.discountPercentage,
          itemList: o.itemList,
          note: o.note,
          paymentId: o.paymentId,
          profileId: o.profileId,
          profileName: o.profileName,
          refId: o.refId,
          stateStore: o.stateStore,
          orderStatus: o.orderStatus,
          takeHome: o.takeHome,
          totalItems: o.totalItems,
          totalPrice: o.totalPrice,
          userDocument: o.userDocument,
          dtSendForPay: o.dtSendForPay
            ? new Date(o.dtSendForPay).toLocaleString()
            : o.dtSendForPay,
          orderInvoiceId: o.orderInvoiceId,
          orderPaymentError: o.orderInvoiceId,
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell component="th" scope="row">
                {n.id}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.profileName}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.userName} ({n.userId})
              </TableCell>
              <TableCell component="th" scope="row">
                {n.statesOrder}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.price}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.dtSendForPay}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.orderInvoiceId}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.orderPaymentError}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.dtCreated}
              </TableCell>
            </>
          );
        }}
      />
    );
  };

  /**
   *
   * @param {Object} order Objeto de produto.
   */
  onClickOrderEdition = async (order) => {
    this.setState({
      checkOrderInfoModalOpen: true,
      selectedOrder: order,
    });
  };

  render() {
    const { lang } = this.props;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        {/* Modal de visualização dos dados do pedido */}
        <OrderInfoModal
          lang={lang}
          open={this.state.checkOrderInfoModalOpen}
          startLoading={() => this.setState({ loading: true })}
          selectedOrder={this.state.selectedOrder}
          onClose={() => {
            this.setState({
              selectedOrder: {},
              checkOrderInfoModalOpen: false,
            });
          }}
        />
        {this.renderTable()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user, order }) => {
  // eslint-disable-next-line no-undef
  let orders =
    order && Array.isArray(order.orders)
      ? order.orders.filter((val) => {
          return val !== undefined;
        })
      : [];
  if (orders) {
    // eslint-disable-next-line no-undef
    orders = _.sortBy(orders, ["id"]);
  }
  return { user, orders };
};

export default connect(mapStateToProps, {
  fetchProfileOrders,
})(AllOrder);
