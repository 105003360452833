export const messagePage = {
  title: "Mensagens Clientes",
  userId: "Id Usúario",
  titleMessage: "Titulo Mensagem",
  message: "Mensagem",
  createdAt: "Criado em",
  cancelationText: "Cancelar",
  close: "Fechar",
  sideModalTitle: "Enviar Mensagem",
  userIdInvalidError: "User Id inválido!",
  userIdRequiredError: "User Id é obrigatório!",
  titleMessageInvalidError: "Titulo inválido!",
  titleMessageRequiredError: "Titulo  é obrigatório!",
  messageInvalidError: "Mensagem inválido!",
  messageRequiredError: "Mensagem  é obrigatório!",
  lengthMessageInvalidError: "Máximo permitido é 160 caracteres!",
  lengthMessageRequiredError: "Máximo permitido é 160 caracteres!",
  messageSuccess: "Mensagem enviada com sucesso!",
  messageSuccessDescription: "Agora já foi enviada.",
  messageSuccessConfirmationText: "OK",
  registerErrorConfirmationText: "OK",
  save: "Enviar",
  cancel: "Cancelar",
  sendErrorDescription: "@MSG",
  registerErrorTitle: "Erro",
  registerErrorSubTitle: "Não foi possível enviar a mensagem!",
  registerSuccessConfirmationText: "OK",
  registerSuccessTitle: "Sucesso",
};
