import React from "react";
import { Route, Redirect } from "react-router-dom";
import { checkIfIsValid } from "../../utils/data";
import { acessControl } from "../../utils/accessControl";
import NotAllowed from "../../pages/NotAllowed";
import Products from "../../pages/Products";
import langs from "../../config/lang";
/**
 * Esse componente retorna o componente customizado de rotas, com regras de negócio.
 */
const CustomRoute = ({
  path,
  Component,
  lang,
  privateRoute,
  dataToCheck,
  permissions,
  ...rest
}) => {
  if (!privateRoute) {
    return (
      <Route
        {...rest}
        exact
        path={path}
        render={(props) => <Component {...props} lang={lang} />}
      />
    );
  }
  return (
    <Route
      {...rest}
      exact
      path={path}
      render={(props) =>
        // eslint-disable-next-line no-nested-ternary
        checkIfIsValid(dataToCheck) ? (
          rulerPermissionRotas(Component, lang, permissions, props, path)
        ) : (
          <Redirect to={{ pathname: "/login", state: {} }} />
        )
      }
    />
  );
};

const rulerPermissionRotas = (Component, lang, permissions, props, path) => {
  if (acessControl(permissions, path)) {
    if (
      !permissions.includes("storePdv") &&
      !permissions.includes("store") &&
      !permissions.includes("storeManager") &&
      path === "/"
    ) {
      return <Products {...props} lang={langs.productsPage} name="products" />;
    }

    return (
      <Component
        {...props}
        lang={lang}
        name={path.replace("/", "") || "order"}
      />
    );
  }

  return <NotAllowed {...props} lang={lang} name="notallowed" />;
};

export default CustomRoute;
