// Bibliotecas
import React, { Component } from "react";
// Recursos visuais.
import { Container } from "./styles.css";

export default class OrderItem extends Component {
  render() {
    const { children, style } = this.props;
    return (
      <Container className="inline-table" style={style || {}}>
        {children}
      </Container>
    );
  }
}
