// Bibliotecas
import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import { connect } from "react-redux";
// Componentes.
import CustomModal from "../../../../components/CustomModal";
import OrderScanModal from "../../OrderScanModal";
// Recursos visuais
import {
  ContentContainer,
  ButtonContainer,
  DescriptionContainer,
  HeaderContainer,
} from "../../../../components/CustomConfirmationModal/styles.css";
// Funções
import { changeOrderState } from "../../../../functions/order";
import { showSnackbar } from "../../../../redux/actions";
// Dados estaticos.
import { states } from "../../../../static/orderStates";

class OrderWithdrawnModal extends Component {
  state = { orderScanModalOpen: false };

  /**
   * Método realizado quando é feito uma retirada com escaneamento
   */
  onWithdrawnWithScan = () => {
    this.setState({ orderScanModalOpen: true });
  };

  /**
   * Método realizado para fazer a retirada do pedido.
   */
  withdrawnOrder = () => {
    const { startLoading, selectedOrder, onClose, token } = this.props;
    // Inicia o carregamento
    startLoading();
    // Solicita mudança de estado.
    changeOrderState(
      selectedOrder.id,
      selectedOrder.profileId,
      states.ORDER_WITHDRAWN.index,
      token,
      null
    );
    // Fecha modal.
    onClose();
  };

  /**
   * Ação realizada ao ler pedido com sucesso.
   */
  onSuccess = ({ profileId = 0, orderId = 0 }) => {
    const { lang, user, selectedOrder } = this.props;
    if (user.selectedProfile.id === profileId && orderId === selectedOrder.id) {
      // VAlida os dados do pedido.
      this.setState({ orderScanModalOpen: false });
      this.withdrawnOrder();
      this.props.showSnackbar(lang.withdrawnSuccess, "success");
    } else {
      throw new Error("Pedido inválido");
    }
  };

  render() {
    const { lang, selectedOrder, open, onClose } = this.props;
    const { orderScanModalOpen } = this.state;
    if (!selectedOrder || Object.keys(selectedOrder).length === 0) {
      return true;
    }
    return (
      <>
        <CustomModal open={open} onClose={onClose} style={{ width: "450px" }}>
          <div>
            <HeaderContainer>
              <Typography className="title" variant="h5">
                {lang.orderNumber.replace("@orderId", selectedOrder.id)}
              </Typography>
            </HeaderContainer>

            <ContentContainer>
              <Typography variant="h6">
                {lang.orderWithdrawnModalTitle.replace(
                  "@userName",
                  selectedOrder.userName
                )}
              </Typography>
              <DescriptionContainer>
                <Typography variant="body1">
                  {lang.orderWithdrawnModalDescription}
                </Typography>
              </DescriptionContainer>
              <ButtonContainer className="flex justify-space-between">
                <Button
                  variant="contained"
                  className="withdrawal-btn"
                  color="primary"
                  onClick={this.withdrawnOrder}
                >
                  {lang.withdrawnLabel}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.onWithdrawnWithScan}
                >
                  {lang.orderWithdrawnModalConfirmationText}
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => {
                    console.log("Cancelado");
                    onClose();
                  }}
                >
                  {lang.cancelationText}
                </Button>
              </ButtonContainer>
            </ContentContainer>
          </div>
        </CustomModal>
        <OrderScanModal
          open={orderScanModalOpen}
          lang={lang}
          onClose={() => this.setState({ orderScanModalOpen: false })}
          onSuccess={(result) => this.onSuccess(result)}
        />
      </>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, { showSnackbar })(OrderWithdrawnModal);
