/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */

/**
 * Essa função testará se o arquivo de linguagem existe.
 * @param {String} pathToFile Caminho do arquivo que será realizado o import de teste.
 */
const tryRequire = pathToFile => {
  try {
    return require(`${pathToFile}`);
  } catch (err) {
    return null;
  }
};

/**
 * Função que realiza a verificação da linguagem do sistema.
 */
const lang = () => {
  const userLang = navigator.language;
  let systemLanguage;
  if (tryRequire(`./${userLang}`)) {
    systemLanguage = userLang;
  } else {
    systemLanguage = "pt-BR";
  }
  const finalPath = `./${systemLanguage}`;
  return require(`${finalPath}`);
};

export default lang();
