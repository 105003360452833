// Bibliotecas
import React, { Component } from "react";
import { Typography, IconButton, Collapse, Button } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { connect } from "react-redux";
// Componentes
import CustomModal from "../../../components/CustomModal";
import OrderItemList from "./OrderItemList";
// Recursos visuais.
import { ButtonContainer } from "../../../components/CustomConfirmationModal/styles.css";
import {
  OrderInfoItemContainer,
  HeaderContainer,
  TakehomeContainer,
  ContentContainer,
} from "./styles.css";

// FUncoes
import { showSnackbar } from "../../../redux/actions";

class OrderInfoModal extends Component {
  state = { collapseOpen: true };

  /**
   * Método que renderiza o desconto.
   */
  renderDiscount = () => {
    const { selectedOrder, lang } = this.props;
    if (
      selectedOrder &&
      selectedOrder.discount &&
      selectedOrder.discountPercentage &&
      selectedOrder.discount > 0 &&
      selectedOrder.discountPercentage > 0
    ) {
      const finalPrice = selectedOrder.totalPrice
        .toFixed(2)
        .replace(".", ",")
        .replace(/\d(?=(\d{3})+\.)/g, "$&.");
      return (
        <>
          <Typography
            variant="subtitle2"
            className="error-msg total-value-label"
          >
            {lang.discountOrderFinalPriceLabel
              .replace("@discountPercentage", selectedOrder.discountPercentage)
              .replace("@finalPrice", finalPrice)}
          </Typography>
        </>
      );
    }
    return true;
  };

  render() {
    const { selectedOrder, onClose, open, lang } = this.props;
    if (!selectedOrder || Object.keys(selectedOrder).length === 0) {
      return true;
    }
    return (
      <CustomModal open={open} onClose={onClose}>
        <div>
          <HeaderContainer className="flex-row justify-space-between align-items-center">
            <Typography className="title" variant="h5">
              {lang.orderNumberLabel.replace("@orderId", selectedOrder.id)}
            </Typography>
          </HeaderContainer>

          <ContentContainer>
            {selectedOrder.takeHome ? (
              <TakehomeContainer>
                <h2>Para viagem</h2>
              </TakehomeContainer>
            ) : (
              true
            )}

            <OrderInfoItemContainer>
              <Typography variant="subtitle2">
                {lang.orderTime}: {selectedOrder.dtCreated}
              </Typography>
            </OrderInfoItemContainer>

            <OrderInfoItemContainer>
              <Typography variant="subtitle2">
                {lang.orderStatus}: {selectedOrder.orderStatus.value}
              </Typography>
            </OrderInfoItemContainer>

            <OrderInfoItemContainer>
              <Typography variant="subtitle2">
                {lang.client}: {selectedOrder.userName}
              </Typography>
            </OrderInfoItemContainer>

            {selectedOrder.note ? (
              <OrderInfoItemContainer>
                <Typography variant="subtitle2">
                  {lang.observation}: {selectedOrder.note}
                </Typography>
              </OrderInfoItemContainer>
            ) : (
              ""
            )}

            <OrderInfoItemContainer>
              <Typography variant="subtitle2">
                {lang.orderItemsLabel}:
                <IconButton
                  variant="contained"
                  size="small"
                  onClick={() =>
                    this.setState({
                      collapseOpen: !this.state.collapseOpen,
                    })
                  }
                >
                  {this.state.collapseOpen ? (
                    <KeyboardArrowUp fontSize="small" />
                  ) : (
                    <KeyboardArrowDown fontSize="small" />
                  )}
                </IconButton>
              </Typography>
              <Collapse
                in={this.state.collapseOpen}
                timeout="auto"
                unmountOnExit
              >
                <OrderItemList selectedOrder={selectedOrder} lang={lang} />
              </Collapse>
            </OrderInfoItemContainer>

            <OrderInfoItemContainer>
              <Typography variant="subtitle2" className="total-value-label">
                {lang.orderValueLabel}: {lang.currencyType}
                {selectedOrder.price}
              </Typography>
            </OrderInfoItemContainer>
            <OrderInfoItemContainer>
              {this.renderDiscount()}
            </OrderInfoItemContainer>

            <ButtonContainer className="flex justify-evenly">
              <Button variant="contained" color="primary" onClick={onClose}>
                {lang.close}
              </Button>
            </ButtonContainer>
          </ContentContainer>
        </div>
      </CustomModal>
    );
  }
}

export default connect(null, { showSnackbar })(OrderInfoModal);
