/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  TableCell,
  TextField,
  Switch,
  Button,
  FormControlLabel,
  IconButton,
  Grid,
  Typography,
  LinearProgress,
  MenuItem,
  FormControl,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Modal,
} from "@material-ui/core";
import axios from "axios";

import { Add, Remove, Delete } from "@material-ui/icons";

import CreatableSelect from "react-select/creatable";

import {
  DatePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from "material-ui-pickers";

import DateFnsUtils from "@date-io/date-fns";
import { checkEmail, checkPhone } from "../../utils/regex";

import api from "../../utils/API";
// Funções e dados.
import {
  PROFILE_API_BASE,
  PROFILE_LOCATION,
  QUERY_ACCESS,
  URL_AMAZON,
  ORDER_AMAZON,
  ORDER_LOCATION,
} from "../../static/URL";
import {
  USER_SELECTED_PROFILE,
  USER_AUTH_TOKEN,
} from "../../static/LocalStorageTags";
import { getItem } from "../../utils/localStorage";

// Componentes.
import CustomTooltip from "../../components/CustomTooltip";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";
import CustomModal from "../../components/CustomModal";
import CustomMaskedInput from "../../components/CustomMaskedInput";
import { loadProducts } from "../../functions/products";

import { PHONE_MASK } from "../../utils/masks";
// Recursos visuais.
import {
  CustomHeaderContainer,
  PrimaryGrid,
  SecondGrid,
  ModalWrapper,
  StyledModalShadownMask,
  StyleModalBody,
  StyleModalHeader,
  StyleModalInnerContent,
  FlexButtonAdd,
} from "./styles.css";

// eslint-disable-next-line import/no-dynamic-require
const { default: locale } = require(`date-fns/locale/${
  navigator.language ||
  navigator.language.split(/[-_]/)[0] ||
  navigator.userLanguage.split(/[-_]/)[0] ||
  "pt-BR"
}`);

class StoreOrder extends Component {
  state = {
    collapseOpen: true,
    openLoadingModal: false,
    openErrorModal: false,
    openSuccessModal: false,
    // Table
    products: [],
    productsSelected: {},
    productsItem: [],
    open: false,
    selected: [],
    order: "asc",
    orderBy: "withdrawalDates",
    page: 0,
    rowsPerPage: 10,
    // SideModal
    imageStatus: "loading",
    sideModalOpen: false,
    id: undefined,
    name: "",
    nameError: false,
    nameShowTooltip: false,
    nameTooltipMessage: "",
    email: undefined,
    emailError: false,
    emailShowTooltip: false,
    emailTooltipMessage: "",
    phoneNumber: undefined,
    phoneNumberError: false,
    phoneNumberShowTooltip: false,
    phoneNumberTooltipMessage: "",
    note: "",
    noteError: false,
    noteShowTooltip: false,
    noteTooltipMessage: "",
    price: undefined,
    priceError: false,
    priceShowTooltip: false,
    priceTooltipMessage: "",
    productsError: false,
    productsShowTooltip: false,
    productsTooltipMessage: "",
    code: "",
    requestDate: "",
    withdrawalDateError: false,
    withdrawalDateShowTooltip: false,
    withdrawalDateTooltipMessage: "",
    withdrawalDate: null,
    withdrawalTime: null,
    withdrawalDates: null,
    withdrawalTimes: null,
    withdrawalTimeDateError: false,
    withdrawalTimeShowTooltip: false,
    withdrawalTimeTooltipMessage: "",
    paymentError: false,
    paymentShowTooltip: false,
    paymentTooltipMessage: "",
    discount: 0,
    total: undefined,
    isPayment: false,
    items: [],
    client: {},
    payment: {},
    createByUserId: undefined,
    profileId: undefined,
    profileName: "",
    categories: [],
    selectOptions: [],
    value: "",
    inputValue: "",
    selectedID: "",
    visibleValue: "",
    category: {},
    modalShow: false,
    isLoading: false,
    selectItem: [],
    clicks: 1,
    show: true,
    selectedPrice: 0,
    schedules: [],
    mensagemError: "",
  };

  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.loadProducts();
    this.loadSchedules();
  }

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user.selectedProfile) {
      return this.setState({ productsItem: [] });
    }
    if (
      (!this.props.user.selectedProfile && nextProps.user.selectedProfile) ||
      (nextProps.user.selectedProfile &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id !==
          nextProps.user.selectedProfile.id)
    ) {
      this.loadProducts();
      this.loadSchedules();
    }
  }

  handleChange = async (event) => {
    const newDate = new Date().toLocaleDateString().split("/").join("/");
    await this.setState({
      selectedID: event.currentTarget.id,
      visibleValue: event.target.value,
    });
    await this.setState({
      payment: {
        method: this.state.selectedID ? this.state.selectedID : undefined,
        date: newDate || "",
      },
    });
  };

  /*
   * Método que carega todas os produto cadastrados∂.
   */
  loadProducts = async () => {
    const userToken = getItem(USER_AUTH_TOKEN);
    const profileId = await this.getUserProfile();
    const productsItem = await loadProducts(profileId, userToken);
    await this.setState({ productsItem });
  };

  // Carrega os agendamentos
  loadSchedules = async () => {
    const { data: schedules } = await axios.get(
      URL_AMAZON + ORDER_AMAZON + ORDER_LOCATION
    );
    await this.setState({ schedules });
  };

  // Retorna o UserProfile
  getUserProfile = async () => {
    const storageProfile = getItem(USER_SELECTED_PROFILE);
    if (storageProfile) {
      return JSON.parse(storageProfile).id;
    }
    const { user } = this.props;
    if (user && user.selectedProfile) {
      return user.selectedProfile.id;
    }
    const userToken = getItem(USER_AUTH_TOKEN);
    const { data: me } = await api.get(
      `${PROFILE_API_BASE + PROFILE_LOCATION}/me?${QUERY_ACCESS + userToken}`
    );
    const lastDataMe = me.sort(
      (a, b) => new Date(b.dtCreated) - new Date(a.dtCreated)
    );
    return lastDataMe[0].id;
  };

  renderScheduleLinkGenerator = () => {
    return (
      <Button
        onClick={() => this.setState({ showModal: true })}
        color="primary"
      >
        {this.props.lang.generateNewScheduling}
      </Button>
    );
  };

  close = async () => {
    await this.setState({ showModal: false });
  };

  handleHourChange = (date) => {
    this.setState({ withdrawalTimes: date });
  };

  handleDateChange = (date) => {
    this.setState({ withdrawalDates: date });
  };

  /*
   * Método que verifica a mudança de statos dos items.
   * @param {newValue} novos valores selecionado.
   * @param {actionMeta} retorna ação de adicionar e deletar.
   */
  onChange = async (newValue) => {
    const { items } = this.state;
    await this.setState({
      productsSelected: {
        id: newValue.id ? newValue.id : undefined,
        name: newValue.name ? newValue.name : "",
        price: newValue.price ? newValue.price : 0,
        quantity: 1,
      },
    });

    let count = false;
    if (items && items.length > 0) {
      items.forEach((product) => {
        if (product.id === newValue.id) {
          // Muda a quantida do produto para + 1.
          product.quantity += 1;
          count = true;
        }
      });
    }
    if (!count) {
      items.push(this.state.productsSelected);
    }

    this.setState({ items });
    this.calculateTotal();
  };

  IncrementItem = (p) => {
    this.setState({ clicks: p.quantity });
    const quantity = this.state.clicks + 1;

    const { items } = this.state;
    // Percorre o array de produtos para encontrar o produto selecionado
    items.forEach((product) => {
      if (product.id === p.id) {
        // Muda o estado do produto para o selecionado.
        product.quantity = quantity;
      }
    });

    // Atualiza o estado.
    this.setState({ items });
    this.setState({ clicks: quantity });
    this.calculateTotal();
  };

  DecreaseItem = async (p) => {
    this.setState({ clicks: p.quantity });
    const quantity = this.state.clicks - 1;
    if (quantity >= 1) {
      const { items } = this.state;
      // Percorre o array de produtos para encontrar o produto selecionado
      items.forEach((product) => {
        if (product.id === p.id) {
          // Muda o estado do produto para o selecionado.
          product.quantity = quantity;
        }
      });

      // Atualiza o estado.
      this.setState({ items });
      this.setState({ clicks: quantity });
      this.calculateTotal();
    }
  };

  /**
   * Metodo que calcula o total da compra atual.
   */
  calculateTotal = () => {
    let total = 0;
    try {
      for (let i = 0; i < this.state.items.length; i++) {
        total += this.state.items[i].price * this.state.items[i].quantity;
      }
    } catch (error) {
      console.log(`1 - Products - calculateTotal: ${error}`);
    }

    this.setState({ total });
  };

  /**
   * Metodo que renderiza o preco, este metodo deve ser alterado
   * para gerar preco de acordo com a localidade.
   * @param {*} price Preco no formato double do produto.
   */
  renderPrice = (price) => {
    let newPrice = "0,00";

    try {
      // Valida se a variavel esta vazia.
      if (price) {
        // Formata o valor.
        newPrice = String(
          price
            .toFixed(2)
            .replace(".", ",")
            .replace(/\d(?=(\d{3})+\.)/g, "$&.")
        );
      }
    } catch (error) {
      console.log(`ProductsShoppingCart - renderPrice: ${error}`);
    }
    // Retorna componente com o valor ja estilizado.
    return newPrice;
  };

  // Renderiza a modal de deletar categorias
  deleteItem = (index) => {
    const { items } = this.state;
    for (let i = 0; i < items.length; i++) {
      if (items[i].id === index) {
        items.splice(i, 1);
      }
    }
    this.setState({ items });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
    });
    this.setState({
      collapseOpen: true,
      openLoadingModal: false,
      openErrorModal: false,
      openSuccessModal: false,
      // Table
      products: [],
      productsSelected: {},
      open: false,
      selected: [],
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      email: undefined,
      emailError: false,
      emailShowTooltip: false,
      emailTooltipMessage: "",
      phoneNumber: undefined,
      phoneNumberError: false,
      phoneNumberShowTooltip: false,
      phoneNumberTooltipMessage: "",
      note: "",
      noteError: false,
      noteShowTooltip: false,
      noteTooltipMessage: "",
      price: undefined,
      priceError: false,
      priceShowTooltip: false,
      priceTooltipMessage: "",
      productsError: false,
      productsShowTooltip: false,
      productsTooltipMessage: "",
      code: "",
      requestDate: "",
      withdrawalDateError: false,
      withdrawalDateShowTooltip: false,
      withdrawalDateTooltipMessage: "",
      withdrawalDate: null,
      withdrawalTime: null,
      withdrawalDates: null,
      withdrawalTimes: null,
      withdrawalTimeDateError: false,
      withdrawalTimeShowTooltip: false,
      withdrawalTimeTooltipMessage: "",
      paymentError: false,
      paymentShowTooltip: false,
      paymentTooltipMessage: "",
      discount: 0,
      total: undefined,
      isPayment: false,
      items: [],
      client: {},
      payment: {},
      createByUserId: undefined,
      profileId: undefined,
      profileName: "",
      selectOptions: [],
      value: "",
      inputValue: "",
      selectedID: "",
      visibleValue: "",
      modalShow: false,
      isLoading: false,
      selectItem: [],
      clicks: 1,
      show: true,
      selectedPrice: 0,
    });
  };

  renderModalSchedulling = () => {
    const { lang } = this.props;
    return (
      <>
        <Modal open={this.state.showModal} onClose={this.handleCloseModal}>
          <ModalWrapper>
            <StyledModalShadownMask>
              <StyleModalInnerContent>
                <StyleModalHeader>
                  <div style={{ marginLeft: "10px" }}>{lang.scheduling}</div>
                </StyleModalHeader>
                <StyleModalBody>
                  <PrimaryGrid>
                    <Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomTooltip
                          onClose={() =>
                            this.setState({
                              nameShowTooltip: false,
                              nameError: false,
                            })
                          }
                          open={this.state.nameShowTooltip}
                          title={this.state.nameTooltipMessage}
                        >
                          <TextField
                            className="input-margin width-90"
                            required
                            label="Nome"
                            error={this.state.nameError}
                            value={this.state.name}
                            onChange={(e) =>
                              this.setState({ name: e.target.value })
                            }
                          />
                        </CustomTooltip>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomTooltip
                          onClose={() =>
                            this.setState({
                              emailShowTooltip: false,
                              emailError: false,
                            })
                          }
                          open={this.state.emailShowTooltip}
                          title={this.state.emailTooltipMessage}
                        >
                          <TextField
                            className="input-margin width-90"
                            required
                            label="E-mail"
                            error={this.state.emailError}
                            value={this.state.email}
                            onChange={(e) =>
                              this.setState({ email: e.target.value })
                            }
                          />
                        </CustomTooltip>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomTooltip
                          onClose={() =>
                            this.setState({
                              phoneNumberShowTooltip: false,
                              phoneNumberError: false,
                            })
                          }
                          open={this.state.phoneNumberShowTooltip}
                          title={this.state.phoneNumberTooltipMessage}
                        >
                          <CustomMaskedInput
                            formControllClassname="input-margin width-90"
                            required
                            value={this.state.phoneNumber}
                            onChange={(phoneNumber) =>
                              this.setState({ phoneNumber })
                            }
                            id="phone"
                            label={this.props.lang.phone}
                            error={this.state.phoneNumberError}
                            mask={PHONE_MASK}
                          />
                        </CustomTooltip>
                      </Grid>

                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        locale={locale}
                      >
                        <Grid item xs={12} sm={6}>
                          <CustomTooltip
                            onClose={() =>
                              this.setState({
                                withdrawalDateShowTooltip: false,
                                withdrawalDateError: false,
                              })
                            }
                            open={this.state.withdrawalDateShowTooltip}
                            title={this.state.withdrawalDateTooltipMessage}
                          >
                            {!this.state.id ? (
                              <DatePicker
                                autoOk
                                clearable
                                margin="normal"
                                id="date-picker-dialog"
                                label="Date Retirada"
                                format="dd/MM/yyyy"
                                className="filter"
                                value={this.state.withdrawalDates}
                                style={{ marginLeft: "10px" }}
                                onChange={this.handleDateChange}
                                minDate={new Date()}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            ) : (
                              <TextField
                                className="input-margin width-90"
                                required
                                label="Date Retirada"
                                value={this.state.withdrawalDates}
                              />
                            )}
                          </CustomTooltip>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <CustomTooltip
                            onClose={() =>
                              this.setState({
                                withdrawalTimeShowTooltip: false,
                                withdrawalTimeError: false,
                              })
                            }
                            open={this.state.withdrawalTimeShowTooltip}
                            title={this.state.withdrawalTimeTooltipMessage}
                          >
                            {!this.state.id ? (
                              <TimePicker
                                margin="normal"
                                id="time-picker"
                                label="Hora Retirada"
                                format="HH:mm"
                                minutesStep={5}
                                value={this.state.withdrawalTimes}
                                onChange={this.handleHourChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change time",
                                }}
                                style={{ marginLeft: "10px" }}
                              />
                            ) : (
                              <TextField
                                className="input-margin width-90"
                                required
                                label="Hora Retirada"
                                value={this.state.withdrawalTimes}
                              />
                            )}
                          </CustomTooltip>
                        </Grid>
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </PrimaryGrid>

                  <SecondGrid>
                    <Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomTooltip
                          onClose={() =>
                            this.setState({
                              productsShowTooltip: false,
                              productsError: false,
                            })
                          }
                          open={this.state.productsShowTooltip}
                          title={this.state.productsTooltipMessage}
                        >
                          <CreatableSelect
                            deleteRemoves={false}
                            isClearable
                            placeholder="Produtos"
                            options={this.state.productsItem}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.name}
                            getNewOptionData={(inputValue, optionLabel) => ({
                              id: inputValue,
                              name: optionLabel,
                            })}
                            onChange={this.onChange}
                          />
                        </CustomTooltip>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>{lang.products}</TableCell>
                              <TableCell align="right">{lang.price}</TableCell>
                              <TableCell align="right">
                                {lang.quantity}
                              </TableCell>
                              <TableCell align="right">{lang.total}</TableCell>
                              <TableCell align="right">{lang.delete}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.items.map((row) => (
                              <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell align="right">
                                  {this.renderPrice(row.price, "lightgrey", 14)}
                                </TableCell>
                                <TableCell
                                  align="right"
                                  style={{ padding: "6px" }}
                                >
                                  <FlexButtonAdd>
                                    <IconButton
                                      color="primary"
                                      aria-label="upload picture"
                                      component="span"
                                      onClick={() => this.DecreaseItem(row)}
                                    >
                                      <Remove />
                                    </IconButton>

                                    <h4>{row.quantity}</h4>

                                    <IconButton
                                      color="primary"
                                      aria-label="upload picture"
                                      component="span"
                                      onClick={() => this.IncrementItem(row)}
                                    >
                                      <Add />
                                    </IconButton>
                                  </FlexButtonAdd>
                                </TableCell>
                                <TableCell align="right">
                                  {this.renderPrice(
                                    row.quantity * row.price,
                                    "lightgrey",
                                    14
                                  )}
                                </TableCell>

                                <TableCell align="right">
                                  <IconButton
                                    onClick={() => {
                                      this.deleteItem(row.id);
                                    }}
                                    aria-label="Delete"
                                  >
                                    <Delete />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell rowSpan={3} />
                              <TableCell colSpan={2}>{lang.subTotal}</TableCell>
                              <TableCell align="right">
                                {!this.state.id
                                  ? this.renderPrice(
                                      this.state.total,
                                      "lightgrey",
                                      14
                                    )
                                  : this.state.total}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{lang.discount}</TableCell>
                              <TableCell align="right" />
                              <TableCell align="right">0</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell colSpan={2}>{lang.total}</TableCell>
                              <TableCell align="right">
                                {!this.state.id
                                  ? this.renderPrice(
                                      this.state.total,
                                      "lightgrey",
                                      14
                                    )
                                  : this.state.total}
                              </TableCell>{" "}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomTooltip
                          onClose={() =>
                            this.setState({
                              noteShowTooltip: false,
                              noteError: false,
                            })
                          }
                          open={this.state.noteShowTooltip}
                          title={this.state.noteTooltipMessage}
                        >
                          <TextField
                            className="input-margin width-100"
                            required
                            label="observação"
                            error={this.state.noteError}
                            value={this.state.note}
                            onChange={(e) =>
                              this.setState({ note: e.target.value })
                            }
                          />
                        </CustomTooltip>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <CustomTooltip
                          onClose={() =>
                            this.setState({
                              paymentShowTooltip: false,
                              paymentError: false,
                            })
                          }
                          open={this.state.paymentShowTooltip}
                          title={this.state.paymentTooltipMessage}
                        >
                          <FormControl fullWidth>
                            <TextField
                              select
                              margin="dense"
                              variant="outlined"
                              label="Forma Pagamento"
                              required
                              error={this.state.paymentError}
                              onChange={this.handleChange}
                              value={this.state.visibleValue}
                            >
                              <MenuItem
                                key="CreditCard"
                                value="CreditCard"
                                id="CreditCard"
                              >
                                Cartão
                              </MenuItem>
                              <MenuItem key="Money" value="Money" id="Money">
                                Dinheiro
                              </MenuItem>
                            </TextField>
                          </FormControl>
                        </CustomTooltip>
                      </Grid>

                      <FormControlLabel
                        control={
                          <Switch
                            checked={this.state.isPayment || false}
                            onChange={(e) =>
                              this.setState({ isPayment: e.target.checked })
                            }
                            color="primary"
                          />
                        }
                        label="Pago"
                      />
                    </Grid>
                  </SecondGrid>
                </StyleModalBody>
                <StyleModalHeader>
                  <Button
                    onClick={this.handleCloseModal}
                    style={{ marginRight: "5px" }}
                  >
                    <div style={{ color: "#fff" }}> {lang.cancel} </div>
                  </Button>
                  {!this.state.id && (
                    <Button
                      onClick={this.validateFormValues}
                      style={{ marginLeft: "5px" }}
                    >
                      <div style={{ color: "#fff" }}> {lang.save} </div>
                    </Button>
                  )}
                </StyleModalHeader>
              </StyleModalInnerContent>
            </StyledModalShadownMask>
          </ModalWrapper>
        </Modal>
      </>
    );
  };

  // eslint-disable-next-line consistent-return
  registerScheduleOrder = async () => {
    try {
      this.setState({ openLoadingModal: true });
      await this.setState({
        profileName: this.props.user.selectedProfile.display,
        profileId: this.props.user.selectedProfile.id,
        createByUserId: this.props.user.user.id,
        requestDate: new Date().toLocaleDateString().split("/").join("/"),
        client: {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phoneNumber,
        },
      });

      const hour =
        (new Date(this.state.withdrawalTimes).getHours() < 10 ? "0" : "") +
        new Date(this.state.withdrawalTimes).getHours();
      const min =
        (new Date(this.state.withdrawalTimes).getMinutes() < 10 ? "0" : "") +
        new Date(this.state.withdrawalTimes).getMinutes();
      const hoursMin = `${hour}:${min}`;
      await this.setState({ withdrawalTime: hoursMin });
      const withdrawalDateFormat = new Date(this.state.withdrawalDates)
        .toLocaleDateString()
        .split("/")
        .join("/");
      await this.setState({ withdrawalDate: withdrawalDateFormat });
      const {
        id,
        code,
        requestDate,
        withdrawalDate,
        withdrawalTime,
        price,
        discount,
        total,
        isPayment,
        note,
        items,
        client,
        payment,
        createByUserId,
        profileId,
        profileName,
      } = this.state;
      await axios.post(URL_AMAZON + ORDER_AMAZON + ORDER_LOCATION, {
        id,
        code,
        requestDate,
        withdrawalDate,
        withdrawalTime,
        price,
        discount,
        total,
        isPayment,
        note,
        items,
        client,
        payment,
        createByUserId,
        profileId,
        profileName,
      });

      this.setState({
        openLoadingModal: false,
        openSuccessModal: true,
        showModal: false,
      });
      await this.loadProducts();
      await this.loadSchedules();
      await this.handleCloseModal();
    } catch (error) {
      console.log({ ...error });
      const message = "Tente novamente, se o erro persistir, entre em contato!";
      this.setState({
        mensagemError: message,
        showModal: false,
        openErrorModal: true,
        openLoadingModal: false,
      });
      return false;
    }
  };

  customHeaderOptions = () => {
    return (
      <CustomHeaderContainer>
        {this.renderScheduleLinkGenerator()}
      </CustomHeaderContainer>
    );
  };

  // Renderiza a tabela de produtos
  renderTable = () => {
    const { lang } = this.props;
    const rows = [
      {
        id: "id",
        numeric: false,
        disablePadding: false,
        label: lang.id,
      },
      {
        id: "nomeCliente",
        numeric: false,
        disablePadding: false,
        label: lang.name,
      },
      {
        id: "dataPedidoRetirada",
        numeric: false,
        disablePadding: false,
        label: lang.dataHoraRetirada,
      },
      {
        id: "Total",
        numeric: false,
        disablePadding: false,
        label: lang.total,
      },
      {
        id: "note",
        numeric: false,
        disablePadding: false,
        label: lang.note,
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        customHeaderOptions={() => this.customHeaderOptions()}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onClick={this.onClickScheduleEdition}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        handleSelectAllClick={(e) => {
          if (e.target.checked) {
            this.setState((state) => ({
              selected: state.productsItem.map((n) => n.id),
            }));
            return;
          }
          this.setState({ selected: [] });
        }}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={this.state.schedules.map((p) => ({
          total: p.total
            .toFixed(2)
            .replace(".", ",")
            .replace("/d(?=(d{3})+.)g", "$&."),
          client: p.client,
          code: p.code,
          date: p.date,
          discount: p.discount,
          id: p.id,
          items: p.items,
          note: p.note,
          payment: p.payment,
          price: p.price,
          profileId: p.profileId,
          profileName: p.profileName,
          request: p.request,
          withdrawalDates: p.withdrawalDate,
          withdrawalTimes: p.withdrawalTime,
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell component="th" scope="row" padding="none">
                {n.id.substring(0, 4)}
              </TableCell>
              <TableCell>{n.client.name}</TableCell>
              <TableCell>
                {n.withdrawalDates} {n.withdrawalTimes}
              </TableCell>
              <TableCell>{n.total}</TableCell>
              <TableCell>{n.note}</TableCell>
            </>
          );
        }}
      />
    );
  };

  /**
   *
   * @param {Object} Schedule Objeto de agendamento.
   */
  onClickScheduleEdition = async (schedule) => {
    const { selected } = this.state;
    this.setState({
      selected: selected.filter((s) => s !== schedule.id),
      id: schedule.id,
      name: schedule.client.name,
      email: schedule.client.email,
      phoneNumber: schedule.client.phone,
      items: schedule.items,
      note: schedule.note,
      code: schedule.code,
      total: schedule.total,
      discount: schedule.price,
      withdrawalDate: schedule.withdrawalDates,
      withdrawalTime: schedule.withdrawalTimes,
      showModal: true,
      visibleValue: schedule.payment.method,
      selectedID: schedule.payment.method,
      withdrawalDates: schedule.withdrawalDates,
      withdrawalTimes: schedule.withdrawalTimes,
    });
  };

  // Método para validar os campos do formulário
  validateFormValues = () => {
    const {
      name,
      email,
      phoneNumber,
      withdrawalDates,
      withdrawalTimes,
      products,
      payment,
      items,
    } = this.state;
    const { lang } = this.props;
    let data = {};

    if (!name || name.trim().length === 0) {
      data = {
        ...data,
        nameShowTooltip: true,
        nameTooltipMessage: !name
          ? lang.nameRequiredError
          : lang.nameInvalidError,
        nameError: true,
      };
    }
    if (
      !email ||
      email.trim().length === 0 ||
      (email.trim().length > 0 && !checkEmail(email))
    ) {
      data = {
        ...data,
        emailShowTooltip: true,
        emailTooltipMessage: !email
          ? lang.emailRequiredError
          : lang.emailRequiredError,
        emailRequiredError: true,
      };
    }
    if (
      !phoneNumber ||
      phoneNumber.trim().length === 0 ||
      (phoneNumber.trim().length > 0 && !checkPhone(phoneNumber))
    ) {
      data = {
        ...data,
        phoneNumberShowTooltip: true,
        phoneNumberTooltipMessage: !phoneNumber
          ? lang.phoneRequiredError
          : lang.phoneInvalidError,
        phoneError: true,
      };
    }
    if (!withdrawalDates || withdrawalDates.toString().trim().length === 0) {
      data = {
        ...data,
        withdrawalDateShowTooltip: true,
        withdrawalDateTooltipMessage: !withdrawalDates
          ? lang.withdrawalDateRequiredError
          : lang.withdrawalDateInvalidError,
        withdrawalDateError: true,
      };
    }
    if (!withdrawalTimes || withdrawalTimes.toString().trim().length === 0) {
      data = {
        ...data,
        withdrawalTimeShowTooltip: true,
        withdrawalTimeTooltipMessage: !withdrawalTimes
          ? lang.withdrawalTimeRequiredError
          : lang.withdrawalTimeInvalidError,
        withdrawalTimeError: true,
      };
    }

    if (!items || items.length === 0) {
      data = {
        ...data,
        productsShowTooltip: true,
        productsTooltipMessage: !products
          ? lang.productsRequiredError
          : lang.productsInvalidError,
        productsError: true,
      };
    }

    if (!payment || Object.keys(payment).length === 0) {
      data = {
        ...data,
        paymentShowTooltip: true,
        paymentTooltipMessage: !payment
          ? lang.paymentRequiredError
          : lang.paymentInvalidError,
        paymentError: true,
      };
    }

    if (Object.keys(data).length > 0) {
      this.setState(data);
    } else {
      this.registerScheduleOrder();
    }
  };

  render() {
    const { lang } = this.props;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <CustomModal
          open={this.state.openLoadingModal}
          onClose={() => this.setState({ openLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">
              {!this.state.id
                ? lang.registerScheduleLoading
                : lang.editScheduleLoading}
            </Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomConfirmationModal
          title={
            !this.state.id
              ? lang.registerSuccessTitle
              : lang.registerSuccessTitle
          }
          open={this.state.openSuccessModal}
          subtitle={
            !this.state.id
              ? lang.registerSuccessSubTitle
              : lang.editSuccessSubTitle
          }
          description={
            !this.state.id
              ? lang.registerSuccessDescription
              : lang.editSuccessDescription
          }
          confirmationText={lang.registerSuccessConfirmationText}
          confirmationAction={() => this.setState({ openSuccessModal: false })}
          onClose={() => this.setState({ openSuccessModal: false })}
        />

        <CustomConfirmationModal
          title={lang.registerErrorTitle}
          open={this.state.openErrorModal}
          subtitle={lang.registerErrorSubTitle}
          description={lang.registerErrorDescription.replace(
            "@MSG",
            this.state.mensagemError
          )}
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() => this.setState({ openErrorModal: false })}
          onClose={() => this.setState({ openErrorModal: false })}
        />
        {this.renderTable()}
        {this.renderModalSchedulling()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(StoreOrder);
