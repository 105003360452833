import styled from "styled-components";

export const CardContainer = styled.div`
  @media only screen and (max-width: 600px) {
    width: 93%;
  }

  cursor: pointer;
  width: 270px;
  padding: 15px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 10px;

  h5,
  p {
    color: #fff;
  }
`;

export const CardListContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  overflow-x: auto;

  @media only screen and (max-width: 600px) {
    display: -webkit-inline-box;
  }
`;

export const Container = styled.div`
  overflow: auto;
  height: 100%;
  margin: ${({ fullScreen }) => (fullScreen ? "20" : "0")}px !important;
`;
