export const itemProductPage = {
  title: "Item do produto",
  itemProduct: "Item do Produto",
  sequence: "Sequência",
  price: "Preço",
  delete: "Deletar",
  createdAt: "Criado em",
  sideModalTitle: "Adicionar Item",
  sideModalTitleEdit: "Editar Item",
  save: "Salvar",
  cancel: "Cancelar",
  nameRequiredError: "Nome do item é obrigatório!",
  nameInvalidError: "Nome da item inválido!",
  priceRequiredError: "Preço do item é obrigatório!",
  priceInvalidError: "Preço da item inválido!",
  registerItemLoading: "Estamos cadastrando seu novo item",
  editItemLoading: "Estamos editando seu item",
  registerErrorTitle: "Erro",
  registerErrorSubTitle: "Não foi possível cadastrar o item!",
  editErrorSubTitle: "Não foi possível editar o item!",
  registerErrorDescription: "@MSG",
  editErrorDescription: "@MSG",
  registerErrorConfirmationText: "OK",
  registerSuccessTitle: "Sucesso",
  registerSuccessSubTitle: "Item criado com sucesso!",
  editSuccessSubTitle: "Item de Produto atualizado com sucesso!",
  registerSuccessDescription: "Agora o seu Item já está disponível no sistema.",
  registerSuccessConfirmationText: "OK",
  deleteItemLoading: "Estamos deletando seu item",
  itemLoading: "Estamos carregando seus items",

  enable: "Ativo",
  // Variaveis para a modal de rejeitar o pedido.
  deleteItemModalTitle: "Tem certeza que deseja deletar?",
  deletarItemModalDescription:
    "Ao deletar o item, ele será removido permanentemente.",
  deleteItemConfirmationText: "Deletar",
  // Variaveis mais aproveitadas.
  cancelationText: "Cancelar",
  deleteErrorTitle: "Erro",
  deleteErrorSubTitle: "Não foi possível deletar o item!",
  deleteErrorDescription: "@MSG",
  deleteErrorConfirmationText: "OK",
};
