import React, { Component } from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";

class CustomMaskedInput extends Component {
  render() {
    return (
      <FormControl
        fullWidth={this.props.fullWidth}
        className={this.props.formControllClassname}
        style={this.props.style}
        error={this.props.error}
      >
        <InputLabel htmlFor={this.props.id}>
          {this.props.label}
          {this.props.required ? " *" : null}
        </InputLabel>
        <Select
          className={this.props.className}
          value={this.props.value}
          onChange={e => this.props.onChange(e.target.value)}
          id={this.props.id}
          required={this.props.required || false}
          error={this.props.error || false}
        >
          {this.props.items &&
            this.props.items.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  }
}

export default CustomMaskedInput;
