import api from "../utils/API";
import { USER_API_BASE, LOGIN_LOCATION, STORE_LOCATION } from "../static/URL";
import {
  USER_ID,
  USER_AUTH_TOKEN,
  USER_AUTH_TOKEN_EXPIRATION,
  USER_TYPE_LOGIN,
} from "../static/LocalStorageTags";
import { setItem } from "../utils/localStorage";

/**
 * Método utilizado para realizar login na aplicação.
 */
export const loginUser = async (fbId, fbToken) => {
  try {
    // Cria objeto para realizar o login.
    const obj = { user: fbId, pass: fbToken };
    // Realiza a chamada.
    const { data } = await api.post(USER_API_BASE + LOGIN_LOCATION, obj);
    if (data && data.userId && data.accessToken && data.expiration) {
      setItem(USER_ID, data.userId);
      setItem(USER_AUTH_TOKEN, data.accessToken);
      setItem(USER_AUTH_TOKEN_EXPIRATION, data.expiration);
    }
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

/**
 * Método utilizado para realizar login com e-mail na aplicação.
 */
export const loginUserEmail = async (fbeUser, fbeToken) => {
  let response = {};
  let sucess = "";

  try {
    // Cria objeto para realizar o login.
    const obj = { user: fbeUser, pass: fbeToken };
    // Realiza a chamada.
    const { data } = await api.post(
      USER_API_BASE + LOGIN_LOCATION + STORE_LOCATION,
      obj
    );
    if (data && data.userId && data.accessToken && data.expiration) {
      setItem(USER_ID, data.userId);
      setItem(USER_AUTH_TOKEN, data.accessToken);
      setItem(USER_AUTH_TOKEN_EXPIRATION, data.expiration);
      setItem(USER_TYPE_LOGIN, "email");
    }

    sucess = true;
    // REtorna o token e os dados do usuario.
    response = { sucess };
  } catch (error) {
    const errorObj = { ...error };
    let message = "Erro ao efetuar o login!";
    sucess = false;
    if (errorObj.isAxiosError) {
      switch (errorObj.response.status) {
        case 400:
          message = "Requisição inválida.";
          break;
        case 401:
          message = "Acesso não autorizado!";
          break;
        case 422:
          message = errorObj.response.data.content;
          break;
        case 500:
          message = "Erro inesperado. Tente novamente em alguns minutos.";
          break;
        default:
          message = "Não foi possível efetuar o login!";
          break;
      }
    }
    response = {
      sucess,
      error: {
        message,
      },
    };
  }

  return response;
};
