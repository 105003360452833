// Biblioteca
import React, { Component } from "react";
import { Typography } from "@material-ui/core";
import { Trail } from "react-spring";
// Componentes
import OrderItem from "./OrderItem";
// Recursos visuais
import { Container, OrdersContainer, TitleContainer } from "./styles.css";

export default class Section extends Component {
  /**
   * Método para renderizar os componentes de poedidos, com sua logica separada.
   */
  renderOrders = () => {
    let returnValue;
    try {
      const { orders } = this.props;
      if (orders && orders.length > 0) {
        returnValue = (
          <Trail
            items={orders || []}
            keys={(item) => item.id || Math.random()}
            from={{ transform: "translate3d(0,-70px,0)" }}
            to={{ transform: "translate3d(0,0px,0)" }}
          >
            {(item) => (props) => (
              <OrderItem style={props} key={item.id}>
                <Typography variant="h1" className="text-system-color">
                  {item.id}
                </Typography>
              </OrderItem>
            )}
          </Trail>
        );
      }
    } catch (error) {
      console.log(error);
      returnValue = true;
    }
    return returnValue;
  };

  render() {
    const { title } = this.props;
    return (
      <Container>
        <TitleContainer>
          <Typography
            variant="h1"
            className={`text-system-color
            text-align-center`}
          >
            {title}
          </Typography>
        </TitleContainer>
        <OrdersContainer className="align-center text-align-center">
          {/* {orders.map(orderItem => (
            <OrderItem key={orderItem.id}>
              <Typography variant="h1" className="text-system-color">
                {orderItem.id}
              </Typography>
            </OrderItem>
          ))} */}
          {this.renderOrders()}
        </OrdersContainer>
      </Container>
    );
  }
}
