// Bibliotecas.
import React, { Component } from "react";
import { Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
// Recursos visuais
import "../style.css";
import { ButtonContainer, InputContainer } from "./styles.css";
// Funções.
import { CPF_MASK, PHONE_MASK } from "../../../utils/masks";
import { checkPhone } from "../../../utils/regex";
import { checkIfCPFIsValid } from "../../../utils/data";
import { changeData } from "../../../redux/actions";
// Componentes.
import CustomMaskedInput from "../../../components/CustomMaskedInput";
import CustomTooltip from "../../../components/CustomTooltip";

// Passo de cadastro de dados pessoais.
class Step2 extends Component {
  state = {
    name: undefined,
    nameShowTooltip: false,
    nameTooltipMessage: "",
    nameError: false,
    phoneNumber: undefined,
    phoneNumberShowTooltip: false,
    phoneNumberTooltipMessage: "",
    phoneNumberError: false,
    document: undefined,
    documentShowTooltip: false,
    documentTooltipMessage: "",
    documentError: false,
    email: undefined,
    emailShowTooltip: false,
    emailTooltipMessage: ""
  };

  /**
   * Método que valida a entrada de dados.
   */
  checkStep1Data = () => {
    let data = {};
    // Validar tamanho e dados dos campos.
    if (!this.props.name || this.props.name.trim().length === 0) {
      // Invalido
    }
    if (!this.props.email || this.props.email.trim().length === 0) {
      // Invalido
    }
    if (
      !this.props.phoneNumber ||
      (this.props.phoneNumber.trim().length > 0 &&
        !checkPhone(this.props.phoneNumber))
    ) {
      data = {
        ...data,
        phoneNumberShowTooltip: true,
        phoneNumberTooltipMessage: !this.props.phoneNumber
          ? this.props.lang.phoneRequiredError
          : this.props.lang.phoneInvalidError,
        phoneNumberError: true
      };
    }
    if (
      !this.props.document ||
      (this.props.document.trim().length > 0 &&
        !checkIfCPFIsValid(this.props.document))
    ) {
      data = {
        ...data,
        documentShowTooltip: true,
        documentTooltipMessage: !this.props.document
          ? this.props.lang.documentRequiredError
          : this.props.lang.documentInvalidError,
        documentError: true
      };
    }
    if (Object.keys(data).length > 0) {
      this.setState(data);
    } else {
      this.props.onSuccess();
    }
  };

  render() {
    return (
      <div
        className={`flex-column
      align-items-center
      shadow-2
      input-div-container
      register-container justify-center`}
      >
        <img src={this.props.fbUrlImage} alt="profile" />
        <InputContainer>
          <TextField
            required
            fullWidth
            id="name"
            label={this.props.lang.name}
            disabled={this.props.name && this.props.name.trim().length > 0}
            value={this.props.name}
            onChange={e => this.setState({ name: e.target.value })}
          />
        </InputContainer>

        <InputContainer>
          <TextField
            required
            fullWidth
            id="email"
            label={this.props.lang.email}
            type="email"
            disabled={this.props.email && this.props.email.trim().length > 0}
            value={this.props.email}
            onChange={e => this.setState({ email: e.target.value })}
          />
        </InputContainer>

        <InputContainer>
          <CustomTooltip
            onClose={() =>
              this.setState({
                phoneNumberShowTooltip: false,
                phoneNumberError: false
              })
            }
            open={this.state.phoneNumberShowTooltip}
            title={this.state.phoneNumberTooltipMessage}
          >
            <CustomMaskedInput
              required
              fullWidth
              value={this.props.phoneNumber}
              onChange={phoneNumber =>
                this.props.changeData("phoneNumber", phoneNumber)
              }
              id="phoneNumber"
              error={this.state.phoneNumberError}
              label={this.props.lang.phoneNumber}
              mask={PHONE_MASK}
            />
          </CustomTooltip>
        </InputContainer>

        <InputContainer>
          <CustomTooltip
            onClose={() =>
              this.setState({
                documentShowTooltip: false,
                documentError: false
              })
            }
            open={this.state.documentShowTooltip}
            title={this.state.documentTooltipMessage}
          >
            <CustomMaskedInput
              required
              fullWidth
              value={this.props.document}
              onChange={document => this.props.changeData("document", document)}
              id="document"
              label={this.props.lang.document}
              error={this.state.documentError}
              mask={CPF_MASK}
              inputProps={{ minLength: 16 }}
            />
          </CustomTooltip>
        </InputContainer>
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.checkStep1Data()}
          >
            {this.props.lang.continue}
          </Button>
          <Button onClick={() => this.props.back()} className="back-button">
            {this.props.lang.goBack}
          </Button>
        </ButtonContainer>
      </div>
    );
  }
}

function mapStateToProps({ register }) {
  try {
    return {
      ...register
    };
  } catch (error) {
    console.log(`Register - mapStateToProps: ${error}`);
    return {};
  }
}

export default connect(mapStateToProps, { changeData })(Step2);
