import React, { Component } from "react";
import { connect } from "react-redux";
import clsx from "clsx";
import { withStyles } from "@material-ui/core/styles";
import {
  Drawer,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import CustomAppBar from "../CustomAppBar";
import ScreensPerPermissions from "../../pages";
import { storeUserRoles } from "../../redux/actions";

const drawerWidth = 240;

const styles = (theme) => ({
  root: {
    display: "flex",
    height: "100%",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing.unit * 7 + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing.unit * 9 + 1,
    },
    boxShadow:
      "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    // overflow: "hidden",
    padding: theme.spacing.unit * 3,
    height: "100%",
  },
});
let menus = {};
class CustomDrawerContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      Screens: {},
    };
    this.ScreensPerPermissions();
  }

  componentDidMount() {
    this.ScreensPerPermissions();
  }

  ScreensPerPermissions = () => {
    menus = ScreensPerPermissions(this.props.user.permissions);
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  checkSelectedScreen = () => {
    let selectedScreen = { text: "" };

    const keys = Object.keys(menus);
    for (let i = 0; i < keys.length; i++) {
      const ScreenListName = keys[i];
      for (let j = 0; j < menus[ScreenListName].length; j++) {
        const screen = menus[ScreenListName][j];
        if (screen.name === this.props.name) {
          selectedScreen = screen;
          break;
        }
      }
      if (selectedScreen) {
        break;
      }
    }
    return selectedScreen;
  };

  render() {
    const { classes, theme } = this.props;
    const selectedScreen = this.checkSelectedScreen();

    return (
      <div className={classes.root}>
        <CustomAppBar
          classesDrawer={classes}
          title={selectedScreen.title}
          open={this.state.open}
          handleDrawerOpen={this.handleDrawerOpen}
          history={this.props.history}
        />
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
            </IconButton>
          </div>
          <Divider />
          <List>
            {menus.firstScreens.map((screen, i) => (
              <ListItem
                title={screen.title}
                selected={this.props.name === screen.name}
                onClick={() => this.props.history.push(screen.url)}
                button
                key={i}
              >
                <ListItemIcon>
                  <screen.icon />
                </ListItemIcon>
                <ListItemText primary={screen.title} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {menus.secondaryScreens.map((screen, i) => (
              <ListItem
                title={screen.title}
                selected={this.props.name === screen.name}
                onClick={() => this.props.history.push(screen.url)}
                button
                key={i}
              >
                <ListItemIcon>
                  <screen.icon />
                </ListItemIcon>
                <ListItemText primary={screen.title} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {menus.thirdScreens.map((screen, i) => (
              <ListItem
                title={screen.title}
                selected={this.props.name === screen.name}
                onClick={() => this.props.history.push(screen.url)}
                button
                key={i}
              >
                <ListItemIcon>
                  <screen.icon />
                </ListItemIcon>
                <ListItemText primary={screen.title} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {this.props.children}
        </main>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, {
  storeUserRoles,
})(withStyles(styles, { withTheme: true })(CustomDrawerContainer));
