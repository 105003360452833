import React, { Component } from "react";
import { Snackbar } from "@material-ui/core";
import CustomSnackbarContent from "./CustomSnackbarContent";

class CustomSnackbar extends Component {
  render() {
    const {
      open,
      autoHideDuration,
      onClose,
      message,
      variant,
      vertical,
      horizontal
    } = this.props;
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: vertical || "bottom",
            horizontal: horizontal || "left"
          }}
          open={open}
          autoHideDuration={autoHideDuration || 6000}
          onClose={onClose}
        >
          <CustomSnackbarContent
            onClose={onClose}
            variant={variant || "info"}
            message={message || ""}
          />
        </Snackbar>
        {/*
          variant="error"
          variant="warning"
          variant="info"
          variant="success"
         */}
      </div>
    );
  }
}

export default CustomSnackbar;
