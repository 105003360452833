import {
  PROFILE_API_BASE,
  PLACE_LOCATION,
  QUERY_ACCESS,
  ME_LOCATION,
} from "../static/URL";
// Bibliotecas de utilidade.
import api from "../utils/API";

/**
 * Método para carregar os locais.
 *
 * @param {String} token token de autenticação
 */
export const loadPlaces = async (token) => {
  try {
    const { data: places } = await api.get(
      `${PROFILE_API_BASE + PLACE_LOCATION + ME_LOCATION}?${
        QUERY_ACCESS + token
      }`
    );
    return places;
  } catch (error) {
    console.log({ ...error });
    return [];
  }
};
