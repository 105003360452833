export const settingsItemsPage = {
  title: "Configuração do Produto",
  config: "Nome da configuração",
  sequence: "Sequência",
  maximum: "máximo",
  minimum: "mínimo",
  configType: "Configurações do Item",
  additional: "Adicional",
  products: "produtos",
  mandatory: "Obrigatório",
  delete: "Deletar",
  createdAt: "Criado em",
  sideModalTitle: "Adicionar Configuração do Produto",
  sideModalTitleEdit: "Editar Configuração do Produto",
  qtda: "Quantidade do Item",
  price: "Preço do Item",
  save: "Salvar",
  cancel: "Cancelar",
  nameRequiredError: "Nome da configuração é obrigatório!",
  nameInvalidError: "Nome da configuração inválido!",
  seqRequiredError: "Sequencia da  configuração é obrigatório!",
  seqInvalidError: "Sequencia da configuração inválido!",
  minRequiredError: "O minimo da configuração é obrigatório!",
  minInvalidError: "Minimo da configuração inválido!",
  maxRequiredError: "Maximo da configuração é obrigatório!",
  maxInvalidError: "Maximo da configuração inválido!",
  minmaxInvalidError: "O valor minimo não pode ser maior que o máximo",
  configTypeRequiredError: "Tipo da configuração é obrigatório!",
  configTypeInvalidError: "Tipo da configuração inválido!",
  itemRequiredError: "O item é obrigatório!",
  itemInvalidError: "Item  inválido!",
  productsNameRequiredError: "Selecionar o produto é obrigatório!",
  productsNameInvalidError: "Produto inválido!",
  registerItemLoading: "Estamos cadastrando seu novo item",
  editItemLoading: "Estamos editando seu item",
  registerErrorTitle: "Erro",
  registerErrorSubTitle: "Não foi possível cadastrar o item!",
  editErrorSubTitle: "Não foi possível editar o item!",
  registerErrorDescription: "@MSG",
  editErrorDescription: "@MSG",
  registerErrorConfirmationText: "OK",
  registerSuccessTitle: "Sucesso",
  registerSuccessSubTitle: "Item criado com sucesso!",
  editSuccessSubTitle: "Item de Produto atualizado com sucesso!",
  registerSuccessDescription: "Agora o seu Item já está disponível no sistema.",
  registerSuccessConfirmationText: "OK",
  deleteItemLoading: "Estamos deletando seu item",
  itemLoading: "Estamos carregando seus items",
  itemTitle: "Item",
  enable: "Ativo",
  // Variaveis para a modal de rejeitar o pedido.
  deleteItemModalTitle: "Tem certeza que deseja deletar?",
  deletarItemModalDescription:
    "Ao deletar a configuração, ele será removido permanentemente.",
  deleteItemConfirmationText: "Deletar",
  // Variaveis mais aproveitadas.
  cancelationText: "Cancelar",
  deleteErrorTitle: "Erro",
  deleteErrorSubTitle: "Não foi possível deletar a configuração!",
  deleteErrorDescription: "@MSG",
  deleteErrorConfirmationText: "OK",
  ok: "Ok",
};
