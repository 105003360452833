/* eslint-disable consistent-return */
import { cypher, decypher } from "./hash";
import env from "../config/keys";

/**
 * Método criado para salvar um item no storage local com criptografia.
 *
 * @param {String} key Chave para criptografia.
 * @param {String} value Valor que será criptografado.
 */
export const setItem = (key, value) => {
  if (!localStorage) {
    throw new Error("No local Storage");
  }
  // Criptografa a chave.
  const encryptedKey = cypher({
    key: env.REACT_APP_STORAGE_CRYPTO_KEY,
    iv: env.REACT_APP_STORAGE_CRYPTO_IV,
    text: key,
  });
  let encryptedValue = null;
  if (value && value !== null && value !== undefined) {
    // Criptografa o valor.
    encryptedValue = cypher({
      key: env.REACT_APP_STORAGE_CRYPTO_KEY,
      iv: env.REACT_APP_STORAGE_CRYPTO_IV,
      text: typeof value === "string" ? value : JSON.stringify(value),
    });
    // Salva no banco local dados criptografados.
    localStorage.setItem(encryptedKey, encryptedValue);
  }
};

/**
 * Método criado para buscar um objeto que foi salvo no storage local com criptografia.
 *
 * @param {String} key chave do item que deseja buscar.
 *
 * @returns {String} Retorna o valor no formato string que foi salvo no banco local.
 */
export const getItem = (key) => {
  if (!localStorage) {
    throw new Error("No local Storage");
  }
  // Criptografa a chave.
  const encryptedKey = cypher({
    key: env.REACT_APP_STORAGE_CRYPTO_KEY,
    iv: env.REACT_APP_STORAGE_CRYPTO_IV,
    text: key,
  });
  // Busca no banco local.
  const value = localStorage.getItem(encryptedKey);
  if (value && value !== null && value !== undefined) {
    // Decriptografa o resultado.
    const decypheredValue = decypher({
      key: env.REACT_APP_STORAGE_CRYPTO_KEY,
      iv: env.REACT_APP_STORAGE_CRYPTO_IV,
      text: value,
    });
    // Retorna o resultado.
    return decypheredValue;
  }
  return null;
};

/**
 * Método criado para buscar todas as chaves do banco local.
 *
 * @returns {Array} Retorna um array com todas as chaves decriptografadas.
 */
export const getAllKeys = () => {
  if (!localStorage) {
    throw new Error("No local Storage");
  }
  // Busca todas as chaves.
  const keys = Object.keys({ ...localStorage });
  // Percorre o array decriptografando as chaves e retorna o novo array.
  return keys.map((key) =>
    decypher({
      key: env.REACT_APP_STORAGE_CRYPTO_KEY,
      iv: env.REACT_APP_STORAGE_CRYPTO_IV,
      text: key,
    })
  );
};

/**
 * Método criado para remover itens do storage local.
 *
 * @param {String} key Chave para criptografia.
 */
export const removeKey = (key) => {
  if (!localStorage) {
    throw new Error("No local Storage");
  }
  // Criptografa a chave.
  const encryptedKey = cypher({
    key: env.REACT_APP_STORAGE_CRYPTO_KEY,
    iv: env.REACT_APP_STORAGE_CRYPTO_IV,
    text: key,
  });
  localStorage.removeItem(encryptedKey);
};

/**
 * Método para limpar o storage local.
 */
export const clearStorage = () => {
  localStorage.clear();
};
