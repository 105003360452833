import { combineReducers } from "redux";
import user from "./user_reducer";
import register from "./register_reducer";
import order from "./order_reducer";

export default combineReducers({
  user,
  register,
  order
});
