/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";

// Recursos visuais.
import "./style.css";

class NotAllowed extends Component {
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <h1>403 Acesso não autorizado!</h1>
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(NotAllowed);
