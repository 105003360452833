export const userPDVPage = {
  title: "Usuários",
  name: "Nome",
  login: "Login",
  email: "E-mail",
  role: "Perfil",
  providerId: "Identificador",
  profileName: "Nome Empresa",
  createdAt: "Criado em",
  sideModalTitle: "Adicionar Usuário",
  sideModalTitleEdit: "Editar usuário",
  save: "Salvar",
  cancel: "Cancelar",
  nameRequiredError: "Nome é obrigatório!",
  nameInvalidError: "Nome  inválido!",
  loginRequiredError: "Login é obrigatória!",
  loginInvalidError: "Login inválida!",
  emailRequiredError: "E-mail é obrigatório!",
  emailInvalidError: "E-mail inválido!",
  roleRequiredError: "Perfil é obrigatório!",
  roleInvalidError: "Perfil inválido!",
  providerIdRequiredError: "Identificador é obrigatorio",
  providerIdInvalidError: "Identificador inválida",
  profileNameRequiredError: "Nome da empresa é obrigatório!",
  profileNameInvalidError: "Nome da empresa inválido!",
  registerCategoryLoading: "Estamos cadastrando seu novo usuário",
  editCategoryLoading: "Estamos editando seu usuário",
  registerErrorTitle: "Erro",
  registerErrorSubTitle: "Não foi possível cadastrar usuário!",
  editErrorSubTitle: "Não foi possível editar usuário!",
  registerErrorDescription:
    "Tente novamente, se o erro persistir, entre em contato!",
  registerErrorConfirmationText: "OK",
  registerSuccessTitle: "Sucesso",
  registerSuccessSubTitle: "Usuário cadastrada com sucesso!",
  editSuccessSubTitle: "Usuário editada com sucesso!",
  registerSuccessDescription:
    "Agora a seu Usuário já está disponível no sistema.",
  registerSuccessConfirmationText: "OK",
  categoryLoading: "Estamos carregando seu usuário",
  enable: "Ativo",
  // Variaveis mais aproveitadas.
  cancelationText: "Cancelar",
  storeManager: "Gerente",
  storePdv: "Frente de Loja",
  storeProducts: "Acesso Prdutos",
  storeOrder: "Realizar Agendamentos",
};
