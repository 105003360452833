export const settingsPage = {
  title: "Configurações",
  name: "Nome",
  documentOwner: "CPF",
  email: "E-mail notificação",
  physicalProduct: "Produto físico",
  autoWithdraw: "Retirada automática",
  autoAdvance: "Avanço automático",
  bankSlip: "Boleto bancario",
  extraDue: "Vencimento",
  reprintExtraDue: "Imprimir Boleto",
  creditCard: "Cartão de crédito",
  softDescriptor: "Descritor de software",
  installments: "Parcelas",
  maxInstallments: "Máximo parcelas",
  maxInstallmentsWithoutInterest: "Máximo parcelas sem juros",
  twoStepTransaction: "Transação em duas etapas",
  installmentsPassInterest: "Parcelamento com juros ",
  feeCard: "Taxa do cartão",
  description: "Nome da Loja",
  createdAt: "Criado em",
  sideModalTitle: "Configurações da conta",
  sideModalTitleEdit: "Editar usuário",
  save: "Salvar",
  cancel: "Cancelar",
  accountsRequiredError: "Conta é obrigatória!",
  accountsInvalidError: "Conta  inválido!",
  ownerRequiredError: "Nome é obrigatória!",
  ownerInvalidError: "Nome inválida!",
  documentOwnerRequiredError: "CPF é obrigatório!",
  documentOwnerInvalidError: "CPF inválido!",
  registerCategoryLoading: "Estamos cadastrando suas configurações",
  editCategoryLoading: "Estamos editando suas configurações",
  registerErrorTitle: "Erro",
  registerErrorSubTitle: "Não foi possível cadastrar configurações!",
  editErrorSubTitle: "Não foi possível editar configurações!",
  registerErrorDescription:
    "Tente novamente, se o erro persistir, entre em contato!",
  registerErrorConfirmationText: "OK",
  registerSuccessTitle: "Sucesso",
  registerSuccessSubTitle: "Configurações cadastrada com sucesso!",
  editSuccessSubTitle: "Configurações editada com sucesso!",
  registerSuccessDescription:
    "Agora a seu configurações já está disponível no sistema.",
  registerSuccessConfirmationText: "OK",
  categoryLoading: "Estamos carregando suas Configurações",
  enable: "Ativo",
  editErrorDescription: "@MSG",
  registerConfigLoading: "Estamos cadastrando suas configurações",
  editConfigLoading: "Estamos editando suas configurações",
};
