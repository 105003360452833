// module.exports = {
//   REACT_APP_STORAGE_CRYPTO_KEY:
//     "c3f58397dffc8bfcd2517ad60830da945e66e176f2c01407ec10100b04272a82",
//   REACT_APP_STORAGE_CRYPTO_IV: "afac6cf759bd38dac2375b55e1432bf0",
//   // REACT_APP_BASE_URL: "https://furafilla-api.azure-api.net",
//   REACT_APP_BASE_URL: "https://api.qas.furafilla.com.br/api",
//   REACT_APP_FIRE_ENV: "development",
// };
module.exports = {
  REACT_APP_STORAGE_CRYPTO_KEY:
    "c3f58397dffc8bfcd2517ad60830da945e66e176f2c01407ec10100b04272a82",
  REACT_APP_STORAGE_CRYPTO_IV: "afac6cf759bd38dac2375b55e1432bf0",
  // REACT_APP_BASE_URL: "https://furafilla-api.azure-api.net",
  REACT_APP_BASE_URL: "https://furafilla-api.azure-api.net/pdv",
  REACT_APP_FIRE_ENV: "production",
};
