import styled from "styled-components";

export const Container = styled.div`
  .window-selected-label {
    margin-top: 20px;
  }
`;

export const ScannerGif = styled.img`
  height: 300px;
  margin-top: 20px;
`;

export const DataForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: baseline;
`;
