/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  TableCell,
  TextField,
  IconButton,
  Grid,
  Typography,
  LinearProgress,
  Switch,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import "firebase/auth";
import api from "../../utils/API";
// Funções e dados.
import {
  PROFILE_API_BASE,
  ITEM_PRODUCT_LOCATION,
  PROFILE_LOCATION,
  QUERY_ACCESS,
} from "../../static/URL";
import {
  USER_SELECTED_PROFILE,
  USER_AUTH_TOKEN,
} from "../../static/LocalStorageTags";
import { getItem } from "../../utils/localStorage";
import { loadItemProduct } from "../../functions/itemProduct";

// Componentes.
import CustomNumberMaskedInput from "../../components/CustomNumberMaskedInput";
import CustomTooltip from "../../components/CustomTooltip";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
import CustomSideModal from "../../components/CustomSideModal";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";
import CustomModal from "../../components/CustomModal";
// Recursos visuais.
import "./style.css";

class ItemProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLoadingModal: false,
      openErrorModal: false,
      openSuccessModal: false,
      // Table
      itemProducts: [],
      open: false,
      selected: [],
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: 10,
      // SideModal
      sideModalOpen: false,
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      price: undefined,
      priceError: false,
      priceShowTooltip: false,
      priceTooltipMessage: "",
      itemList: [],
      isEnable: true,
      visibleValue: "",
      mensagemError: "",
      // Variavel para controlar modal de deletar o item
      deleteItemModalOpen: false,
      delete: false,
      openDeleteErrorModal: false,
      openDeleteLoadingModal: false,
      idDelete: undefined,
      openLoadItem: false,
    };
  }

  componentDidMount() {
    this.loadItemProducts();
  }

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user.selectedProfile) {
      return this.setState({ itemList: [] });
    }
    if (
      (!this.props.user.selectedProfile && nextProps.user.selectedProfile) ||
      (nextProps.user.selectedProfile &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id !==
          nextProps.user.selectedProfile.id)
    ) {
      this.loadItemProducts();
    }
  }

  /*
   * Método que carega todos items do produtos cadastrados.
   */
  loadItemProducts = async () => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const itemList = await loadItemProduct(profileId, userToken);
      this.setState({ itemList });
    } catch (error) {
      console.log({ ...error });
      this.setState({ itemList: [] });
    }
  };

  // Retorna o UserProfile
  getUserProfile = async () => {
    const storageProfile = getItem(USER_SELECTED_PROFILE);
    if (storageProfile) {
      return JSON.parse(storageProfile).id;
    }
    const { user } = this.props;
    if (user && user.selectedProfile) {
      return user.selectedProfile.id;
    }
    const userToken = getItem(USER_AUTH_TOKEN);
    const { data: me } = await api.get(
      `${PROFILE_API_BASE + PROFILE_LOCATION}/me?${QUERY_ACCESS + userToken}`
    );
    const lastDataMe = me.sort(
      (a, b) => new Date(b.dtCreated) - new Date(a.dtCreated)
    );
    return lastDataMe[0].id;
  };

  // Renderiza a tabela de produtos
  renderTable = () => {
    const { lang } = this.props;
    const { itemList } = this.state;
    const rows = [
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: lang.itemProduct,
      },
      {
        id: "price",
        numeric: false,
        disablePadding: false,
        label: lang.price,
      },
      {
        id: "dtCreated",
        numeric: false,
        disablePadding: false,
        label: lang.createdAt,
      },
      {
        id: "isEnable",
        numeric: false,
        disablePadding: false,
        label: lang.enable,
      },
      {
        id: "del",
        numeric: false,
        disablePadding: false,
        label: lang.delete,
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        showAddButton
        onAddEvent={() => this.setState({ sideModalOpen: true })}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        onClick={this.onClickItemEdition}
        handleSelectAllClick={(e) => {
          if (e.target.checked) {
            this.setState((state) => ({
              selected: state.itemList.map((n) => n.id),
            }));
            return;
          }
          this.setState({ selected: [] });
        }}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={itemList.map((i) => ({
          id: i.id,
          name: i.name,
          // itemList: p.itemList.length,
          price: i.price
            .toFixed(2)
            .replace(".", ",")
            .replace("/d(?=(d{3})+.)g", "$&."),
          dtCreated: new Date(i.dtCreated)
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("/"),
          isEnable: i.isEnable,
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell component="th" scope="row" padding="none">
                {n.name.length > 20 ? `${n.name.substr(0, 20)}...` : n.name}
              </TableCell>
              {/* <TableCell>{n.itemList > 0 ? n.itemList : "-"}</TableCell> */}
              <TableCell>R$ {n.price}</TableCell>
              <TableCell>{n.dtCreated}</TableCell>
              <TableCell padding="checkbox">
                <Switch
                  checked={n.isEnable}
                  onClick={(event) => event.stopPropagation()}
                  onChange={(event, checked) => {
                    this.editState(event.target.checked, n);
                  }}
                  color="primary"
                />
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    this.openDeleteModal(n.id);
                  }}
                  aria-label="Delete"
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </>
          );
        }}
      />
    );
  };

  // Renderiza a modal de deletar os items.
  openDeleteModal = (id) => {
    this.setState({
      idDelete: id,
      sideModalOpen: false,
      deleteItemModalOpen: true,
    });
  };

  /**
   * Método para deletar o item na base de dados.
   */
  deleteItem = async () => {
    this.setState({ sideModalOpen: false });
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const { idDelete } = this.state;

      if (idDelete) {
        this.setState({ openDeleteLoadingModal: true });
        await api.delete(
          `${PROFILE_API_BASE + PROFILE_LOCATION}/${
            profileId + ITEM_PRODUCT_LOCATION
          }/${idDelete}?${QUERY_ACCESS + userToken}`,
          {
            idDelete,
          }
        );
        await this.loadItemProducts();
        this.setState({ openDeleteLoadingModal: false });
      }
    } catch (error) {
      const errorObj = { ...error };
      this.setState({
        openDeleteLoadingModal: false,
      });
      let message = "Erro ao exluir item";
      if (errorObj.isAxiosError) {
        switch (errorObj.response.status) {
          case 400:
            message = "Requisição inválida.";
            break;
          case 401:
            message = "Acesso não autorizado!";
            break;
          case 422:
            message = errorObj.response.data.content;
            break;
          case 500:
            message = "Erro inesperado. Tente novamente em alguns minutos.";
            break;
          default:
            message = "Não foi possível excluir o item!";
            break;
        }
      }
      this.setState({
        mensagemError: message,
        openDeleteErrorModal: true,
        openLoadingModal: false,
        deleteCategoryModalOpen: false,
        openDeleteLoadingModal: false,
      });
    }
  };

  /**
   * Metodo para editar o estado de um item na tabela.
   *
   * @param {Boolean} active estado do switch.
   * @param {Object} p Objeto do item.
   */
  editState = async (active, p) => {
    // Recupera dados do produto selecionado.
    const { id, name, price } = p;
    const { itemList } = this.state;

    // Percorre o array de item  para encontrar o item selecionado
    for (let i = 0; i < itemList.length; i++) {
      if (itemList[i].id === id) {
        // Muda o estado do item  para o selecionado.
        itemList[i].isEnable = active;
        break;
      }
    }

    // Atualiza o estado.
    this.setState({ itemList });
    // Pega os dados do usuario/perfil
    const userToken = getItem(USER_AUTH_TOKEN);
    const profileId = await this.getUserProfile();
    // Realiza atualização no backend.
    api
      .put(
        `${PROFILE_API_BASE + PROFILE_LOCATION}/${
          profileId + ITEM_PRODUCT_LOCATION
        }/${id}?${QUERY_ACCESS + userToken}`,
        {
          id,
          name,
          price: p.price.toString().split("R$")[1]
            ? parseFloat(price.toString().split("R$")[1].replace(",", "."))
            : parseFloat(price.toString().replace(",", ".")),
          isEnable: active,
        }
      )
      .catch((error) => {
        // Caso ocorra algum erro, desfaz na tela a mudança.
        console.log({ ...error });
        itemList.forEach((item) => {
          if (item.id === id) {
            item.isEnable = !item.isEnable;
          }
        });
        this.setState({ itemList });
      });
  };

  /**
   *
   * @param {Object} item Objeto do item do produto.
   */
  onClickItemEdition = async (item) => {
    const { selected } = this.state;
    this.setState({
      selected: selected.filter((s) => s !== item.id),
      id: item.id,
      name: item.name,
      price: item.price,
      sideModalOpen: true,
      isEnable: item.isEnable,
    });
  };

  // Renderiza a modal de criar produtos
  renderSideModal = () => {
    const { lang } = this.props;
    const { sideModalOpen } = this.state;
    return (
      <CustomSideModal
        open={sideModalOpen}
        handleClose={this.handleCloseModal}
        title={!this.state.id ? lang.sideModalTitle : lang.sideModalTitleEdit}
        confirmationAction={this.validateFormValues}
        confirmationText={lang.save}
        cancelationAction={this.handleCloseModal}
        cancelationText={lang.cancel}
        activeSwitch
        activeChecked={this.state.isEnable}
        onChangeSwitch={(state) => this.setState({ isEnable: state })}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  nameShowTooltip: false,
                  nameError: false,
                })
              }
              open={this.state.nameShowTooltip}
              title={this.state.nameTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.itemProduct}
                error={this.state.nameError}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  priceShowTooltip: false,
                  priceError: false,
                })
              }
              open={this.state.priceShowTooltip}
              title={this.state.priceTooltipMessage}
            >
              <CustomNumberMaskedInput
                className="width-90 input-margin "
                required
                error={this.state.priceError}
                value={this.state.price}
                onChange={(e) => this.setState({ price: e })}
                id="price"
                label={lang.price}
                prefix="R$"
                thousandSeparator="."
                decimalSeparator=","
              />
            </CustomTooltip>
          </Grid>
        </Grid>
      </CustomSideModal>
    );
  };

  // Método realizado ao fechar a modal de adição do item.
  handleCloseModal = () => {
    this.setState({
      sideModalOpen: false,
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      price: undefined,
      priceError: false,
      priceShowTooltip: false,
      priceTooltipMessage: "",
      selectedID: "",
      visibleValue: "",
    });
  };

  // Método realizado para envio dos dados do formulário para o back
  handleModalSubmit = async () => {
    this.setState({ openLoadingModal: true });
    const itemProductRes = await this.registerItemProduct();
    if (itemProductRes) {
      this.setState({
        openLoadingModal: false,
        openSuccessModal: true,
        sideModalOpen: false,
      });
      await this.loadItemProducts();
      this.handleCloseModal();
    } else {
      console.log("erro ao cadastrar o item do produto");
      this.setState({ openLoadingModal: false, openErrorModal: true });
    }
  };

  /**
   * Método para cadastrar item do produto na base de dados.
   */
  registerItemProduct = async () => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const { name, id, isEnable } = this.state;

      let { price } = this.state;
      if (price.includes(".")) {
        price = price.split(".").join("");
      }

      if (id) {
        const response = await api.put(
          `${PROFILE_API_BASE + PROFILE_LOCATION}/${
            profileId + ITEM_PRODUCT_LOCATION
          }/${id}?${QUERY_ACCESS + userToken}`,
          {
            id,
            name,
            price: price.toString().split("R$")[1]
              ? parseFloat(
                  price
                    .toString()
                    .split("R$")[1]
                    .split(".")
                    .join("")
                    .replace(",", ".")
                )
              : parseFloat(
                  price.toString().split(".").join("").replace(",", ".")
                ),

            isEnable,
          }
        );
        return response;
      }
      const response = await api.post(
        `${PROFILE_API_BASE + PROFILE_LOCATION}/${
          profileId + ITEM_PRODUCT_LOCATION
        }?${QUERY_ACCESS + userToken}`,
        {
          id,
          name,
          price: price.toString().split("R$")[1]
            ? parseFloat(
                price
                  .toString()
                  .split("R$")[1]
                  .split(".")
                  .join("")
                  .replace(",", ".")
              )
            : parseFloat(
                price.toString().split(".").join("").replace(",", ".")
              ),

          isEnable,
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      const errorObj = { ...error };
      console.log({ ...error });
      let message = "Não foi possível cadastrar o item do produto!";
      if (errorObj.isAxiosError) {
        switch (errorObj.response.status) {
          case 400:
            message = "Solicitação inválida.";
            break;
          case 401:
            message = "Acesso não autorizado!";
            break;
          case 422:
            message = errorObj.response.data.content;
            break;
          case 404:
            message = "Item de produto não encontrada para o perfil!";
            break;
          case 500:
            message = "Erro inesperado. Tente novamente em alguns minutos.";
            break;
          default:
            message = "Erro ao cadastrar item!";
            break;
        }
      }
      this.setState({
        mensagemError: message,
      });
      return false;
    }
  };

  // Método para validar os campos do formulário
  validateFormValues = () => {
    const { name, price } = this.state;
    const { lang } = this.props;
    let data = {};
    if (!name || name.trim().length === 0) {
      data = {
        ...data,
        nameShowTooltip: true,
        nameTooltipMessage: !name
          ? lang.nameRequiredError
          : lang.nameInvalidError,
        nameError: true,
      };
    }
    if (!price || price.toString().trim().length === 0) {
      data = {
        ...data,
        priceShowTooltip: true,
        priceTooltipMessage: !price
          ? lang.priceRequiredError
          : lang.priceInvalidError,
        priceError: true,
      };
    }
    if (Object.keys(data).length > 0) {
      console.log("aqui erro", data);
      this.setState(data);
    } else {
      console.log("aqui");
      this.handleModalSubmit();
    }
  };

  render() {
    const { lang } = this.props;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <CustomModal
          open={this.state.openLoadingModal}
          onClose={() => this.setState({ openLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">
              {!this.state.id ? lang.registerItemLoading : lang.editItemLoading}
            </Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomConfirmationModal
          title={
            !this.state.id
              ? lang.registerSuccessTitle
              : lang.registerSuccessTitle
          }
          open={this.state.openSuccessModal}
          subtitle={
            !this.state.id
              ? lang.registerSuccessSubTitle
              : lang.editSuccessSubTitle
          }
          description={
            !this.state.id
              ? lang.registerSuccessDescription
              : lang.editSuccessDescription
          }
          confirmationText={lang.registerSuccessConfirmationText}
          confirmationAction={() => this.setState({ openSuccessModal: false })}
          onClose={() => this.setState({ openSuccessModal: false })}
        />

        <CustomConfirmationModal
          title={!this.state.id ? lang.registerErrorTitle : lang.editErrorTitle}
          open={this.state.openErrorModal}
          subtitle={
            !this.state.id ? lang.registerErrorSubTitle : lang.editErrorSubTitle
          }
          description={
            !this.state.id
              ? lang.registerErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
              : lang.editErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
          }
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() => this.setState({ openErrorModal: false })}
          onClose={() => this.setState({ openErrorModal: false })}
        />

        {/* Modal de confirmação para deletar categorias */}
        <CustomConfirmationModal
          title={lang.deleteItemModalTitle}
          description={lang.deletarItemModalDescription}
          open={this.state.deleteItemModalOpen}
          confirmationText={lang.deleteItemConfirmationText}
          confirmationAction={async () => this.deleteItem()}
          cancelationText={lang.cancelationText}
          cancelationAction={() =>
            this.setState({ deleteItemModalOpen: false })
          }
          onClose={() => this.setState({ deleteItemModalOpen: false })}
        />

        <CustomConfirmationModal
          title={lang.deleteErrorTitle}
          open={this.state.openDeleteErrorModal}
          subtitle={lang.deleteErrorSubTitle}
          description={lang.deleteErrorDescription.replace(
            "@MSG",
            this.state.mensagemError
          )}
          confirmationText={lang.deleteErrorConfirmationText}
          confirmationAction={() =>
            this.setState({ openDeleteErrorModal: false })
          }
          onClose={() => this.setState({ openDeleteErrorModal: false })}
        />
        <CustomModal
          open={this.state.openLoadItem}
          onClose={() => this.setState({ openLoadItem: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">{lang.itemLoading}</Typography>
            <LinearProgress />
          </div>
        </CustomModal>
        {this.renderTable()}
        {this.renderSideModal()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(ItemProduct);
