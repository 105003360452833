import styled from "styled-components";
import { Avatar, CircularProgress } from "@material-ui/core";

export const CardContainer = styled.div`
  max-width: 300px;
  border-radius: 9px;
  color: #616161;
  margin: 24px;
  padding: 10px;
  background: #fff;
  border: 2px solid #673ab7;
  display: flex;
  justify-content: center;
  font-size: medium;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
  @media (max-width: 768px) {
    min-width: auto;
    width: 250px !important;
    height: 250px !important;
  }
`;

export const StyledAvatar = styled(Avatar)`
  width: 150px !important;
  height: 150px !important;
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
  align-self: center;
  border: ${(props) =>
    props.imgstatus === "loading" ? "none" : "2px solid #673ab7 !important"};
`;

export const StyledProgress = styled(CircularProgress)`
  align-self: center;
  margin-top: 20px;
  color: #673ab7;
  position: absolute;
  z-index: 1;
`;

export const StyledSpan = styled.span`
  text-overflow: ellipsis;
  overflow: hidden !important;
  width: 300px;
  margin-top: 10px;
  @media (max-width: 768px) {
    -webkit-line-clamp: 2;
    width: 250px !important;
  }
`;
