/**
 * Metodo que verifica se o usuario tem acesso ao menu que está acessando.
 * @param {*} permisson
 * @param {*} page
 */
export const acessControl = (permissions, page) => {
  let menu;
  if (permissions && permissions.length > 0) {
    for (let i = 0; i < permissions.length; i++) {
      const permissionsPage = screens[permissions[i]];
      if (permissionsPage !== undefined) {
        const { pages } = permissionsPage;
        if (pages && pages !== undefined) {
          if (permissions[i] === "storeProducts" && page === "/") {
            menu = page.replace("/", "") || "products";
          } else {
            menu = page.replace("/", "") || "order";
          }
          if (pages.includes(menu)) {
            return true;
          }
        }
      }
    }
  }

  return false;
};

/**
 * Metodo que verifica se o usuario tem acesso ao menu que está acessando para fazer redirecionamento ao menu correto.
 * @param {*} permisson
 */
export const acessControlMenuDefault = (permisson) => {
  const menusDefault = permisson.reduce((prev, cur) => {
    if (cur === "storePdv") {
      prev = "order";
    }
    if (cur === "storeProducts") {
      if (
        !permisson.includes("storePdv") &&
        !permisson.includes("store") &&
        !permisson.includes("storeManager")
      ) {
        prev = "products";
      }
    }

    if (cur === "store" || cur === "storeManager") {
      prev = "order";
    }

    return prev;
  }, "");

  return menusDefault;
};

// Retorna o array de menus com as permissões de cada usuario.
const screens = {
  store: {
    pages: [
      "dashboard",
      "order",
      "orderPanel",
      "orderList",
      "companies",
      "category",
      "products",
      "items",
      "accounts",
      "users",
      "storeOrder",
      "places",
      "settings",
      "settingsItems",
      "linkPlacesCompanies",
      "listUser",
      "allOrder",
      "listProductsInPreparation",
      "message",
    ],
  },
  storeManager: {
    pages: [
      "dashboard",
      "order",
      "orderPanel",
      "orderList",
      "companies",
      "category",
      "products",
      "items",
      "users",
      "settingsItems",
      "listProductsInPreparation",
    ],
  },
  storePdv: {
    pages: ["order", "orderPanel", "orderList"],
  },
  storeProducts: {
    pages: ["products"],
  },
};
