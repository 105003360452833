import React from "react";

// Recursos visuais
import {
  CardContainer,
  CardTitle,
  CardValue,
  CardIconContainer,
  StyledIcon,
  CardContent
} from "./styles.css";

const Card = ({ value, title, icon }) => (
  <CardContainer className="system-primary-background shadow-3 flex align-items-center">
    <CardIconContainer className="container flex align-items-center justify-center">
      <StyledIcon>{icon}</StyledIcon>
    </CardIconContainer>
    <CardContent className="container flex align-items-center justify-center">
      <CardTitle>{title}</CardTitle>
      <CardValue>{value}</CardValue>
    </CardContent>
  </CardContainer>
);

export default Card;
