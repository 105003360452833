// Bibliotecas
import React, { Component } from "react";
import _ from "lodash";
// import { IconButton } from "@material-ui/core";
import { Fullscreen, FullscreenExit } from "@material-ui/icons";
import { connect } from "react-redux";
// Componentes
import Board from "./Board";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomFabButtom from "../../components/CustomFabButtom";
// Funções e dados estaticos.
import { fetchProfileOrders } from "../../redux/actions";
import { states } from "../../static/orderStates";
// import { openFullscreen } from "../../utils/browser";
// Recursos visuais
import { Container } from "./styles.css";

class OrderPanel extends Component {
  state = { fullScreen: false, orders: [], newOrders: [] };

  componentDidMount() {
    const profile = this.props.user.selectedProfile;
    if (profile) {
      this.setState({ orders: [] });
      // Pegar empresas do usuário.
      this.props.fetchProfileOrders(profile.id);
    }
  }

  /**
   *
   * @param {Object} nextProps Proximo objeto de props do componente.
   */
  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(nextProps) {
    try {
      this.setState({ orders: nextProps.orders });
      // Valida se o id do perfil selecionado no redux esta vazio.
      if (
        !nextProps.user.selectedProfile ||
        !nextProps.user.selectedProfile.id
      ) {
        this.setState({ orders: [] });
      }
      // Caso nao esteja, valida a seleção.
      else if (
        nextProps.user.selectedProfile &&
        nextProps.user.selectedProfile.id &&
        (!this.props.user.selectedProfile ||
          (this.props.user.selectedProfile &&
            this.props.user.selectedProfile.id !==
              nextProps.user.selectedProfile.id))
      ) {
        this.setState({ orders: [] });
        this.props.fetchProfileOrders(nextProps.user.selectedProfile.id);
      }
    } catch (error) {
      console.log(`Order - componentWillReceiveProps${error}`);
    }
  }

  /**
   * Método que renderiza tudo que está dentro da tela, para que tanto em fullscren
   * como em tela normal apareça as mesmas coisas
   */
  renderComponents = () => (
    <Container id="screen" fullScreen={this.state.fullScreen}>
      <>
        {/* Botão para entrar em tela cheia */}
        <CustomFabButtom
          color="primary"
          screenOnFullScreen={this.state.fullScreen}
          onClick={async () => {
            await this.setState({ fullScreen: !this.state.fullScreen });
            // if (this.state.fullScreen) {
            //   openFullscreen("screen");
            // }
          }}
        >
          {this.state.fullScreen ? <FullscreenExit /> : <Fullscreen />}
        </CustomFabButtom>
      </>
      <Board lang={this.props.lang} orders={this.state.orders} />
    </Container>
  );

  render() {
    return !this.state.fullScreen ? (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        {this.renderComponents()}
      </CustomDrawerContainer>
    ) : (
      this.renderComponents()
    );
  }
}

const mapStateToProps = ({ user, order }) => {
  let orders =
    order && Array.isArray(order.orders)
      ? order.orders.filter((val) => {
          return val !== undefined;
        })
      : [];
  if (orders) {
    // eslint-disable-next-line no-undef
    orders = _.sortBy(orders, ["dtCreated"]);

    orders = orders.filter(
      (orderItem) =>
        orderItem.orderStatus &&
        orderItem.orderStatus.index &&
        (orderItem.orderStatus.index === states.PREPARING.index ||
          orderItem.orderStatus.index === states.ORDER_IS_READY.index)
    );
  }
  return { user, orders };
};

export default connect(mapStateToProps, {
  fetchProfileOrders,
})(OrderPanel);
