/* eslint-disable react/jsx-indent */
import React, { Component } from "react";
import { Typography, List, ListItem } from "@material-ui/core";
import { ListColumnContainer } from "./styles.css";

class OrderItemList extends Component {
  render() {
    const { selectedOrder, lang } = this.props;
    return (
      <List
        component="div"
        className="flex-row justify-space-between"
        disablePadding
      >
        {/* Coluna do nome do produto */}
        <ListColumnContainer>
          <Typography>{lang.product}</Typography>
          <Typography
            variant="caption"
            gutterBottom
            className="product-subtitle"
          >
            Opções estão indicadas com o símbolo <b>'+'</b>
          </Typography>
          <ListItem className="flex-column align-start list-item">
            {selectedOrder.itemList.map((item) => (
              <div key={item.id}>
                <Typography key={item.id} variant="overline">
                  {item.prodName.length > 31
                    ? `${item.prodName.substr(0, 29)}...`
                    : item.prodName}
                </Typography>
                {item.additional &&
                Array.isArray(item.additional) &&
                item.additional.length > 0
                  ? item.additional.map((a) => (
                      <Typography
                        key={a.id}
                        variant="overline"
                        title={a.name}
                        className="additional-item"
                      >
                        {" "}
                        {a.name.length > 31
                          ? `+ ${a.name.substr(0, 23)}...`
                          : `+ ${a.name}`}
                      </Typography>
                    ))
                  : true}
              </div>
            ))}
          </ListItem>
        </ListColumnContainer>
        {/* Coluna de quantidade */}
        <ListColumnContainer>
          <Typography>{lang.quantity}</Typography>
          <Typography
            variant="caption"
            gutterBottom
            className="product-subtitle"
          >
            -
          </Typography>
          <ListItem className="flex-column align-start list-item">
            {selectedOrder.itemList.map((item) => (
              <div key={item.id}>
                <Typography key={item.id} variant="overline">
                  {item.quantity}x
                </Typography>
                {item.additional &&
                Array.isArray(item.additional) &&
                item.additional.length > 0
                  ? item.additional.map((a) => (
                      <Typography key={a.id} variant="overline">
                        {`${a.quantity}X`}
                      </Typography>
                    ))
                  : true}
              </div>
            ))}
          </ListItem>
        </ListColumnContainer>
        {/* Coluna com o total */}
        <ListColumnContainer>
          <Typography>{lang.total}</Typography>
          <Typography
            variant="caption"
            gutterBottom
            className="product-subtitle"
          >
            -
          </Typography>
          <ListItem className="flex-column align-start list-item">
            {selectedOrder.itemList.map((item) => (
              <div key={item.id}>
                <Typography key={item.id} variant="overline">
                  {lang.currencyType}
                  {(item.quantity * item.prodPrice)
                    .toFixed(2)
                    .replace(".", ",")
                    .replace(/\d(?=(\d{3})+\.)/g, "$&.")}
                </Typography>
                {item.additional &&
                Array.isArray(item.additional) &&
                item.additional.length > 0
                  ? item.additional.map((a) => (
                      <Typography key={a.id} variant="overline">
                        {lang.currencyType}
                        {(a.quantity * a.price)
                          .toFixed(2)
                          .replace(".", ",")
                          .replace(/\d(?=(\d{3})+\.)/g, "$&.")}
                      </Typography>
                    ))
                  : true}
              </div>
            ))}
          </ListItem>
        </ListColumnContainer>
      </List>
    );
  }
}

export default OrderItemList;
