import styled from "styled-components";
import { Icon } from "@material-ui/core";

export const CardContainer = styled.div`
  height: 200px;
  border-radius: 9px;
  color: #fff;
  margin-bottom: 24px;
  margin-right: 24px;
  padding: 0 10px 0 10px;
`;

export const CardContent = styled.div`
  width: 75%;
  flex-direction: column;
  padding-left: 10px;
`;

export const CardValue = styled.span`
  font-size: 50px;
  font-weight: bold;
  margin-top: 10px;
`;

export const CardTitle = styled.span`
  font-size: 25px;
`;
export const CardIconContainer = styled.span`
  width: 25%;
  border-right: 1px solid #fff;
`;

export const StyledIcon = styled(Icon)`
  font-size: 60px !important;
`;
