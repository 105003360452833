import React from "react";
import {
  Dialog,
  withMobileDialog,
  Slide,
  IconButton,
  Typography,
  DialogContent,
  Button,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import PaperComponent from "./paperComponent";
import { ModalHeader, DialogActions } from "./styles.css";
import lang from "../../config/lang";

function Transition(props) {
  return <Slide direction="left" {...props} />;
}

class CustomSideModal extends React.Component {
  render() {
    return (
      <Dialog
        TransitionComponent={Transition}
        disableBackdropClick={this.props.disableBackdropClick || false}
        disableEscapeKeyDown={this.props.disableEscapeKeyDown || false}
        open={this.props.open}
        PaperProps={{
          component: PaperComponent,
          // Props para o componente
          threeQuarters: this.props.threeQuarters,
          quarter: this.props.quarter,
        }}
        onClose={this.props.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <ModalHeader
          className={`
        flex-row
        align-items-center
        shadow-3
        system-primary-background`}
        >
          <IconButton
            color="inherit"
            onClick={this.props.handleClose}
            aria-label="Close"
          >
            <Close />
          </IconButton>
          <Typography variant="h6" color="inherit">
            {this.props.title}
          </Typography>
        </ModalHeader>
        <DialogContent id="content">{this.props.children}</DialogContent>
        <DialogActions>
          <div>
            {this.props.activeSwitch && (
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Switch
                    checked={this.props.activeChecked || false}
                    onChange={(event, checked) => {
                      this.props.onChangeSwitch(event.target.checked);
                    }}
                    color="primary"
                  />
                }
                label={lang.sideModal.active}
              />
            )}
          </div>
          <div>
            <Button onClick={this.props.confirmationAction} color="primary">
              {this.props.confirmationText}
            </Button>
            <Button onClick={this.props.cancelationAction} color="default">
              {this.props.cancelationText}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(CustomSideModal);
