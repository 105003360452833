/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  TableCell,
  TextField,
  Grid,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import api from "../../utils/API";
// Funções e dados.
import {
  PROFILE_API_BASE,
  PROFILE_LOCATION,
  QUERY_ACCESS,
  USER_API_BASE,
  NOTIFICATIONS,
  USERS_LOCATION,
  TITLE,
  MESSAGE,
} from "../../static/URL";
import {
  USER_SELECTED_PROFILE,
  USER_AUTH_TOKEN,
} from "../../static/LocalStorageTags";
import { getItem } from "../../utils/localStorage";

// Componentes.
import CustomNumberMaskedInput from "../../components/CustomNumberMaskedInput";
import CustomTooltip from "../../components/CustomTooltip";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
import CustomSideModal from "../../components/CustomSideModal";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";
import CustomModal from "../../components/CustomModal";

// Recursos visuais.
import "./style.css";

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLoadingModal: false,
      openErrorModal: false,
      openSuccessModal: false,
      // Table
      messageList: [],
      open: false,
      selected: [],
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: 10,
      // SideModal
      imageStatus: "loading",
      sideModalOpen: false,
      id: undefined,
      userId: "",
      userIdError: false,
      userIdShowTooltip: false,
      userIdTooltipMessage: "",
      titleMessage: "",
      titleMessageError: false,
      titleMessageShowTooltip: false,
      titleMessageTooltipMessage: "",
      message: "",
      messageError: false,
      messageShowTooltip: false,
      messageTooltipMessage: "",
    };
  }

  componentDidMount() {}

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillReceiveProps(nextProps) {
    // eslint-disable-next-line no-empty
    if (!nextProps.user.selectedProfile) {
    }
    if (
      (!this.props.user.selectedProfile && nextProps.user.selectedProfile) ||
      (nextProps.user.selectedProfile &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id !==
          nextProps.user.selectedProfile.id)
      // eslint-disable-next-line no-empty
    ) {
    }
  }

  // Retorna o UserProfile
  getUserProfile = async () => {
    const storageProfile = getItem(USER_SELECTED_PROFILE);
    console.log(this.props);
    if (storageProfile) {
      return JSON.parse(storageProfile).id;
    }
    const { user } = this.props;
    if (user && user.selectedProfile) {
      return user.selectedProfile.id;
    }
    const userToken = getItem(USER_AUTH_TOKEN);
    const { data: me } = await api.get(
      `${PROFILE_API_BASE + PROFILE_LOCATION}/me?${QUERY_ACCESS + userToken}`
    );
    const lastDataMe = me.sort(
      (a, b) => new Date(b.dtCreated) - new Date(a.dtCreated)
    );
    return lastDataMe[0].id;
  };

  // Renderiza a tabela de produtos
  renderTable = () => {
    const { lang } = this.props;
    const { messageList } = this.state;
    const rows = [
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: lang.userId,
      },
      {
        id: "titleMessage",
        numeric: false,
        disablePadding: false,
        label: lang.titleMessage,
      },
      {
        id: "message",
        numeric: false,
        disablePadding: false,
        label: lang.message,
      },
      {
        id: "dtCreated",
        numeric: false,
        disablePadding: false,
        label: lang.createdAt,
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        showAddButton
        onAddEvent={() => this.setState({ sideModalOpen: true })}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        onClick={this.onClickAccountsEdition}
        handleSelectAllClick={(e) => {
          if (e.target.checked) {
            this.setState((state) => ({
              selected: state.accounts.map((n) => n.id),
            }));
            return;
          }
          this.setState({ selected: [] });
        }}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={messageList.map((a) => ({
          id: a.id,
          userId: a.userId,
          titleMessage: a.titleMessage,
          message: a.message,
          dtCreated: new Date(a.dtCreated)
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("/"),
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell component="th" scope="row" padding="none">
                {n.titleMessage.titleMessage > 20
                  ? `${n.titleMessage.substr(0, 20)}...`
                  : n.titleMessage}
              </TableCell>
              <TableCell>{n.message}</TableCell>
              <TableCell>{n.dtCreated}</TableCell>
            </>
          );
        }}
      />
    );
  };

  /**
   *
   * @param {Object} account Objeto de account.
   */
  onClickAccountsEdition = async (message) => {
    const { selected } = this.state;
    this.setState({
      selected: selected.filter((s) => s !== message.id),
      userId: message.userId,
      titleMessage: message.titleMessage,
      message: message.message,
      sideModalOpen: true,
    });
  };

  // Renderiza a modal de criar produtos
  renderSideModal = () => {
    const { lang } = this.props;
    const { sideModalOpen } = this.state;
    return (
      <CustomSideModal
        open={sideModalOpen}
        handleClose={this.handleCloseModal}
        title={!this.state.id ? lang.sideModalTitle : lang.sideModalTitleEdit}
        confirmationAction={this.validateFormValues}
        confirmationText={lang.save}
        cancelationAction={this.handleCloseModal}
        cancelationText={lang.cancel}
        activeSwitch
        activeChecked={this.state.isEnable}
        onChangeSwitch={(state) => this.setState({ isEnable: state })}
      >
        <Grid container>
          <Grid item xs={3}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  userIdShowTooltip: false,
                  userIdError: false,
                })
              }
              open={this.state.userIdShowTooltip}
              title={this.state.userIdTooltipMessage}
            >
              <CustomNumberMaskedInput
                className="width-90 input-margin "
                required
                error={this.state.userIdError}
                value={this.state.userId}
                onChange={(e) => this.setState({ userId: e })}
                id="userId"
                label={lang.userId}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  titleMessageShowTooltip: false,
                  titleMessageError: false,
                })
              }
              open={this.state.titleMessageShowTooltip}
              title={this.state.titleMessageTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.titleMessage}
                error={this.state.titleMessageError}
                value={this.state.titleMessage}
                onChange={(e) =>
                  this.setState({ titleMessage: e.target.value })
                }
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  messageShowTooltip: false,
                  messageError: false,
                })
              }
              open={this.state.messageShowTooltip}
              title={this.state.messageTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.message}
                error={this.state.messageError}
                value={this.state.message}
                onChange={(e) => this.setState({ message: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
        </Grid>
      </CustomSideModal>
    );
  };

  // Método realizado ao fechar a modal de adição de produtos.
  handleCloseModal = () => {
    this.setState({
      imageStatus: "loading",
      sideModalOpen: false,
      id: undefined,
      userId: "",
      userIdError: false,
      userIdShowTooltip: false,
      userIdTooltipMessage: "",
      titleMessage: "",
      titleMessageError: false,
      titleMessageShowTooltip: false,
      titleMessageTooltipMessage: "",
      message: "",
      messageError: false,
      messageShowTooltip: false,
      messageTooltipMessage: "",
    });
  };

  // Método realizado para envio dos dados do formulário para o back
  handleModalSubmit = async () => {
    this.setState({ openLoadingModal: true });
    const messageRes = await this.sendMessage();
    if (messageRes) {
      this.setState({
        openLoadingModal: false,
        openSuccessModal: true,
        sideModalOpen: false,
      });
      this.handleCloseModal();
    } else {
      this.setState({ openLoadingModal: false, openErrorModal: true });
    }
  };

  /**
   * Método para cadastrar produto na base de dados.
   */
  /**
   * Método para cadastrar produto na base de dados.
   */
  sendMessage = async () => {
    try {
      const { userId, titleMessage, message } = this.state;
      const userToken = getItem(USER_AUTH_TOKEN);
      const response = await api.post(
        `${USER_API_BASE + NOTIFICATIONS + USERS_LOCATION}/${userId}?${
          TITLE + titleMessage
        }&${MESSAGE + message}&${QUERY_ACCESS + userToken}`,
        {
          userId,
          titleMessage,
          message,
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      const errorObj = { ...error };
      console.log({ ...error });
      let messagesError = "Não foi possível enviar menssagem!";
      if (errorObj.isAxiosError) {
        switch (errorObj.response.status) {
          case 400:
            messagesError = "Requisição inválida.";
            break;
          case 401:
            messagesError = "Acesso não autorizado!";
            break;
          case 422:
            messagesError = errorObj.response.data.content;
            break;
          case 500:
            messagesError =
              "Erro inesperado. Tente novamente em alguns minutos.";
            break;
          default:
            messagesError = "Erro ao enviar mensagem";
            break;
        }
      }
      this.setState({
        mensagemError: messagesError,
      });
      return false;
    }
  };

  // Método para validar os campos do formulário
  validateFormValues = async () => {
    const { userId, titleMessage, message } = this.state;

    const { lang } = this.props;
    let data = {};
    if (!userId || userId.trim().length === 0) {
      data = {
        ...data,
        userIdShowTooltip: true,
        userIdTooltipMessage: !userId
          ? lang.userIdRequiredError
          : lang.userIdInvalidError,
        userIdError: true,
      };
    }

    if (!titleMessage || titleMessage.toString().trim().length === 0) {
      data = {
        ...data,
        titleMessageShowTooltip: true,
        titleMessageTooltipMessage: !titleMessage
          ? lang.titleMessageRequiredError
          : lang.titleMessageInvalidError,
        titleMessageError: true,
      };
    }
    if (!message || message.toString().trim().length === 0) {
      data = {
        ...data,
        messageShowTooltip: true,
        messageTooltipMessage: !message
          ? lang.messageRequiredError
          : lang.messageInvalidError,
        messageError: true,
      };
    }

    if (message.toString().trim().length > 160) {
      data = {
        ...data,
        messageShowTooltip: true,
        messageTooltipMessage: !message
          ? lang.lengthMessageRequiredError
          : lang.lengthMessageInvalidError,
        messageError: true,
      };
    }

    if (Object.keys(data).length > 0) {
      this.setState(data);
    } else {
      this.handleModalSubmit();
    }
  };

  render() {
    const { lang } = this.props;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <CustomModal
          open={this.state.openLoadingModal}
          onClose={() => this.setState({ openLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">{lang.registerProductLoading}</Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomConfirmationModal
          title={lang.registerSuccessTitle}
          open={this.state.openSuccessModal}
          subtitle={lang.registerSuccessSubTitle}
          description={lang.messageSuccess}
          confirmationText={lang.registerSuccessConfirmationText}
          confirmationAction={() => this.setState({ openSuccessModal: false })}
          onClose={() => this.setState({ openSuccessModal: false })}
        />

        <CustomConfirmationModal
          title={lang.registerErrorTitle}
          open={this.state.openErrorModal}
          subtitle={lang.registerErrorSubTitle}
          description={lang.sendErrorDescription.replace(
            "@MSG",
            this.state.mensagemError
          )}
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() => this.setState({ openErrorModal: false })}
          onClose={() => this.setState({ openErrorModal: false })}
        />

        {this.renderTable()}
        {this.renderSideModal()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(Message);
