// Bibliotecas
import React, { Component } from "react";
import { Typography, Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
// recursos visuais.
import CustomModal from "../../../components/CustomModal";
import {
  ContentContainer,
  ButtonContainer,
  HeaderContainer
} from "../../../components/CustomConfirmationModal/styles.css";
import { Container, ScannerGif, DataForm } from "./styles.css";
import qrscangif from "../../../assets/qrcode-scan.gif";
// Funções.
import { checkBase64, checkIfIsObject } from "../../../utils/data";
import { showSnackbar } from "../../../redux/actions";

class OrderScanModal extends Component {
  state = { data: "", screenIsFocused: true };

  componentDidMount() {
    // Inicia os listeners de evento.
    window.addEventListener("focus", this.onFocus);
    window.addEventListener("click", this.onFocus);
    window.addEventListener("blur", this.onBlur);
  }

  componentWillUnmount() {
    // Remove os listeners.
    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("click", this.onFocus);
    window.removeEventListener("blur", this.onBlur);
  }

  /**
   * Metodo realizado ao dar foco na tela.
   */
  onFocus = () => {
    // Indica o foco
    this.setState({ screenIsFocused: true });
    if (this.dataInput) {
      // Foca no input
      this.dataInput.focus();
    }
  };

  /**
   * Metodo realizado ao sair da tela, para mostrar para o cliente que
   * precisa ter foco na tela para fazer a leitura.
   */
  onBlur = () => {
    this.setState({ screenIsFocused: false });
  };

  /**
   * Metodo realizado ao enviar o formulario (dados/leitura)
   */
  onSubmitForm = e => {
    e.preventDefault();
    // Valida se e base64
    if (this.state.data && this.checkStringPatternBase64(this.state.data)) {
      // Base64
      const decodedString = this.checkStringPatternBase64(this.state.data);
      const qrCodeObject = checkIfIsObject(decodedString);
      this.checkOrder(qrCodeObject);
    }
    // Valida se e um numero
    else if (!isNaN(this.state.data)) {
      // Numero
      this.checkOrder({
        profileId: this.props.user.selectedProfile.id,
        orderId: parseInt(this.state.data, 10)
      });
    }
    // Caso nao entre nas condições, vai dar erro.
    else {
      // invalido
      this.onInvalidOrder();
    }
  };

  /**
   * Valida se o pedido lido bate com o objeto recebido.
   *
   * @param {Object} objeto deve conter os dados de perfil e pedido.
   */
  checkOrder = result => {
    const { onSuccess } = this.props;
    const { profileId, orderId } = result;
    try {
      onSuccess({ profileId, orderId });
      this.setState({ data: "", screenIsFocused: true });
    } catch (error) {
      console.log({ ...error });
      // Erro
      this.onInvalidOrder();
    }
  };

  /**
   * Metodo realizado ao ocorrer erro na retirada do pedido.
   */
  onInvalidOrder = () => {
    const { lang } = this.props;
    this.setState({ data: "", screenIsFocused: true });
    this.props.showSnackbar(lang.withdrawnError, "error");
  };

  /**
   * Metodo para validar se uma string esta no formato base64
   *
   * @param {String} value String para testar.
   */
  checkStringPatternBase64 = value => {
    const base = checkBase64(value);
    if (base) {
      return base;
    }
    const match = value.match(/\|(.*?)==\|/g);
    if (match && match.length > 0) {
      const final = match[0].split("|").join("");
      return checkBase64(final);
    }
    return false;
  };

  /**
   * Metodo que renderiza o formulario que recebera o numero/base64
   */
  renderForm = () => {
    const { lang } = this.props;
    return (
      <DataForm id="dataForm" onSubmit={this.onSubmitForm}>
        <TextField
          inputRef={input => {
            this.dataInput = input;
          }}
          className="input-margin"
          label={lang.orderNumberInputLabel}
          value={this.state.data}
          onChange={e => this.setState({ data: e.target.value })}
          autoFocus
        />

        <Button variant="contained" color="primary" type="submit">
          OK
        </Button>
      </DataForm>
    );
  };

  render() {
    const { open, onClose, lang } = this.props;
    return (
      <CustomModal
        open={open}
        onClose={() => {
          this.setState({ data: "", screenIsFocused: true });
          onClose();
        }}
        disableBackdropClick
      >
        <div>
          <HeaderContainer>
            <Typography className="title" variant="h5">
              {lang.awaitingScanner}
            </Typography>
          </HeaderContainer>
          <ContentContainer>
            <Container className="flex-column flex-center">
              <ScannerGif src={qrscangif} alt="scanner" />
              {!this.state.screenIsFocused && (
                <Typography
                  variant="subtitle1"
                  className="error-text window-selected-label"
                >
                  {lang.screenNotSelectedLabel}
                </Typography>
              )}
              {this.renderForm()}
              <ButtonContainer className="flex justify-space-between">
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => {
                    onClose();
                  }}
                >
                  {lang.cancelationText}
                </Button>
              </ButtonContainer>
            </Container>
          </ContentContainer>
        </div>
      </CustomModal>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, { showSnackbar })(OrderScanModal);
