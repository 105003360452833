// Dados estáticos.
import {
  PAYMENT_API_BASE,
  BANK_LOCATION,
  ACCOUNT_PROFILE_LOCATION,
  QUERY_ACCESS,
} from "../static/URL";
// Bibliotecas de utilidade.
import api from "../utils/API";

/**
 * Método para carregar as contas.
 *
 * @param {String} profileId da empresa
 * @param {String} token token de autenticação
 */
export const loadAccounts = async (profileId, token) => {
  try {
    const { data: accounts } = await api.get(
      `${
        PAYMENT_API_BASE + BANK_LOCATION + ACCOUNT_PROFILE_LOCATION
      }/${profileId}?${QUERY_ACCESS + token}`
    );
    return accounts;
  } catch (error) {
    console.log({ ...error });
    return [];
  }
};
