// Mascara de CPF para campos. (999.999.999-99)
export const CPF_MASK = [
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];
// Mascara de CNPJ para campos. (99.999.999/9999-99)
export const CNPJ_MASK = [
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  ".",
  /\d/,
  /\d/,
  /\d/,
  "/",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
];
// Mascara de CELULAR para campos. ((99) 99999-9999)
export const PHONE_MASK = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
// Mascara de TELEFONE para campos. ((99) 9999-9999)
export const TELEPHONE_MASK = [
  "(",
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
// Mascara de CEP para campos. (99999-999)
export const CEP_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/];
// Mascara de ESTADO para campos. (XX)
export const STATE_MASK = [/\w/, /\w/];
// Mascara de AGENCIA bancaria para campos. (XXXX)
export const AGENCY_MASK = [/\d/, /\d/, /\d/, /\d/];
// Mascara de DIGITO bancaria para campos. (X)
export const DIGITY_MASK = [/\d/];
export const BR_CURRENCY_MASK = ["R", "$", /\d/, /\d/, ".", /\d/, /\d/];
