function importAll(r) {
  return r.keys().map(r);
}
const dictionariesArr = importAll(
  require.context("./", false, /\.(dict).(js)$/)
);
const dictionaries = {};
dictionariesArr.forEach(dict => {
  dictionaries[Object.keys(dict)[0]] = dict[Object.keys(dict)[0]];
});

module.exports = dictionaries;
