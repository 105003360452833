export const registerPage = {
  // Tela cadastro
  STEPS: [
    "Iniciar o cadastro",
    "Insira suas informações pessoais",
    "Dados da sua empresa",
    "Endereço da sua empresa",
  ],
  btnFbLogin: "Entrar com Facebook",
  btnGmailLogin: "Entrar com Gmail",
  registerLoading: "Estamos cadastrando seus dados.",
  registerError: "Ocorreu um erro ao cadastrar seus dados, entre em contato!",
  // Step2
  name: "Nome",
  email: "Email",
  phoneNumber: "Celular",
  document: "CPF",
  goBack: "Voltar",
  continue: "Continuar",
  phoneRequiredError: "Celular é obrigatório!",
  phoneInvalidError: "Celular inválido!",
  documentRequiredError: "Documento é obrigatório!",
  documentInvalidError: "Documento inválido!",
  // Step4
  logoLabel: "Arraste sua logo ou clique aqui",
  profileName: "Razão Social",
  display: "Nome Fantasia",
  profileDocument: "CNPJ",
  description: "Breve descrição da sua empresa",
  profileEmail: "Email da empresa",
  profilePhoneNumber: "Telefone da empresa",
  whatsapp: "Whatsapp da empresa",
  profileNameRequired: "Nome é obrigatório!",
  profileNameInvalid: "Nome inválido!",
  displayRequired: "Nome fantasia é obrigatório!",
  displayInvalid: "Nome fantasia inválido!",
  profileDocumentRequired: "CNPJ é obrigatório!",
  profileDocumentInvalid: "CNPJ inválido!",
  descriptionRequired: "Descrição é obrigatória!",
  descriptionInvalid: "Descrição inválida!",
  profileEmailRequired: "Email da empresa é obrigatório!",
  profileEmailInvalid: "Email da empresa inválido!",
  profilePhoneNumberRequired: "Telefone da empresa é obrigatório!",
  profilePhoneNumberInvalid: "Telefone da empresa inválido!",
  whatsappRequired: "Whatsapp da empresa é obrigatório!",
  whatsappInvalid: "Whatsapp da empresa inválido!",
  // Step5
  address: "Endereço",
  zipCode: "CEP",
  street: "Rua",
  number: "Número",
  neighborhood: "Bairro",
  complement: "Complemento",
  city: "Cidade",
  state: "Estado",
  streetRequired: "Rua da empresa é obrigatório!",
  streetInvalid: "Rua da empresa é obrigatório!",
  numberRequired: "Número da empresa é obrigatório!",
  numberInvalid: "Número da empresa inválido!",
  neighborhoodRequired: "Bairro da empresa é obrigatório!",
  neighborhoodInvalid: "Bairro da empresa inválido!",
  zipCodeRequired: "CEP da empresa é obrigatório!",
  zipCodeInvalid: "CEP da empresa inválido!",
  cityRequired: "Cidade da empresa é obrigatória!",
  cityInvalid: "Cidade da empresa inválido!",
  stateRequired: "Estado da empresa é obrigatório!",
  stateInvalid: "Estado da empresa inválido!",
};
