import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
// Componentes.
import {
  Fab,
  Grid,
  TextField,
  IconButton,
  ClickAwayListener,
  Typography,
  LinearProgress,
  FormControlLabel,
  Switch,
  Select,
  MenuItem,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Dropzone from "react-dropzone";
import { Delete, Alarm } from "@material-ui/icons";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomMaskedInput from "../../components/CustomMaskedInput";
import CustomTooltip from "../../components/CustomTooltip";
import CustomSideModal from "../../components/CustomSideModal";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";
import CustomModal from "../../components/CustomModal";
import CustomCrudCard from "../../components/CustomCrudCard";
// Funções e dados.
import {
  PHONE_MASK,
  CNPJ_MASK,
  TELEPHONE_MASK,
  CEP_MASK,
  STATE_MASK,
} from "../../utils/masks";
import {
  PROFILE_API_BASE,
  PROFILE_LOCATION,
  QUERY_ACCESS,
} from "../../static/URL";
import { USER_ID, USER_AUTH_TOKEN } from "../../static/LocalStorageTags";
import api from "../../utils/API";
import { getfileUrlFromPath, saveLogoToStorage } from "../../utils/firebase";
import { getItem } from "../../utils/localStorage";
import { checkIfCNPJIsValid, checkCEP } from "../../utils/data";
import { checkEmail, checkPhone } from "../../utils/regex";
import { fetchUserProfiles } from "../../functions/profile";
// Recursos visuais.
import { StyledAvatar, StyledProgress } from "./styles.css";

class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLoadingModal: false,
      openErrorModal: false,
      openSuccessModal: false,
      profiles: [],
      // SideModal
      sideModalOpen: false,
      id: undefined,
      name: undefined,
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      display: undefined,
      displayError: false,
      displayShowTooltip: false,
      displayTooltipMessage: "",
      document: undefined,
      documentError: false,
      documentShowTooltip: false,
      documentTooltipMessage: "",
      description: undefined,
      descriptionError: false,
      descriptionShowTooltip: false,
      descriptionTooltipMessage: "",
      email: undefined,
      emailError: false,
      emailShowTooltip: false,
      emailTooltipMessage: "",
      phoneNumber: undefined,
      phoneNumberError: false,
      phoneNumberShowTooltip: false,
      phoneNumberTooltipMessage: "",
      whatsApp: undefined,
      whatsAppError: false,
      whatsAppShowTooltip: false,
      whatsAppTooltipMessage: "",
      isEnable: true,
      imageStatus: "loading",
      imageUrl: undefined,
      imageUrlError: false,
      imageOnEdit: undefined,
      orderControlMode: false,
      street: undefined,
      streetError: false,
      streetShowTooltip: false,
      streetTooltipMessage: "",
      number: undefined,
      numberError: false,
      numberShowTooltip: false,
      numberTooltipMessage: "",
      neighborhood: undefined,
      neighborhoodError: false,
      neighborhoodShowTooltip: false,
      neighborhoodTooltipMessage: "",
      complement: undefined,
      complementError: false,
      complementShowTooltip: false,
      complementTooltipMessage: "",
      zipCode: undefined,
      zipCodeError: false,
      zipCodeShowTooltip: false,
      zipCodeTooltipMessage: "",
      city: undefined,
      cityError: false,
      cityShowTooltip: false,
      cityTooltipMessage: "",
      state: undefined,
      stateError: false,
      stateShowTooltip: false,
      stateTooltipMessage: "",
      discountPercentage: 0,
      orderAutoSale: false,
      deliveryOnTable: false,
      orderScheduling: false,
      mensagemError: "",
      isOpen: false,
      pickUpAtCounter: false,
      deliveryOnTableQrCode: false,
      averageOrderTime: 0,
      hasGift: false,
    };
  }

  componentDidMount() {
    // Carrega os estabelecimentos do usuário.
    this.loadProfiles();
  }

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user.selectedProfile) {
      return this.setState({ profiles: [] });
    }
    if (
      (!this.props.user.selectedProfile && nextProps.user.selectedProfile) ||
      (nextProps.user.selectedProfile &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id !==
          nextProps.user.selectedProfile.id)
    ) {
      this.loadProfiles();
    }
  }

  /**
   * Método que carega os estabelecimentos do usuário.
   */
  loadProfiles = async () => {
    const userId = getItem(USER_ID);
    const userToken = getItem(USER_AUTH_TOKEN);
    const profiles = await fetchUserProfiles(userId, userToken);
    this.setState({ profiles });
  };

  /**
   * Método realizado ao inserir dados no cep.
   */
  onChangeCEP = async (zipCode) => {
    // Realiza ação no estado compartilhado
    this.setState({ zipCode });
    // Cria regex para validar.
    const regex = new RegExp(/\d{5}-\d{3}/g);
    // Testa se o CEP esta no formato do regex.
    if (zipCode && regex.test(zipCode)) {
      // Se sim, realiza a chamada para a API da viacep, para completar os dados do cadastro.
      const res = await axios.get(`https://viacep.com.br/ws/${zipCode}/json`);
      // Recupera os dados mais interessantes.
      const {
        bairro: neighborhood,
        localidade: city,
        logradouro: street,
        uf: state,
      } = res.data;
      if (neighborhood && city && street && state) {
        // Define os dados no estado compartilhado.
        this.setState({
          state,
          city,
          street,
          neighborhood,
        });
      }
    }
  };

  /**
   * Renderiza área para adição da imagem do estabelecimento
   */
  renderDropzoneContent = (getRootProps, getInputProps) => {
    const { lang } = this.props;
    return (
      <ClickAwayListener
        onClickAway={() => this.setState({ imageUrlError: false })}
      >
        <div
          className={
            this.state.imageUrlError
              ? "upload width-90 upload-error"
              : "upload width-90"
          }
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <p>
            {this.state.imageUrl
              ? this.state.imageUrl[0].name
              : lang.dropZoneLabel}
          </p>
        </div>
      </ClickAwayListener>
    );
  };

  /**
   * Renderiza botão para deletar a imagem do estabelecimento
   */
  renderButtonDeleteImage = () =>
    this.state.imageUrl ? (
      <IconButton
        onClick={() => this.setState({ imageUrl: undefined })}
        aria-label="Delete"
      >
        <Delete />
      </IconButton>
    ) : (
      true
    );

  /**
   * Renderiza a modal de criar estabelecimentos
   */
  renderSideModal = () => {
    const { lang } = this.props;
    const { sideModalOpen, imageOnEdit, imageStatus } = this.state;
    return (
      <CustomSideModal
        open={sideModalOpen}
        handleClose={this.handleCloseModal}
        title={!this.state.id ? lang.sideModalTitle : lang.sideModalTitleEdit}
        confirmationAction={this.validateFormValues}
        confirmationText={lang.save}
        cancelationAction={this.handleCloseModal}
        cancelationText={lang.cancel}
        activeSwitch
        activeChecked={this.state.isEnable}
        onChangeSwitch={(state) => this.setState({ isEnable: state })}
      >
        <Grid container>
          <Grid item xs={12}>
            <div className="flex-row justify-center align-items-center">
              {imageOnEdit ? (
                <>
                  <StyledAvatar
                    imgProps={{
                      style: { objectFit: "contain", cursor: "pointer" },
                      onLoad: () => this.setState({ imageStatus: "loaded" }),
                      onClick: () => this.setState({ imageOnEdit: undefined }),
                      onMouseOver: (e) => {
                        e.currentTarget.src =
                          "https://www.materialui.co/materialIcons/action/delete_black_192x192.png";
                        e.currentTarget.style.transform = "scale(0.3)";
                        e.currentTarget.style.padding = "200px";
                      },
                      onMouseOut: (e) => {
                        e.currentTarget.src = imageOnEdit;
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.padding = "0px";
                      },
                    }}
                    alt=""
                    src={imageOnEdit}
                  >
                    <Delete />
                  </StyledAvatar>
                  {imageStatus === "loading" && (
                    <StyledProgress size={150} thickness={2} />
                  )}
                </>
              ) : (
                <>
                  <Dropzone
                    multiple={false}
                    accept="image/*"
                    onDropAccepted={(file) => this.setState({ imageUrl: file })}
                  >
                    {({ getRootProps, getInputProps }) =>
                      this.renderDropzoneContent(getRootProps, getInputProps)
                    }
                  </Dropzone>
                  {this.renderButtonDeleteImage()}
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  nameShowTooltip: false,
                  nameError: false,
                })
              }
              open={this.state.nameShowTooltip}
              title={this.state.nameTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.name}
                error={this.state.nameError}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  displayShowTooltip: false,
                  displayError: false,
                })
              }
              open={this.state.displayShowTooltip}
              title={this.state.displayTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.display}
                error={this.state.displayError}
                value={this.state.display}
                onChange={(e) => this.setState({ display: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  documentShowTooltip: false,
                  documentError: false,
                })
              }
              open={this.state.documentShowTooltip}
              title={this.state.documentTooltipMessage}
            >
              <CustomMaskedInput
                formControllClassname="input-margin width-90"
                required
                value={this.state.document}
                onChange={(document) => this.setState({ document })}
                id="document"
                label={this.props.lang.document}
                error={this.state.documentError}
                mask={CNPJ_MASK}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  emailShowTooltip: false,
                  emailError: false,
                })
              }
              open={this.state.emailShowTooltip}
              title={this.state.emailTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.email}
                error={this.state.emailError}
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  phoneNumberShowTooltip: false,
                  phoneNumberError: false,
                })
              }
              open={this.state.phoneNumberShowTooltip}
              title={this.state.phoneNumberTooltipMessage}
            >
              <CustomMaskedInput
                formControllClassname="input-margin width-90"
                required
                value={this.state.phoneNumber}
                onChange={(phoneNumber) => this.setState({ phoneNumber })}
                id="phoneNumber"
                label={this.props.lang.phoneNumber}
                error={this.state.phoneNumberError}
                mask={TELEPHONE_MASK}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  whatsAppShowTooltip: false,
                  whatsAppError: false,
                })
              }
              open={this.state.whatsAppShowTooltip}
              title={this.state.whatsAppTooltipMessage}
            >
              <CustomMaskedInput
                formControllClassname="input-margin width-90"
                required
                value={this.state.whatsApp}
                onChange={(whatsApp) => this.setState({ whatsApp })}
                id="whatsApp"
                label={this.props.lang.whatsapp}
                error={this.state.whatsAppError}
                mask={PHONE_MASK}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  descriptionShowTooltip: false,
                  descriptionError: false,
                })
              }
              open={this.state.descriptionShowTooltip}
              title={this.state.descriptionTooltipMessage}
            >
              <TextField
                className="input-margin width-95"
                multiline
                rows={3}
                required
                label={lang.profileDescription}
                error={this.state.descriptionError}
                value={this.state.description}
                onChange={(e) => this.setState({ description: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  zipCodeShowTooltip: false,
                  zipCodeError: false,
                })
              }
              open={this.state.zipCodeShowTooltip}
              title={this.state.zipCodeTooltipMessage}
            >
              <CustomMaskedInput
                formControllClassname="input-margin width-90"
                required
                value={this.state.zipCode}
                onChange={(zipCode) => this.onChangeCEP(zipCode)}
                id="zipCode"
                label={this.props.lang.zipCode}
                error={this.state.zipCodeError}
                mask={CEP_MASK}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item xs={9}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  streetShowTooltip: false,
                  streetError: false,
                })
              }
              open={this.state.streetShowTooltip}
              title={this.state.streetTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                id="street"
                label={lang.street}
                error={this.state.streetError}
                value={this.state.street}
                onChange={(e) => this.setState({ street: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={3}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  numberShowTooltip: false,
                  numberError: false,
                })
              }
              open={this.state.numberShowTooltip}
              title={this.state.numberTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                id="number"
                label={lang.number}
                error={this.state.numberError}
                value={this.state.number}
                onChange={(e) => this.setState({ number: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  neighborhoodShowTooltip: false,
                  neighborhoodError: false,
                })
              }
              open={this.state.neighborhoodShowTooltip}
              title={this.state.neighborhoodTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                id="neighborhood"
                label={lang.neighborhood}
                error={this.state.neighborhoodError}
                value={this.state.neighborhood}
                onChange={(e) =>
                  this.setState({ neighborhood: e.target.value })
                }
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  complementShowTooltip: false,
                  complementError: false,
                })
              }
              open={this.state.complementShowTooltip}
              title={this.state.complementTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                id="complement"
                label={lang.complement}
                error={this.state.complementError}
                value={this.state.complement}
                onChange={(e) => this.setState({ complement: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={9}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  cityShowTooltip: false,
                  cityError: false,
                })
              }
              open={this.state.cityShowTooltip}
              title={this.state.cityTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                id="city"
                label={lang.city}
                error={this.state.cityError}
                value={this.state.city}
                onChange={(e) => this.setState({ city: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={3}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  stateShowTooltip: false,
                  stateError: false,
                })
              }
              open={this.state.stateShowTooltip}
              title={this.state.stateTooltipMessage}
            >
              <CustomMaskedInput
                formControllClassname="input-margin width-90"
                required
                value={this.state.state}
                onChange={(state) => this.setState({ state })}
                id="state"
                label={this.props.lang.state}
                error={this.state.stateError}
                mask={STATE_MASK}
              />
            </CustomTooltip>
          </Grid>
          <Grid container className="input-margin">
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.orderAutoSale}
                    onChange={(e) => {
                      this.setState({ orderAutoSale: e.target.checked });
                    }}
                    color="primary"
                  />
                }
                label={this.props.lang.orderAutoSale}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isOpen}
                    onChange={(e) => {
                      this.setState({ isOpen: e.target.checked });
                    }}
                    color="primary"
                  />
                }
                label={this.props.lang.open}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.deliveryOnTable}
                    onChange={(e) => {
                      this.setState({ deliveryOnTable: e.target.checked });
                      // eslint-disable-next-line no-unused-expressions
                      !this.state.deliveryOnTable
                        ? this.setState({ deliveryOnTableQrCode: false })
                        : "";
                    }}
                    color="primary"
                  />
                }
                label={this.props.lang.deliveryOnTable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={
                      !this.state.deliveryOnTable
                        ? false
                        : this.state.deliveryOnTableQrCode
                    }
                    disabled={!this.state.deliveryOnTable}
                    onChange={(e) => {
                      this.setState({
                        deliveryOnTableQrCode: e.target.checked,
                      });
                    }}
                    color="primary"
                  />
                }
                label={this.props.lang.deliveryOnTableQrCode}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.pickUpAtCounter}
                    onChange={(e) => {
                      this.setState({ pickUpAtCounter: e.target.checked });
                    }}
                    color="primary"
                  />
                }
                label={this.props.lang.pickUpAtCounter}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.orderScheduling}
                    onChange={(e) => {
                      this.setState({ orderScheduling: e.target.checked });
                    }}
                    color="primary"
                  />
                }
                label={this.props.lang.orderScheduling}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.orderControlMode}
                    onChange={(e) => {
                      this.setState({ orderControlMode: e.target.checked });
                    }}
                    color="primary"
                  />
                }
                label={this.props.lang.orderControlMode}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.hasGift}
                    onChange={(e) => {
                      this.setState({ hasGift: e.target.checked });
                    }}
                    color="primary"
                  />
                }
                label={this.props.lang.gift}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Select
                value={this.state.averageOrderTime}
                onChange={(e) => {
                  this.setState({ averageOrderTime: e.target.value });
                }}
                inputProps={{
                  name: "averageOrderTime",
                  id: "averageOrderTime",
                }}
              >
                <MenuItem selected value={0}>
                  <Alarm />
                </MenuItem>
                <MenuItem value={5}>5 minutos</MenuItem>
                <MenuItem value={10}>10 minutos</MenuItem>
                <MenuItem value={15}>15 minutos</MenuItem>
                <MenuItem value={20}>20 minutos</MenuItem>
                <MenuItem value={25}>25 minutos</MenuItem>
                <MenuItem value={30}>30 minutos</MenuItem>
                <MenuItem value={35}>35 minutos</MenuItem>
                <MenuItem value={40}>40 minutos</MenuItem>
                <MenuItem value={45}>45 minutos</MenuItem>
                <MenuItem value={50}>50 minutos</MenuItem>
                <MenuItem value={55}>55 minutos</MenuItem>
                <MenuItem value={60}>60 minutos</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </CustomSideModal>
    );
  };

  /**
   * Método para validar os campos do formulário
   */
  validateFormValues = () => {
    const {
      id,
      name,
      display,
      document,
      description,
      email,
      phoneNumber,
      whatsApp,
      imageUrl,
      street,
      number,
      neighborhood,
      zipCode,
      city,
      state,
    } = this.state;
    const { lang } = this.props;
    let data = {};
    // Validação da imagem.
    if (!id || !imageUrl) {
      if (!imageUrl) {
        data = {
          ...data,
          imageUrlError: true,
        };
      }
    }
    // Validação do nome.
    if (!name || name.trim().length === 0) {
      data = {
        ...data,
        nameShowTooltip: true,
        nameTooltipMessage: !name ? lang.nameRequired : lang.nameInvalid,
        nameError: true,
      };
    }
    // Validação do nome fantasia.
    if (!display || display.trim().length === 0) {
      data = {
        ...data,
        displayShowTooltip: true,
        displayTooltipMessage: !display
          ? lang.displayRequired
          : lang.displayInvalid,
        displayError: true,
      };
    }
    // Validação do documento.
    if (
      !document ||
      document.trim().length === 0 ||
      (document.trim().length > 0 && !checkIfCNPJIsValid(document))
    ) {
      data = {
        ...data,
        documentShowTooltip: true,
        documentTooltipMessage: !document
          ? lang.documentRequired
          : lang.documentInvalid,
        documentError: true,
      };
    }
    // Validação da descrição.
    if (!description || description.trim().length === 0) {
      data = {
        ...data,
        descriptionShowTooltip: true,
        descriptionTooltipMessage: !description
          ? lang.descriptionRequired
          : lang.descriptionInvalid,
        descriptionError: true,
      };
    }
    if (
      !email ||
      email.trim().length === 0 ||
      (email.trim().length > 0 && !checkEmail(email))
    ) {
      data = {
        ...data,
        emailShowTooltip: true,
        emailTooltipMessage: !email ? lang.emailRequired : lang.emailInvalid,
        emailError: true,
      };
    }
    // Validação do telefone.
    if (
      !phoneNumber ||
      phoneNumber.trim().length === 0 ||
      (phoneNumber.trim().length > 0 && !checkPhone(phoneNumber))
    ) {
      data = {
        ...data,
        phoneNumberShowTooltip: true,
        phoneNumberTooltipMessage: !phoneNumber
          ? lang.phoneNumberRequired
          : lang.phoneNumberInvalid,
        phoneNumberError: true,
      };
    }
    // Validação do whatsApp.
    if (
      !whatsApp ||
      whatsApp.trim().length === 0 ||
      (whatsApp.trim().length > 0 && !checkPhone(whatsApp))
    ) {
      data = {
        ...data,
        whatsAppShowTooltip: true,
        whatsAppTooltipMessage: !whatsApp
          ? lang.whatsappRequired
          : lang.whatsappInvalid,
        whatsAppError: true,
      };
    }
    // Validação da rua.
    if (!street || street.trim().length === 0) {
      data = {
        ...data,
        streetShowTooltip: true,
        streetTooltipMessage: !street
          ? lang.streetRequired
          : lang.streetInvalid,
        streetError: true,
      };
    }
    // Validação do número.
    if (!number || number.trim().length === 0 || isNaN(number)) {
      data = {
        ...data,
        numberShowTooltip: true,
        numberTooltipMessage: !number
          ? lang.numberRequired
          : lang.numberInvalid,
        numberError: true,
      };
    }
    // Validação do bairro.
    if (!neighborhood || neighborhood.trim().length === 0) {
      data = {
        ...data,
        neighborhoodShowTooltip: true,
        neighborhoodTooltipMessage: !neighborhood
          ? lang.neighborhoodRequired
          : lang.neighborhoodInvalid,
        neighborhoodError: true,
      };
    }
    // Validação do CEP.
    if (!zipCode || zipCode.trim().length === 0 || !checkCEP(zipCode)) {
      data = {
        ...data,
        zipCodeShowTooltip: true,
        zipCodeTooltipMessage: !zipCode
          ? lang.zipCodeRequired
          : lang.zipCodeInvalid,
        zipCodeError: true,
      };
    }
    // Validação da cidade.
    if (!city || city.trim().length === 0) {
      data = {
        ...data,
        cityShowTooltip: true,
        cityTooltipMessage: !city ? lang.cityRequired : lang.cityInvalid,
        cityError: true,
      };
    }
    // Validação do estado.
    if (!state || state.trim().length === 0 || state.trim().length > 2) {
      data = {
        ...data,
        stateShowTooltip: true,
        stateTooltipMessage: !state ? lang.stateRequired : lang.stateInvalid,
        stateError: true,
      };
    }
    if (Object.keys(data).length > 0) {
      this.setState(data);
    } else {
      this.handleModalSubmit();
    }
  };

  /**
   * Método realizado para envio dos dados do formulário para o back
   */

  handleModalSubmit = async () => {
    this.setState({ openLoadingModal: true });
    let resImage;
    if (!this.state.imageOnEdit && this.state.imageUrl) {
      resImage = await this.registerProfileImage();
    }
    if (resImage || this.state.id) {
      const profileRes = await this.registerProfile(resImage);
      if (profileRes) {
        this.setState({
          openLoadingModal: false,
          openSuccessModal: true,
          sideModalOpen: false,
        });
        await this.loadProfiles();
        this.handleCloseModal();
      } else {
        console.log("erro ao cadastrar o estabelecimento");
        this.setState({ openLoadingModal: false, openErrorModal: true });
      }
    } else {
      console.log("erro ao salvar a imagem do estabelecimento");
      this.setState({ openLoadingModal: false, openErrorModal: true });
    }
  };

  /**
   * Método para cadastrar a imagem do estabelecimento.
   * */

  registerProfileImage = async () => {
    try {
      // Salva a imagem para o storage.
      const res = await saveLogoToStorage(this.state.imageUrl[0]);
      // Valida se houve erro no retorno.
      if (res.error) {
        return false;
      }
      // Se nao, retorna a foto salva.
      return res.snapshot;
    } catch (error) {
      console.log({ ...error });
      console.log({ ...error });
      const message = "Tente novamente, se o erro persistir, entre em contato!";
      this.setState({
        mensagemError: message,
      });
      return false;
    }
  };

  /**
   * Método para cadastrar o estabelecimento.
   * */

  registerProfile = async (snapshotLogo) => {
    try {
      const token = getItem(USER_AUTH_TOKEN);
      // Recupera a url da logo.
      let imageUrl;
      if (snapshotLogo) {
        imageUrl = await getfileUrlFromPath(snapshotLogo.ref.location.path);
      }

      if (!this.state.deliveryOnTable) {
        await this.setState({ deliveryOnTableQrCode: false });
      }

      const {
        id,
        name,
        display,
        document,
        description,
        email,
        phoneNumber,
        whatsApp,
        isEnable,
        orderControlMode,
        street,
        number,
        neighborhood,
        complement,
        zipCode,
        city,
        state,
        discountPercentage,
        orderAutoSale,
        deliveryOnTable,
        orderScheduling,
        isOpen,
        pickUpAtCounter,
        deliveryOnTableQrCode,
        averageOrderTime,
        hasGift,
      } = this.state;

      if (id) {
        const profileToEdit = {
          // Monta o objeto para cadastro.
          id,
          name,
          display,
          document,
          description,
          email,
          phoneNumber,
          whatsApp,
          isEnable,
          orderControlMode,
          imageUrl: imageUrl || this.state.imageOnEdit,
          address: {
            street,
            number,
            neighborhood,
            complement,
            zipCode,
            city,
            state,
          },
          discountPercentage,
          orderAutoSale,
          deliveryOnTable,
          orderScheduling,
          isOpen,
          pickUpAtCounter,
          deliveryOnTableQrCode,
          averageOrderTime,
          hasGift,
        };
        // Realiza chamada
        const response = await api.put(
          `${
            PROFILE_API_BASE + PROFILE_LOCATION
          }/${id}?${QUERY_ACCESS}${token}`,
          profileToEdit
        );
        // Retorna a resposta, que é um sucesso (201).
        return response;
      }

      const profileToSave = {
        // Monta o objeto para cadastro.
        name,
        display,
        document,
        description,
        email,
        phoneNumber,
        whatsApp,
        isEnable,
        orderControlMode,
        imageUrl,
        address: {
          street,
          number,
          neighborhood,
          complement,
          zipCode,
          city,
          state,
        },
        discountPercentage,
        orderAutoSale,
        deliveryOnTable,
        orderScheduling,
        isOpen,
        pickUpAtCounter,
        deliveryOnTableQrCode,
        averageOrderTime,
        hasGift,
      };
      // Realiza chamada
      const response = await api.post(
        `${PROFILE_API_BASE + PROFILE_LOCATION}?${QUERY_ACCESS}${token}`,
        profileToSave
      );
      // Retorna a resposta, que é um sucesso (201).
      return response;
    } catch (error) {
      console.log(error);
      const errorObj = { ...error };
      let message = "Não foi possível cadastrar o estabelecimento!";
      if (errorObj.isAxiosError) {
        switch (errorObj.response.status) {
          case 400:
            message = "Requisição inválida.";
            break;
          case 401:
            message = "Acesso não autorizado!";
            break;
          case 422:
            message = errorObj.response.data.content;
            break;
          case 500:
            message = "Erro inesperado. Tente novamente em alguns minutos.";
            break;
          default:
            message = "Erro ao notificar cliente";
            break;
        }
      }
      this.setState({
        mensagemError: message,
      });

      return false;
    }
  };

  /**
   * Método que limpa os campos ao fechar a modal.
   */
  handleCloseModal = () => {
    this.setState({
      sideModalOpen: false,
      id: undefined,
      name: undefined,
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      display: undefined,
      displayError: false,
      displayShowTooltip: false,
      displayTooltipMessage: "",
      document: undefined,
      documentError: false,
      documentShowTooltip: false,
      documentTooltipMessage: "",
      description: undefined,
      descriptionError: false,
      descriptionShowTooltip: false,
      descriptionTooltipMessage: "",
      email: undefined,
      emailError: false,
      emailShowTooltip: false,
      emailTooltipMessage: "",
      phoneNumber: undefined,
      phoneNumberError: false,
      phoneNumberShowTooltip: false,
      phoneNumberTooltipMessage: "",
      whatsApp: undefined,
      whatsAppError: false,
      whatsAppShowTooltip: false,
      whatsAppTooltipMessage: "",
      isEnable: true,
      orderControlMode: false,
      imageStatus: "loading",
      imageUrl: undefined,
      imageUrlError: false,
      imageOnEdit: undefined,
      street: undefined,
      streetError: false,
      streetShowTooltip: false,
      streetTooltipMessage: "",
      number: undefined,
      numberError: false,
      numberShowTooltip: false,
      numberTooltipMessage: "",
      neighborhood: undefined,
      neighborhoodError: false,
      neighborhoodShowTooltip: false,
      neighborhoodTooltipMessage: "",
      complement: undefined,
      complementError: false,
      complementShowTooltip: false,
      complementTooltipMessage: "",
      zipCode: undefined,
      zipCodeError: false,
      zipCodeShowTooltip: false,
      zipCodeTooltipMessage: "",
      city: undefined,
      cityError: false,
      cityShowTooltip: false,
      cityTooltipMessage: "",
      state: undefined,
      stateError: false,
      stateShowTooltip: false,
      stateTooltipMessage: "",
      discountPercentage: 0,
      discountPercentageError: false,
      discountPercentageShowTooltip: false,
      discountPercentageTooltipMessage: "",
      orderAutoSale: false,
      deliveryOnTable: false,
      orderScheduling: false,
      isOpen: false,
      pickUpAtCounter: false,
      deliveryOnTableQrCode: false,
      averageOrderTime: 0,
      hasGift: false,
    });
  };

  /**
   * Método que preenche os campos da modal para edição.
   */
  onClickProfileEdition = (profile) => {
    this.setState({
      id: profile.id,
      name: profile.name,
      display: profile.display,
      document: profile.document,
      description: profile.description,
      email: profile.email,
      phoneNumber: profile.phoneNumber,
      whatsApp: profile.whatsApp,
      isEnable: profile.isEnable,
      imageOnEdit: profile.imageUrl,
      imageUrl: profile.imageUrl,
      discountPercentage: profile.discountPercentage,
      street: profile.address.street,
      number: profile.address.number,
      neighborhood: profile.address.neighborhood,
      complement: profile.address.complement,
      zipCode: profile.address.zipCode,
      city: profile.address.city,
      state: profile.address.state,
      orderAutoSale: profile.orderAutoSale,
      deliveryOnTable: profile.deliveryOnTable || false,
      orderScheduling: profile.orderScheduling,
      sideModalOpen: true,
      isOpen: profile.isOpen,
      pickUpAtCounter: profile.pickUpAtCounter,
      deliveryOnTableQrCode: profile.deliveryOnTableQrCode,
      averageOrderTime: profile.averageOrderTime,
      hasGift: profile.hasGift,
      orderControlMode: profile.orderControlMode,
    });
  };

  render() {
    const { lang } = this.props;
    const { profiles } = this.state;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <div
          id="companies-list-container"
          className="flex-wrap container align-content-start"
        >
          {profiles &&
            profiles.map((profile) => (
              <CustomCrudCard
                onClick={() => this.onClickProfileEdition(profile)}
                key={profile.id}
                img={profile.imageUrl}
                title={profile.display}
                description={profile.description}
              />
            ))}
        </div>
        {this.renderSideModal()}
        <Fab
          className="table-add-button fixed"
          color="primary"
          onClick={() => this.setState({ sideModalOpen: true })}
        >
          <AddIcon />
        </Fab>
        <CustomModal
          open={this.state.openLoadingModal}
          onClose={() => this.setState({ openLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">
              {!this.state.id
                ? lang.registerProfileLoading
                : lang.editProfileLoading}
            </Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomConfirmationModal
          title={
            !this.state.id
              ? lang.registerSuccessTitle
              : lang.registerSuccessTitle
          }
          open={this.state.openSuccessModal}
          subtitle={
            !this.state.id
              ? lang.registerSuccessSubTitle
              : lang.editSuccessSubTitle
          }
          description={
            !this.state.id
              ? lang.registerSuccessDescription
              : lang.editSuccessDescription
          }
          confirmationText={lang.registerSuccessConfirmationText}
          confirmationAction={() => this.setState({ openSuccessModal: false })}
          onClose={() => this.setState({ openSuccessModal: false })}
        />

        <CustomConfirmationModal
          title={!this.state.id ? lang.registerErrorTitle : lang.editErrorTitle}
          open={this.state.openErrorModal}
          subtitle={
            !this.state.id ? lang.registerErrorSubTitle : lang.editErrorSubTitle
          }
          description={
            !this.state.id
              ? lang.registerErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
              : lang.editErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
          }
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() => this.setState({ openErrorModal: false })}
          onClose={() => this.setState({ openErrorModal: false })}
        />
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(Companies);
