import React, { Component } from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";

class CustomNumberMaskedInput extends Component {
  numberFormatCustom = props => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.formattedValue
            }
          });
        }}
        thousandSeparator={this.props.thousandSeparator}
        decimalSeparator={this.props.decimalSeparator}
        prefix={this.props.prefix}
      />
    );
  };

  render() {
    return (
      <TextField
        fullWidth={this.props.fullWidth}
        style={this.props.style}
        className={this.props.className}
        label={this.props.label}
        value={this.props.value}
        onChange={e => this.props.onChange(e.target.value)}
        id={this.props.id}
        required={this.props.required || false}
        error={this.props.error || false}
        InputProps={{
          inputComponent: this.numberFormatCustom
        }}
      />
    );
  }
}

export default CustomNumberMaskedInput;
