import styled from "styled-components";

export const Paper = styled.div`
  @media only screen and (max-width: 600px) {
    width: 100% !important;
  }
  /* min-width: ${(props) => checkWidth(props)} !important; */
  width: ${(props) => checkWidth(props)} !important;
  background: #fff;
  min-height: 100%;
  right: 0 !important;
  top: 0;
  position: absolute;
  margin: 0;
  display: flex;
  flex-direction: column;

  #content {
    height: 1px;
  }

`;

export const ModalHeader = styled.div`
  color: white;
  margin-bottom: 20px;
`;

const checkWidth = (props) => {
  if (props.quarter) {
    return "25%";
  }
  if (props.threeQuarters) {
    return "75%";
  }
  return "50%";
};

export const DialogActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 auto;
  margin: 8px 4px;
  padding: 0px 10px;
  border-top: 1px solid lightgray;
`;
