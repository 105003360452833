export const orderPage = {
  title: "Pedidos",
  // Listas
  withdrawnAndComplete: "Retirados e Finalizados",
  processingPayment: "Processando Pagamento",
  accepted: "Aceitos",
  takeaway: "Take Away",
  // Botões dentro dos cards.
  startPreparingLabel: "Iniciar o preparo",
  orderReadyLabel: "Pedido pronto",
  withdrawnLabel: "Confirmar Retirada",
  completeOrderLabel: "Finalizar pedido",
  // Variaveis mais aproveitadas.
  cancelationText: "Cancelar",
  totalValue: "Valor total",
  order: "Pedido",
  orderNumber: "Pedido n° @orderId",
  orderTime: "Hora do pedido",
  date: "Data",
  time: "Hora",
  waitingTime: "Espera",
  estimateTime: "Tempo estimado",
  minutes: "Minutos",
  deliverOrderAt: "Entregar pedido",
  finishedTime: "Finalizado",
  currencyType: "R$",
  close: "Fechar",
  // Variaveis para a modal de confirmar o pedido.
  confirmOrderModalTitle:
    "Tem certeza que deseja confirmar o pedido de @userName?",
  confirmOrderModalDescription:
    "Ao confirmar o pedido, ele iniciará o processo de pagamento para que seu estabelecimento possa iniciar o preparo.",
  confirmOrderModalConfirmationText: "Confirmar",
  // Variaveis para a modal de rejeitar o pedido.
  rejectOrderModalTitle:
    "Tem certeza que deseja rejeitar o pedido de @userName?",
  rejectOrderModalDescription:
    "Ao rejeitar o pedido, ele será removido e esse processo não tem volta.",
  rejectOrderConfirmationText: "Rejeitar",
  // Variaveis para a modal de iniciar preparo do pedido.
  startPreparingModalTitle:
    "Tem certeza que deseja iniciar o preparo do pedido de @userName?",
  startPreparingModalDescription:
    "Ao iniciar o preparo, o cliente será notificado e o pedido será atualizado.",
  startPreparingModalConfirmationText: "Iniciar preparo",
  // Variaveis para a modal de finalizar o preparo do pedido.
  orderReadyModalTitle:
    "Tem certeza que deseja finalizar o preparo do pedido de @userName?",
  orderReadyModalDescription:
    "Ao finalizar o preparo, o cliente será notificado de que o pedido está pronto e poderá vir ao estabelecimento retirar.",
  orderReadyModalConfirmationText: "Finalizar o preparo",
  // Variaveis para a modal de retirar o pedido.
  orderWithdrawnModalTitle:
    "Deseja confirmar a retirada do pedido de @userName?",
  orderWithdrawnModalDescription:
    "Para retirar o pedido, você também pode realizar a leitura do QR Code no aplicativo do cliente.",
  orderWithdrawnModalConfirmationText: "Ler QrCode Pedido",
  orderNumberInputLabel: "Numero do pedido",
  awaitingScanner: "Aguardando QR Code",
  screenNotSelectedLabel: "Tela não está selecionada",
  // Variaveis para a modal de notificar o cliente.
  notifyClientModalTitle:
    "Tem certeza que deseja notificar o cliente @userName para retirar seu pedido?",
  notifyClientModalDescription:
    "Se aceitar, será enviada uma notificação para o cliente, para lembra-lo que deve vir ao estabelecimento retirar o pedido.",
  notifyClientModalConfirmationText: "Notificar Cliente",
  // Variaveis para a modal de finalizar o pedido.
  completeOrderModalTitle:
    "Tem certeza que deseja finalizer o pedido de @userName?",
  completeOrderModalDescription:
    "Ao finalizar o pedido, ele será finalizado para o cliente e terminará o fluxo.",
  completeOrderModalConfirmationText: "Finalizar o pedido",
  // Variaveis para a modal de visualizar o pedido.
  discountOrderFinalPriceLabel:
    "Valor final com desconto (@discountPercentage%): R$@finalPrice",
  orderNumberLabel: "Pedido n°@orderId",
  orderStatus: "Estado do pedido",
  orderItemsLabel: "Itens do pedido",
  orderValueLabel: "Valor total",
  total: "Total",
  name: "Nome",
  product: "Produto",
  quantity: "Qtd",
  client: "Cliente",
  observation: "Observação",
  totalOf: "Total de ",
  // Mensagens
  withdrawnSuccess: "Retirado com sucesso!",
  withdrawnError: "Pedido inválido",
  startPreparingSuccess: "Iniciado preparo!",
};
