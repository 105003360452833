import styled from "styled-components";

export const ListContainer = styled.div`
  padding: 0 15px;
  height: 100%;
  max-width: 90%;
  overflow-y: auto;

  /* flex-grow: 0;
  flex-shrink: 0;
  flex-basis: ${props =>
    props.data && props.data.length > 0 ? 300 : 230}px; */

  width: 100%;

  opacity: ${props => (props.done ? 0.6 : 1)};

  border-left: 1px solid rgba(0, 0, 0, 0.05);
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;

    h2 {
      font-weight: 500;
      font-size: 16px;
      padding: 0 10px;
    }

    button {
      width: 42px;
      height: 42px;
      border-radius: 18px;
      background: #3b5bfd;
      cursor: pointer;
    }
  }

  ul {
    margin-top: 30px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 300px;
  /* flex-basis: ${props =>
    props.data && props.data.length > 0 ? 300 : 250}px; */
  /* flex-basis: 16.67%; */
  /* flex-basis: 300px; */
  flex-grow: 0;
  flex-shrink: 0;

  @media only screen and (min-width: 1600px) {
    flex-basis: 390px;
  }

  .header-style {
    padding: 5px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .list-header-title {
    white-space: nowrap;
    overflow: hidden;
    max-width: 85%;
    text-overflow: ellipsis;
    font-weight: bold;
    color: #fff;
  }

  .list-subtitle {
    color: #fff;
  }
`;
