/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  TableCell,
  TextField,
  Grid,
  Typography,
  LinearProgress,
  MenuItem,
  FormControl,
  Switch,
} from "@material-ui/core";
import "firebase/auth";
import api from "../../utils/API";
// Funções e dados.
import {
  PROFILE_API_BASE,
  PROFILE_LOCATION,
  QUERY_ACCESS,
  USER_API_BASE,
  USERS_LOCATION,
  STORE_LOCATION,
  PROFILESTORE_LOCATION,
} from "../../static/URL";
import {
  USER_SELECTED_PROFILE,
  USER_AUTH_TOKEN,
} from "../../static/LocalStorageTags";
import { getItem } from "../../utils/localStorage";
import { checkEmail } from "../../utils/regex";

// Componentes.
import CustomTooltip from "../../components/CustomTooltip";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
import CustomSideModal from "../../components/CustomSideModal";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";
import CustomModal from "../../components/CustomModal";

// Recursos visuais.
// import "./style.css.js";

class UserPDV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLoadingModal: false,
      openErrorModal: false,
      openSuccessModal: false,
      // Table
      users: [],
      open: false,
      selected: [],
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: 10,
      // SideModal
      sideModalOpen: false,
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      login: "",
      loginError: false,
      loginShowTooltip: false,
      loginTooltipMessage: "",
      email: "",
      emailError: false,
      emailShowTooltip: false,
      emailTooltipMessage: "",
      role: "",
      roleError: false,
      roleShowTooltip: false,
      roleTooltipMessage: "",
      profileId: undefined,
      profileIdError: false,
      profileIdShowTooltip: false,
      profileIdTooltipMessage: "",
      profileName: "",
      profileNameError: false,
      profileNameShowTooltip: false,
      profileNameTooltipMessage: "",
      isEnable: true,
      providerId: "",
      providerIdError: false,
      providerIdShowTooltip: false,
      providerIdTooltipMessage: "",
      user_id: "",
      user_idError: false,
      user_idShowTooltip: false,
      user_idTooltipMessage: "",
      mensagemError: "",
      openLoadUser: false,
      inputValue: "",
      selectedID: "",
      visibleValue: "",
      checked: [],
    };
  }

  componentDidMount() {
    this.loadUsers();
  }

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user.selectedProfile) {
      return this.setState({ users: [] });
    }
    if (
      (!this.props.user.selectedProfile && nextProps.user.selectedProfile) ||
      (nextProps.user.selectedProfile &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id !==
          nextProps.user.selectedProfile.id)
    ) {
      this.loadUsers();
    }
  }

  // Carrega os usuários
  loadUsers = async () => {
    const userToken = getItem(USER_AUTH_TOKEN);
    const profileId = await this.getUserProfile();
    const { data: users } = await api.get(
      `${
        USER_API_BASE + USERS_LOCATION + STORE_LOCATION + PROFILESTORE_LOCATION
      }/${profileId}?${QUERY_ACCESS + userToken}`
    );
    this.setState({ users });
  };

  // Retorna o UserProfile
  getUserProfile = async () => {
    const storageProfile = getItem(USER_SELECTED_PROFILE);
    if (storageProfile) {
      return JSON.parse(storageProfile).id;
    }
    const { user } = this.props;
    if (user && user.selectedProfile) {
      return user.selectedProfile.id;
    }
    const userToken = getItem(USER_AUTH_TOKEN);
    const { data: me } = await api.get(
      `${PROFILE_API_BASE + PROFILE_LOCATION}/me?${QUERY_ACCESS + userToken}`
    );
    const lastDataMe = me.sort(
      (a, b) => new Date(b.dtCreated) - new Date(a.dtCreated)
    );
    return lastDataMe[0].id;
  };

  handleChange = async (event) => {
    await this.setState({
      selectedID: event.currentTarget.id,
      visibleValue: event.target.value,
    });
    await this.setState({
      role: this.state.selectedID ? this.state.selectedID : "",
    });
  };

  getRole = (role) => {
    if (role && role === "storeManager") {
      return this.props.lang.storeManager;
    }
    if (role && role === "storePdv") {
      return this.props.lang.storePdv;
    }
    if (role && role === "storeProducts") {
      return this.props.lang.storeProducts;
    }
    if (role && role === "storeOrder") {
      return this.props.lang.storeOrder;
    }
    return "";
  };

  // Renderiza a tabela de usuário
  renderTable = () => {
    const { lang } = this.props;
    const { users } = this.state;
    const rows = [
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: lang.name,
      },
      {
        id: "login",
        numeric: false,
        disablePadding: false,
        label: lang.login,
      },
      {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: lang.email,
      },
      {
        id: "role",
        numeric: false,
        disablePadding: false,
        label: lang.role,
      },
      {
        id: "dtCreated",
        numeric: false,
        disablePadding: false,
        label: lang.createdAt,
      },
      {
        id: "isEnable",
        numeric: false,
        disablePadding: false,
        label: lang.enable,
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        showAddButton
        onAddEvent={() => this.setState({ sideModalOpen: true })}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        onClick={this.onClickUserEdition}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={users.map((u) => ({
          id: u.id,
          name: u.name,
          login: u.login,
          email: u.email,
          role: u.role,
          isEnable: u.isEnable,
          profileId: u.profileId,
          profileName: u.profileName,
          providerId: u.providerId,
          user_id: u.user_id,
          dtCreated: new Date(u.dtCreated)
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("/"),
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell component="th" scope="row" padding="none">
                {n.name.length > 20 ? `${n.name.substr(0, 20)}...` : n.name}
              </TableCell>
              <TableCell>{n.login}</TableCell>
              <TableCell>{n.email}</TableCell>
              <TableCell>{this.getRole(n.role)}</TableCell>
              <TableCell>{n.dtCreated}</TableCell>
              <TableCell padding="checkbox">
                <Switch
                  checked={n.isEnable}
                  onClick={(event) => event.stopPropagation()}
                  onChange={(event, checked) => {
                    this.editState(event.target.checked, n);
                  }}
                  color="primary"
                />
              </TableCell>
            </>
          );
        }}
      />
    );
  };

  /**
   * Metodo para editar o estado de um usuario na tabela.
   *
   * @param {Boolean} active estado do switch.
   * @param {Object} p Objeto do usuario.
   */
  editState = async (active, u) => {
    // Recupera dados do usuario selecionado.
    const {
      id,
      name,
      login,
      email,
      role,
      profileId,
      profileName,
      providerId,
      user_id,
    } = u;
    const { users } = this.state;
    // Percorre o array de produtos para encontrar o produto selecionado
    users.forEach((user) => {
      if (user.id === id) {
        // Muda o estado do produto para o selecionado.
        user.isEnable = active;
      }
    });

    let activeted = "disable";

    if (active) {
      activeted = "enable";
    }
    // Atualiza o estado.
    this.setState({ users });
    // Pega os dados do usuario/perfil
    const userToken = getItem(USER_AUTH_TOKEN);

    api
      .post(
        `${
          USER_API_BASE + USERS_LOCATION + STORE_LOCATION
        }/${id}/${activeted}?${QUERY_ACCESS + userToken}`,
        {
          id,
          name,
          login,
          email,
          role,
          profileId,
          profileName,
          providerId,
          user_id,
          isEnable: active,
        }
      )
      .catch((error) => {
        // Caso ocorra algum erro, desfaz na tela a mudança.
        console.log({ ...error });
        users.forEach((user) => {
          if (user.id === id) {
            user.isEnable = !user.isEnable;
          }
        });
        this.setState({ users });
      });
  };

  /**
   *
   * @param {Object} user Objeto da usuario.
   */
  onClickUserEdition = async (user) => {
    const { selected } = this.state;
    this.setState({
      selected: selected.filter((s) => s !== user.id),
      id: user.id,
      name: user.name,
      login: user.login,
      email: user.email,
      role: user.role,
      isEnable: user.isEnable,
      profileId: user.profileId,
      profileName: user.profileName,
      providerId: user.providerId,
      user_id: user.providerId,
      sideModalOpen: true,
      visibleValue: user.role,
    });
  };

  // Renderiza a modal de criar usuario
  renderSideModal = () => {
    const { lang } = this.props;
    const { sideModalOpen } = this.state;
    return (
      <CustomSideModal
        open={sideModalOpen}
        handleClose={this.handleCloseModal}
        title={!this.state.id ? lang.sideModalTitle : lang.sideModalTitleEdit}
        confirmationAction={this.validateFormValues}
        confirmationText={lang.save}
        cancelationAction={this.handleCloseModal}
        cancelationText={lang.cancel}
        activeSwitch
        activeChecked={this.state.isEnable}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  nameShowTooltip: false,
                  nameError: false,
                })
              }
              open={this.state.nameShowTooltip}
              title={this.state.nameTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.name}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  loginShowTooltip: false,
                  loginError: false,
                })
              }
              open={this.state.loginShowTooltip}
              title={this.state.loginTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.login}
                value={this.state.login}
                onChange={(e) => this.setState({ login: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  emailShowTooltip: false,
                  emailError: false,
                })
              }
              open={this.state.emailShowTooltip}
              title={this.state.emailTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.email}
                value={this.state.email}
                onChange={(e) => this.setState({ email: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  providerIdShowTooltip: false,
                  providerIdError: false,
                })
              }
              open={this.state.providerIdShowTooltip}
              title={this.state.providerIdTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.providerId}
                value={this.state.providerId}
                onChange={(e) => this.setState({ providerId: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  roleShowTooltip: false,
                  roleError: false,
                })
              }
              open={this.state.roleShowTooltip}
              title={this.state.roleTooltipMessage}
            >
              <FormControl fullWidth>
                <TextField
                  select
                  margin="dense"
                  variant="outlined"
                  label={lang.role}
                  required
                  error={this.state.roleError}
                  onChange={this.handleChange}
                  value={this.state.visibleValue}
                >
                  <MenuItem
                    key="storeManager"
                    value="storeManager"
                    id="storeManager"
                  >
                    {lang.storeManager}
                  </MenuItem>
                  <MenuItem key="storePdv" value="storePdv" id="storePdv">
                    {lang.storePdv}
                  </MenuItem>
                  <MenuItem
                    key="storeProducts"
                    value="storeProducts"
                    id="storeProducts"
                  >
                    {lang.storeProducts}
                  </MenuItem>
                  <MenuItem key="storeOrder" value="storeOrder" id="storeOrder">
                    {lang.storeOrder}
                  </MenuItem>
                </TextField>
              </FormControl>
            </CustomTooltip>
          </Grid>
        </Grid>
      </CustomSideModal>
    );
  };

  // Método realizado ao fechar a modal de adição de usuario.
  handleCloseModal = () => {
    this.setState({
      sideModalOpen: false,
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      login: "",
      loginError: false,
      loginShowTooltip: false,
      loginTooltipMessage: "",
      email: "",
      emailError: false,
      emailShowTooltip: false,
      emailTooltipMessage: "",
      role: "",
      roleError: false,
      roleShowTooltip: false,
      roleTooltipMessage: "",
      providerId: "",
      providerIdError: false,
      providerIdShowTooltip: false,
      providerIdTooltipMessage: "",
      visibleValue: "",
    });
  };

  // Método realizado para envio dos dados do formulário para o back
  handleModalSubmit = async () => {
    this.setState({ openLoadingModal: true });

    const usertRes = await this.registerUser();
    if (usertRes) {
      this.setState({
        openLoadingModal: false,
        openSuccessModal: true,
        sideModalOpen: false,
      });
      await this.loadUsers();
      this.handleCloseModal();
    } else {
      console.log("erro ao cadastrar a usuário");
      this.setState({ openLoadingModal: false, openErrorModal: true });
    }
  };

  /**
   * Método para cadastrar usuario na base de dados.
   */
  registerUser = async () => {
    try {
      await this.setState({
        profileName: this.props.user.selectedProfile.display,
        profileId: this.props.user.selectedProfile.id,
        user_id: this.props.user.user.id,
      });
      const userToken = getItem(USER_AUTH_TOKEN);
      const {
        id,
        name,
        login,
        email,
        role,
        isEnable,
        profileId,
        profileName,
        providerId,
        user_id,
      } = this.state;
      if (id) {
        const response = await api.put(
          `${USER_API_BASE + USERS_LOCATION + STORE_LOCATION}/${id}?${
            QUERY_ACCESS + userToken
          }`,
          {
            id,
            name,
            login,
            email,
            role,
            isEnable,
            profileId,
            profileName,
            providerId,
            user_id,
          }
        );
        return response;
      }
      const response = await api.post(
        `${USER_API_BASE + USERS_LOCATION + STORE_LOCATION}?${
          QUERY_ACCESS + userToken
        }`,
        {
          id,
          name,
          login,
          email,
          role,
          isEnable,
          profileId,
          profileName,
          providerId,
          user_id,
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      const errorObj = { ...error };
      console.log({ ...error });
      let message = "Não foi possível cadastrar o usuário!";
      if (errorObj.isAxiosError) {
        switch (errorObj.response.status) {
          case 400:
            message = "Solicitação inválida.";
            break;
          case 401:
            message = "Acesso não autorizado!";
            break;
          case 422:
            message = errorObj.response.data.content;
            break;
          case 404:
            message = "Usuário não encontrado para o perfil!";
            break;
          case 500:
            message = "Erro inesperado. Tente novamente em alguns minutos.";
            break;
          default:
            message = "Erro ao cadastrar usuário!";
            break;
        }
      }
      this.setState({
        mensagemError: message,
      });
      return false;
    }
  };

  // Método para validar os campos do formulário
  validateFormValues = () => {
    const { name, login, email, role, providerId } = this.state;
    const { lang } = this.props;
    let data = {};

    if (!name || name.trim().length === 0) {
      data = {
        ...data,
        nameShowTooltip: true,
        nameTooltipMessage: !name
          ? lang.nameRequiredError
          : lang.nameInvalidError,
        nameError: true,
      };
    }
    if (!login || login.trim().length === 0) {
      data = {
        ...data,
        loginShowTooltip: true,
        loginTooltipMessage: !name
          ? lang.loginRequiredError
          : lang.loginInvalidError,
        loginError: true,
      };
    }

    if (
      !email ||
      email.trim().length === 0 ||
      (email.trim().length > 0 && !checkEmail(email))
    ) {
      data = {
        ...data,
        emailShowTooltip: true,
        emailTooltipMessage: !name
          ? lang.emailRequiredError
          : lang.emailInvalidError,
        emailError: true,
      };
    }
    if (!providerId || providerId.trim().length === 0) {
      data = {
        ...data,
        providerIdShowTooltip: true,
        providerIdTooltipMessage: !name
          ? lang.providerIdRequiredError
          : lang.providerIdInvalidError,
        providerIdError: true,
      };
    }
    if (!role || role.trim().length === 0) {
      data = {
        ...data,
        roleShowTooltip: true,
        roleTooltipMessage: !name
          ? lang.roleRequiredError
          : lang.roleInvalidError,
        roleError: true,
      };
    }
    if (Object.keys(data).length > 0) {
      this.setState(data);
    } else {
      this.handleModalSubmit();
    }
  };

  render() {
    const { lang } = this.props;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <CustomModal
          open={this.state.openLoadingModal}
          onClose={() => this.setState({ openLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">
              {!this.state.id ? lang.registerUserLoading : lang.editUserLoading}
            </Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomConfirmationModal
          title={
            !this.state.id
              ? lang.registerSuccessTitle
              : lang.registerSuccessTitle
          }
          open={this.state.openSuccessModal}
          subtitle={
            !this.state.id
              ? lang.registerSuccessSubTitle
              : lang.editSuccessSubTitle
          }
          description={
            !this.state.id
              ? lang.registerSuccessDescription
              : lang.registerSuccessDescription
          }
          confirmationText={lang.registerSuccessConfirmationText}
          confirmationAction={() => this.setState({ openSuccessModal: false })}
          onClose={() => this.setState({ openSuccessModal: false })}
        />

        <CustomConfirmationModal
          title={!this.state.id ? lang.registerErrorTitle : lang.editErrorTitle}
          open={this.state.openErrorModal}
          subtitle={
            !this.state.id ? lang.registerErrorSubTitle : lang.editErrorSubTitle
          }
          description={
            !this.state.id
              ? lang.registerErrorDescription
              : lang.editErrorDescription
          }
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() => this.setState({ openErrorModal: false })}
          onClose={() => this.setState({ openErrorModal: false })}
        />

        <CustomModal
          open={this.state.openLoadUser}
          onClose={() => this.setState({ openLoadUser: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">{lang.usuarioLoading}</Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        {this.renderTable()}
        {this.renderSideModal()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(UserPDV);
