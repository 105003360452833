import styled from "styled-components";

export const Logo = styled.img`
  border-radius: 30px;
  height: 40px;
  margin-right: 15px;
`;

export const appbarStyle = {
  background: "var(--main-color)"
};

export const ButtonContainer = styled.div`
  @media only screen and (max-width: 600px) {
    .profile-name {
      display: none;
    }
  }
`;
