export const placesPage = {
  title: "Locais",
  dropZoneLabel: "Arraste a imagem do local ou clique aqui",
  sideModalTitle: "Adicionar Local",
  sideModalTitleEdit: "Editar Local",
  save: "Salvar",
  cancel: "Cancelar",
  name: "Nome",
  nameRequired: "Nome é obrigatório!",
  nameInvalid: "Nome inválido!",
  desc: "Breve descrição do seu local",
  descRequired: "Descrição é obrigatória!",
  descInvalid: "Descrição inválida!",
  beginHour: "Hora de abertura",
  beginHourRequired: "Hora de abertura é obrigatória!",
  beginHourInvalid: "Hora de abertura inválida!",
  endHour: "Hora de fechamento",
  endHourRequired: "Hora de fechamento é obrigatória!",
  endHourInvalid: "Hora de fechamento inválida!",
  type: "Tipo de local",
  typeRequired: "Tipo de local é obrigatório!",
  typeInvalid: "Tipo de local inválido!",
  address: "Endereço",
  zipCode: "CEP",
  zipCodeRequired: "CEP do local é obrigatório!",
  zipCodeInvalid: "CEP do local inválido!",
  street: "Rua",
  streetRequired: "Rua do local é obrigatório!",
  streetInvalid: "Rua do local é obrigatório!",
  number: "Número",
  numberRequired: "Número do local é obrigatório!",
  numberInvalid: "Número do local inválido!",
  neighborhood: "Bairro",
  neighborhoodRequired: "Bairro do local é obrigatório!",
  neighborhoodInvalid: "Bairro do local inválido!",
  complement: "Complemento",
  city: "Cidade",
  cityRequired: "Cidade do local é obrigatória!",
  cityInvalid: "Cidade do local inválido!",
  state: "Estado",
  stateRequired: "Estado do local é obrigatório!",
  stateInvalid: "Estado do local inválido!",

  mall: 'Shopping',
  university: 'Universidade',
  school: 'Escola',
  galery: 'Galeria',
  parking: 'Estacionamento',
  square: 'Praça',

  registerPlaceLoading: "Estamos cadastrando seu novo local",
  editPlaceLoading: "Estamos editando seu local",
  registerErrorTitle: "Erro",
  registerErrorSubTitle: "Não foi possível cadastrar o local!",
  editErrorSubTitle: "Não foi possível editar o local!",
  registerErrorDescription:
    "Tente novamente, se o erro persistir, entre em contato!",
  registerErrorConfirmationText: "OK",
  registerSuccessTitle: "Sucesso",
  registerSuccessSubTitle: "Local cadastrado com sucesso!",
  editSuccessSubTitle: "Local editado com sucesso!",
  registerSuccessDescription:
    "Agora o seu local já está disponível no sistema.",
  registerSuccessConfirmationText: "OK"
}