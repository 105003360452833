export const categoryPage = {
	title: 'Categorias',
	category: 'Categoria',
	sequence: 'Seqüência',
	delete: 'Deletar',
	createdAt: 'Criado em',
	sideModalTitle: 'Adicionar Categoria',
	sideModalTitleEdit: 'Editar Categoria',
	save: 'Salvar',
	cancel: 'Cancelar',
	nameRequiredError: 'Nome da categoria é obrigatório!',
	nameInvalidError: 'Nome da categoria inválido!',
	sequenceRequiredError: 'Descrição é obrigatória!',
	sequenceInvalidError: 'Descrição inválida!',
	registerCategoryLoading: 'Estamos cadastrando sua nova categoria',
	editCategoryLoading: 'Estamos editando sua categoria',
	registerErrorTitle: 'Erro',
	registerErrorSubTitle: 'Não foi possível cadastrar a categoria!',
	editErrorSubTitle: 'Não foi possível editar a categoria!',
	registerErrorDescription: 'Tente novamente, se o erro persistir, entre em contato!',
	registerErrorConfirmationText: 'OK',
	registerSuccessTitle: 'Sucesso',
	registerSuccessSubTitle: 'Categoria cadastrada com sucesso!',
	editSuccessSubTitle: 'Categoria editada com sucesso!',
	registerSuccessDescription: 'Agora a sua Categoria já está disponível no sistema.',
	registerSuccessConfirmationText: 'OK',
	deleteCategoryLoading: 'Estamos deletando sua categoria',
	categoryLoading: 'Estamos carregando suas categorias',

	enable: 'Ativo',
	// Variaveis para a modal de rejeitar o pedido.
	deleteCategoryModalTitle: 'Tem certeza que deseja deletar?',
	deletarCategoryModalDescription: 'Ao deletar a categoria, ele será removido permanentemente.',
	deleteCategoryConfirmationText: 'Deletar',
	// Variaveis mais aproveitadas.
	cancelationText: 'Cancelar',
	deleteErrorTitle: 'Erro',
	deleteErrorSubTitle: 'Não foi possível deletar a categoria!',
	deleteErrorDescription: '@MSG',
	deleteErrorConfirmationText: 'OK'
};
