// Ações referentes ao cadastro.
export const CHANGE_DATA = "CHANGE_DATA";
// Ações referentes ao usuario.
export const LOGOUT_USER = "LOGOUT_USER";
export const STORE_USER_DATA = "STORE_USER_DATA";
// Ações referentes ao usuário e seu perfil
export const SELECT_PROFILE = "SELECT_PROFILE";
// Ações referentes aos pedidos
export const FETCH_ORDERS_SUCCESS = "FETCH_ORDERS_SUCCESS";
// Ações referentes a snackbar do painel de pedidos.
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
// Ações referentes ao loader do card para ser acionado de outro lugar.
export const SHOW_LOADER = "SHOW_LOADER";
export const CLOSE_LOADER = "CLOSE_LOADER";
// Acão referente ao login com e-mail
export const TYPE_LOGIN = "TYPE_LOGIN";
// Acão referente ao regras usuario
export const FETCH_ROLE = "FETCH_ROLE";
