export const bankAccountPage = {
  title: "Conta Corrente",
  name: "Nome da Conta",
  document: "CNPJ",
  agency: "Agência",
  number: "Conta",
  digit: "Digito",
  bank: "Banco",
  ownerName: "Nome responsável",
  ownerDocument: "CPF responsável",
  delete: "Deletar",
  createdAt: "Criado em",
  sideModalTitle: "Adicionar Conta",
  sideModalTitleEdit: "Editar Conta",
  save: "Salvar",
  cancel: "Cancelar",
  nameRequiredError: "Nome  é obrigatório!",
  nameInvalidError: "Nome inválido!",
  ownerNameRequiredError: "Nome do responsável é obrigatório!",
  ownerNameInvalidError: "Nome responsável inválido!",
  ownerDocumentRequiredError: "CPF do responsável é obrigatório!",
  ownerDocumentInvalidError: "CPF responsável inválido!",
  agencyRequiredError: "Agência  é obrigatório!",
  agencyInvalidError: "Agência inválida!",
  numberRequiredError: "Numero da conta é obrigatório!",
  numberInvalidError: "Conta inválida!",
  digitRequiredError: "Digito da conta é obrigatório!",
  digitInvalidError: "Digito inválido!",
  bankRequiredError: "O campo banco é obrigatório!",
  bankInvalidError: "Banco inválido!",
  documentRequiredError: "CNPJ é obrigatória!",
  documentInvalidError: "CNPJ inválida!",
  registerAccountLoading: "Estamos cadastrando sua nova conta",
  editAccountLoading: "Estamos editando sua conta",
  registerErrorTitle: "Erro",
  registerErrorSubTitle: "Não foi possível cadastrar a conta!",
  editErrorSubTitle: "Não foi possível editar a conta!",
  registerErrorDescription: "@MSG",
  editErrorDescription: "@MSG",
  registerErrorConfirmationText: "OK",
  registerSuccessTitle: "Sucesso",
  registerSuccessSubTitle: "Conta cadastrada com sucesso!",
  editSuccessSubTitle: "Conta editada com sucesso!",
  registerSuccessDescription:
    "Agora a sua Conta já está disponível no sistema.",
  registerSuccessConfirmationText: "OK",
  deleteAccountLoading: "Estamos deletando sua conta",
  accountLoading: "Estamos carregando suas conta",
  enable: "Ativo",
  // Variaveis mais aproveitadas.
  cancelationText: "Cancelar",
  deleteErrorTitle: "Erro",
  deleteErrorSubTitle: "Não foi possível deletar a conta!",
  deleteErrorDescription: "@MSG",
  deleteErrorConfirmationText: "OK",
};
