/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableCell, Button } from "@material-ui/core";
import "firebase/auth";

import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import isWithinInterval from "date-fns/isWithinInterval";

// Funções e dados estaticos.
import { fetchProfileOrders } from "../../redux/actions";
// Componentes.
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
// Recursos visuais.
import { FiltersContainer } from "./styles.css";
import OrderInfoModal from "./OrderInfoModal";
// eslint-disable-next-line import/no-dynamic-require

// eslint-disable-next-line import/no-dynamic-require
const { default: locale } = require(`date-fns/locale/${
  navigator.language ||
  navigator.language.split(/[-_]/)[0] ||
  navigator.userLanguage.split(/[-_]/)[0] ||
  "pt-BR"
}`);

class OrderList extends Component {
  state = {
    openLoadingModal: false,
    openErrorModal: false,
    openSuccessModal: false,
    // Table

    open: false,
    selected: [],
    order: "desc",
    orderBy: "id",
    page: 0,
    rowsPerPage: 10,
    // SideModal
    sideModalOpen: false,
    openLoadCategory: false,
    orders: [],
    newOrders: [],
    // SideModal
    originalOrders: [],
    dateInitFilter: null,
    dateEndFilter: null,
    selectedOrder: {},
    checkOrderInfoModalOpen: false,
  };

  async componentDidMount() {
    const dateInitFilter = new Date();
    dateInitFilter.setHours(0);
    dateInitFilter.setMinutes(1);
    dateInitFilter.setSeconds(1);
    const dateEndFilter = new Date();
    dateEndFilter.setHours(23);
    dateEndFilter.setMinutes(59);
    dateEndFilter.setSeconds(59);
    this.setState({ dateInitFilter, dateEndFilter });
    const profile = this.props.user.selectedProfile;
    if (profile) {
      this.setState({ orders: [] });
      // Pegar empresas do usuário.
      this.props.fetchProfileOrders(profile.id);
    }
  }

  // eslint-disable-next-line consistent-return
  async UNSAFE_componentWillReceiveProps(nextProps) {
    try {
      await this.setState({
        orders: nextProps.orders,
        originalOrders: nextProps.orders,
      });
      this.filterOrders();
      // Valida se o id do perfil selecionado no redux esta vazio.
      if (
        !nextProps.user.selectedProfile ||
        !nextProps.user.selectedProfile.id ||
        !this.props.user.selectedProfile ||
        !this.props.user.selectedProfile.id
      ) {
        this.setState({ orders: [], originalOrders: [] });
      }
      // Caso nao esteja, valida a seleção.
      else if (
        nextProps.user.selectedProfile &&
        nextProps.user.selectedProfile.id &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id &&
        this.props.user.selectedProfile.id !== nextProps.user.selectedProfile.id
      ) {
        const dateInitFilter = new Date();
        dateInitFilter.setHours(0);
        dateInitFilter.setMinutes(1);
        dateInitFilter.setSeconds(1);
        const dateEndFilter = new Date();
        dateEndFilter.setHours(23);
        dateEndFilter.setMinutes(59);
        dateEndFilter.setSeconds(59);
        this.setState({
          orders: [],
          originalOrders: [],
          dateInitFilter,
          dateEndFilter,
        });
        this.props.fetchProfileOrders(nextProps.user.selectedProfile.id);
      }
    } catch (error) {
      console.log(`Order - componentWillReceiveProps${error}`);
    }
  }

  filterOrders = () => {
    let filteredOrders = this.state.originalOrders;
    if (
      this.state.dateInitFilter &&
      this.state.dateInitFilter instanceof Date
    ) {
      let endDate = new Date();
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      if (
        this.state.dateEndFilter &&
        this.state.dateEndFilter instanceof Date
      ) {
        endDate = this.state.dateEndFilter;
      }
      filteredOrders = filteredOrders.filter((o) =>
        isWithinInterval(new Date(o.dtCreated), {
          start: this.state.dateInitFilter,
          end: endDate,
        })
      );
    }

    if (Array.isArray(filteredOrders)) {
      this.setState({ orders: filteredOrders });
    }
  };

  renderFilters = () => {
    return (
      <FiltersContainer>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <DatePicker
            autoOk
            clearable
            format="dd/MM/yyyy"
            className="filter"
            maxDate={this.state.dateEndFilter || new Date()}
            margin="normal"
            label={this.props.lang.initDateFilter}
            value={this.state.dateInitFilter}
            onChange={async (dateInitFilter) => {
              dateInitFilter.setHours(0);
              dateInitFilter.setMinutes(1);
              dateInitFilter.setSeconds(1);
              await this.setState({ dateInitFilter });
            }}
          />

          <DatePicker
            autoOk
            clearable
            format="dd/MM/yyyy"
            className="filter"
            maxDate={new Date()}
            minDate={this.state.dateInitFilter}
            margin="normal"
            label={this.props.lang.endDateFilter}
            value={this.state.dateEndFilter}
            onChange={async (dateEndFilter) => {
              dateEndFilter.setHours(23);
              dateEndFilter.setMinutes(59);
              dateEndFilter.setSeconds(59);
              await this.setState({ dateEndFilter });
            }}
          />
        </MuiPickersUtilsProvider>
        <Button
          variant="contained"
          color="primary"
          className="filter-clear-btn"
          onClick={() => this.filterOrders()}
        >
          {this.props.lang.searchFilters}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className="filter-clear-btn"
          onClick={async () => {
            await this.setState({
              dateInitFilter: null,
              dateEndFilter: null,
              orders: this.state.originalOrders,
            });
            this.filterOrders();
          }}
        >
          {this.props.lang.cleanFilters}
        </Button>
      </FiltersContainer>
    );
  };

  // Renderiza a tabela de categorias
  renderTable = () => {
    const { lang } = this.props;
    const { orders } = this.state;
    const rows = [
      {
        id: "pedido",
        numeric: false,
        disablePadding: false,
        label: lang.order,
      },
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: lang.name,
      },
      {
        id: "pedidoAtual",
        numeric: false,
        disablePadding: false,
        label: lang.currentOrder,
      },
      {
        id: "valor",
        numeric: false,
        disablePadding: false,
        label: lang.totalValue,
      },
      {
        id: "dataHora",
        numeric: false,
        disablePadding: false,
        label: lang.dateTime,
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        onAddEvent={() => this.setState({ sideModalOpen: true })}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        onClick={this.onClickOrderEdition}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={orders.map((o) => ({
          id: o.id,
          userName: o.userName,
          statesOrder:
            o.orderStatus && o.orderStatus.value
              ? o.orderStatus.value
              : "Estado do pedido não identificado",
          price: (o.price ? o.price : 0)
            .toFixed(2)
            .replace(".", ",")
            .replace("/d(?=(d{3})+.)g", "$&."),
          dtCreated: new Date(o.dtCreated).toLocaleString(),
          discount: o.discount,
          discountPercentage: o.discountPercentage,
          dtResponsePay: o.dtResponsePay,
          itemList: o.itemList,
          note: o.note,
          orderStatus: o.orderStatus,
          payOnCheckout: o.payOnCheckout,
          paymentId: o.paymentId,
          profileId: o.profileId,
          profileName: o.profileName,
          refId: o.refId,
          stateStore: o.stateStore,
          takeHome: o.takeHome,
          totalItems: o.totalItems,
          totalPrice: o.totalPrice,
          userDocument: o.userDocument,
          userId: o.userId,
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell component="th" scope="row">
                {n.id}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.userName}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.statesOrder}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.price}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.dtCreated}
              </TableCell>
            </>
          );
        }}
      />
    );
  };

  /**
   *
   * @param {Object} order Objeto de produto.
   */
  onClickOrderEdition = async (order) => {
    this.setState({
      checkOrderInfoModalOpen: true,
      selectedOrder: order,
    });
  };

  render() {
    const { lang } = this.props;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        {/* Modal de visualização dos dados do pedido */}
        <OrderInfoModal
          lang={lang}
          open={this.state.checkOrderInfoModalOpen}
          startLoading={() => this.setState({ loading: true })}
          selectedOrder={this.state.selectedOrder}
          onClose={() => {
            this.setState({
              selectedOrder: {},
              checkOrderInfoModalOpen: false,
            });
          }}
        />
        {this.renderFilters()}
        {this.renderTable()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user, order }) => {
  // eslint-disable-next-line no-undef
  let orders =
    order && Array.isArray(order.orders)
      ? order.orders.filter((val) => {
          return val !== undefined;
        })
      : [];
  if (orders) {
    // eslint-disable-next-line no-undef
    orders = _.sortBy(orders, ["id"]);
  }
  return { user, orders };
};

export default connect(mapStateToProps, {
  fetchProfileOrders,
})(OrderList);
