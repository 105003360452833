import styled from "styled-components";

export const ButtonContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  width: 80% !important;
  margin-top: 10px !important;
`;
