export const companiesPage = {
  title: "Estabelecimentos",
  establishment: "Estabeleciento",
  description: "Descrição",
  sideModalTitle: "Adicionar Estabelecimento",
  sideModalTitleEdit: "Editar Estabelecimento",
  save: "Salvar",
  cancel: "Cancelar",
  dropZoneLabel: "Arraste a imagem do estabelecimento ou clique aqui",
  name: "Razão Social",
  nameRequired: "Razão Social é obrigatória!",
  nameInvalid: "Razão Social inválida!",
  display: "Nome Fantasia",
  displayRequired: "Nome fantasia é obrigatório!",
  displayInvalid: "Nome fantasia inválido!",
  document: "CNPJ",
  documentRequired: "CNPJ é obrigatório!",
  documentInvalid: "CNPJ inválido!",
  email: "Email do estabelecimento",
  emailRequired: "Email do estabelecimento é obrigatório!",
  emailInvalid: "Email do estabelecimento inválido!",
  phoneNumber: "Telefone do estabelecimento",
  phoneNumberRequired: "Telefone do estabelecimento é obrigatório!",
  phoneNumberInvalid: "Telefone do estabelecimento inválido!",
  whatsapp: "Whatsapp do estabelecimento",
  whatsappRequired: "Whatsapp do estabelecimento é obrigatório!",
  whatsappInvalid: "Whatsapp do estabelecimento inválido!",
  profileDescription: "Breve descrição do seu estabelecimento",
  descriptionRequired: "Descrição é obrigatória!",
  descriptionInvalid: "Descrição inválida!",
  address: "Endereço",
  zipCode: "CEP",
  zipCodeRequired: "CEP do estabelecimento é obrigatório!",
  zipCodeInvalid: "CEP do estabelecimento inválido!",
  street: "Rua",
  streetRequired: "Rua do estabelecimento é obrigatório!",
  streetInvalid: "Rua do estabelecimento é obrigatório!",
  number: "Número",
  numberRequired: "Número do estabelecimento é obrigatório!",
  numberInvalid: "Número do estabelecimento inválido!",
  neighborhood: "Bairro",
  neighborhoodRequired: "Bairro do estabelecimento é obrigatório!",
  neighborhoodInvalid: "Bairro do estabelecimento inválido!",
  complement: "Complemento",
  city: "Cidade",
  cityRequired: "Cidade do estabelecimento é obrigatória!",
  cityInvalid: "Cidade do estabelecimento inválido!",
  state: "Estado",
  stateRequired: "Estado do estabelecimento é obrigatório!",
  stateInvalid: "Estado do estabelecimento inválido!",
  orderAutoSale: "Pedido automático",
  deliveryOnTable: "Entrega na mesa",
  orderScheduling: "Agendamento",
  open: "Aberto",
  gift: "Presente",
  pickUpAtCounter: "Retirada no balcão",
  orderControlMode: "Somente Cardápio",
  deliveryOnTableQrCode: "Entraga na mesa com qrcode",
  registerProfileLoading: "Estamos cadastrando seu novo estabelecimento",
  editProfileLoading: "Estamos editando seu estabelecimento",
  registerErrorTitle: "Erro",
  editErrorTitle: "Erro",
  registerErrorSubTitle: "Não foi possível cadastrar o estabelecimento!",
  editErrorSubTitle: "Não foi possível editar o estabelecimento!",
  registerErrorDescription: "@MSG",
  editErrorDescription: "@MSG",
  registerErrorConfirmationText: "OK",
  registerSuccessTitle: "Sucesso",
  registerSuccessSubTitle: "Estabelecimento cadastrado com sucesso!",
  editSuccessSubTitle: "Estabelecimento editado com sucesso!",
  registerSuccessDescription:
    "Agora o seu estabelecimento já está disponível no sistema.",
  registerSuccessConfirmationText: "OK",
};
