/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  TableCell,
  TextField,
  Switch,
  FormControlLabel,
  ClickAwayListener,
  IconButton,
  Grid,
  Typography,
  LinearProgress,
  Avatar,
  CircularProgress,
  MenuItem,
  FormControl,
} from "@material-ui/core";

import Dropzone from "react-dropzone";
import { Delete } from "@material-ui/icons";
import {
  getfileUrlFromPath,
  saveProductImgToStorage,
} from "../../utils/firebase";
import "firebase/auth";
import api from "../../utils/API";
// Funções e dados.
import {
  PROFILE_API_BASE,
  PRODUCTS_LOCATION,
  PROFILE_LOCATION,
  QUERY_ACCESS,
  ME_LOCATION,
  ITEM_PRODUCT_LOCATION,
} from "../../static/URL";
import {
  USER_SELECTED_PROFILE,
  USER_AUTH_TOKEN,
} from "../../static/LocalStorageTags";
import { getItem } from "../../utils/localStorage";
import { loadCategory } from "../../functions/category";
import { loadItemProduct } from "../../functions/itemProduct";

// Componentes.
import CustomNumberMaskedInput from "../../components/CustomNumberMaskedInput";
import CustomTooltip from "../../components/CustomTooltip";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
import CustomSideModal from "../../components/CustomSideModal";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";
import CustomModal from "../../components/CustomModal";
// Recursos visuais.
import "./style.css";

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: [],
      isValidNewOptionValue: undefined,
      openLoadingModal: false,
      openErrorModalItem: false,
      openErrorModal: false,
      openSuccessModal: false,
      openDeleteErrorModal: false,
      // Table
      products: [],
      open: false,
      selected: [],
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: 10,
      // SideModal
      imageStatus: "loading",
      sideModalOpen: false,
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      desc: "",
      descError: false,
      descShowTooltip: false,
      descTooltipMessage: "",
      isStock: false,
      isControlSale: false,
      price: undefined,
      priceError: false,
      priceShowTooltip: false,
      priceTooltipMessage: "",
      imageOnEdit: undefined,
      imageUrl: undefined,
      imageUrlError: false,
      itemListChecked: [],
      additionalItems: [],
      isCustomizable: false,
      forAdult: false,
      toGift: false,
      isEnable: true,
      categoryId: undefined,
      categoryName: "",
      categories: [],
      selectOptions: [],
      value: "",
      inputValue: "",
      selectedID: "",
      visibleValue: "",
      category: {},
      mensagemError: "",
      checkedB: true,
      checked: [],
      itemList: [],
      valuesItem: [],
      selecteds: [],
      isLoading: false,
      toSchedule: false,
      itemsConfig: [],
    };
  }

  componentDidMount() {
    this.loadProducts();
    this.loadCategories();
    this.loadItemProduct();
  }

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user.selectedProfile) {
      return this.setState({ products: [] });
    }
    if (
      (!this.props.user.selectedProfile && nextProps.user.selectedProfile) ||
      (nextProps.user.selectedProfile &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id !==
          nextProps.user.selectedProfile.id)
    ) {
      this.loadProducts();
      this.loadCategories();
      this.loadItemProduct();
    }
  }

  /*
   * Método que carega todas items do produto cadastradas.
   */
  loadItemProduct = async () => {
    const userToken = getItem(USER_AUTH_TOKEN);
    const profileId = await this.getUserProfile();
    const itemList = await loadItemProduct(profileId, userToken);
    await this.setState({ itemList });
  };

  /*
   * Método que verifica a mudança de statos dos items.
   * @param {newValue} novos valores selecionado.
   * @param {actionMeta} retorna ação de adicionar e deletar.
   */
  onChangeConfig = async (newValue, actionMeta) => {
    this.setState({ isLoading: true });
    switch (actionMeta.action) {
      case "remove-value":
        const deleteItemRes = await this.deleteItemProduct(
          actionMeta.removedValue
        );
        if (deleteItemRes) {
          await this.setState({ additionalItems: newValue });
        } else {
          console.log("erro ao remover o  item no produto");
          this.setState({ openDeleteErrorModal: true });
        }
        this.setState({ isLoading: false });
        break;
      case "select-option":
        const itemProductRes = await this.registerItemProduct(
          actionMeta.option
        );
        if (itemProductRes) {
          await this.setState({ additionalItems: newValue });
        } else {
          console.log("erro ao cadastrar o  item no produto");
          this.setState({ openErrorModalItem: true });
        }

        this.setState({ isLoading: false });
        break;
      default:
        this.setState({ isLoading: false });
        break;
    }
  };

  // value = orderOptions(value);
  // this.setState({ valuesItem: value });

  /**
   * Método que retorna condição para validar se existe itemProduto cadastrado.
   */
  itemsProductAvailable = () => {
    return this.state.additionalItems.length > 0;
  };

  handleChange = async (event) => {
    await this.setState({
      selectedID: event.currentTarget.id,
      visibleValue: event.target.value,
    });
    await this.setState({
      category: {
        id: this.state.selectedID ? this.state.selectedID : undefined,
        name: this.state.visibleValue ? this.state.visibleValue : "",
      },
    });
  };

  // Carrega os produtos
  loadProducts = async () => {
    const userToken = getItem(USER_AUTH_TOKEN);
    const profileId = await this.getUserProfile();

    const { data: products } = await api.get(
      `${PROFILE_API_BASE + PROFILE_LOCATION}/${
        profileId + PRODUCTS_LOCATION + ME_LOCATION
      }?${QUERY_ACCESS + userToken}`
    );
    this.setState({ products });
  };

  renderCustomHeader = () => {
    // eslint-disable-next-line jsx-a11y/heading-has-content
    return <h1 />;
  };

  /*
   * Método que carega todas categorias cadastradas.
   */
  loadCategories = async () => {
    this.setState({ openLoadCategory: true });
    const userToken = getItem(USER_AUTH_TOKEN);
    const profileId = await this.getUserProfile();
    const categories = await loadCategory(profileId, userToken);
    this.setState({ categories });
  };

  // Retorna o UserProfile
  getUserProfile = async () => {
    const storageProfile = getItem(USER_SELECTED_PROFILE);
    console.log("profile store", storageProfile);
    if (storageProfile) {
      return JSON.parse(storageProfile).id;
    }
    const { user } = this.props;
    if (user && user.selectedProfile) {
      return user.selectedProfile.id;
    }
    const userToken = getItem(USER_AUTH_TOKEN);
    const { data: me } = await api.get(
      `${PROFILE_API_BASE + PROFILE_LOCATION}/me?${QUERY_ACCESS + userToken}`
    );
    const lastDataMe = me.sort(
      (a, b) => new Date(b.dtCreated) - new Date(a.dtCreated)
    );
    return lastDataMe[0].id;
  };

  // Renderiza a tabela de produtos
  renderTable = () => {
    const { lang } = this.props;
    const { products } = this.state;
    const rows = [
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: lang.product,
      },
      {
        id: "desc",
        numeric: false,
        disablePadding: false,
        label: lang.description,
      },
      {
        id: "price",
        numeric: false,
        disablePadding: false,
        label: lang.price,
      },
      {
        id: "category",
        numeric: false,
        disablePadding: false,
        label: lang.category,
      },
      {
        id: "dtCreated",
        numeric: false,
        disablePadding: false,
        label: lang.createdAt,
      },
      {
        id: "isEnable",
        numeric: false,
        disablePadding: false,
        label: lang.enable,
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        showAddButton
        onAddEvent={() => this.setState({ sideModalOpen: true })}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        onClick={this.onClickProductEdition}
        handleSelectAllClick={(e) => {
          if (e.target.checked) {
            this.setState((state) => ({
              selected: state.products.map((n) => n.id),
            }));
            return;
          }
          this.setState({ selected: [] });
        }}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={products.map((p) => ({
          id: p.id,
          name: p.name,
          desc: p.desc,
          price: p.price
            .toFixed(2)
            .replace(".", ",")
            .replace("/d(?=(d{3})+.)g", "$&."),
          dtCreated: new Date(p.dtCreated)
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("/"),
          categoryId: p.category ? p.category.id : undefined,
          categoryName: p.category ? p.category.name : "-",
          imageUrl: p.imageUrl,
          isControlSale: p.isControlSale,
          isCustomizable: p.isCustomizable,
          forAdult: p.forAdult,
          isStock: p.isStock,
          isEnable: p.isEnable,
          additionalItems: p.additionalItems,
          category: p.category,
          toGift: p.toGift,
          toSchedule: p.toSchedule,
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell component="th" scope="row" padding="none">
                {n.name.length > 20 ? `${n.name.substr(0, 20)}...` : n.name}
              </TableCell>
              <TableCell>
                {n.desc.length > 50 ? `${n.desc.substr(0, 50)}...` : n.desc}
              </TableCell>
              <TableCell>R$ {n.price}</TableCell>
              <TableCell>{n.categoryName}</TableCell>
              <TableCell>{n.dtCreated}</TableCell>
              <TableCell padding="checkbox">
                <Switch
                  checked={n.isEnable}
                  onClick={(event) => event.stopPropagation()}
                  onChange={(event) => {
                    this.editState(event.target.checked, n);
                  }}
                  color="primary"
                />
              </TableCell>
            </>
          );
        }}
      />
    );
  };

  /**
   * Metodo para editar o estado de um produto na tabela.
   *
   * @param {Boolean} active estado do switch.
   * @param {Object} p Objeto do produto.
   */
  editState = async (active, p) => {
    // Recupera dados do produto selecionado.
    const {
      id,
      name,
      desc,
      isStock,
      isControlSale,
      price,
      imageUrl,
      itemListChecked,
      isCustomizable,
      category,
      toGift,
      toSchedule,
    } = p;
    const { products } = this.state;
    // Percorre o array de produtos para encontrar o produto selecionado
    products.forEach((product) => {
      if (product.id === id) {
        // Muda o estado do produto para o selecionado.
        product.isEnable = active;
      }
    });

    // Atualiza o estado.
    this.setState({ products });
    // Pega os dados do usuario/perfil
    const userToken = getItem(USER_AUTH_TOKEN);
    const profileId = await this.getUserProfile();

    // Realiza atualização no backend.
    api
      .put(
        `${PROFILE_API_BASE + PROFILE_LOCATION}/${
          profileId + PRODUCTS_LOCATION
        }/${id}?${QUERY_ACCESS + userToken}`,
        {
          id,
          name,
          desc,
          isStock,
          isControlSale,
          price: p.price.toString().split("R$")[1]
            ? parseFloat(price.toString().split("R$")[1].replace(",", "."))
            : parseFloat(price.toString().replace(",", ".")),
          imageUrl,
          itemListChecked: itemListChecked === 0 ? [] : itemListChecked,
          isCustomizable,
          isEnable: active,
          category,
          toGift,
          toSchedule,
        }
      )
      .catch((error) => {
        // Caso ocorra algum erro, desfaz na tela a mudança.
        console.log({ ...error });
        products.forEach((product) => {
          if (product.id === id) {
            product.isEnable = !product.isEnable;
          }
        });
        this.setState({ products });
      });
  };

  /**
   *
   * @param {Object} product Objeto de produto.
   */
  onClickProductEdition = async (product) => {
    const { selected } = this.state;
    this.setState({
      selected: selected.filter((s) => s !== product.id),
      id: product.id,
      name: product.name,
      price: product.price,
      desc: product.desc,
      isControlSale: product.isControlSale,
      isCustomizable: product.isCustomizable,
      forAdult: product.forAdult || false,
      isStock: product.isStock,
      imageOnEdit: product.imageUrl,
      imageUrl: product.imageUrl,
      sideModalOpen: true,
      isEnable: product.isEnable,
      visibleValue: product.categoryName,
      selectedID: product.categoryId,
      additionalItems: product.additionalItems,
      toGift: product.toGift,
      toSchedule: product.toSchedule,
    });
  };

  // Renderiza a modal de criar produtos
  renderSideModal = () => {
    const { lang } = this.props;
    const { sideModalOpen, imageOnEdit, imageStatus } = this.state;
    return (
      <CustomSideModal
        open={sideModalOpen}
        handleClose={this.handleCloseModal}
        title={!this.state.id ? lang.sideModalTitle : lang.sideModalTitleEdit}
        confirmationAction={this.validateFormValues}
        confirmationText={lang.save}
        cancelationAction={this.handleCloseModal}
        cancelationText={lang.cancel}
        activeSwitch
        activeChecked={this.state.isEnable}
        onChangeSwitch={(state) => this.setState({ isEnable: state })}
      >
        <Grid container>
          <Grid item xs={12}>
            <div className="flex-row justify-center align-items-center">
              {imageOnEdit ? (
                <>
                  <Avatar
                    imgProps={{
                      style: { objectFit: "contain", cursor: "pointer" },
                      onLoad: () => this.setState({ imageStatus: "loaded" }),
                      onClick: () => this.setState({ imageOnEdit: undefined }),
                      onMouseOver: (e) => {
                        e.currentTarget.src =
                          "https://www.materialui.co/materialIcons/action/delete_black_192x192.png";
                        e.currentTarget.style.transform = "scale(0.3)";
                        e.currentTarget.style.padding = "200px";
                      },
                      onMouseOut: (e) => {
                        e.currentTarget.src = imageOnEdit;
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.padding = "0px";
                      },
                    }}
                    alt=""
                    src={imageOnEdit}
                    className="product-avatar"
                  >
                    <Delete />
                  </Avatar>
                  {imageStatus === "loading" && (
                    <CircularProgress
                      size={150}
                      thickness={2}
                      className="avatar-progress"
                    />
                  )}
                </>
              ) : (
                <>
                  <Dropzone
                    multiple={false}
                    accept="image/*"
                    onDropAccepted={(file) => this.setState({ imageUrl: file })}
                  >
                    {({ getRootProps, getInputProps }) =>
                      this.renderDropzoneContent(getRootProps, getInputProps)
                    }
                  </Dropzone>
                  {this.renderButtonDeleteImage()}
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  nameShowTooltip: false,
                  nameError: false,
                })
              }
              open={this.state.nameShowTooltip}
              title={this.state.nameTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.product}
                error={this.state.nameError}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  priceShowTooltip: false,
                  priceError: false,
                })
              }
              open={this.state.priceShowTooltip}
              title={this.state.priceTooltipMessage}
            >
              <CustomNumberMaskedInput
                className="width-90 input-margin "
                required
                error={this.state.priceError}
                value={this.state.price}
                onChange={(e) => this.setState({ price: e })}
                id="price"
                label={lang.price}
                prefix="R$"
                thousandSeparator="."
                decimalSeparator=","
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  descShowTooltip: false,
                  descError: false,
                })
              }
              open={this.state.descShowTooltip}
              title={this.state.descTooltipMessage}
            >
              <TextField
                className="input-margin width-95"
                multiline
                rows={3}
                required
                label={lang.description}
                error={this.state.descError}
                value={this.state.desc}
                onChange={(e) => this.setState({ desc: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                select
                margin="dense"
                variant="outlined"
                label="Categoria"
                onChange={this.handleChange}
                value={this.state.visibleValue}
              >
                <MenuItem value="">
                  <em>Nenhuma</em>
                </MenuItem>
                {this.state.categories.map((category) => (
                  <MenuItem
                    key={category.seq}
                    value={category.name}
                    id={category.id}
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
          </Grid>
          <Grid container className="input-margin">
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isStock || false}
                    onChange={(e) =>
                      this.setState({ isStock: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label={lang.isStock}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isControlSale}
                    onChange={(e) =>
                      this.setState({ isControlSale: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label={lang.isControlSale}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.isCustomizable}
                    onChange={(e) =>
                      this.setState({ isCustomizable: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label={lang.isCustomizable}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.forAdult}
                    onChange={(e) =>
                      this.setState({ forAdult: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label={lang.forAdult}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.toGift}
                    onChange={(e) =>
                      this.setState({ toGift: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label={lang.toGift}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={this.state.toSchedule}
                    onChange={(e) =>
                      this.setState({ toSchedule: e.target.checked })
                    }
                    color="primary"
                  />
                }
                label={lang.toSchedule}
              />
            </Grid>
          </Grid>
        </Grid>
      </CustomSideModal>
    );
  };

  // Renderiza área para adição da imagem do produto
  renderDropzoneContent = (getRootProps, getInputProps) => {
    const { lang } = this.props;
    return (
      <ClickAwayListener
        onClickAway={() => this.setState({ imageUrlError: false })}
      >
        <div
          className={
            this.state.imageUrlError
              ? "upload width-90 upload-error"
              : "upload width-90"
          }
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <p>
            {this.state.imageUrl
              ? this.state.imageUrl[0].name
              : lang.dropZoneLabel}
          </p>
        </div>
      </ClickAwayListener>
    );
  };

  // Renderiza botão para deletar a imagem do produto
  renderButtonDeleteImage = () =>
    this.state.imageUrl ? (
      <IconButton
        onClick={() => this.setState({ imageUrl: undefined })}
        aria-label="Delete"
      >
        <Delete />
      </IconButton>
    ) : (
      true
    );

  // Método para cadastrar a imagem do produto.
  registerProductImage = async () => {
    try {
      // Salva a imagem para o storage.
      const res = await saveProductImgToStorage(this.state.imageUrl[0]);
      // Valida se houve erro no retorno.
      if (res.error) {
        return false;
      }
      // Se nao, retorna a foto salva.
      return res.snapshot;
    } catch (error) {
      console.log({ ...error });
      const message = "Tente novamente, se o erro persistir, entre em contato!";
      this.setState({
        mensagemError: message,
      });
      return false;
    }
  };

  // Método realizado ao fechar a modal de adição de produtos.
  handleCloseModal = () => {
    this.setState({
      imageStatus: "loading",
      sideModalOpen: false,
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      desc: "",
      descError: false,
      descShowTooltip: false,
      descTooltipMessage: "",
      isStock: false,
      toGift: false,
      isControlSale: false,
      price: undefined,
      priceError: false,
      priceShowTooltip: false,
      priceTooltipMessage: "",
      imageOnEdit: undefined,
      imageUrl: undefined,
      imageUrlError: false,
      itemListChecked: [],
      isCustomizable: false,
      selectedID: "",
      visibleValue: "",
      category: {},
      additionalItems: [],
      toSchedule: false,
    });
  };

  // Método realizado para envio dos dados do formulário para o back
  handleModalSubmit = async () => {
    this.setState({ openLoadingModal: true });
    let resImage;
    if (!this.state.imageOnEdit && this.state.imageUrl) {
      resImage = await this.registerProductImage();
    }
    if (resImage || this.state.id) {
      const productRes = await this.registerProduct(resImage);
      if (productRes) {
        this.setState({
          openLoadingModal: false,
          openSuccessModal: true,
          sideModalOpen: false,
        });
        await this.loadProducts();
        this.handleCloseModal();
      } else {
        console.log("erro ao cadastrar o produto");
        this.setState({ openLoadingModal: false, openErrorModal: true });
      }
    } else {
      console.log("erro ao salvar a imagem do produto");
      this.setState({ openLoadingModal: false, openErrorModal: true });
    }
  };

  /**
   * Metodo para deletar o item  produto na tabela.
   *
   * @param {itemProduct} objeto do item do produto.
   */
  deleteItemProduct = async (itemProduct) => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const additional = "additional";
      const { id } = this.state;
      await api.delete(
        `${PROFILE_API_BASE + PROFILE_LOCATION}/${
          profileId + PRODUCTS_LOCATION
        }/${id}/${additional + ITEM_PRODUCT_LOCATION}/${itemProduct.id}?${
          QUERY_ACCESS + userToken
        }`,
        itemProduct.id
      );
      await this.loadProducts();
      return true;
    } catch (error) {
      const errorObj = { ...error };
      let message = "Erro ao exluir item";
      if (errorObj.isAxiosError) {
        switch (errorObj.response.status) {
          case 400:
            message = "Requisição inválida.";
            break;
          case 401:
            message = "Acesso não autorizado!";
            break;
          case 422:
            message = errorObj.response.data.content;
            break;
          case 500:
            message = "Erro inesperado. Tente novamente em alguns minutos.";
            break;
          default:
            message = "Não foi possível excluir o item!";
            break;
        }
      }
      this.setState({
        mensagemError: message,
      });

      return false;
    }
  };

  /**
   * Metodo para adicionar o item  produto na tabela.
   *
   * @param {itemProduct} objeto do item do produto.
   */
  registerItemProduct = async (itemProduct) => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const additional = "additional";
      const { id } = this.state;
      const response = await api.post(
        `${PROFILE_API_BASE + PROFILE_LOCATION}/${
          profileId + PRODUCTS_LOCATION
        }/${id}/${additional + ITEM_PRODUCT_LOCATION}/${itemProduct.id}?${
          QUERY_ACCESS + userToken
        }`,
        itemProduct
      );
      await this.loadProducts();
      return response;
    } catch (error) {
      console.log(error);
      const errorObj = { ...error };
      console.log({ ...error });
      let message = "Não foi possível cadastrar o item produto!";
      if (errorObj.isAxiosError) {
        switch (errorObj.response.status) {
          case 400:
            message = "Requisição inválida.";
            break;
          case 401:
            message = "Acesso não autorizado!";
            break;
          case 422:
            message = errorObj.response.data.content;
            break;
          case 500:
            message = "Erro inesperado. Tente novamente em alguns minutos.";
            break;
          default:
            message = "Erro ao notificar cliente";
            break;
        }
      }
      this.setState({
        mensagemError: message,
      });
      return false;
    }
  };

  /**
   * Método para cadastrar produto na base de dados.
   */
  registerProduct = async (snapshotImgLogo) => {
    try {
      /**
       * Validando o banco se estiver vazio verificar no selectedId, vivislbeValue verficar se estão preenchido
       * feito essa validação porque quando editava estava ficando ser o valor o bank.
       */
      if (this.state.category.categoryId === undefined) {
        await this.setState({
          category: {
            id: this.state.selectedID ? this.state.selectedID : undefined,
            name: this.state.visibleValue ? this.state.visibleValue : "",
          },
        });
      }

      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const {
        category,
        name,
        desc,
        isStock,
        isControlSale,
        itemListChecked,
        isCustomizable,
        forAdult,
        id,
        imageOnEdit,
        isEnable,
        toGift,
        toSchedule,
      } = this.state;

      let { price } = this.state;
      let imageUrl;
      if (snapshotImgLogo) {
        imageUrl = await getfileUrlFromPath(snapshotImgLogo.ref.location.path);
      }
      if (price.includes(".")) {
        price = price.split(".").join("");
      }

      let produto = {
        id,
        name,
        desc,
        isStock,
        isControlSale,
        price: price.toString().split("R$")[1]
          ? parseFloat(
              price
                .toString()
                .split("R$")[1]
                .split(".")
                .join("")
                .replace(",", ".")
            )
          : parseFloat(price.toString().split(".").join("").replace(",", ".")),
        imageUrl: imageUrl || imageOnEdit,
        itemListChecked: itemListChecked === 0 ? [] : itemListChecked,
        isCustomizable,
        forAdult,
        isEnable,
        toGift,
        toSchedule,
      };

      if (
        category &&
        category.name &&
        category.name.trim().length !== 0 &&
        category.id !== undefined
      ) {
        produto = { ...produto, category };
      }
      if (id) {
        const response = await api.put(
          `${PROFILE_API_BASE + PROFILE_LOCATION}/${
            profileId + PRODUCTS_LOCATION
          }/${id}?${QUERY_ACCESS + userToken}`,

          produto
        );
        return response;
      }
      const response = await api.post(
        `${PROFILE_API_BASE + PROFILE_LOCATION}/${
          profileId + PRODUCTS_LOCATION
        }?${QUERY_ACCESS + userToken}`,

        produto
      );
      return response;
    } catch (error) {
      console.log(error);
      const errorObj = { ...error };
      console.log({ ...error });
      let message = "Não foi possível cadastrar o produto!";
      if (errorObj.isAxiosError) {
        switch (errorObj.response.status) {
          case 400:
            message = "Requisição inválida.";
            break;
          case 401:
            message = "Acesso não autorizado!";
            break;
          case 422:
            message = errorObj.response.data.content;
            break;
          case 500:
            message = "Erro inesperado. Tente novamente em alguns minutos.";
            break;
          default:
            message = "Erro ao notificar cliente";
            break;
        }
      }
      this.setState({
        mensagemError: message,
      });
      return false;
    }
  };

  // Método para validar os campos do formulário
  validateFormValues = () => {
    const { name, desc, price, imageUrl, id } = this.state;
    const { lang } = this.props;
    let data = {};
    if (!id || !imageUrl) {
      if (!imageUrl) {
        data = {
          ...data,
          imageUrlError: true,
        };
      }
    }
    if (!name || name.trim().length === 0) {
      data = {
        ...data,
        nameShowTooltip: true,
        nameTooltipMessage: !name
          ? lang.nameRequiredError
          : lang.nameInvalidError,
        nameError: true,
      };
    }
    if (!desc || desc.trim().length === 0) {
      data = {
        ...data,
        descShowTooltip: true,
        descTooltipMessage: !desc
          ? lang.descriptionRequiredError
          : lang.descriptionInvalidError,
        descError: true,
      };
    }
    if (!price || price.toString().trim().length === 0) {
      data = {
        ...data,
        priceShowTooltip: true,
        priceTooltipMessage: !price
          ? lang.priceRequiredError
          : lang.priceInvalidError,
        priceError: true,
      };
    }
    if (Object.keys(data).length > 0) {
      console.log("aqui erro", data);
      this.setState(data);
    } else {
      this.handleModalSubmit();
    }
  };

  render() {
    const { lang } = this.props;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <CustomModal
          open={this.state.openLoadingModal}
          onClose={() => this.setState({ openLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">
              {!this.state.id
                ? lang.registerProductLoading
                : lang.editProductLoading}
            </Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomConfirmationModal
          title={
            !this.state.id
              ? lang.registerSuccessTitle
              : lang.registerSuccessTitle
          }
          open={this.state.openSuccessModal}
          subtitle={
            !this.state.id
              ? lang.registerSuccessSubTitle
              : lang.editSuccessSubTitle
          }
          description={
            !this.state.id
              ? lang.registerSuccessDescription
              : lang.editSuccessDescription
          }
          confirmationText={lang.registerSuccessConfirmationText}
          confirmationAction={() => this.setState({ openSuccessModal: false })}
          onClose={() => this.setState({ openSuccessModal: false })}
        />

        <CustomConfirmationModal
          title={!this.state.id ? lang.registerErrorTitle : lang.editErrorTitle}
          open={this.state.openErrorModal}
          subtitle={
            !this.state.id ? lang.registerErrorSubTitle : lang.editErrorSubTitle
          }
          description={
            !this.state.id
              ? lang.registerErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
              : lang.editErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
          }
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() => this.setState({ openErrorModal: false })}
          onClose={() => this.setState({ openErrorModal: false })}
        />

        <CustomConfirmationModal
          title={lang.registerErrorTitle}
          open={this.state.openErrorModalItem}
          subtitle={lang.registerItemProductErrorSubTitle}
          description={lang.registerErrorDescription.replace(
            "@MSG",
            this.state.mensagemError
          )}
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() =>
            this.setState({ openErrorModalItem: false })
          }
          onClose={() => this.setState({ openErrorModalItem: false })}
        />

        <CustomConfirmationModal
          title={lang.deleteErrorTitle}
          open={this.state.openDeleteErrorModal}
          subtitle={lang.deleteErrorSubTitle}
          description={lang.deleteErrorDescription.replace(
            "@MSG",
            this.state.mensagemError
          )}
          confirmationText={lang.deleteErrorConfirmationText}
          confirmationAction={() =>
            this.setState({ openDeleteErrorModal: false })
          }
          onClose={() => this.setState({ openDeleteErrorModal: false })}
        />

        {this.renderTable()}
        {this.renderSideModal()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(Products);
