// Bibliotecas
import React, { Component } from "react";
import { Typography, IconButton, Collapse, Button } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp, Print } from "@material-ui/icons";
import { formatDistance } from "date-fns";
import { connect } from "react-redux";
// Componentes
import CustomModal from "../../../../components/CustomModal";
import OrderItemList from "./OrderItemList";
// Recursos visuais.
import { ButtonContainer } from "../../../../components/CustomConfirmationModal/styles.css";
import {
  OrderInfoItemContainer,
  HeaderContainer,
  TakehomeContainer,
  ContentContainer,
} from "./styles.css";
// Dados estaticos
import { states } from "../../../../static/orderStates";
import { USER_AUTH_TOKEN } from "../../../../static/LocalStorageTags";
// FUncoes
import { changeOrderState, printOrder } from "../../../../functions/order";
import { getItem } from "../../../../utils/localStorage";
import { showSnackbar } from "../../../../redux/actions";

// Importa o 'locale' para o texto de formatação de data.
// eslint-disable-next-line import/no-dynamic-require

// eslint-disable-next-line import/no-dynamic-require
const { default: locale } = require(`date-fns/locale/${
  navigator.language ||
  navigator.language.split(/[-_]/)[0] ||
  navigator.userLanguage.split(/[-_]/)[0] ||
  "pt-BR"
}`);

class OrderInfoModal extends Component {
  state = { collapseOpen: true };

  /**
   * Método que renderiza o desconto.
   */
  renderDiscount = () => {
    const { selectedOrder, lang } = this.props;
    if (
      selectedOrder &&
      selectedOrder.discount &&
      selectedOrder.discountPercentage &&
      selectedOrder.discount > 0 &&
      selectedOrder.discountPercentage > 0
    ) {
      const finalPrice = selectedOrder.totalPrice
        .toFixed(2)
        .replace(".", ",")
        .replace(/\d(?=(\d{3})+\.)/g, "$&.");
      return (
        <>
          <Typography
            variant="subtitle2"
            className="error-msg total-value-label"
          >
            {lang.discountOrderFinalPriceLabel
              .replace("@discountPercentage", selectedOrder.discountPercentage)
              .replace("@finalPrice", finalPrice)}
          </Typography>
        </>
      );
    }
    return true;
  };

  render() {
    const { selectedOrder, onClose, open, lang, startLoading } = this.props;
    if (!selectedOrder || Object.keys(selectedOrder).length === 0) {
      return true;
    }
    return (
      <CustomModal open={open} onClose={onClose}>
        <div>
          <HeaderContainer className="flex-row justify-space-between align-items-center">
            <Typography className="title" variant="h5">
              {lang.orderNumberLabel.replace("@orderId", selectedOrder.id)}
            </Typography>

            <IconButton
              onClick={() => printOrder(selectedOrder)}
              aria-label="Print"
            >
              <Print className="print-icon" />
            </IconButton>
          </HeaderContainer>

          <ContentContainer>
            {selectedOrder.takeHome ? (
              <TakehomeContainer>
                <h2>Para viagem</h2>
              </TakehomeContainer>
            ) : (
              true
            )}

            <OrderInfoItemContainer>
              <Typography variant="subtitle2">
                {lang.orderTime}:{" "}
                {new Date(selectedOrder.dtCreated).toLocaleString()}
              </Typography>
            </OrderInfoItemContainer>

            <OrderInfoItemContainer>
              <Typography variant="subtitle2">
                {lang.orderStatus}: {selectedOrder.orderStatus.value}
              </Typography>
            </OrderInfoItemContainer>

            <OrderInfoItemContainer>
              <Typography variant="subtitle2">
                {lang.client}: {selectedOrder.userName}
              </Typography>
            </OrderInfoItemContainer>

            <OrderInfoItemContainer>
              <Typography variant="subtitle2">
                {selectedOrder.orderStatus.index < states.ORDER_IS_READY.index
                  ? lang.waitingTime
                  : lang.finishedTime}
                :{" "}
                {formatDistance(selectedOrder.dtCreated, new Date(), {
                  addSuffix: true,
                  locale,
                })}
              </Typography>
            </OrderInfoItemContainer>

            {selectedOrder.estimateTime && selectedOrder.estimateTime > 0 ? (
              <OrderInfoItemContainer>
                <Typography variant="subtitle2">
                  {lang.estimateTime}: {selectedOrder.estimateTime}{" "}
                  {lang.minutes}
                </Typography>
              </OrderInfoItemContainer>
            ) : (
              true
            )}

            {selectedOrder.tableNumber &&
            selectedOrder.tableNumber.trim().length > 0 ? (
              <OrderInfoItemContainer>
                <Typography variant="subtitle2">
                  {lang.deliverOrderAt}: {selectedOrder.tableNumber}
                </Typography>
              </OrderInfoItemContainer>
            ) : (
              true
            )}

            {selectedOrder.note ? (
              <OrderInfoItemContainer>
                <Typography variant="subtitle2">
                  {lang.observation}: {selectedOrder.note}
                </Typography>
              </OrderInfoItemContainer>
            ) : (
              ""
            )}

            <OrderInfoItemContainer>
              <Typography variant="subtitle2">
                {lang.orderItemsLabel}:
                <IconButton
                  variant="contained"
                  size="small"
                  onClick={() =>
                    this.setState({
                      collapseOpen: !this.state.collapseOpen,
                    })
                  }
                >
                  {this.state.collapseOpen ? (
                    <KeyboardArrowUp fontSize="small" />
                  ) : (
                    <KeyboardArrowDown fontSize="small" />
                  )}
                </IconButton>
              </Typography>
              <Collapse
                in={this.state.collapseOpen}
                timeout="auto"
                unmountOnExit
              >
                <OrderItemList selectedOrder={selectedOrder} lang={lang} />
              </Collapse>
            </OrderInfoItemContainer>

            <OrderInfoItemContainer>
              <Typography variant="subtitle2" className="total-value-label">
                {lang.orderValueLabel}: {lang.currencyType}
                {selectedOrder.price
                  .toFixed(2)
                  .replace(".", ",")
                  .replace(/\d(?=(\d{3})+\.)/g, "$&.")}
              </Typography>
            </OrderInfoItemContainer>
            <OrderInfoItemContainer>
              {this.renderDiscount()}
            </OrderInfoItemContainer>

            <ButtonContainer className="flex justify-evenly">
              {selectedOrder.orderStatus.index ===
                states.ORDER_IS_READY.index && (
                <Button
                  variant="contained"
                  color="primary"
                  className="reject-btn"
                  onClick={async () => {
                    const token = getItem(USER_AUTH_TOKEN);
                    startLoading();
                    // Solicita mudança de estado.
                    this.props.showSnackbar(lang.withdrawnSuccess, "success");
                    onClose();
                    changeOrderState(
                      selectedOrder.id,
                      selectedOrder.profileId,
                      states.ORDER_WITHDRAWN.index,
                      token,
                      null
                    );
                  }}
                >
                  {lang.withdrawnLabel}
                </Button>
              )}

              {selectedOrder.orderStatus &&
                selectedOrder.orderStatus.index &&
                selectedOrder.orderStatus.index ===
                  states.REMOVE_AT_COUNTER.index && (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={async () => {
                      const token = getItem(USER_AUTH_TOKEN);
                      startLoading();
                      await changeOrderState(
                        selectedOrder.id,
                        selectedOrder.profileId,
                        states.PREPARING.index,
                        token,
                        null
                      );
                      // Solicita mudança de estado.
                      onClose();
                      this.props.showSnackbar(
                        lang.startPreparingSuccess,
                        "success"
                      );
                    }}
                  >
                    {lang.startPreparingLabel}
                  </Button>
                )}

              <Button variant="contained" color="primary" onClick={onClose}>
                {lang.close}
              </Button>
            </ButtonContainer>
          </ContentContainer>
        </div>
      </CustomModal>
    );
  }
}

export default connect(null, { showSnackbar })(OrderInfoModal);
