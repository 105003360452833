import firebase from "firebase/app";
import "firebase/auth";
import {
  STORE_USER_DATA,
  LOGOUT_USER,
  SELECT_PROFILE,
  TYPE_LOGIN,
  FETCH_ROLE,
} from "./types";
import { clearStorage } from "../../utils/localStorage";

/**
 * Esse método será utilizado sempre que for preciso guardar dados do usuário no store do redux.
 *
 * @param {Object} user Objeto com dados do usuário no servidor.
 */
export const storeUserData = (user) => (dispatch) => {
  try {
    dispatch({ type: STORE_USER_DATA, payload: user });
  } catch (error) {
    console.log(`storeUserData: ${error}`);
  }
};

/**
 * Esse método será utilizado sempre que for preciso guardar as regras do usuário no store do redux.
 *
 * @param {Object} roles Objeto com dados das regras no servidor.
 */
export const storeUserRoles = (permissions) => (dispatch) => {
  try {
    dispatch({ type: FETCH_ROLE, payload: permissions });
  } catch (error) {
    console.log(`storeUserData: ${error}`);
  }
};
/**
 * Esse método será utilizado sempre que for preciso deslogar o usuário.
 */
export const logUserOut = () => async (dispatch) => {
  try {
    clearStorage();
    await firebase.auth().signOut();
    dispatch({ type: LOGOUT_USER });
  } catch (error) {
    console.log(`logUserOut: ${error}`);
  }
};

/**
 * Esse método será para definir o tipo de login.
 */
export const logUserType = (typeLogin) => async (dispatch) => {
  try {
    dispatch({ type: TYPE_LOGIN, payload: typeLogin });
  } catch (error) {
    console.log(`logUserType: ${error}`);
  }
};

/**
 * Método para selecionar um perfil para o estado do redux
 *
 * @param {Object} profile objeto do perfil
 */
export const selectProfile = (profile) => (dispatch) => {
  try {
    dispatch({ type: SELECT_PROFILE, payload: profile });
  } catch (error) {
    console.log(`selectProfile: ${error}`);
  }
};
