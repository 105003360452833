/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  TableCell,
  TextField,
  IconButton,
  Grid,
  Typography,
  LinearProgress,
  MenuItem,
  FormControl,
  Button,
  Dialog,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import CreatableSelect from "react-select/creatable";
import api from "../../utils/API";

// Funções e dados.
import {
  PROFILE_API_BASE,
  PROFILE_LOCATION,
  QUERY_ACCESS,
  PRODUCTS_LOCATION,
  CONFIG_LOCATION,
} from "../../static/URL";
import { fetchErrors } from "../../functions/error";

import {
  USER_SELECTED_PROFILE,
  USER_AUTH_TOKEN,
} from "../../static/LocalStorageTags";
import { getItem } from "../../utils/localStorage";
import { loadItemProduct } from "../../functions/itemProduct";
import { loadProducts } from "../../functions/products";
// Componentes.
import CustomNumberMaskedInput from "../../components/CustomNumberMaskedInput";
import CustomTooltip from "../../components/CustomTooltip";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
import CustomSideModal from "../../components/CustomSideModal";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";
import CustomModal from "../../components/CustomModal";
// Recursos visuais.
import {
  CustomHeaderContainer,
  ContentContainer,
  ButtonContainer,
  DescriptionContainer,
  HeaderContainer,
} from "./style.css";

class SettingsItems extends Component {
  state = {
    openLoadingModal: false,
    openErrorModal: false,
    openSuccessModal: false,
    // Table
    itemProducts: [],
    open: false,
    selected: [],
    order: "asc",
    orderBy: "name",
    page: 0,
    rowsPerPage: 10,
    // SideModal
    sideModalOpen: false,
    id: undefined,
    name: "",
    nameError: false,
    nameShowTooltip: false,
    nameTooltipMessage: "",
    seq: undefined,
    seqError: false,
    seqShowTooltip: false,
    seqTooltipMessage: "",
    min: undefined,
    minError: false,
    minShowTooltip: false,
    minTooltipMessage: "",
    max: undefined,
    maxError: false,
    maxShowTooltip: false,
    maxTooltipMessage: "",
    configType: "",
    configTypeError: false,
    configTypeShowTooltip: false,
    configTypeTooltipMessage: "",
    qtda: undefined,
    qtdaError: false,
    qtdaShowTooltip: false,
    qtdaTooltipMessage: "",
    price: undefined,
    priceError: false,
    priceShowTooltip: false,
    priceTooltipMessage: "",
    productsNameError: false,
    productsNameShowTooltip: false,
    productsNameTooltipMessage: "",
    ItemError: false,
    itemShowTooltip: false,
    ItemTooltipMessage: "",
    isEnable: true,
    visibleValue: "",
    selectedID: "",
    mensagemError: "",
    // Variavel para controlar modal de deletar o item
    deleteItemModalOpen: false,
    delete: false,
    openDeleteErrorModal: false,
    openDeleteLoadingModal: false,
    idDelete: undefined,
    openLoadItem: false,
    isLoading: false,
    products: [],
    items: [],
    idProduct: undefined,
    configs: [],
    confiselectedID: "",
    configvisibleValue: "",
    item: {},
    itemsValue: [],
    productsSelected: [],
    itemsSelected: [],
  };

  componentDidMount() {
    this.loadItemProducts();
    this.loadProducts();
  }

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user.selectedProfile) {
      return this.setState({ itemList: [] });
    }
    if (
      (!this.props.user.selectedProfile && nextProps.user.selectedProfile) ||
      (nextProps.user.selectedProfile &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id !==
          nextProps.user.selectedProfile.id)
    ) {
      this.loadItemProducts();
      this.loadProducts();
    }
  }

  /*
   * Método que carega todas items do produto cadastradas.
   */
  loadItemProducts = async () => {
    const userToken = getItem(USER_AUTH_TOKEN);
    const profileId = await this.getUserProfile();
    const itemList = await loadItemProduct(profileId, userToken);
    await this.setState({ itemList });
  };

  /*
   * Método que carega todos os produtos.
   */
  loadProducts = async () => {
    const userToken = getItem(USER_AUTH_TOKEN);
    const profileId = await this.getUserProfile();
    const products = await loadProducts(profileId, userToken);
    await this.setState({ products });
  };

  loadConfigs = async (id) => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const { data: config } = await api.get(
        `${PROFILE_API_BASE + PROFILE_LOCATION}/${
          profileId + PRODUCTS_LOCATION
        }/${id + CONFIG_LOCATION}?${QUERY_ACCESS + userToken}`
      );
      await this.setState({ configs: config ? config : [] });
    } catch (error) {
      console.log(error);
      const errorObj = { ...error };
      console.log({ ...error });
      let message = "Não foi possível cadastrar o item do produto!";
      if (errorObj.isAxiosError) {
        switch (errorObj.response.status) {
          case 400:
            message = "Solicitação inválida.";
            break;
          case 401:
            message = "Acesso não autorizado!";
            break;
          case 422:
            message = errorObj.response.data.content;
            break;
          case 404:
            message = "Configurações do produto não encontrada para o perfil!";
            break;
          case 500:
            message = "Erro inesperado. Tente novamente em alguns minutos.";
            break;
          default:
            message = "Erro ao cadastrar item!";
            break;
        }
        this.setState({
          mensagemError: message,
        });
      }
    }
  };

  // Retorna o UserProfile
  getUserProfile = async () => {
    const storageProfile = getItem(USER_SELECTED_PROFILE);
    if (storageProfile) {
      return JSON.parse(storageProfile).id;
    }
    const { user } = this.props;
    if (user && user.selectedProfile) {
      return user.selectedProfile.id;
    }
    const userToken = getItem(USER_AUTH_TOKEN);
    const { data: me } = await api.get(
      `${PROFILE_API_BASE + PROFILE_LOCATION}/me?${QUERY_ACCESS + userToken}`
    );
    const lastDataMe = me.sort(
      (a, b) => new Date(b.dtCreated) - new Date(a.dtCreated)
    );
    return lastDataMe[0].id;
  };

  handleChange = async (newValue) => {
    await this.loadConfigs(newValue.id);
    await this.setState({
      selectedID: newValue.id ? newValue.id : undefined,
      visibleValue: newValue.name ? newValue.name : "",
      productsSelected: newValue || [],
    });
  };

  handleChangeConfig = async (event) => {
    await this.setState({
      confiselectedID: event.currentTarget.id,
      configvisibleValue: event.target.value,
    });

    await this.setState({
      configType: this.state.confiselectedID
        ? this.state.confiselectedID
        : undefined,
    });
  };

  renderSelectsProducts = () => {
    return (
      <CreatableSelect
        value={this.state.productsSelected}
        deleteRemoves={false}
        isClearable
        placeholder="Produtos"
        options={this.state.products}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.name}
        getNewOptionData={(inputValue, optionLabel) => ({
          id: inputValue,
          name: optionLabel,
        })}
        onChange={this.handleChange}
      />
    );
  };

  renderModalItems = () => {
    const { lang } = this.props;
    return (
      <div>
        <Dialog open={this.state.open} aria-labelledby="form-dialog-title">
          <HeaderContainer>{lang.itemTitle}</HeaderContainer>
          <ContentContainer>
            <DescriptionContainer>
              <CustomTooltip
                onClose={() =>
                  this.setState({
                    qtdaShowTooltip: false,
                    qtdaError: false,
                  })
                }
                open={this.state.qtdaShowTooltip}
                title={this.state.qtdaTooltipMessage}
              >
                <CustomNumberMaskedInput
                  className="width-90 input-margin "
                  value={this.state.qtda}
                  onChange={(e) => this.setState({ qtda: e })}
                  id="qtda"
                  label={lang.qtda}
                />
              </CustomTooltip>
              <CustomTooltip
                onClose={() =>
                  this.setState({
                    qtdaShowTooltip: false,
                    qtdaError: false,
                  })
                }
                open={this.state.qtdaShowTooltip}
                title={this.state.qtdaTooltipMessage}
              >
                <CustomNumberMaskedInput
                  className="width-90 input-margin "
                  required
                  error={this.state.priceError}
                  value={this.state.price}
                  onChange={(e) => this.setState({ price: e })}
                  id="price"
                  label={lang.price}
                  prefix="R$"
                  thousandSeparator="."
                  decimalSeparator=","
                />
              </CustomTooltip>
            </DescriptionContainer>
            <ButtonContainer className="flex justify-evenly">
              <Button
                variant="contained"
                color="primary"
                onClick={this.getItemOptions}
              >
                {lang.ok}
              </Button>
            </ButtonContainer>
          </ContentContainer>
        </Dialog>
      </div>
    );
  };

  customHeaderOptions = () => {
    return (
      <CustomHeaderContainer>
        {this.renderSelectsProducts()}
      </CustomHeaderContainer>
    );
  };

  getItemOptions = async () => {
    const { items } = this.state;

    const price = this.state.price ? this.state.price : 0;
    let qtda = 1;
    if (this.state.qtda && this.state.qtda > 0) {
      qtda = this.state.qtda;
    }
    const value = {
      min: 0,
      max: 0,
      value: qtda,
      price: price.toString().split("R$")[1]
        ? parseFloat(
            price
              .toString()
              .split("R$")[1]
              .split(".")
              .join("")
              .replace(",", ".")
          )
        : parseFloat(price.toString().split(".").join("").replace(",", ".")),
      item: {
        id: this.state.itemsValue.id,
        name: this.state.itemsValue.name,
        price: this.state.itemsValue.price,
        isEnable: this.state.itemsValue.enable,
      },
    };
    items.push(value);
    this.setState({ items });
    this.setState({ open: false });
    this.setState({ qtda: undefined, price: undefined });
  };

  /*
   * Método que verifica a mudança de statos dos items.
   * @param {newValue} novos valores selecionado.
   * @param {actionMeta} retorna ação de adicionar e deletar.
   */
  onChange = async (newValue, actionMeta) => {
    this.setState({ isLoading: true });
    switch (actionMeta.action) {
      case "remove-value":
        const { items } = this.state;
        const { itemsSelected } = this.state;

        for (let i = 0; i < items.length; i++) {
          if (items[i].item.id === actionMeta.removedValue.id) {
            items.splice(i, 1);
            itemsSelected.splice(i, 1);
            this.setState({ itemsSelected });
            this.setState({ items });
          }
        }
        await this.setState({ itemsSelected: newValue });
        this.setState({ isLoading: false });
        break;
      case "select-option":
        await this.setState({ itemsSelected: newValue });
        await this.setState({ itemsValue: actionMeta.option });
        this.setState({ isLoading: false });
        await this.setState({ open: true });
        break;
      default:
        this.setState({ isLoading: false });
        break;
    }
  };

  // Renderiza a tabela de produtos
  renderTable = () => {
    const { lang } = this.props;
    const { configs } = this.state;
    const rows = [
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "name",
      },
      {
        id: "tipo",
        numeric: false,
        disablePadding: false,
        label: "Tipo",
      },
      {
        id: "dtCreated",
        numeric: false,
        disablePadding: false,
        label: lang.createdAt,
      },
      {
        id: "del",
        numeric: false,
        disablePadding: false,
        label: "Excluir",
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        showAddButton
        customHeaderOptions={() => this.customHeaderOptions()}
        onAddEvent={() => this.setState({ sideModalOpen: true })}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        onClick={this.onClickItemEdition}
        handleSelectAllClick={(e) => {
          if (e.target.checked) {
            this.setState((state) => ({
              selected: state.itemList.map((n) => n.id),
            }));
            return;
          }
          this.setState({ selected: [] });
        }}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={configs.map((c) => ({
          id: c.id,
          name: c.name,
          dtCreated: new Date(c.dtCreated)
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("/"),
          configType: c.configType,
          items: c.items,
          max: c.max,
          min: c.min,
          seq: c.seq,
          itemsSelected: c.items,
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell component="th" scope="row" padding="none">
                {n.name.length > 20 ? `${n.name.substr(0, 20)}...` : n.name}
              </TableCell>
              {/* <TableCell>{n.itemList > 0 ? n.itemList : "-"}</TableCell> */}
              <TableCell>
                {n.configType === "ADDITIONAL"
                  ? lang.additional
                  : lang.mandatory}
              </TableCell>
              <TableCell>{n.dtCreated}</TableCell>
              <TableCell>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    this.openDeleteModal(n.id);
                  }}
                  aria-label="Delete"
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </>
          );
        }}
      />
    );
  };

  // Renderiza a modal de deletar os items.
  openDeleteModal = (id) => {
    this.setState({
      idDelete: id,
      sideModalOpen: false,
      deleteItemModalOpen: true,
    });
  };

  /**
   * Método para deletar o item na base de dados.
   */
  deleteItem = async () => {
    this.setState({ sideModalOpen: false });
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const { idDelete } = this.state;
      const productId = this.state.selectedID;

      if (idDelete) {
        this.setState({ openDeleteLoadingModal: true });
        await api.delete(
          `${PROFILE_API_BASE + PROFILE_LOCATION}/${
            profileId + PRODUCTS_LOCATION
          }/${productId + CONFIG_LOCATION}/${idDelete}?${
            QUERY_ACCESS + userToken
          }`,
          {
            idDelete,
          }
        );
        this.setState({ openDeleteLoadingModal: false });
        await this.loadConfigs(this.state.selectedID);
      }
    } catch (error) {
      const errorObj = { ...error };
      this.setState({
        openDeleteLoadingModal: false,
      });
      let message = "";
      message = fetchErrors(
        errorObj.response.status,
        errorObj.response.data.content,
        "excluir a configuração!"
      );
      this.setState({
        mensagemError: message,
        openDeleteErrorModal: true,
        openLoadingModal: false,
        deleteCategoryModalOpen: false,
        openDeleteLoadingModal: false,
      });
    }
  };

  /**
   *
   * @param {Object} item Objeto do item do produto.
   */
  onClickItemEdition = async (config) => {
    const { selected } = this.state;
    await this.setState({
      selected: selected.filter((s) => s !== config.id),
      id: config.id,
      name: config.name,
      seq: config.seq,
      min: config.min,
      max: config.max,
      configvisibleValue: config.configType,
      configType: config.configType,
      sideModalOpen: true,
      items: config.items,
    });

    let { itemsSelected } = this.state;
    itemsSelected = await config.items.map(function (e) {
      return e.item;
    });

    await this.setState({ itemsSelected });
  };

  // Renderiza a modal de criar produtos
  renderSideModal = () => {
    const { lang } = this.props;
    const { sideModalOpen } = this.state;
    return (
      <CustomSideModal
        open={sideModalOpen}
        handleClose={this.handleCloseModal}
        title={!this.state.id ? lang.sideModalTitle : lang.sideModalTitleEdit}
        confirmationAction={this.validateFormValues}
        confirmationText={lang.save}
        cancelationAction={this.handleCloseModal}
        cancelationText={lang.cancel}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  productsNameShowTooltip: false,
                  productsNameError: false,
                })
              }
              open={this.state.productsNameShowTooltip}
              title={this.state.productsNameTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.products}
                error={this.state.productsNameError}
                value={this.state.visibleValue}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  nameShowTooltip: false,
                  nameError: false,
                })
              }
              open={this.state.nameShowTooltip}
              title={this.state.nameTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.config}
                error={this.state.nameError}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  seqShowTooltip: false,
                  seqError: false,
                })
              }
              open={this.state.seqShowTooltip}
              title={this.state.seqTooltipMessage}
            >
              <CustomNumberMaskedInput
                className="width-90 input-margin "
                value={this.state.seq}
                onChange={(e) => this.setState({ seq: e })}
                id="seq"
                label={lang.sequence}
              />
            </CustomTooltip>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  minShowTooltip: false,
                  minError: false,
                })
              }
              open={this.state.minShowTooltip}
              title={this.state.minTooltipMessage}
            >
              <CustomNumberMaskedInput
                className="width-90 input-margin "
                value={this.state.min}
                onChange={(e) => this.setState({ min: e })}
                id="min"
                label={lang.minimum}
              />
            </CustomTooltip>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  maxShowTooltip: false,
                  maxError: false,
                })
              }
              open={this.state.maxShowTooltip}
              title={this.state.maxTooltipMessage}
            >
              <CustomNumberMaskedInput
                className="width-90 input-margin "
                value={this.state.max}
                onChange={(e) => this.setState({ max: e })}
                id="min"
                label={lang.maximum}
              />
            </CustomTooltip>
          </Grid>

          <Grid item xs={12}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  configTypeShowTooltip: false,
                  configTypeError: false,
                })
              }
              open={this.state.configTypeShowTooltip}
              title={this.state.configTypeTooltipMessage}
            >
              <FormControl fullWidth>
                <TextField
                  select
                  margin="dense"
                  variant="outlined"
                  label={lang.configType}
                  required
                  error={this.state.configTypeError}
                  onChange={this.handleChangeConfig}
                  value={this.state.configvisibleValue}
                >
                  <MenuItem key="ADDITIONAL" value="ADDITIONAL" id="ADDITIONAL">
                    {lang.additional}
                  </MenuItem>
                  <MenuItem key="MANDATORY" value="MANDATORY" id="MANDATORY">
                    {lang.mandatory}
                  </MenuItem>
                </TextField>
              </FormControl>
            </CustomTooltip>
          </Grid>

          <Grid item xs={12}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  itemShowTooltip: false,
                  itemTypeError: false,
                })
              }
              open={this.state.itemShowTooltip}
              title={this.state.itemTooltipMessage}
            >
              <CreatableSelect
                value={this.state.itemsSelected}
                deleteRemoves={false}
                isMulti
                required
                error={this.state.itemError}
                placeholder="Items do Produto"
                options={this.state.itemList}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.name}
                getNewOptionData={(inputValue, optionLabel) => ({
                  id: inputValue,
                  name: optionLabel,
                })}
                onChange={this.onChange}
                isLoading={this.state.isLoading}
              />
            </CustomTooltip>
          </Grid>
        </Grid>
      </CustomSideModal>
    );
  };

  // Método realizado ao fechar a modal de adição do item.
  handleCloseModal = () => {
    this.setState({
      sideModalOpen: false,
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      seq: undefined,
      seqError: false,
      seqShowTooltip: false,
      seqTooltipMessage: "",
      min: undefined,
      minError: false,
      minShowTooltip: false,
      minTooltipMessage: "",
      max: undefined,
      maxError: false,
      maxShowTooltip: false,
      maxTooltipMessage: "",
      configType: "",
      configTypeError: false,
      configTypeShowTooltip: false,
      configTypeTooltipMessage: "",
      value: 0,
      valueError: false,
      valueShowTooltip: false,
      valueTooltipMessage: "",
      price: 0.0,
      priceError: false,
      priceShowTooltip: false,
      priceTooltipMessage: "",
      itemsSelected: [],
      isEnable: true,
      mensagemError: "",
      idDelete: undefined,
      items: [],
      idProduct: undefined,
      confiselectedID: "",
      configvisibleValue: "",
      item: {},
      itemsValue: [],
    });
  };

  // Método realizado para envio dos dados do formulário para o back
  handleModalSubmit = async () => {
    this.setState({ openLoadingModal: true });
    const configRes = await this.registerConfig();
    if (configRes) {
      this.setState({
        openLoadingModal: false,
        openSuccessModal: true,
        sideModalOpen: false,
      });
      await this.loadItemProducts();
      await this.loadProducts();
      await this.loadConfigs(this.state.selectedID);

      this.handleCloseModal();
    } else {
      this.setState({ openLoadingModal: false, openErrorModal: true });
    }
  };

  /**
   * Método para cadastrar item do produto na base de dados.
   */
  registerConfig = async () => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const { name, id, seq, min, max, configType, items } = this.state;
      const productId = this.state.selectedID;
      if (id) {
        const response = await api.put(
          `${PROFILE_API_BASE + PROFILE_LOCATION}/${
            profileId + PRODUCTS_LOCATION
          }/${productId + CONFIG_LOCATION}/${id}?${QUERY_ACCESS + userToken}`,
          {
            id,
            name,
            seq,
            min,
            max,
            configType,
            items,
          }
        );
        return response;
      }
      const response = await api.post(
        `${PROFILE_API_BASE + PROFILE_LOCATION}/${
          profileId + PRODUCTS_LOCATION
        }/${productId + CONFIG_LOCATION}?${QUERY_ACCESS + userToken}`,
        {
          id,
          name,
          seq,
          min,
          max,
          configType,
          items,
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      const errorObj = { ...error };
      console.log({ ...error });
      let msg = "";
      if (this.state.id) {
        msg = "editar as configurações!";
      } else {
        msg = "cadastrar as configurações!";
      }
      let message = "";
      message = fetchErrors(
        errorObj.response.status,
        errorObj.response.data.content,
        msg
      );
      this.setState({
        mensagemError: message,
      });
      return false;
    }
  };

  // Método para validar os campos do formulário
  validateFormValues = () => {
    const { name, seq, min, max, configType, items, visibleValue } = this.state;
    const { lang } = this.props;
    let data = {};
    if (!visibleValue || visibleValue.toString().trim().length === 0) {
      data = {
        ...data,
        productsNameShowTooltip: true,
        productsNameTooltipMessage: !visibleValue
          ? lang.productsNameRequiredError
          : lang.productsNameInvalidError,
        productsNameError: true,
      };
    }

    if (!name || name.trim().length === 0) {
      data = {
        ...data,
        nameShowTooltip: true,
        nameTooltipMessage: !name
          ? lang.nameRequiredError
          : lang.nameInvalidError,
        nameError: true,
      };
    }

    if (!seq || seq.toString().trim().length === 0) {
      data = {
        ...data,
        seqShowTooltip: true,
        seqTooltipMessage: !visibleValue
          ? lang.seqRequiredError
          : lang.seqInvalidError,
        seqError: true,
      };
    }

    if (!min || min.toString().trim().length === 0) {
      data = {
        ...data,
        minShowTooltip: true,
        minTooltipMessage: !min ? lang.minRequiredError : lang.minInvalidError,
        minError: true,
      };
    }

    if (!max || max.toString().trim().length === 0) {
      data = {
        ...data,
        maxShowTooltip: true,
        maxTooltipMessage: !max ? lang.maxRequiredError : lang.maxInvalidError,
        maxError: true,
      };
    }

    if (
      max &&
      max.toString().trim().length > 0 &&
      min &&
      min.toString().trim().length > 0
    ) {
      if (parseFloat(min) > parseFloat(max)) {
        data = {
          ...data,
          minShowTooltip: true,
          minTooltipMessage: lang.minmaxInvalidError,
          minError: true,
        };
      }
    }

    if (!configType || configType.toString().trim().length === 0) {
      data = {
        ...data,
        configTypeShowTooltip: true,
        configTypeTooltipMessage: !configType
          ? lang.configTypeRequiredError
          : lang.configTypeInvalidError,
        configTypeError: true,
      };
    }

    if (!items || items.length === 0) {
      data = {
        ...data,
        itemShowTooltip: true,
        itemTooltipMessage: !items
          ? lang.itemRequiredError
          : lang.itemInvalidError,
        itemError: true,
      };
    }

    if (Object.keys(data).length > 0) {
      this.setState(data);
    } else {
      this.handleModalSubmit();
    }
  };

  render() {
    const { lang } = this.props;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <CustomModal
          open={this.state.openLoadingModal}
          onClose={() => this.setState({ openLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">
              {!this.state.id ? lang.registerItemLoading : lang.editItemLoading}
            </Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomConfirmationModal
          title={
            !this.state.id
              ? lang.registerSuccessTitle
              : lang.registerSuccessTitle
          }
          open={this.state.openSuccessModal}
          subtitle={
            !this.state.id
              ? lang.registerSuccessSubTitle
              : lang.editSuccessSubTitle
          }
          description={
            !this.state.id
              ? lang.registerSuccessDescription
              : lang.editSuccessDescription
          }
          confirmationText={lang.registerSuccessConfirmationText}
          confirmationAction={() => this.setState({ openSuccessModal: false })}
          onClose={() => this.setState({ openSuccessModal: false })}
        />

        <CustomConfirmationModal
          title={!this.state.id ? lang.registerErrorTitle : lang.editErrorTitle}
          open={this.state.openErrorModal}
          subtitle={
            !this.state.id ? lang.registerErrorSubTitle : lang.editErrorSubTitle
          }
          description={
            !this.state.id
              ? lang.registerErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
              : lang.editErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
          }
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() => this.setState({ openErrorModal: false })}
          onClose={() => this.setState({ openErrorModal: false })}
        />

        {/* Modal de confirmação para deletar categorias */}
        <CustomConfirmationModal
          title={lang.deleteItemModalTitle}
          description={lang.deletarItemModalDescription}
          open={this.state.deleteItemModalOpen}
          confirmationText={lang.deleteItemConfirmationText}
          confirmationAction={async () => this.deleteItem()}
          cancelationText={lang.cancelationText}
          cancelationAction={() =>
            this.setState({ deleteItemModalOpen: false })
          }
          onClose={() => this.setState({ deleteItemModalOpen: false })}
        />

        <CustomConfirmationModal
          title={lang.deleteErrorTitle}
          open={this.state.openDeleteErrorModal}
          subtitle={lang.deleteErrorSubTitle}
          description={lang.deleteErrorDescription.replace(
            "@MSG",
            this.state.mensagemError
          )}
          confirmationText={lang.deleteErrorConfirmationText}
          confirmationAction={() =>
            this.setState({ openDeleteErrorModal: false })
          }
          onClose={() => this.setState({ openDeleteErrorModal: false })}
        />
        <CustomModal
          open={this.state.openLoadItem}
          onClose={() => this.setState({ openLoadItem: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">{lang.itemLoading}</Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        {this.renderTable()}
        {this.renderSideModal()}
        {this.renderModalItems()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(SettingsItems);
