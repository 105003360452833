import {
  STORE_USER_DATA,
  LOGOUT_USER,
  SELECT_PROFILE,
  TYPE_LOGIN,
  FETCH_ROLE,
} from "../actions/types";

const INITIAL_STATE = {
  user: undefined,
  selectedProfile: undefined,
  login: undefined,
  permissions: ["default"],
};
const REFERENCE_STATE = {
  ...INITIAL_STATE,
};

/**
 * Funcao realizada apos o 'Dispach' do redux.
 */
export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case STORE_USER_DATA:
      return { ...state, user: action.payload };
    case LOGOUT_USER:
      return REFERENCE_STATE;
    case SELECT_PROFILE:
      return { ...state, selectedProfile: action.payload };
    case TYPE_LOGIN:
      return { ...state, login: action.payload };
    case FETCH_ROLE:
      return { ...state, permissions: action.payload };
    default:
      return state;
  }
}
