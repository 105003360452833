// Dados estáticos.
import { PROFILE_LOCATION, PROFILE_API_BASE, QUERY_ACCESS, ITEM_PRODUCT_LOCATION } from '../static/URL';
// Bibliotecas de utilidade.
import api from '../utils/API';

/**
   * Método para carregar os item do produto.
   *
   * @param {String} profileId do profile
   * @param {String} token token de autenticação
   */
export const loadItemProduct = async (profileId, token) => {
	try {
		const { data: categories } = await api.get(
			`${PROFILE_API_BASE + PROFILE_LOCATION}/${profileId + ITEM_PRODUCT_LOCATION}?${QUERY_ACCESS + token}`
		);
		return categories;
	} catch (error) {
		console.log({ ...error });
		return [];
	}
};
