import React, { Component } from "react";
import { Button, Typography } from "@material-ui/core";
import CustomModal from "../CustomModal";
import {
  ContentContainer,
  ButtonContainer,
  DescriptionContainer,
  HeaderContainer
} from "./styles.css";

class CustomConfirmationModal extends Component {
  render() {
    const {
      title,
      subtitle,
      description,
      open,
      confirmationText,
      confirmationAction,
      cancelationText,
      cancelationAction,
      onClose
    } = this.props;
    return (
      <CustomModal open={open} onClose={onClose}>
        <div>
          <HeaderContainer>
            <Typography className="title" variant="h5">
              {title}
            </Typography>
          </HeaderContainer>

          <ContentContainer>
            <Typography variant="h6">{subtitle}</Typography>
            <DescriptionContainer>
              <Typography variant="body1">{description}</Typography>
            </DescriptionContainer>
            <ButtonContainer
              className={
                cancelationText && cancelationAction
                  ? "flex justify-evenly"
                  : "flex justify-center"
              }
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  confirmationAction();
                  onClose();
                }}
              >
                {confirmationText}
              </Button>
              {cancelationText && cancelationAction && (
                <Button
                  variant="contained"
                  color="default"
                  onClick={() => {
                    cancelationAction();
                    onClose();
                  }}
                >
                  {cancelationText}
                </Button>
              )}
            </ButtonContainer>
          </ContentContainer>
        </div>
      </CustomModal>
    );
  }
}

export default CustomConfirmationModal;
