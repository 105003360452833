/* eslint-disable no-restricted-globals */
import React from "react";
import * as Sentry from "@sentry/react";
import Routes from "./routes";
import env from "./config/keys";

if (env.REACT_APP_FIRE_ENV !== "development") {
  console.log("removendo console.log");
  console.log = () => {};
}

Sentry.init({
  dsn: "https://b267bec9001c4acc867161e22b40b343@o424976.ingest.sentry.io/5357520",
});

const App = () => <Routes />;

export default App;
