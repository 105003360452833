import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/database';

/**
 * Metodo que realiza o login com o facebook via firebase.
 */
export const facebookLogin = async () => {
	let response = {};
	try {
		// Cria o provider de atenticação do facebook.
		const provider = new firebase.auth.FacebookAuthProvider();
		// Adiciona o escopo de dados.
		// provider.addScope("user_birthday");
		// Indica a linguagem que sera utilizada.
		firebase.auth().useDeviceLanguage();
		// DEfine os parametros customizaveis, para indicar para abrir um pop-up para logar.
		provider.setCustomParameters(
			{
				// display: "popup"
			}
		);
		// REaliza o login.
		const result = await firebase.auth().signInWithPopup(provider);
		// REtira o token da resposta.
		const token = result.credential.accessToken;
		// Retira o usuario da resposta.
		const { user } = result;
		// REtorna o token e os dados do usuario.
		response = { user, token };
	} catch (error) {
		// Handle Errors here.
		const errorCode = error.code;
		const errorMessage = error.message;
		response = {
			error: {
				errorCode,
				errorMessage,
				error
			}
		};
	}
	// REtorna a resposta que pode conter o erro ou os dados do usuario logado.
	return response;
};

export const emailLogin = async (email, password) => {
	let response = {};
	try {
		const result = await firebase.auth().signInWithEmailAndPassword(email, password);
		// Indica a linguagem que sera utilizada.
		firebase.auth().useDeviceLanguage();

		// REtira o token da resposta.
		const token = result.user.ma;
		// Retira o usuario da resposta.
		const user = result.user.uid;

		// REtorna o token e os dados do usuario.
		response = { user, token };
	} catch (error) {
		// Handle Errors here.
		const errorCode = error.code;
		const errorMessage = error.message;
		response = {
			error: {
				errorCode,
				errorMessage,
				error
			}
		};
	}
	// REtorna a resposta que pode conter o erro ou os dados do usuario logado.
	return response;
};

/**
 * Método para salvar dados no storage do firebase.
 *
 * @param {Object} data Dados para salvar no storage
 * @param {*} path Caminho para salvar no storage.
 */
export const saveLogoToStorage = (data) => {
	const database = firebase.database();
	const storageRef = firebase.storage().ref();
	const { uid } = firebase.auth().currentUser;
	const childKey = database.ref().child('company').push().key;
	const logoRef = storageRef.child(`company/${uid}/${childKey}/empresa_logo`);
	return logoRef
		.put(data)
		.then((snapshot) => {
			return { snapshot };
		})
		.catch((error) => {
			console.log(error);
			return { error };
		});
};

/**
 * Método para salvar dados no storage do firebase.
 *
 * @param {Object} data Dados para salvar no storage
 * @param {*} path Caminho para salvar no storage.
 */
export const saveProductImgToStorage = (data) => {
	const database = firebase.database();
	const storageRef = firebase.storage().ref();
	const { uid } = firebase.auth().currentUser;
	const childKey = database.ref().child('produto').push().key;
	const logoRef = storageRef.child(`produto/${uid}/${childKey}/produto_logo`);
	return logoRef
		.put(data)
		.then((snapshot) => {
			return { snapshot };
		})
		.catch((error) => {
			console.log(error);
			return { error };
		});
};

/**
 * Método para salvar dados no storage do firebase.
 *
 * @param {Object} data Dados para salvar no storage
 * @param {*} path Caminho para salvar no storage.
 */
export const savePlaceImgToStorage = (data) => {
	const database = firebase.database();
	const storageRef = firebase.storage().ref();
	const { uid } = firebase.auth().currentUser;
	const childKey = database.ref().child('place').push().key;
	const logoRef = storageRef.child(`place/${uid}/${childKey}/place_logo`);
	return logoRef
		.put(data)
		.then((snapshot) => {
			return { snapshot };
		})
		.catch((error) => {
			console.log(error);
			return { error };
		});
};

/**
 * Método para recuperar a URL de download de arquivos do storage.
 *
 * @param {String} path String com o caminho do arquivo.
 */
export const getfileUrlFromPath = (path) => {
	const storageRef = firebase.storage().ref();
	const ref = storageRef.child(path);
	return ref
		.getDownloadURL()
		.then((url) => {
			return url;
		})
		.catch((error) => {
			console.log(error);
			return false;
		});
};
