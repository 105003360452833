// Dados estáticos.
import {
  ORDER_API_BASE,
  ORDER_LOCATION,
  QUERY_ACCESS,
  NOTIFY_LOCATION,
} from "../static/URL";
// Bibliotecas de utilidade.
import api from "../utils/API";

/**
 * Método para atualizar o estado de um pedido.
 *
 * @param {String} orderId Id do pedido
 * @param {String} profileId id do perfil dono do pedido
 * @param {String} newStatus Novo estado do pedido
 * @param {String} token token de autenticação
 */
export const changeOrderState = async (
  orderId,
  profileId,
  newStatus,
  token,
  estimateTime
) => {
  try {
    const URL = `${ORDER_API_BASE + ORDER_LOCATION}/${orderId}?${
      QUERY_ACCESS + token
    }`;
    let body = {
      id: orderId,
      profileId,
      orderStatus: { index: newStatus },
    };
    if (estimateTime) {
      body = { ...body, estimateTime };
    }
    const res = await api.patch(URL, body);
    return res;
  } catch (error) {
    console.log({ ...error });
    return false;
  }
};

/**
 * Metodo utilizado para realizar a chamada do cliente ao estabelecimento,
 * enviando uma notificacao
 *
 * @param {Number} orderId id do pedido
 */
export const notifyClient = async (orderId, token) => {
  try {
    const URL = `${
      ORDER_API_BASE + ORDER_LOCATION
    }/${orderId}${NOTIFY_LOCATION}?${QUERY_ACCESS + token}`;
    const res = await api.post(URL);
    return { message: "Cliente notificado com sucesso!", res };
  } catch (error) {
    const errorObj = { ...error };
    let message = "Erro ao notificar cliente";
    if (errorObj.isAxiosError) {
      switch (errorObj.response.status) {
        case 400:
          message = "Requisição inválida.";
          break;
        case 401:
          message = "Acesso não autorizado!";
          break;
        case 422:
          message = "Pedido não atende as regras de negócio.";
          break;
        case 500:
          message = "Erro inesperado. Tente novamente em alguns minutos.";
          break;
        default:
          message = "Erro ao notificar cliente";
          break;
      }
    }
    return { message, res: error };
  }
};

export const printOrder = (order) => {
  if (!order) {
    return false;
  }
  try {
    const mywindow = window.open("", "my div", "height=450,width=400");
    mywindow.document.write(
      `
      <span>Furafilla</span>
      <br/>
      <br/>
      <b>${order.profileName}</b>
      <br/>
      <h3><b>Pedido: ${order.id}</b></h3>
      ${order.takeHome ? `<span><b>PARA VIAGEM</span><b/><br/><br/>` : ""}
      <span>Cliente: ${order.userName}</span>
      <br/>
      <br/>
      ${order.tableNumber ? `<span>${order.tableNumber}</span><br/><br/>` : ""}
      ------------------------
      <br/>
      Qtde. - Produto
      <br/>
      ------------------------
      <br/>
      ${order.itemList
        .map(
          (item) =>
            `<b>${
              item.quantity >= 10 ? item.quantity : `0${item.quantity}`
            } - ${item.prodName}</b>${
              item.additional &&
              Array.isArray(item.additional) &&
              item.additional.length > 0
                ? item.additional
                    .map(
                      (a) =>
                        `<br/>    + ${
                          a.quantity > 9 ? a.quantity : `0${a.quantity}`
                        } - ${a.name}`
                    )
                    .join()
                    .replaceAll(",", "")
                : ""
            }<br/>`
        )
        .join()
        .replaceAll(",", "")}
      ------------------------
      <br/>
      <br/>
      ${order.note ? `Observação: <b>${order.note}</b><br/><br/>` : ""}
      Total: <b>R$ ${order.totalPrice
        .toFixed(2)
        .replace(".", ",")
        .replace(/\d(?=(\d{3})+\.)/g, "$&.")}
      </b>
    `.trim()
    );

    mywindow.print();
    mywindow.close();

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
