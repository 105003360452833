// Bibliotecas
import React, { Component } from "react";
import {
  Button,
  TextField,
  IconButton,
  ClickAwayListener
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
// Funções.
import { changeData } from "../../../redux/actions";
import { checkIfCNPJIsValid } from "../../../utils/data";
import { checkEmail, checkPhone } from "../../../utils/regex";
import { PHONE_MASK, CNPJ_MASK, TELEPHONE_MASK } from "../../../utils/masks";
// Recursos visuais
import "../style.css";
import { ButtonContainer } from "./styles.css";
// Componentes.
import CustomMaskedInput from "../../../components/CustomMaskedInput";
import CustomTooltip from "../../../components/CustomTooltip";

// Passo de cadastro dos dados da empresa.
class Step4 extends Component {
  state = {
    logoError: false,
    profileNameShowTooltip: false,
    profileNameTooltipMessage: "",
    profileNameError: false,
    displayShowTooltip: false,
    displayTooltipMessage: "",
    displayError: false,
    profileDocumentShowTooltip: false,
    profileDocumentTooltipMessage: "",
    profileDocumentError: false,
    descriptionShowTooltip: false,
    descriptionTooltipMessage: "",
    descriptionError: false,
    profileEmailShowTooltip: false,
    profileEmailTooltipMessage: "",
    profileEmailError: false,
    profilePhoneNumberShowTooltip: false,
    profilePhoneNumberTooltipMessage: "",
    profilePhoneNumberError: false,
    whatsappShowTooltip: false,
    whatsappTooltipMessage: "",
    whatsappError: false
  };

  /**
   * Método que valida os dados que serão cadastrados.
   */
  checkStep4Data = () => {
    let data = {};
    // Validar tamanho e dados dos campos.
    if (!this.props.logoFile) {
      data = {
        ...data,
        logoError: true
      };
    }
    if (!this.props.profileName || this.props.profileName.trim().length === 0) {
      data = {
        ...data,
        profileNameShowTooltip: true,
        profileNameTooltipMessage: !this.props.profileName
          ? this.props.lang.profileNameRequired
          : this.props.lang.profileNameInvalid,
        profileNameError: true
      };
    }
    if (!this.props.display || this.props.display.trim().length === 0) {
      data = {
        ...data,
        displayShowTooltip: true,
        displayTooltipMessage: !this.props.display
          ? this.props.lang.displayRequired
          : this.props.lang.displayInvalid,
        displayError: true
      };
    }
    if (
      !this.props.profileDocument ||
      this.props.profileDocument.trim().length === 0 ||
      (this.props.profileDocument.trim().length > 0 &&
        !checkIfCNPJIsValid(this.props.profileDocument))
    ) {
      data = {
        ...data,
        profileDocumentShowTooltip: true,
        profileDocumentTooltipMessage: !this.props.profileDocument
          ? this.props.lang.profileDocumentRequired
          : this.props.lang.profileDocumentInvalid,
        profileDocumentError: true
      };
    }
    if (!this.props.description || this.props.description.trim().length === 0) {
      data = {
        ...data,
        descriptionShowTooltip: true,
        descriptionTooltipMessage: !this.props.description
          ? this.props.lang.descriptionRequired
          : this.props.lang.descriptionInvalid,
        descriptionError: true
      };
    }
    if (
      !this.props.profileEmail ||
      this.props.profileEmail.trim().length === 0 ||
      (this.props.profileEmail.trim().length > 0 &&
        !checkEmail(this.props.profileEmail))
    ) {
      data = {
        ...data,
        profileEmailShowTooltip: true,
        profileEmailTooltipMessage: !this.props.profileEmail
          ? this.props.lang.profileEmailRequired
          : this.props.lang.profileEmailInvalid,
        profileEmailError: true
      };
    }
    if (
      !this.props.profilePhoneNumber ||
      this.props.profilePhoneNumber.trim().length === 0 ||
      (this.props.profilePhoneNumber.trim().length > 0 &&
        !checkPhone(this.props.profilePhoneNumber))
    ) {
      data = {
        ...data,
        profilePhoneNumberShowTooltip: true,
        profilePhoneNumberTooltipMessage: !this.props.profilePhoneNumber
          ? this.props.lang.profilePhoneNumberRequired
          : this.props.lang.profilePhoneNumberInvalid,
        profilePhoneNumberError: true
      };
    }
    if (
      !this.props.whatsapp ||
      this.props.whatsapp.trim().length === 0 ||
      (this.props.whatsapp.trim().length > 0 &&
        !checkPhone(this.props.whatsapp))
    ) {
      data = {
        ...data,
        whatsappShowTooltip: true,
        whatsappTooltipMessage: !this.props.whatsapp
          ? this.props.lang.whatsappRequired
          : this.props.lang.whatsappInvalid,
        whatsappError: true
      };
    }
    if (Object.keys(data).length > 0) {
      this.setState(data);
    } else {
      this.props.onSuccess();
    }
  };

  renderDropzoneContent = (getRootProps, getInputProps) => (
    <ClickAwayListener onClickAway={() => this.setState({ logoError: false })}>
      <div
        className={this.state.logoError ? "upload upload-error" : "upload"}
        {...getRootProps()}
      >
        <input {...getInputProps()} />
        <p>
          {this.props.logoFile
            ? this.props.logoFile[0].name
            : this.props.lang.logoLabel}
        </p>
      </div>
    </ClickAwayListener>
  );

  handleUpload = file => {
    this.props.changeData("logoFile", file);
  };

  renderButton = () =>
    this.props.logoFile ? (
      <IconButton
        onClick={() => this.props.changeData("logoFile", undefined)}
        aria-label="Delete"
      >
        <Delete />
      </IconButton>
    ) : (
      true
    );

  render() {
    return (
      <div
        className={`flex-column
        input-div-container
        justify-center
        shadow-2
        register-container`}
      >
        <div className="flex-row justify-center align-items-center">
          <Dropzone
            multiple={false}
            accept="image/*"
            onDropAccepted={this.handleUpload}
          >
            {({ getRootProps, getInputProps }) =>
              this.renderDropzoneContent(getRootProps, getInputProps)
            }
          </Dropzone>
          {this.renderButton()}
        </div>
        <div className="flex-row justify-center align-items-center">
          <CustomTooltip
            onClose={() =>
              this.setState({
                profileNameShowTooltip: false,
                profileNameError: false
              })
            }
            open={this.state.profileNameShowTooltip}
            title={this.state.profileNameTooltipMessage}
          >
            <TextField
              className="input-margin"
              required
              id="profileName"
              label={this.props.lang.profileName}
              error={this.state.profileNameError}
              value={this.props.profileName}
              onChange={e =>
                this.props.changeData("profileName", e.target.value)
              }
            />
          </CustomTooltip>
          <CustomTooltip
            onClose={() =>
              this.setState({
                displayShowTooltip: false,
                displayError: false
              })
            }
            open={this.state.displayShowTooltip}
            title={this.state.displayTooltipMessage}
          >
            <TextField
              className="input-margin"
              required
              id="display"
              error={this.state.displayError}
              label={this.props.lang.display}
              value={this.props.display}
              onChange={e => this.props.changeData("display", e.target.value)}
            />
          </CustomTooltip>
        </div>
        <CustomTooltip
          onClose={() =>
            this.setState({
              profileDocumentShowTooltip: false,
              profileDocumentError: false
            })
          }
          open={this.state.profileDocumentShowTooltip}
          title={this.state.profileDocumentTooltipMessage}
        >
          <CustomMaskedInput
            formControllClassname="input-margin"
            required
            value={this.props.profileDocument}
            onChange={profileDocument =>
              this.props.changeData("profileDocument", profileDocument)
            }
            id="profileDocument"
            label={this.props.lang.profileDocument}
            error={this.state.profileDocumentError}
            mask={CNPJ_MASK}
          />
        </CustomTooltip>
        <CustomTooltip
          onClose={() =>
            this.setState({
              descriptionShowTooltip: false,
              descriptionError: false
            })
          }
          open={this.state.descriptionShowTooltip}
          title={this.state.descriptionTooltipMessage}
        >
          <TextField
            className="input-margin"
            required
            id="description"
            multiline
            error={this.state.descriptionError}
            rows="4"
            label={this.props.lang.description}
            value={this.props.description}
            onChange={e => this.props.changeData("description", e.target.value)}
          />
        </CustomTooltip>
        <CustomTooltip
          onClose={() =>
            this.setState({
              profileEmailShowTooltip: false,
              profileEmailError: false
            })
          }
          open={this.state.profileEmailShowTooltip}
          title={this.state.profileEmailTooltipMessage}
        >
          <TextField
            className="input-margin"
            required
            id="profileEmail"
            error={this.state.profileEmailError}
            type="email"
            label={this.props.lang.profileEmail}
            value={this.props.profileEmail}
            onChange={e =>
              this.props.changeData("profileEmail", e.target.value)
            }
          />
        </CustomTooltip>
        <div className="flex-row justify-center align-items-center">
          <CustomTooltip
            onClose={() =>
              this.setState({
                profilePhoneNumberShowTooltip: false,
                profilePhoneNumberError: false
              })
            }
            open={this.state.profilePhoneNumberShowTooltip}
            title={this.state.profilePhoneNumberTooltipMessage}
          >
            <CustomMaskedInput
              formControllClassname="input-margin"
              required
              value={this.props.profilePhoneNumber}
              onChange={profilePhoneNumber =>
                this.props.changeData("profilePhoneNumber", profilePhoneNumber)
              }
              error={this.state.profilePhoneNumberError}
              id="profilePhoneNumber"
              label={this.props.lang.profilePhoneNumber}
              mask={TELEPHONE_MASK}
            />
          </CustomTooltip>

          <CustomTooltip
            onClose={() =>
              this.setState({
                whatsappShowTooltip: false,
                whatsappError: false
              })
            }
            open={this.state.whatsappShowTooltip}
            title={this.state.whatsappTooltipMessage}
          >
            <CustomMaskedInput
              formControllClassname="input-margin"
              required
              error={this.state.whatsappError}
              value={this.props.whatsapp}
              onChange={whatsapp => this.props.changeData("whatsapp", whatsapp)}
              id="whatsapp"
              label={this.props.lang.whatsapp}
              mask={PHONE_MASK}
            />
          </CustomTooltip>
        </div>
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={this.checkStep4Data}
          >
            {this.props.lang.continue}
          </Button>
          <Button onClick={this.props.back} className="back-button">
            {this.props.lang.goBack}
          </Button>
        </ButtonContainer>
      </div>
    );
  }
}

function mapStateToProps({ register }) {
  try {
    return {
      ...register
    };
  } catch (error) {
    console.log(`Register - mapStateToProps: ${error}`);
    return {};
  }
}

export default connect(mapStateToProps, { changeData })(Step4);
