import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

// Componentes.
import {
  Fab,
  Grid,
  TextField,
  IconButton,
  ClickAwayListener,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Dropzone from "react-dropzone";
import { Delete } from "@material-ui/icons";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomMaskedInput from "../../components/CustomMaskedInput";
import CustomSingleSelect from "../../components/CustomSingleSelect";
import CustomTooltip from "../../components/CustomTooltip";
import CustomSideModal from "../../components/CustomSideModal";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";
import CustomModal from "../../components/CustomModal";
import CustomCrudCard from "../../components/CustomCrudCard";
// Funções e dados.
import { CEP_MASK, STATE_MASK } from "../../utils/masks";
import {
  PROFILE_API_BASE,
  PLACE_LOCATION,
  QUERY_ACCESS,
  ME_LOCATION,
} from "../../static/URL";
import { USER_AUTH_TOKEN } from "../../static/LocalStorageTags";
import api from "../../utils/API";
import {
  getfileUrlFromPath,
  savePlaceImgToStorage,
} from "../../utils/firebase";
import { checkCEP } from "../../utils/data";
import { getItem } from "../../utils/localStorage";
// Recursos visuais.
import { StyledAvatar, StyledProgress } from "./styles.css";

class Places extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLoadingModal: false,
      openErrorModal: false,
      openSuccessModal: false,
      places: [],
      // SideModal
      sideModalOpen: false,
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      desc: "",
      descError: false,
      descShowTooltip: false,
      descTooltipMessage: "",
      beginHour: 0,
      beginHourError: false,
      beginHourShowTooltip: false,
      beginHourTooltipMessage: "",
      endHour: 0,
      endHourError: false,
      endHourShowTooltip: false,
      endHourTooltipMessage: "",
      imageStatus: "loading",
      imageUrl: undefined,
      imageUrlError: false,
      imageOnEdit: undefined,
      street: undefined,
      streetError: false,
      streetShowTooltip: false,
      streetTooltipMessage: "",
      number: undefined,
      numberError: false,
      numberShowTooltip: false,
      numberTooltipMessage: "",
      neighborhood: undefined,
      neighborhoodError: false,
      neighborhoodShowTooltip: false,
      neighborhoodTooltipMessage: "",
      complement: undefined,
      complementError: false,
      complementShowTooltip: false,
      complementTooltipMessage: "",
      zipCode: undefined,
      zipCodeError: false,
      zipCodeShowTooltip: false,
      zipCodeTooltipMessage: "",
      city: undefined,
      cityError: false,
      cityShowTooltip: false,
      cityTooltipMessage: "",
      state: undefined,
      stateError: false,
      stateShowTooltip: false,
      stateTooltipMessage: "",
      type: "",
      typeError: false,
      typeShowTooltip: false,
      typeTooltipMessage: "",
      isActive: true,
    };
  }

  componentDidMount() {
    // Carrega os locais.
    this.loadPlaces();
  }

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user.selectedProfile) {
      return this.setState({ profiles: [] });
    }
    if (
      (!this.props.user.selectedProfile && nextProps.user.selectedProfile) ||
      (nextProps.user.selectedProfile &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id !==
          nextProps.user.selectedProfile.id)
    ) {
      this.loadPlaces();
    }
  }

  /**
   * Método que carega os locais.
   */
  loadPlaces = async () => {
    const userToken = getItem(USER_AUTH_TOKEN);
    const { data: places } = await api.get(
      `${PROFILE_API_BASE + PLACE_LOCATION + ME_LOCATION}?${
        QUERY_ACCESS + userToken
      }`
    );
    this.setState({ places });
  };

  /**
   * Método realizado ao inserir dados no cep.
   */
  onChangeCEP = async (zipCode) => {
    // Realiza ação no estado compartilhado
    this.setState({ zipCode });
    // Cria regex para validar.
    const regex = new RegExp(/\d{5}-\d{3}/g);
    // Testa se o CEP esta no formato do regex.
    if (zipCode && regex.test(zipCode)) {
      // Se sim, realiza a chamada para a API da viacep, para completar os dados do cadastro.
      const res = await axios.get(`https://viacep.com.br/ws/${zipCode}/json`);
      // Recupera os dados mais interessantes.
      const {
        bairro: neighborhood,
        localidade: city,
        logradouro: street,
        uf: state,
      } = res.data;
      if (neighborhood && city && street && state) {
        // Define os dados no estado compartilhado.
        this.setState({
          state,
          city,
          street,
          neighborhood,
        });
      }
    }
  };

  /**
   * Renderiza área para adição da imagem do local
   */
  renderDropzoneContent = (getRootProps, getInputProps) => {
    const { lang } = this.props;
    return (
      <ClickAwayListener
        onClickAway={() => this.setState({ imageUrlError: false })}
      >
        <div
          className={
            this.state.imageUrlError
              ? "upload width-90 upload-error"
              : "upload width-90"
          }
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <p>
            {this.state.imageUrl
              ? this.state.imageUrl[0].name
              : lang.dropZoneLabel}
          </p>
        </div>
      </ClickAwayListener>
    );
  };

  /**
   * Renderiza botão para deletar a imagem do local
   */
  renderButtonDeleteImage = () =>
    this.state.imageUrl ? (
      <IconButton
        onClick={() => this.setState({ imageUrl: undefined })}
        aria-label="Delete"
      >
        <Delete />
      </IconButton>
    ) : (
      true
    );

  enumPlaceTypes = () => [
    {
      value: "MALL",
      label: this.props.lang.mall,
    },
    {
      value: "UNIVERSITY",
      label: this.props.lang.university,
    },
    {
      value: "SCHOOL",
      label: this.props.lang.school,
    },
    {
      value: "STREET",
      label: this.props.lang.street,
    },
    {
      value: "GALERY",
      label: this.props.lang.galery,
    },
    {
      value: "PARKING",
      label: this.props.lang.parking,
    },
    {
      value: "SQUARE",
      label: this.props.lang.square,
    },
  ];

  /**
   * Renderiza a modal de criar locais
   */
  renderSideModal = () => {
    const { lang } = this.props;
    const { sideModalOpen, imageOnEdit, imageStatus } = this.state;
    return (
      <CustomSideModal
        open={sideModalOpen}
        handleClose={this.handleCloseModal}
        title={!this.state.id ? lang.sideModalTitle : lang.sideModalTitleEdit}
        confirmationAction={this.validateFormValues}
        confirmationText={lang.save}
        cancelationAction={this.handleCloseModal}
        cancelationText={lang.cancel}
        activeSwitch
        activeChecked={this.state.isActive}
        onChangeSwitch={(state) => this.setState({ isActive: state })}
      >
        <Grid container>
          <Grid item xs={12}>
            <div className="flex-row justify-center align-items-center">
              {imageOnEdit ? (
                <>
                  <StyledAvatar
                    imgProps={{
                      style: { objectFit: "contain", cursor: "pointer" },
                      onLoad: () => this.setState({ imageStatus: "loaded" }),
                      onClick: () => this.setState({ imageOnEdit: undefined }),
                      onMouseOver: (e) => {
                        e.currentTarget.src =
                          "https://www.materialui.co/materialIcons/action/delete_black_192x192.png";
                        e.currentTarget.style.transform = "scale(0.3)";
                        e.currentTarget.style.padding = "200px";
                      },
                      onMouseOut: (e) => {
                        e.currentTarget.src = imageOnEdit;
                        e.currentTarget.style.transform = "scale(1)";
                        e.currentTarget.style.padding = "0px";
                      },
                    }}
                    alt=""
                    src={imageOnEdit}
                  >
                    <Delete />
                  </StyledAvatar>
                  {imageStatus === "loading" && (
                    <StyledProgress size={150} thickness={2} />
                  )}
                </>
              ) : (
                <>
                  <Dropzone
                    multiple={false}
                    accept="image/*"
                    onDropAccepted={(file) => this.setState({ imageUrl: file })}
                  >
                    {({ getRootProps, getInputProps }) =>
                      this.renderDropzoneContent(getRootProps, getInputProps)
                    }
                  </Dropzone>
                  {this.renderButtonDeleteImage()}
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  nameShowTooltip: false,
                  nameError: false,
                })
              }
              open={this.state.nameShowTooltip}
              title={this.state.nameTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.name}
                error={this.state.nameError}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  beginHourShowTooltip: false,
                  beginHourError: false,
                })
              }
              open={this.state.beginHourShowTooltip}
              title={this.state.beginHourTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.beginHour}
                error={this.state.beginHourError}
                value={this.state.beginHour}
                onChange={(e) => this.setState({ beginHour: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={6} sm={3}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  endHourShowTooltip: false,
                  endHourError: false,
                })
              }
              open={this.state.endHourShowTooltip}
              title={this.state.endHourTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.endHour}
                error={this.state.endHourError}
                value={this.state.endHour}
                onChange={(e) => this.setState({ endHour: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  typeShowTooltip: false,
                  typeError: false,
                })
              }
              open={this.state.typeShowTooltip}
              title={this.state.typeTooltipMessage}
            >
              <CustomSingleSelect
                formControllClassname="input-margin width-90"
                required
                value={this.state.type}
                onChange={(type) => this.setState({ type })}
                id="place-type"
                label={lang.type}
                error={this.state.typeError}
                items={this.enumPlaceTypes()}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  descShowTooltip: false,
                  descError: false,
                })
              }
              open={this.state.descShowTooltip}
              title={this.state.descTooltipMessage}
            >
              <TextField
                className="input-margin width-95"
                multiline
                rows={3}
                required
                label={lang.desc}
                error={this.state.descError}
                value={this.state.desc}
                onChange={(e) => this.setState({ desc: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  zipCodeShowTooltip: false,
                  zipCodeError: false,
                })
              }
              open={this.state.zipCodeShowTooltip}
              title={this.state.zipCodeTooltipMessage}
            >
              <CustomMaskedInput
                formControllClassname="input-margin width-90"
                required
                value={this.state.zipCode}
                onChange={(zipCode) => this.onChangeCEP(zipCode)}
                id="zipCode"
                label={this.props.lang.zipCode}
                error={this.state.zipCodeError}
                mask={CEP_MASK}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item xs={9}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  streetShowTooltip: false,
                  streetError: false,
                })
              }
              open={this.state.streetShowTooltip}
              title={this.state.streetTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                id="street"
                label={lang.street}
                error={this.state.streetError}
                value={this.state.street}
                onChange={(e) => this.setState({ street: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={3}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  numberShowTooltip: false,
                  numberError: false,
                })
              }
              open={this.state.numberShowTooltip}
              title={this.state.numberTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                id="number"
                label={lang.number}
                error={this.state.numberError}
                value={this.state.number}
                onChange={(e) => this.setState({ number: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  neighborhoodShowTooltip: false,
                  neighborhoodError: false,
                })
              }
              open={this.state.neighborhoodShowTooltip}
              title={this.state.neighborhoodTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                id="neighborhood"
                label={lang.neighborhood}
                error={this.state.neighborhoodError}
                value={this.state.neighborhood}
                onChange={(e) =>
                  this.setState({ neighborhood: e.target.value })
                }
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  complementShowTooltip: false,
                  complementError: false,
                })
              }
              open={this.state.complementShowTooltip}
              title={this.state.complementTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                id="complement"
                label={lang.complement}
                error={this.state.complementError}
                value={this.state.complement}
                onChange={(e) => this.setState({ complement: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={9}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  cityShowTooltip: false,
                  cityError: false,
                })
              }
              open={this.state.cityShowTooltip}
              title={this.state.cityTooltipMessage}
            >
              <TextField
                required
                className="input-margin width-90"
                id="city"
                label={lang.city}
                error={this.state.cityError}
                value={this.state.city}
                onChange={(e) => this.setState({ city: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={3}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  stateShowTooltip: false,
                  stateError: false,
                })
              }
              open={this.state.stateShowTooltip}
              title={this.state.stateTooltipMessage}
            >
              <CustomMaskedInput
                formControllClassname="input-margin width-90"
                required
                value={this.state.state}
                onChange={(state) => this.setState({ state })}
                id="state"
                label={this.props.lang.state}
                error={this.state.stateError}
                mask={STATE_MASK}
              />
            </CustomTooltip>
          </Grid>
        </Grid>
      </CustomSideModal>
    );
  };

  /**
   * Método para validar os campos do formulário
   */
  validateFormValues = () => {
    const {
      id,
      name,
      desc,
      beginHour,
      endHour,
      type,
      imageUrl,
      street,
      number,
      neighborhood,
      zipCode,
      city,
      state,
    } = this.state;
    const { lang } = this.props;
    let data = {};

    // Validação da imagem.
    if (!id || !imageUrl) {
      if (!imageUrl) {
        data = {
          ...data,
          imageUrlError: true,
        };
      }
    }
    // Validação do nome.
    if (!name || name.trim().length === 0) {
      data = {
        ...data,
        nameShowTooltip: true,
        nameTooltipMessage: !name ? lang.nameRequired : lang.nameInvalid,
        nameError: true,
      };
    }
    // Validação da descrição.
    if (!desc || desc.trim().length === 0) {
      data = {
        ...data,
        descShowTooltip: true,
        descTooltipMessage: !desc ? lang.descRequired : lang.descInvalid,
        descError: true,
      };
    }
    // Validação da hora de abertura.
    if (
      beginHour === undefined ||
      beginHour === null ||
      isNaN(beginHour) ||
      beginHour > 23 ||
      beginHour < 0
    ) {
      data = {
        ...data,
        beginHourShowTooltip: true,
        beginHourTooltipMessage:
          beginHour === undefined || beginHour === null
            ? lang.beginHourRequired
            : lang.beginHourInvalid,
        beginHourError: true,
      };
    }
    // Validação da hora de fechar.
    if (
      endHour === undefined ||
      endHour === null ||
      isNaN(endHour) ||
      endHour > 23 ||
      endHour < 0
    ) {
      data = {
        ...data,
        endHourShowTooltip: true,
        endHourTooltipMessage:
          endHour === undefined || endHour === null
            ? lang.endHourRequired
            : lang.endHourInvalid,
        endHourError: true,
      };
    }
    // Validação do tipo.
    if (!type || type.trim().length === 0) {
      data = {
        ...data,
        typeShowTooltip: true,
        typeTooltipMessage: !type ? lang.typeRequired : lang.typeInvalid,
        typeError: true,
      };
    }
    // Validação da rua.
    if (!street || street.trim().length === 0) {
      data = {
        ...data,
        streetShowTooltip: true,
        streetTooltipMessage: !street
          ? lang.streetRequired
          : lang.streetInvalid,
        streetError: true,
      };
    }
    // Validação do número.
    if (!number || number.trim().length === 0 || isNaN(number)) {
      data = {
        ...data,
        numberShowTooltip: true,
        numberTooltipMessage: !number
          ? lang.numberRequired
          : lang.numberInvalid,
        numberError: true,
      };
    }
    // Validação do bairro.
    if (!neighborhood || neighborhood.trim().length === 0) {
      data = {
        ...data,
        neighborhoodShowTooltip: true,
        neighborhoodTooltipMessage: !neighborhood
          ? lang.neighborhoodRequired
          : lang.neighborhoodInvalid,
        neighborhoodError: true,
      };
    }
    // Validação do CEP.
    if (!zipCode || zipCode.trim().length === 0 || !checkCEP(zipCode)) {
      data = {
        ...data,
        zipCodeShowTooltip: true,
        zipCodeTooltipMessage: !zipCode
          ? lang.zipCodeRequired
          : lang.zipCodeInvalid,
        zipCodeError: true,
      };
    }
    // Validação da cidade.
    if (!city || city.trim().length === 0) {
      data = {
        ...data,
        cityShowTooltip: true,
        cityTooltipMessage: !city ? lang.cityRequired : lang.cityInvalid,
        cityError: true,
      };
    }
    // Validação do estado.
    if (!state || state.trim().length === 0 || state.trim().length > 2) {
      data = {
        ...data,
        stateShowTooltip: true,
        stateTooltipMessage: !state ? lang.stateRequired : lang.stateInvalid,
        stateError: true,
      };
    }

    if (Object.keys(data).length > 0) {
      this.setState(data);
    } else {
      this.handleModalSubmit();
    }
  };

  /**
   * Método realizado para envio dos dados do formulário para o back
   */
  handleModalSubmit = async () => {
    this.setState({ openLoadingModal: true });
    let resImage;
    if (!this.state.imageOnEdit && this.state.imageUrl) {
      resImage = await this.registerPlaceImage();
    }
    if (resImage || this.state.id) {
      const placeRes = await this.registerPlace(resImage);
      if (placeRes) {
        this.setState({
          openLoadingModal: false,
          openSuccessModal: true,
          sideModalOpen: false,
        });
        await this.loadPlaces();
      } else {
        console.log("erro ao cadastrar o local");
        this.setState({ openLoadingModal: false, openErrorModal: true });
      }
    } else {
      console.log("erro ao salvar a imagem do local");
      this.setState({ openLoadingModal: false, openErrorModal: true });
    }
  };

  /**
   * Método para cadastrar a imagem do local.
   * */
  registerPlaceImage = async () => {
    try {
      // Salva a imagem para o storage.
      const res = await savePlaceImgToStorage(this.state.imageUrl[0]);
      // Valida se houve erro no retorno.
      if (res.error) {
        return false;
      }
      // Se nao, retorna a foto salva.
      return res.snapshot;
    } catch (error) {
      console.log({ ...error });
      return false;
    }
  };

  /**
   * Método para cadastrar o local.
   * */
  registerPlace = async (snapshotLogo) => {
    try {
      const token = getItem(USER_AUTH_TOKEN);
      // Recupera a url da logo.
      let imageUrl;
      if (snapshotLogo) {
        imageUrl = await getfileUrlFromPath(snapshotLogo.ref.location.path);
      }
      const {
        id,
        name,
        desc,
        beginHour,
        endHour,
        type,
        isActive,
        street,
        number,
        neighborhood,
        complement,
        zipCode,
        city,
        state,
      } = this.state;

      if (id) {
        const placeToEdit = {
          // Monta o objeto para cadastro.
          id,
          name,
          desc,
          description: desc,
          beginHour,
          endHour,
          type,
          isActive,
          active: isActive,
          imageUrl: imageUrl || this.state.imageOnEdit,
          address: {
            street,
            number,
            neighborhood,
            complement,
            zipCode,
            city,
            state,
          },
        };
        // Realiza chamada
        const response = await api.put(
          `${PROFILE_API_BASE + PLACE_LOCATION}/${id}?${QUERY_ACCESS}${token}`,
          placeToEdit
        );

        // Retorna a resposta, que é um sucesso (201).
        return response;
      }

      const placeToSave = {
        // Monta o objeto para cadastro.
        name,
        desc,
        description: desc,
        beginHour,
        endHour,
        type,
        isActive,
        active: isActive,
        imageUrl,
        address: {
          street,
          number,
          neighborhood,
          complement,
          zipCode,
          city,
          state,
        },
      };
      // Realiza chamada
      const response = await api.post(
        `${PROFILE_API_BASE + PLACE_LOCATION}?${QUERY_ACCESS}${token}`,
        placeToSave
      );
      // Retorna a resposta, que é um sucesso (201).
      return response;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  /**
   * Método que limpa os campos ao fechar a modal.
   */
  handleCloseModal = () => {
    this.setState({
      sideModalOpen: false,
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      desc: "",
      descError: false,
      descShowTooltip: false,
      descTooltipMessage: "",
      beginHour: 0,
      beginHourError: false,
      beginHourShowTooltip: false,
      beginHourTooltipMessage: "",
      endHour: 0,
      endHourError: false,
      endHourShowTooltip: false,
      endHourTooltipMessage: "",
      imageStatus: "loading",
      imageUrl: undefined,
      imageUrlError: false,
      imageOnEdit: undefined,
      street: undefined,
      streetError: false,
      streetShowTooltip: false,
      streetTooltipMessage: "",
      number: undefined,
      numberError: false,
      numberShowTooltip: false,
      numberTooltipMessage: "",
      neighborhood: undefined,
      neighborhoodError: false,
      neighborhoodShowTooltip: false,
      neighborhoodTooltipMessage: "",
      complement: undefined,
      complementError: false,
      complementShowTooltip: false,
      complementTooltipMessage: "",
      zipCode: undefined,
      zipCodeError: false,
      zipCodeShowTooltip: false,
      zipCodeTooltipMessage: "",
      city: undefined,
      cityError: false,
      cityShowTooltip: false,
      cityTooltipMessage: "",
      state: undefined,
      stateError: false,
      stateShowTooltip: false,
      stateTooltipMessage: "",
      type: "",
      isActive: true,
    });
  };

  /**
   * Método que preenche os campos da modal para edição.
   */
  onClickPlaceEdition = (place) => {
    this.setState({
      id: place.id,
      name: place.name,
      desc: place.desc,
      beginHour: place.beginHour,
      endHour: place.endHour,
      type: place.type,
      isActive: place.isActive,
      imageOnEdit: place.imageUrl,
      imageUrl: place.imageUrl,
      street: place.address.street,
      number: place.address.number,
      neighborhood: place.address.neighborhood,
      complement: place.address.complement,
      zipCode: place.address.zipCode,
      city: place.address.city,
      state: place.address.state,
      sideModalOpen: true,
    });
  };

  render() {
    const { lang } = this.props;
    const { places } = this.state;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <div className="flex-wrap container overflow-auto align-content-start">
          {places &&
            places.map((place) => (
              <CustomCrudCard
                onClick={() => this.onClickPlaceEdition(place)}
                key={place.id}
                img={place.imageUrl}
                title={place.name}
                description={place.desc}
              />
            ))}
        </div>
        {this.renderSideModal()}
        <Fab
          className="table-add-button"
          color="primary"
          onClick={() => this.setState({ sideModalOpen: true })}
        >
          <AddIcon />
        </Fab>
        <CustomModal
          open={this.state.openLoadingModal}
          onClose={() => this.setState({ openLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">
              {!this.state.id
                ? lang.registerPlaceLoading
                : lang.editPlaceLoading}
            </Typography>
            <LinearProgress />
          </div>
        </CustomModal>
        <CustomConfirmationModal
          title={
            !this.state.id
              ? lang.registerSuccessTitle
              : lang.registerSuccessTitle
          }
          open={this.state.openSuccessModal}
          subtitle={
            !this.state.id
              ? lang.registerSuccessSubTitle
              : lang.editSuccessSubTitle
          }
          description={
            !this.state.id
              ? lang.registerSuccessDescription
              : lang.editSuccessDescription
          }
          confirmationText={lang.registerSuccessConfirmationText}
          confirmationAction={() => this.setState({ openSuccessModal: false })}
          onClose={() => this.setState({ openSuccessModal: false })}
        />
        <CustomConfirmationModal
          title={!this.state.id ? lang.registerErrorTitle : lang.editErrorTitle}
          open={this.state.openErrorModal}
          subtitle={
            !this.state.id ? lang.registerErrorSubTitle : lang.editErrorSubTitle
          }
          description={
            !this.state.id
              ? lang.registerErrorDescription
              : lang.editErrorDescription
          }
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() => this.setState({ openErrorModal: false })}
          onClose={() => this.setState({ openErrorModal: false })}
        />
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(Places);
