import {
  FETCH_ORDERS_SUCCESS,
  SHOW_SNACKBAR,
  CLOSE_SNACKBAR,
  SHOW_LOADER,
  CLOSE_LOADER
} from "../actions/types";

const INITIAL_STATE = {
  orders: [],
  // Variaveis para snackbar
  open: false,
  message: "teste",
  variant: "success",
  // Variavel para indicador de carregamento do card.
  loading: false,
  orderIdToLoad: null
};

/**
 * Funcao realizada apos o 'Dispach' do redux.
 */
export default function(state = INITIAL_STATE, action) {
  try {
    switch (action.type) {
      case FETCH_ORDERS_SUCCESS:
        return { ...state, orders: action.payload };
      case SHOW_SNACKBAR:
        return {
          ...state,
          open: true,
          message: action.payload.message,
          variant: action.payload.variant
        };
      case CLOSE_SNACKBAR:
        return {
          ...state,
          open: false
        };
      case SHOW_LOADER:
        return {
          ...state,
          loading: true,
          orderIdToLoad: action.payload.orderId
        };
      case CLOSE_LOADER:
        return {
          ...state,
          loading: false,
          orderIdToLoad: null
        };
      default:
        return state;
    }
  } catch (error) {
    console.log(`order_reducer: ${error}`);
    return state;
  }
}
