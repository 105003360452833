// Dados estáticos.
import {
  PROFILE_LOCATION,
  PROFILE_API_BASE,
  QUERY_ACCESS,
  PRODUCTS_LOCATION,
  ME_LOCATION,
} from "../static/URL";
// Bibliotecas de utilidade.
import api from "../utils/API";

/**
 * Método para carregar as categorias.
 *
 * @param {String} orderId Id do pedido
 * @param {String} token token de autenticação
 */
export const loadProducts = async (profileId, token) => {
  try {
    const { data: productsItem } = await api.get(
      `${PROFILE_API_BASE + PROFILE_LOCATION}/${
        profileId + PRODUCTS_LOCATION + ME_LOCATION
      }?${QUERY_ACCESS + token}`
    );
    return productsItem;
  } catch (error) {
    console.log({ ...error });
    return [];
  }
};
