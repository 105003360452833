import React from "react";
import ReactDOM from "react-dom";
import firebase from "firebase/app";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./index.css";
import App from "./App";

import env from "./config/keys";

require("dotenv").config();
/**
 * Variavel com os dados de configuração do firebase.
 */
const firebaseConfig =
  env.REACT_APP_FIRE_ENV === "development"
    ? {
        apiKey: "AIzaSyCfrwyp6asZYNFcjJWAK7FGwdW6IT7TJ8E",
        authDomain: "rst-app-4af38.firebaseapp.com",
        databaseURL: "https://rst-app-4af38.firebaseio.com",
        projectId: "rst-app-4af38",
        storageBucket: "rst-app-4af38.appspot.com",
        messagingSenderId: "995954384009",
        appId: "1:995954384009:web:458975d15710d15ceefa58",
      }
    : {
        apiKey: "AIzaSyAbpbDyqCkHnhcXs6hzsT5CY6xPg3x9UBc",
        authDomain: "furafilla-store.firebaseapp.com",
        databaseURL: "https://furafilla-store.firebaseio.com",
        projectId: "furafilla-store",
        storageBucket: "furafilla-store.appspot.com",
        messagingSenderId: "405569546098",
        appId: "1:405569546098:web:42149e66f545207236dfeb",
        measurementId: "G-FH1JEBGHT9",
      };

console.log(firebaseConfig.projectId);
console.log(env.REACT_APP_BASE_URL);
// Inicializa o Firebase.
firebase.initializeApp(firebaseConfig);

/**
 * Método para validar informações iniciais da aplicação.
 */
const logInitialInfo = async () => {
  console.log(process.env.NODE_ENV);
};

logInitialInfo();

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
