import jwt from "jsonwebtoken";

/**
 * Método para validar permissão do usuario.
 *
 * @param {String}  token de autenticação
 */
export const loadPermissionUser = async (token) => {
  try {
    const decoded = jwt.decode(token, { complete: true });
    const { permissions } = await decoded.payload;
    return permissions;
  } catch (error) {
    console.log({ ...error });
    return [];
  }
};
