import styled from "styled-components";

export const Container = styled.div`
  @media only screen and (min-width: 1000px) {
    justify-content: center;
  }

  @media only screen and (min-width: 1366px) {
    height: 75% !important;
  }

  display: flex;
  height: 80%;
  overflow-x: auto;
  /* height: calc(100% - 80px); */
  max-width: 100%;
  min-width: 100%;
`;
