/**
 * Método para valdar um telefone no formato (99)99999-9999 ou (99)9999-9999.
 *
 * @param {String} phone Telefone
 */
export const checkPhone = (phone) => {
  const phoneRegex = new RegExp(/\(\d{2}\) \d{4,5}-\d{4}/g);
  return phoneRegex.test(phone);
};

/**
 * Método para validar um email.
 *
 * @param {String} email email
 */
export const checkEmail = (email) => {
  const regex = new RegExp(
    // eslint-disable-next-line max-len
    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[A-Za-z]+$/
  );
  return regex.test(String(email).toLowerCase());
};
