// Bibliotecas.
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CircularProgress,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  LinearProgress,
} from "@material-ui/core";
import firebase from "firebase/app";
import "firebase/auth";
import api from "../../utils/API";
// Funções e dados.
import {
  USER_API_BASE,
  USERS_LOCATION,
  PROFILE_API_BASE,
  PROFILE_LOCATION,
  QUERY_ACCESS,
} from "../../static/URL";
import { storeUserData, changeData } from "../../redux/actions";
import {
  facebookLogin,
  saveLogoToStorage,
  getfileUrlFromPath,
} from "../../utils/firebase";
import { loginUser } from "../../functions/user";
import { USER_ID, USER_AUTH_TOKEN } from "../../static/LocalStorageTags";
import { getItem } from "../../utils/localStorage";
// Componentes
import Step2 from "./Step2";
import Step4 from "./Step4";
import Step5 from "./Step5";
import CustomModal from "../../components/CustomModal";
// Recursos visuais.
import "./style.css";
// import logo from "../../assets/youpay_logo.png";
import logo from "../../assets/furafilla_logo_black.png";
import fbLogo from "../../assets/square-facebook-512.png";
import googleLogo from "../../assets/google-logo.png";

// Tela de cadastro.
class Register extends Component {
  state = {
    isCheckingUser: true,
    activeStep: 0,
    openModal: false,
    openErrorModal: false,
  };

  /**
   * Método realizado ao iniciar a tela.
   */
  componentDidMount() {
    // Recupera a linguagem do browser para os dados do usuário.
    const language = navigator.language || navigator.userLanguage;
    this.props.changeData("language", language);
    if (this.props.user && this.props.user.user) {
      // redirecionar para tela principal.
      this.props.history.replace("/");
    }
    // Mostra os formulários, ao inves do componente de load.
    this.setState({ isCheckingUser: false });
  }

  /**
   * Método realizado quando novos dados chegam para o estado.
   * @param {Object} props Novos dados chegando para a tela.
   * @param {Object} state Dados atuais do estado.
   */
  static getDerivedStateFromProps(props) {
    return props;
  }

  /**
   * Método assincrono realizado quando o botão de login com facebook é clicado.
   */
  facebookLoginBtn = async () => {
    try {
      this.setState({ isCheckingUser: true });
      // REaliza login no facebook.
      const res = await facebookLogin();
      // Valida erros.
      if (!res.error) {
        // Se o login for realizado com sucesso, retira os dados importantes da resposta.
        const data = {
          name: res.user.displayName,
          email: res.user.email,
          fbUrlImage: res.user.photoURL,
          fbIdStore: res.user.providerData[0].uid,
          fbToken: res.token,
        };
        const { uid } = firebase.auth().currentUser;
        const userData = {
          ...data,
          fireIdStore: uid,
        };
        // Realiza o processo de login.
        const resLogin = await loginUser(
          res.user.providerData[0].uid,
          res.token
        );
        // Valida se logou com sucesso.
        if (resLogin) {
          const userId = getItem(USER_ID);
          const token = getItem(USER_AUTH_TOKEN);
          const { data: user } = await api.get(
            `${USER_API_BASE + USERS_LOCATION}/${userId}?${
              QUERY_ACCESS + token
            }`
          );
          // Salvar os dados no redux.
          this.props.storeUserData(user);
          // redirecionar para tela principal.
          this.props.history.replace("/");
        } else {
          // Define no estado compartilhado todos os dados recuperados do firebase/facebook.
          Object.keys(userData).forEach((key) => {
            this.props.changeData(key, userData[key]);
          });
          // Passa para o proximo passo do formulário.
          this.changeStep(1);
        }
      }
    } catch (error) {
      console.log(error);
    }
    this.setState({ isCheckingUser: false });
  };

  /**
   * Método utilizado para realizar o login.
   */
  login = async () => {
    try {
      const userAuthData = await loginUser(
        this.state.fbIdStore,
        this.state.fbToken
      );
      return userAuthData;
    } catch (error) {
      return false;
    }
  };

  /**
   * Método realizado ao finalizar o processo de cadastro por completo.
   */
  onSuccess = async () => {
    try {
      // Pegar os dados do usuário pela API.
      const token = getItem(USER_AUTH_TOKEN);
      const userId = getItem(USER_ID);
      const { data: user } = await api.get(
        `${USER_API_BASE + USERS_LOCATION}/${userId}?${QUERY_ACCESS + token}`
      );
      // Salvar os dados no redux.
      this.props.storeUserData(user);
      // redirecionar para tela principal.
      this.props.history.replace("/");
    } catch (error) {
      console.log({ ...error });
      console.log(
        "Ocorreu algum erro ao realizar o processo de login após cadastro com sucesso."
      );
      this.setState({ openErrorModal: true });
    }
  };

  /**
   * Método realizado ao finalizar a ultima parte do cadastro, aonde é necessario realizar as chamadas.
   */
  onCompleteRegister = async () => {
    // Abre a modal de carregamento.
    this.setState({ openModal: true });
    // Realiza cadastro do usuário.
    const resUser = await this.registerUser();
    // Valida se conseguiu cadastrar usuário com sucesso.
    if (resUser) {
      // Se realizar o cadastro com sucesso, realiza o cadastro da logo.
      const resLogo = await this.registerLogo();
      // Valida se conseguiu salvar a logo com sucesso.
      if (resLogo) {
        // Se sim, realiza o cadastro do perfil.
        const resProfile = await this.registerProfile(resLogo);
        // Valida se cadastrou o perfil/empresa com sucesso.
        if (resProfile) {
          this.setState({ openModal: false });
          await this.onSuccess();
        } else {
          console.log("erro ao cadastrar o perfil");
          this.setState({ openModal: false, openErrorModal: true });
        }
      } else {
        console.log("erro ao salvar a logo");
        this.setState({ openModal: false, openErrorModal: true });
      }
    } else {
      console.log(`erro ao cadastrar usuário`);
      this.setState({ openModal: false, openErrorModal: true });
    }
  };

  /**
   * Método para cadastrar usuário na base de dados.
   */
  registerUser = async () => {
    try {
      // Retira os dados do usuário do estado compartilhado.
      const {
        name,
        phoneNumber,
        document,
        email,
        language,
        fbUrlImage,
        fbToken,
        fireIdStore,
        fbIdStore,
      } = this.state;
      // Realiza a chamada de criação do usuário.
      const response = await api.post(
        `${USER_API_BASE + USERS_LOCATION}?scope=store`,
        {
          name,
          phoneNumber,
          document,
          email,
          language,
          fbUrlImage,
          fbToken,
          fireIdStore,
          fbIdStore,
          dateOfBirth: "2000-01-01",
        }
      );
      // Retorna a resposta, que é um sucesso (201).
      return response;
    } catch (error) {
      console.log({ ...error });
      return false;
    }
  };

  /**
   * Método para cadastrar a logo.
   */
  registerLogo = async () => {
    try {
      // Salva a logo para o storage.
      const res = await saveLogoToStorage(this.props.logoFile[0]);
      // Valida se houve erro no retorno.
      if (res.error) {
        return false;
      }
      // Se nao, retorna a foto salva.
      return res.snapshot;
    } catch (error) {
      console.log({ ...error });
      return false;
    }
  };

  /**
   * Método utilizado para salvar a primeira empresa/perfil do usuário.
   *
   * @param {File} snapshotLogo arquivo salvo no storage - logo do perfil.
   */
  registerProfile = async (snapshotLogo) => {
    try {
      const userAuthData = await this.login();
      if (userAuthData) {
        const token = getItem(USER_AUTH_TOKEN);
        // Recupera a url da logo.
        const url = await getfileUrlFromPath(snapshotLogo.ref.location.path);
        // Recupera os dados da empresa.
        const {
          profileName: name,
          display,
          profileDocument: document,
          description,
          profileEmail: email,
          profilePhoneNumber: phoneNumber,
          whatsapp,
          street,
          number,
          neighborhood,
          complement,
          zipCode,
          city,
          state,
        } = this.state;
        // Monta o objeto para cadastro.
        const firstProfileToSave = {
          name,
          display,
          document,
          description,
          email,
          phoneNumber,
          whatsApp: whatsapp,
          isEnabled: true,
          orderControlMode: false,
          imageUrl: url,
          address: {
            street,
            number,
            neighborhood,
            complement,
            zipCode,
            city,
            state,
          },
          discountPercentage: 0,
          orderAutoSale: false,
        };
        // Realiza chamada
        await api.post(
          `${PROFILE_API_BASE + PROFILE_LOCATION}?${QUERY_ACCESS}${token}`,
          firstProfileToSave
        );
        // Retorna a resposta, que nesse ponto, provavelmente é sucesso (201)
        return userAuthData;
      }
      return false;
    } catch (error) {
      console.log({ ...error });
      return false;
    }
  };

  /**
   * Método para renderizar o primeiro passo do cadastro.
   */
  step1 = () => (
    <div className="flex-column align-items-center justify-evenly shadow-2 register-container">
      <img src={logo} alt="Logo YouPay" className="logo-image" />
      <div className="button-container">
        <Button
          className="btn-login btn-login-fb"
          variant="contained"
          color="primary"
          onClick={async () => {
            await this.facebookLoginBtn();
          }}
        >
          <img src={fbLogo} alt="" height="15" className="fb-img" />
          {this.props.lang.btnFbLogin}
        </Button>

        <Button
          className="btn-login btn-login-google"
          variant="contained"
          color="primary"
        >
          <img src={googleLogo} alt="" height="15" className="fb-img" />
          {this.props.lang.btnGmailLogin}
        </Button>
      </div>
      {}
    </div>
  );

  /**
   * Método para reiniciar o formulário.
   */
  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  /**
   * Método para trocar de passo o formulário.
   */
  changeStep = (index) => {
    this.setState((state) => ({
      activeStep: state.activeStep + index,
    }));
  };

  /**
   * Método que valida qual parte do formulário deve renderizar.
   */
  checkStep = () => {
    switch (this.state.activeStep) {
      case 0:
        return this.step1();
      case 1:
        return (
          <Step2
            lang={this.props.lang}
            back={() => this.changeStep(-1)}
            onSuccess={() => {
              this.changeStep(1);
            }}
          />
        );
      case 2:
        return (
          <Step4
            lang={this.props.lang}
            back={() => this.changeStep(-1)}
            onSuccess={() => {
              this.changeStep(1);
            }}
          />
        );
      case 3:
        return (
          <Step5
            lang={this.props.lang}
            back={() => this.changeStep(-1)}
            onSuccess={async () => {
              await this.onCompleteRegister();
            }}
          />
        );
      default:
        return this.step1();
    }
  };

  /**
   * Método que renderiza o formulário da vez.
   */
  renderComponent = () => {
    return (
      <div className="register-form">
        <form className="register-form">{this.checkStep()}</form>
      </div>
    );
  };

  /**
   * Método que renderiza o componente indicador do passo.
   */
  renderStepper = () => {
    const steps = this.props.lang.STEPS;
    const { activeStep } = this.state;
    return (
      <div className="stepper-container">
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    );
  };

  /**
   * Método que renderiza todo o componente.
   */
  render() {
    return (
      <div className="container flex-column flex-center">
        {!this.state.isCheckingUser ? (
          <div className="container full-width flex-column flex-center">
            {this.renderStepper()}
            {this.renderComponent()}
          </div>
        ) : (
          <CircularProgress />
        )}
        <CustomModal
          open={this.state.openModal}
          onClose={() => this.setState({ openModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div>
            <Typography variant="h6">
              {this.props.lang.registerLoading}
            </Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomModal
          open={this.state.openErrorModal}
          onClose={() => this.setState({ openErrorModal: false })}
        >
          <div>
            <Typography variant="h6">
              {this.props.lang.registerError}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.setState({ openErrorModal: false })}
            >
              OK
            </Button>
          </div>
        </CustomModal>
      </div>
    );
  }
}

function mapStateToProps({ register, user }) {
  try {
    return {
      ...register,
      user,
    };
  } catch (error) {
    console.log(`Register - mapStateToProps: ${error}`);
    return {};
  }
}

export default connect(mapStateToProps, { storeUserData, changeData })(
  Register
);
