// Bibliotecas
import React, { Component } from "react";
import {
  Button,
  Typography,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { connect } from "react-redux";
// Recursos visuais
import "./style.css";
import fbLogo from "../../assets/square-facebook-512.png";
import googleLogo from "../../assets/google-logo.png";
import CustomModal from "../../components/CustomModal";
import CustomTooltip from "../../components/CustomTooltip";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";

// Funções
import { facebookLogin, emailLogin } from "../../utils/firebase";
import { getItem } from "../../utils/localStorage";
import { USER_ID, USER_AUTH_TOKEN } from "../../static/LocalStorageTags";
import {
  storeUserData,
  logUserType,
  storeUserRoles,
} from "../../redux/actions";
import api from "../../utils/API";
import { USER_API_BASE, USERS_LOCATION, QUERY_ACCESS } from "../../static/URL";
import { loginUser, loginUserEmail } from "../../functions/user";
import { checkEmail } from "../../utils/regex";
import { loadPermissionUser } from "../../utils/permission";
import { acessControlMenuDefault } from "../../utils/accessControl";

import env from "../../config/keys";

// Tela de login.
class Login extends Component {
  state = {
    isCheckingUser: true,
    openErrorModal: false,
    email: "",
    emailError: false,
    emailShowTooltip: false,
    emailTooltipMessage: "",
    password: "",
    passwordError: false,
    passwordShowTooltip: false,
    passwordTooltipMessage: "",
    openPasswordSenhaErrorModal: false,
    mensagemError: "",
    openLoginErrorModal: false,
  };

  /**
   * Método realizado ao iniciar a tela.
   */
  componentDidMount() {
    if (this.props.user && this.props.user.user) {
      // redirecionar para tela principal.
      this.props.history.replace("/");
    }
    this.setState({ isCheckingUser: false });
  }

  emailLoginBtn = async () => {
    try {
      this.setState({ isCheckingUser: true });
      const { email, password } = this.state;
      // Realiza o login com e-mail e senha.
      const res = await emailLogin(email, password);

      if (res.error) {
        this.setState({
          isCheckingUser: false,
          openPasswordSenhaErrorModal: true,
        });
      } else {
        const fbUser = res.user;
        const fbaToken = res.token;

        // Recupera dados importantes do usuário do facebook.
        // Realiza o processo de login.
        const resLoginEmail = await loginUserEmail(fbUser, fbaToken);
        // Valida se logou com sucesso.
        if (resLoginEmail.sucess) {
          const userId = getItem(USER_ID);
          const token = getItem(USER_AUTH_TOKEN);
          const { data: user } = await api.get(
            `${USER_API_BASE + USERS_LOCATION}/${userId}?${
              QUERY_ACCESS + token
            }`
          );

          const permissions = await loadPermissionUser(token);
          // Salvar os dados no redux.
          this.props.storeUserData(user);
          this.props.storeUserRoles(permissions);
          // Salvar os dados no redux do tipo do login.
          this.props.logUserType("email");

          // Verifica a permissão do usuario para fazer o redirecionamento da tela.
          const menu = acessControlMenuDefault(permissions);
          // se tem permissão a order redirecionar para tela principal.
          if (menu === "order") {
            this.props.history.replace("/");
          } else {
            this.props.history.replace(`/${menu}`);
          }
        } else {
          this.setState({
            mensagemError: resLoginEmail.error.message,
            openLoginErrorModal: true,
            isCheckingUser: false,
          });
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ isCheckingUser: false });
    }
  };

  /**
   * Método assincrono realizado quando o botão de login com facebook é clicado.
   */
  facebookLoginBtn = async () => {
    try {
      this.setState({ isCheckingUser: true });
      // REaliza login no facebook.
      const res = await facebookLogin();
      // Valida erros.
      if (res.error) {
        // Se houver erros, apenas troca o valor da variavel de estado que mostra carregamento.
        this.setState({ isCheckingUser: false });
      } else {
        // Recupera dados importantes do usuário do facebook.
        const fbToken = res.token;
        const { uid } = res.user.providerData[0];
        // Realiza o processo de login.
        const resLogin = await loginUser(uid, fbToken);
        // Valida se logou com sucesso.
        if (resLogin) {
          const userId = getItem(USER_ID);
          const token = getItem(USER_AUTH_TOKEN);
          const { data: user } = await api.get(
            `${USER_API_BASE + USERS_LOCATION}/${userId}?${
              QUERY_ACCESS + token
            }`
          );
          // Busca as permissoes passando o token.
          const permissions = await loadPermissionUser(token);
          // Salvar os dados no redux.
          this.props.storeUserData(user);
          this.props.storeUserRoles(permissions);
          // Verifica a permissão do usuario para fazer o redirecionamento da tela.
          const menu = acessControlMenuDefault(permissions);
          // se tem permissão a order redirecionar para tela principal.
          if (menu === "order") {
            this.props.history.replace("/");
          } else {
            this.props.history.replace(`/${menu}`);
          }
        } else {
          // Se houver erros, apenas troca o valor da variavel de estado que mostra carregamento.
          this.setState({ isCheckingUser: false });
          // redirecionar para tela principal.
          this.props.history.replace("/register");
        }
      }
    } catch (error) {
      console.log(error);
      this.setState({ isCheckingUser: false });
    }
  };

  /**
   * Método para validar os campos do formulário
   */
  validateFormValues = () => {
    const { email, password } = this.state;
    const { lang } = this.props;
    let data = {};
    if (
      !email ||
      email.trim().length === 0 ||
      (email.trim().length > 0 && !checkEmail(email))
    ) {
      data = {
        ...data,
        emailShowTooltip: true,
        emailTooltipMessage: !email ? lang.emailRequired : lang.emailInvalid,
        emailError: true,
      };
    }

    // Validação do password.
    if (!password || password.trim().length === 0) {
      data = {
        ...data,
        stateShowTooltip: true,
        stateTooltipMessage: lang.passRequired,
        stateError: true,
      };
    }
    if (Object.keys(data).length > 0) {
      this.setState(data);
    } else {
      this.emailLoginBtn();
    }
  };

  render() {
    return (
      <div className="container flex flex-center">
        {!this.state.isCheckingUser ? (
          <form
            className="login-form"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="flex-column align-items-center shadow-2 login-container">
              <div className="top-container">
                <Typography variant="h5">
                  {`${this.props.lang.welcomeText} `}FuraFilla
                </Typography>
              </div>
              <div className="flex flex-column full-width align-items-center input-container">
                <CustomTooltip
                  onClose={() =>
                    this.setState({
                      emailShowTooltip: false,
                      emailError: false,
                    })
                  }
                  open={this.state.emailShowTooltip}
                  title={this.state.emailTooltipMessage}
                >
                  <TextField
                    id="email"
                    required
                    type="email"
                    label={`${this.props.lang.emailLabel}`}
                    className="input"
                    placeholder={`${this.props.lang.emailInputPlaceholder}`}
                    value={this.state.email || ""}
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </CustomTooltip>

                <CustomTooltip
                  onClose={() =>
                    this.setState({
                      passwordShowTooltip: false,
                      passwordError: false,
                    })
                  }
                  open={this.state.passwordShowTooltip}
                  title={this.state.passwordTooltipMessage}
                >
                  <TextField
                    type="password"
                    id="password"
                    required
                    label={`${this.props.lang.passwordLabel}`}
                    className="input"
                    placeholder={`${this.props.lang.passwordInputPlaceholder}`}
                    value={this.state.password || ""}
                    onChange={(e) =>
                      this.setState({ password: e.target.value })
                    }
                  />
                </CustomTooltip>

                <Button
                  className="btn-login"
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={this.validateFormValues}
                >
                  {this.props.lang.btnLogin}
                </Button>
                {env.REACT_APP_HEROKU ? (
                  <>
                    <Button
                      className="btn-login btn-login-fb"
                      variant="contained"
                      color="primary"
                      onClick={this.facebookLoginBtn}
                    >
                      <img src={fbLogo} alt="" height="15" className="fb-img" />
                      {this.props.lang.btnFbLogin}
                    </Button>
                    <Button
                      className="btn-login btn-login-google"
                      variant="contained"
                      color="primary"
                    >
                      <img
                        src={googleLogo}
                        alt=""
                        height="15"
                        className="fb-img"
                      />
                      {this.props.lang.btnGmailLogin}
                    </Button>
                  </>
                ) : (
                  true
                )}
              </div>
              <div className="bottom-container">
                <Typography variant="caption">
                  {/* this.props.lang.signUpText
                  <a href="/register">{this.props.lang.signUpLink}</a>. */}
                </Typography>
              </div>
            </div>
          </form>
        ) : (
          <CircularProgress />
        )}
        <CustomModal
          open={this.state.openErrorModal}
          onClose={() => this.setState({ openErrorModal: false })}
        >
          <div>
            <Typography variant="h6">
              {this.props.lang.noAccountError}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={this.props.onClose}
            >
              OK
            </Button>
          </div>
        </CustomModal>
        <CustomConfirmationModal
          title={this.props.lang.passwordEmailTitle}
          open={this.state.openPasswordSenhaErrorModal}
          description={this.props.lang.passwordEmailError}
          confirmationText={this.props.lang.passwordEmailButton}
          confirmationAction={() =>
            this.setState({ openPasswordSenhaErrorModal: false })
          }
          onClose={() => this.setState({ openPasswordSenhaErrorModal: false })}
        />
        <CustomConfirmationModal
          title={this.props.lang.passwordEmailTitle}
          open={this.state.openLoginErrorModal}
          description={this.state.mensagemError}
          confirmationText={this.props.lang.passwordEmailButton}
          confirmationAction={() =>
            this.setState({ openLoginErrorModal: false })
          }
          onClose={() => this.setState({ openLoginErrorModal: false })}
        />
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, {
  storeUserData,
  logUserType,
  storeUserRoles,
})(Login);
