export const storeOrderPage = {
  title: "Realizar Agendamentos",
  id: "id",
  scheduling: "Agendamentos",
  discount: "Desconto",
  total: "Total",
  subTotal: "SubTotal",
  dataHoraRetirada: "Data Hora Retirada",
  delete: "Excluir",
  createdAt: "Criado em",
  payment: "Pagamento",
  withdrawalDate: "Data retirada",
  withdrawalTime: "Horário de retirada",
  email: "E-Mail",
  name: "Nome",
  products: "Produtos",
  price: "Preço (UN)",
  quantity: "Quantidade",
  note: "Descrição",
  phone: "Celular",
  nameRequiredError: "Nome do cliente é obrigatório!",
  nameInvalidError: "Nome do cliente inválido!",
  emailRequiredError: "E-mail é obrigatória!",
  emailInvalidError: "E-mail inválida!",
  phoneRequiredError: "Telefone é obrigatório!",
  phoneInvalidError: "Telefone inválido!",
  withdrawalDateRequiredError: "Data de retirada é obrigatório!",
  withdrawalDateInvalidError: "Data de retirada inválido!",
  withdrawalTimeRequiredError: "Horário de retirada é obrigatório!",
  withdrawalTimeInvalidError: "Horário de retirada inválido!",
  productsRequiredError: "Produtos são obrigatórios!",
  productsInvalidError: "Produtos inválido!",
  paymentRequiredError: "Forma de Pagamento é obrigatório!",
  paymentInvalidError: "Forma de Pagamento inválido!",
  sideModalTitle: "Adicionar Agendamento",
  sideModalTitleEdit: "Editar Agendamento",
  save: "Salvar",
  cancel: "Cancelar",
  generateNewScheduling: "Gerar Novo Agendamento",
  registerScheduleLoading: "Estamos cadastrando o agendamento",
  editScheduleLoading: "Estamos editando seu agendamento",
  registerSuccessTitle: "Sucesso",
  registerSuccessSubTitle: "Agendamento cadastrado com sucesso!",
  editSuccessSubTitle: "Agendamento editado com sucesso!",
  registerSuccessDescription:
    "Agora o seu agendamento já está disponível no sistema.",
  registerSuccessConfirmationText: "OK",
  registerErrorConfirmationText: "OK",
  registerErrorTitle: "Erro",
  registerErrorDescription: "@MSG",
};
