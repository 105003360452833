export const userListPage = {
  title: "Usuários",
  id: "Hash",
  name: "Nome",
  login: "Login",
  email: "E-mail",
  phone: "Telefone",
  photo: "Foto",
  dateOfBirth: "Data Nascimento",
  providerId: "Identificador",
  createdAt: "Criado em",
  cancel: "Cancelar",
  registerCategoryLoading: "Estamos cadastrando seu novo usuário",
  editCategoryLoading: "Estamos editando seu usuário",
  registerErrorTitle: "Erro",
  registerErrorSubTitle: "Não foi possível cadastrar usuário!",
  editErrorSubTitle: "Não foi possível editar usuário!",
  registerErrorDescription:
    "Tente novamente, se o erro persistir, entre em contato!",
  registerErrorConfirmationText: "OK",
  registerSuccessTitle: "Sucesso",
  registerSuccessSubTitle: "Usuário cadastrada com sucesso!",
  editSuccessSubTitle: "Usuário editada com sucesso!",
  registerSuccessDescription:
    "Agora a seu Usuário já está disponível no sistema.",
  registerSuccessConfirmationText: "OK",
  categoryLoading: "Estamos carregando seu usuário",
};
