import styled from "styled-components";
import { Avatar, CircularProgress } from "@material-ui/core";

export const StyledAvatar = styled(Avatar)`
  margin: 20px;
  width: 150px !important;
  height: 150px !important;
  border: 1px solid #e0e0e0;
  background-color: #e0e0e0;
`;

export const StyledProgress = styled(CircularProgress)`
  color: #673ab7;
  position: absolute;
  z-index: 1;
`;