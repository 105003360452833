export const orderListPage = {
  title: "Pedidos",
  name: "Cliente",
  totalValue: "Valor total",
  currentOrder: "Estado Atual do Pedido",
  dateTime: "Data",
  order: "N° Pedido",

  specificDateFilter: "Filtre a data",
  initDateFilter: "Data inicio",
  endDateFilter: "Data fim",
  cleanFilters: "Limpar filtros",
  searchFilters: "Pesquisar",

  accepted: "Aceitos",
  awaitingScanner: "Aguardando QR Code",
  cancelationText: "Cancelar",
  client: "Cliente",
  close: "Fechar",
  completeOrderLabel: "Finalizar pedido",
  completeOrderModalConfirmationText: "Finalizar o pedido",
  completeOrderModalDescription:
    "Ao finalizar o pedido, ele será finalizado para o cliente e terminará o fluxo.",
  completeOrderModalTitle:
    "Tem certeza que deseja finalizer o pedido de @userName?",
  confirmOrderModalConfirmationText: "Confirmar",
  confirmOrderModalDescription:
    "Ao confirmar o pedido, ele iniciará o processo de pagamento para que seu estabelecimento possa iniciar o preparo.",
  confirmOrderModalTitle:
    "Tem certeza que deseja confirmar o pedido de @userName?",
  currencyType: "R$",
  date: "Data",
  deliverOrderAt: "Entregar pedido",
  discountOrderFinalPriceLabel:
    "Valor final com desconto (@discountPercentage%): R$@finalPrice",
  estimateTime: "Tempo estimado",
  finishedTime: "Finalizado",
  minutes: "Minutos",
  observation: "Observação",
  orderItemsLabel: "Itens do pedido",
  orderNumber: "Pedido n° @orderId",
  orderNumberInputLabel: "Numero do pedido",
  orderNumberLabel: "Pedido n°@orderId",
  orderReadyLabel: "Pedido pronto",
  orderReadyModalConfirmationText: "Finalizar o preparo",
  orderReadyModalDescription:
    "Ao finalizar o preparo, o cliente será notificado de que o pedido está pronto e poderá vir ao estabelecimento retirar.",
  orderReadyModalTitle:
    "Tem certeza que deseja finalizar o preparo do pedido de @userName?",
  orderStatus: "Estado do pedido",
  orderTime: "Hora do pedido",
  orderValueLabel: "Valor total",
  orderWithdrawnModalConfirmationText: "Ler QrCode Pedido",
  orderWithdrawnModalDescription:
    "Para retirar o pedido, você também pode realizar a leitura do QR Code no aplicativo do cliente.",
  orderWithdrawnModalTitle:
    "Deseja confirmar a retirada do pedido de @userName?",
  processingPayment: "Processando Pagamento",
  product: "Produto",
  quantity: "Qtd",
  rejectOrderConfirmationText: "Rejeitar",
  rejectOrderModalDescription:
    "Ao rejeitar o pedido, ele será removido e esse processo não tem volta.",
  rejectOrderModalTitle:
    "Tem certeza que deseja rejeitar o pedido de @userName?",
  screenNotSelectedLabel: "Tela não está selecionada",
  startPreparingLabel: "Iniciar o preparo",
  startPreparingModalConfirmationText: "Iniciar preparo",
  startPreparingModalDescription:
    "Ao iniciar o preparo, o cliente será notificado e o pedido será atualizado.",
  startPreparingModalTitle:
    "Tem certeza que deseja iniciar o preparo do pedido de @userName?",
  startPreparingSuccess: "Iniciado preparo!",
  takeaway: "Take Away",
  time: "Hora",
  total: "Total",
  totalOf: "Total de ",
  waitingTime: "Espera",
  withdrawnAndComplete: "Retirados e Finalizados",
  withdrawnError: "Pedido inválido",
  withdrawnLabel: "Confirmar Retirada",
  withdrawnSuccess: "Retirado com sucesso!",
};
