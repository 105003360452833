/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  TableCell,
  TextField,
  Grid,
  Typography,
  LinearProgress,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import api from "../../utils/API";
// Funções e dados.
import {
  PROFILE_API_BASE,
  PROFILE_LOCATION,
  QUERY_ACCESS,
  BANK_LOCATION,
  ACCOUNT_LOCATION,
  PAYMENT_API_BASE,
} from "../../static/URL";
import {
  USER_SELECTED_PROFILE,
  USER_AUTH_TOKEN,
} from "../../static/LocalStorageTags";
import { getItem } from "../../utils/localStorage";
import { AGENCY_MASK, CPF_MASK, DIGITY_MASK } from "../../utils/masks";
import { checkIfCPFIsValid } from "../../utils/data";

import { loadAccounts } from "../../functions/accounts";
import { loadBanks } from "../../functions/bank";

// Componentes.
import CustomNumberMaskedInput from "../../components/CustomNumberMaskedInput";
import CustomTooltip from "../../components/CustomTooltip";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
import CustomSideModal from "../../components/CustomSideModal";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";
import CustomModal from "../../components/CustomModal";
import CustomMaskedInput from "../../components/CustomMaskedInput";

// Recursos visuais.
import "./style.css";

class BankAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLoadingModal: false,
      openErrorModal: false,
      openSuccessModal: false,
      // Table
      accounts: [],
      open: false,
      selected: [],
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: 10,
      // SideModal
      imageStatus: "loading",
      sideModalOpen: false,
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      document: "",
      documentError: false,
      documentShowTooltip: false,
      documentTooltipMessage: "",
      agency: undefined,
      agencyError: false,
      agencyShowTooltip: false,
      agencyTooltipMessage: "",
      number: undefined,
      numberError: false,
      numberShowTooltip: false,
      numberTooltipMessage: "",
      digit: undefined,
      digitError: false,
      digitShowTooltip: false,
      digitTooltipMessage: "",
      bankId: undefined,
      bankName: "",
      banksList: [],
      selectOptions: [],
      value: "",
      inputValue: "",
      selectedID: "",
      visibleValue: "",
      bank: {},
      bankError: false,
      bankShowTooltip: false,
      bankTooltipMessage: "",
      mensagemError: "",
      userId: undefined,
      ownerName: "",
      ownerNameError: false,
      ownerNameShowTooltip: false,
      ownerTooltipMessage: "",
      ownerDocument: "",
      ownerDocumentError: false,
      ownerDocumentShowTooltip: false,
      ownerDocumentTooltipMessage: "",
    };
  }

  componentDidMount() {
    this.loadAccounts();
    this.loadBanks();
  }

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user.selectedProfile) {
      return this.setState({ accounts: [] });
    }
    if (
      (!this.props.user.selectedProfile && nextProps.user.selectedProfile) ||
      (nextProps.user.selectedProfile &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id !==
          nextProps.user.selectedProfile.id)
    ) {
      this.loadAccounts();
      this.loadBanks();
    }
  }

  /**
   * Método que verefica qual banco foi selecionado.
   */
  handleChange = async (event) => {
    await this.setState({
      selectedID: event.currentTarget.id,
      visibleValue: event.target.value,
    });
    await this.setState({
      bank: {
        id: this.state.selectedID ? this.state.selectedID : undefined,
        name: this.state.visibleValue ? this.state.visibleValue : "",
      },
    });
  };

  /*
   * Método que carega todas as contas cadastradas.
   */
  loadAccounts = async () => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const accounts = await loadAccounts(profileId, userToken);
      this.setState({ accounts });
    } catch (error) {
      console.log({ ...error });
      this.setState({ accounts: [] });
    }
  };

  /**
   * Método que carega os bancos.
   */
  loadBanks = async () => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const banksList = await loadBanks(userToken);
      this.setState({ banksList });
    } catch (error) {
      console.log({ ...error });
      this.setState({ banksList: [] });
    }
  };

  // Retorna o UserProfile
  getUserProfile = async () => {
    const storageProfile = getItem(USER_SELECTED_PROFILE);
    console.log(this.props);
    if (storageProfile) {
      return JSON.parse(storageProfile).id;
    }
    const { user } = this.props;
    if (user && user.selectedProfile) {
      return user.selectedProfile.id;
    }
    const userToken = getItem(USER_AUTH_TOKEN);
    const { data: me } = await api.get(
      `${PROFILE_API_BASE + PROFILE_LOCATION}/me?${QUERY_ACCESS + userToken}`
    );
    const lastDataMe = me.sort(
      (a, b) => new Date(b.dtCreated) - new Date(a.dtCreated)
    );
    return lastDataMe[0].id;
  };

  // Renderiza a tabela de produtos
  renderTable = () => {
    const { lang } = this.props;
    const { accounts } = this.state;
    const rows = [
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: lang.name,
      },
      {
        id: "document",
        numeric: false,
        disablePadding: false,
        label: lang.document,
      },
      {
        id: "bank",
        numeric: false,
        disablePadding: false,
        label: lang.bank,
      },
      {
        id: "agency",
        numeric: false,
        disablePadding: false,
        label: lang.agency,
      },
      {
        id: "number",
        numeric: false,
        disablePadding: false,
        label: lang.number,
      },
      {
        id: "dtCreated",
        numeric: false,
        disablePadding: false,
        label: lang.createdAt,
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        showAddButton
        onAddEvent={() => this.setState({ sideModalOpen: true })}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        onClick={this.onClickAccountsEdition}
        handleSelectAllClick={(e) => {
          if (e.target.checked) {
            this.setState((state) => ({
              selected: state.accounts.map((n) => n.id),
            }));
            return;
          }
          this.setState({ selected: [] });
        }}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={accounts.map((a) => ({
          id: a.id,
          name: a.name,
          agency: a.agency,
          number: a.number,
          document: a.document,
          digit: a.digit,
          dtCreated: new Date(a.dtCreated)
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("/"),
          bankId: a.bank ? a.bank.id : undefined,
          bankName: a.bank ? a.bank.name : "-",
          ownerName: a.ownerName,
          ownerDocument: a.ownerDocument,
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell component="th" scope="row" padding="none">
                {n.name.length > 20 ? `${n.name.substr(0, 20)}...` : n.name}
              </TableCell>
              <TableCell>{n.document}</TableCell>
              <TableCell>{n.bankName}</TableCell>
              <TableCell>{n.agency}</TableCell>
              <TableCell>
                {n.number}-{n.digit}
              </TableCell>
              <TableCell>{n.dtCreated}</TableCell>
            </>
          );
        }}
      />
    );
  };

  /**
   *
   * @param {Object} account Objeto de account.
   */
  onClickAccountsEdition = async (account) => {
    const { selected } = this.state;
    this.setState({
      selected: selected.filter((s) => s !== account.id),
      id: account.id,
      name: account.name,
      document: account.document,
      agency: account.agency,
      number: account.number,
      digit: account.digit,
      visibleValue: account.bankName,
      selectedID: account.bankId,
      ownerName: account.ownerName,
      ownerDocument: account.ownerDocument,
      sideModalOpen: true,
    });
  };

  // Renderiza a modal de criar produtos
  renderSideModal = () => {
    const { lang } = this.props;
    const { sideModalOpen } = this.state;
    return (
      <CustomSideModal
        open={sideModalOpen}
        handleClose={this.handleCloseModal}
        title={!this.state.id ? lang.sideModalTitle : lang.sideModalTitleEdit}
        confirmationAction={this.validateFormValues}
        confirmationText={lang.save}
        cancelationAction={this.handleCloseModal}
        cancelationText={lang.cancel}
        activeSwitch
        activeChecked={this.state.isEnable}
        onChangeSwitch={(state) => this.setState({ isEnable: state })}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  nameShowTooltip: false,
                  nameError: false,
                })
              }
              open={this.state.nameShowTooltip}
              title={this.state.nameTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.name}
                error={this.state.nameError}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  bankShowTooltip: false,
                  bankError: false,
                })
              }
              open={this.state.bankShowTooltip}
              title={this.state.bankTooltipMessage}
            >
              <FormControl fullWidth>
                <TextField
                  select
                  margin="dense"
                  variant="outlined"
                  label="Bancos"
                  required
                  error={this.state.bankError}
                  onChange={this.handleChange}
                  value={this.state.visibleValue}
                >
                  {this.state.banksList.map((bank) => (
                    <MenuItem key={bank.id} value={bank.name} id={bank.id}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </CustomTooltip>
          </Grid>
          <Grid item xs={3}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  agencyShowTooltip: false,
                  agencyError: false,
                })
              }
              open={this.state.agencyShowTooltip}
              title={this.state.agencyTooltipMessage}
            >
              <CustomMaskedInput
                formControllClassname="input-margin width-30"
                required
                error={this.state.agencyError}
                value={this.state.agency}
                onChange={(agency) => this.setState({ agency })}
                id="agency"
                label={this.props.lang.agency}
                mask={AGENCY_MASK}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={3}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  numberShowTooltip: false,
                  numberError: false,
                })
              }
              open={this.state.numberShowTooltip}
              title={this.state.numberTooltipMessage}
            >
              <CustomNumberMaskedInput
                className="width-50 input-margin "
                required
                error={this.state.numberError}
                value={this.state.number}
                onChange={(e) => this.setState({ number: e })}
                id="number"
                label={lang.number}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={1}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  digitShowTooltip: false,
                  digitError: false,
                })
              }
              open={this.state.digitShowTooltip}
              title={this.state.digitTooltipMessage}
            >
              <CustomMaskedInput
                formControllClassname="input-margin width-5"
                required
                error={this.state.digitError}
                value={this.state.digit}
                onChange={(digit) => this.setState({ digit })}
                id="digit"
                label={this.props.lang.digit}
                mask={DIGITY_MASK}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  ownerNameShowTooltip: false,
                  ownerNameError: false,
                })
              }
              open={this.state.ownerNameShowTooltip}
              title={this.state.ownerNameTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.ownerName}
                error={this.state.ownerNameError}
                value={this.state.ownerName}
                onChange={(e) => this.setState({ ownerName: e.target.value })}
              />
            </CustomTooltip>
          </Grid>

          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  ownerDocumentShowTooltip: false,
                  ownerDocumentError: false,
                })
              }
              open={this.state.ownerDocumentShowTooltip}
              title={this.state.ownerDocumentTooltipMessage}
            >
              <CustomMaskedInput
                formControllClassname="input-margin width-90"
                required
                value={this.state.ownerDocument}
                onChange={(ownerDocument) => this.setState({ ownerDocument })}
                id="ownerDocument"
                label={lang.ownerDocument}
                error={this.state.ownerDocumentError}
                mask={CPF_MASK}
              />
            </CustomTooltip>
          </Grid>
        </Grid>
      </CustomSideModal>
    );
  };

  // Método realizado ao fechar a modal de adição de produtos.
  handleCloseModal = () => {
    this.setState({
      imageStatus: "loading",
      sideModalOpen: false,
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      document: "",
      documentError: false,
      documentShowTooltip: false,
      documentTooltipMessage: "",
      agency: undefined,
      agencyError: false,
      agencyShowTooltip: false,
      agencyTooltipMessage: "",
      number: undefined,
      numberError: false,
      numberShowTooltip: false,
      numberTooltipMessage: "",
      bankError: false,
      bankShowTooltip: false,
      bankTooltipMessage: "",
      digit: undefined,
      digitError: false,
      digitShowTooltip: false,
      digitTooltipMessage: "",
      bankId: undefined,
      bankName: "",
      selectOptions: [],
      value: "",
      inputValue: "",
      selectedID: "",
      visibleValue: "",
      bank: {},
      mensagemError: "",
      userId: undefined,
      ownerName: "",
      ownerError: false,
      ownerShowTooltip: false,
      ownerTooltipMessage: "",
      ownerDocument: "",
      ownerDocumentError: false,
      ownerDocumentShowTooltip: false,
      ownerDocumentTooltipMessage: "",
    });
  };

  // Método realizado para envio dos dados do formulário para o back
  handleModalSubmit = async () => {
    this.setState({ openLoadingModal: true });
    const accountsRes = await this.registerAccounts();
    if (accountsRes) {
      this.setState({
        openLoadingModal: false,
        openSuccessModal: true,
        sideModalOpen: false,
      });
      await this.loadAccounts();
      this.handleCloseModal();
    } else {
      this.setState({ openLoadingModal: false, openErrorModal: true });
    }
  };

  /**
   * Método para cadastrar produto na base de dados.
   */
  registerAccounts = async () => {
    try {
      await this.setState({
        document: this.props.user.selectedProfile.document,
      });
      await this.setState({ userId: this.props.user.user.id });
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const {
        bank,
        id,
        name,
        agency,
        number,
        digit,
        document,
        userId,
        ownerName,
        ownerDocument,
      } = this.state;
      if (id) {
        const response = await api.put(
          `${PAYMENT_API_BASE + BANK_LOCATION + ACCOUNT_LOCATION}/${id}?${
            QUERY_ACCESS + userToken
          }`,
          {
            id,
            name,
            document,
            agency,
            number,
            digit,
            profileId,
            userId,
            bank,
            ownerName,
            ownerDocument,
          }
        );
        return response;
      }
      const response = await api.post(
        `${PAYMENT_API_BASE + BANK_LOCATION + ACCOUNT_LOCATION}?${
          QUERY_ACCESS + userToken
        }`,
        {
          id,
          name,
          document,
          agency,
          number,
          digit,
          profileId,
          userId,
          bank,
          ownerName,
          ownerDocument,
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      const errorObj = { ...error };
      console.log({ ...error });
      let message = "Não foi possível cadastrar a conta!";
      if (errorObj.isAxiosError) {
        switch (errorObj.response.status) {
          case 400:
            message = "Requisição inválida.";
            break;
          case 401:
            message = "Acesso não autorizado!";
            break;
          case 422:
            message = errorObj.response.data.content;
            break;
          case 500:
            message = "Erro inesperado. Tente novamente em alguns minutos.";
            break;
          default:
            message = "Erro ao cadastrar conta";
            break;
        }
      }
      this.setState({
        mensagemError: message,
      });
      return false;
    }
  };

  // Método para validar os campos do formulário
  validateFormValues = async () => {
    /**
     * Validando o banco se estiver vazio verificar no selectedId, vivislbeValue verficar se estão preenchido
     * feito essa validação porque quando editava estava ficando ser o valor o bank.
     */
    if (this.state.bank.id === undefined) {
      await this.setState({
        bank: {
          id: this.state.selectedID ? this.state.selectedID : undefined,
          name: this.state.visibleValue ? this.state.visibleValue : "",
        },
      });
    }
    const {
      name,
      agency,
      number,
      digit,
      bank,
      ownerDocument,
      ownerName,
    } = this.state;

    const { lang } = this.props;
    let data = {};
    if (!name || name.trim().length === 0) {
      data = {
        ...data,
        nameShowTooltip: true,
        nameTooltipMessage: !name
          ? lang.nameRequiredError
          : lang.nameInvalidError,
        nameError: true,
      };
    }

    if (!agency || agency.toString().trim().length === 0) {
      data = {
        ...data,
        agencyShowTooltip: true,
        agencyTooltipMessage: !agency
          ? lang.agencyRequiredError
          : lang.agencyInvalidError,
        agencyError: true,
      };
    }
    if (!number || number.toString().trim().length === 0) {
      data = {
        ...data,
        numberShowTooltip: true,
        numberTooltipMessage: !agency
          ? lang.numberRequiredError
          : lang.numberInvalidError,
        numberError: true,
      };
    }

    if (!digit || digit.toString().trim().length === 0) {
      data = {
        ...data,
        digitShowTooltip: true,
        digitTooltipMessage: !agency
          ? lang.digitRequiredError
          : lang.digitInvalidError,
        digitError: true,
      };
    }
    if (!ownerName || ownerName.toString().trim().length === 0) {
      data = {
        ...data,
        ownerNameShowTooltip: true,
        ownerNameTooltipMessage: !agency
          ? lang.ownerDocumentRequiredError
          : lang.ownerNameInvalidError,
        ownerNameError: true,
      };
    }

    if (bank.id === undefined) {
      data = {
        ...data,
        bankShowTooltip: true,
        bankTooltipMessage: !bank
          ? lang.bankRequiredError
          : lang.bankInvalidError,
        bankError: true,
      };
    }
    // Validação do documento.
    if (
      !ownerDocument ||
      ownerDocument.trim().length === 0 ||
      (ownerDocument.trim().length > 0 && !checkIfCPFIsValid(ownerDocument))
    ) {
      data = {
        ...data,
        ownerDocumentShowTooltip: true,
        ownerDocumentTooltipMessage: !ownerDocument
          ? lang.ownerDocumentRequiredError
          : lang.ownerDocumentInvalidError,
        ownerDocumentError: true,
      };
    }

    if (Object.keys(data).length > 0) {
      console.log("aqui erro", data);
      this.setState(data);
    } else {
      console.log("aqui");
      this.handleModalSubmit();
    }
  };

  render() {
    const { lang } = this.props;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <CustomModal
          open={this.state.openLoadingModal}
          onClose={() => this.setState({ openLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">
              {!this.state.id
                ? lang.registerProductLoading
                : lang.editProductLoading}
            </Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomConfirmationModal
          title={
            !this.state.id
              ? lang.registerSuccessTitle
              : lang.editSuccessSubTitle
          }
          open={this.state.openSuccessModal}
          subtitle={
            !this.state.id
              ? lang.registerSuccessSubTitle
              : lang.editSuccessSubTitle
          }
          description={
            !this.state.id
              ? lang.registerSuccessDescription
              : lang.editSuccessDescription
          }
          confirmationText={lang.registerSuccessConfirmationText}
          confirmationAction={() => this.setState({ openSuccessModal: false })}
          onClose={() => this.setState({ openSuccessModal: false })}
        />

        <CustomConfirmationModal
          title={!this.state.id ? lang.registerErrorTitle : lang.editErrorTitle}
          open={this.state.openErrorModal}
          subtitle={
            !this.state.id ? lang.registerErrorSubTitle : lang.editErrorSubTitle
          }
          description={
            !this.state.id
              ? lang.registerErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
              : lang.editErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
          }
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() => this.setState({ openErrorModal: false })}
          onClose={() => this.setState({ openErrorModal: false })}
        />
        {this.renderTable()}
        {this.renderSideModal()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(BankAccount);
