export const linkPlacesCompaniesPage = {
  title: "Vincular estabelecimentos por CNPJ",
  place: "local",
  delete: "Deletar",
  createdAt: "Criado em",
  sideModalTitle: "Vincular estabelecimentos por CNPJ",
  sideModalTitleEdit: "Editar Configuração do Produto",
  qtda: "Quantidade do Item",
  price: "Preço do Item",
  save: "Salvar",
  cancel: "Cancelar",
  deleted: "excluir",
  cnpj: "CNPJ",
  name: "nome",
  placeRequiredError: "Nome do local é obrigatório!",
  placeInvalidError: "Nome do local inválido!",
  companiesRequiredError: "Estabelecimento  é obrigatório!",
  companiesInvalidError: "Estabelecimento inválido!",
  registerCompaniesLoading: "Estamos cadastrando seu vinculo",
  editCompaniesLoading: "Estamos editando seu vinculo",
  registerErrorTitle: "Erro",
  registerErrorSubTitle: "Não foi possível cadastrar o vinculo!",
  editErrorSubTitle: "Não foi possível editar o vinculo!",
  registerErrorDescription: "@MSG",
  editErrorDescription: "@MSG",
  registerErrorConfirmationText: "OK",
  registerSuccessTitle: "Sucesso",
  registerSuccessSubTitle: "Vinculo criado com sucesso!",
  editSuccessSubTitle: "Vinculo atualizado com sucesso!",
  registerSuccessDescription:
    "Agora o seu vinculo já está disponível no sistema.",
  registerSuccessConfirmationText: "OK",
  deleteCompaniesLoading: "Estamos deletando seu vinculo",
  itemLoading: "Estamos carregando seus items",
  itemTitle: "Item",
  enable: "Ativo",
  // Variaveis para a modal de rejeitar o pedido.
  deleteCompaniesModalTitle: "Tem certeza que deseja deletar?",
  deletarCompaniesModalDescription:
    "Ao deletar o vinculo, ele será removido permanentemente.",
  deleteCompaniesConfirmationText: "Deletar",
  // Variaveis mais aproveitadas.
  cancelationText: "Cancelar",
  deleteErrorTitle: "Erro",
  deleteErrorSubTitle: "Não foi possível deletar o vinculo!",
  deleteErrorDescription: "@MSG",
  deleteErrorConfirmationText: "OK",
  ok: "Ok",
};
