/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  TableCell,
  TextField,
  IconButton,
  Grid,
  Typography,
  LinearProgress,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import CreatableSelect from "react-select/creatable";
import api from "../../utils/API";

// Funções e dados.
import {
  PROFILE_API_BASE,
  PROFILE_LOCATION,
  QUERY_ACCESS,
  PLACEID_LOCATION,
  PLACE_LOCATION,
} from "../../static/URL";
import { fetchErrors } from "../../functions/error";

import {
  USER_SELECTED_PROFILE,
  USER_AUTH_TOKEN,
  USER_ID,
} from "../../static/LocalStorageTags";
import { getItem } from "../../utils/localStorage";
import { loadPlaces } from "../../functions/places";
// Componentes.
import CustomTooltip from "../../components/CustomTooltip";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
import CustomSideModal from "../../components/CustomSideModal";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";
import CustomModal from "../../components/CustomModal";
import { fetchUserProfiles } from "../../functions/profile";

// Recursos visuais.
import { CustomHeaderContainer } from "./style.css";

class LinkPlacesCompanies extends Component {
  state = {
    openLoadingModal: false,
    openErrorModal: false,
    openSuccessModal: false,
    // Table
    open: false,
    selected: [],
    order: "asc",
    orderBy: "name",
    page: 0,
    rowsPerPage: 10,
    // SideModal
    sideModalOpen: false,
    id: undefined,
    companiesName: "",
    companiesError: false,
    companiesShowTooltip: false,
    companiesTooltipMessage: "",
    placesName: undefined,
    placesError: false,
    placesShowTooltip: false,
    placesTooltipMessage: "",
    isEnable: true,
    visibleValue: "",
    selectedID: undefined,
    mensagemError: "",
    // Variavel para controlar modal de deletar o item
    deleteCompaniesModalOpen: false,
    delete: false,
    openDeleteErrorModal: false,
    openDeleteLoadingModal: false,
    idDelete: undefined,
    openLoadItem: false,
    isLoading: false,
    companieselectedID: undefined,
    companiesvisibleValue: "",
    places: [],
    placesSelected: [],
    companies: [],
    profilesList: [],
  };

  componentDidMount() {
    this.loadPlaces();
    this.loadProfiles();
  }

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user.selectedProfile) {
      return this.setState({ itemList: [] });
    }
    if (
      (!this.props.user.selectedProfile && nextProps.user.selectedProfile) ||
      (nextProps.user.selectedProfile &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id !==
          nextProps.user.selectedProfile.id)
    ) {
      this.loadPlaces();
      this.loadProfiles();
    }
  }

  /*
   * Método que carega todas items do produto cadastradas.
   */
  loadPlaces = async () => {
    const userToken = getItem(USER_AUTH_TOKEN);
    const places = await loadPlaces(userToken);
    await this.setState({ places });
  };

  /**
   * Método que carega os estabelecimentos do usuário.
   */
  loadProfiles = async () => {
    const userId = getItem(USER_ID);
    const userToken = getItem(USER_AUTH_TOKEN);
    const profilesList = await fetchUserProfiles(userId, userToken);
    this.setState({ profilesList });
  };

  loadPlacesCompanies = async (id) => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const { data: companies } = await api.get(
        `${PROFILE_API_BASE + PLACE_LOCATION}/${id + PROFILE_LOCATION}?${
          QUERY_ACCESS + userToken
        }`
      );
      await this.setState({ companies: companies || [] });
    } catch (error) {
      await this.setState({ companies: [] });
      console.log(error);
      const errorObj = { ...error };
      console.log({ ...error });
      const msg = "Não foi possível carregar os estabelecimento!";
      let message = "";
      message = fetchErrors(
        errorObj.response.status,
        errorObj.response.data.content,
        msg
      );
      this.setState({
        mensagemError: message,
      });
    }
  };

  // Retorna o UserProfile
  getUserProfile = async () => {
    const storageProfile = getItem(USER_SELECTED_PROFILE);
    if (storageProfile) {
      return JSON.parse(storageProfile).id;
    }
    const { user } = this.props;
    if (user && user.selectedProfile) {
      return user.selectedProfile.id;
    }
    const userToken = getItem(USER_AUTH_TOKEN);
    const { data: me } = await api.get(
      `${PROFILE_API_BASE + PROFILE_LOCATION}/me?${QUERY_ACCESS + userToken}`
    );
    const lastDataMe = me.sort(
      (a, b) => new Date(b.dtCreated) - new Date(a.dtCreated)
    );
    return lastDataMe[0].id;
  };

  handleChange = async (newValue) => {
    await this.loadPlacesCompanies(newValue.id);
    await this.setState({
      selectedID: newValue.id ? newValue.id : undefined,
      visibleValue: newValue.name ? newValue.name : "",
      placesSelected: newValue || [],
    });
  };

  handleChangeCompanies = async (event) => {
    await this.setState({
      companieselectedID: event.currentTarget.id
        ? event.currentTarget.id
        : undefined,
      companiesvisibleValue: event.target.value,
    });
  };

  renderSelectsCompanies = () => {
    return (
      <CreatableSelect
        value={this.state.placesSelected}
        deleteRemoves={false}
        isClearable
        placeholder="Lugares"
        options={this.state.places}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.name}
        getNewOptionData={(inputValue, optionLabel) => ({
          id: inputValue,
          name: optionLabel,
        })}
        onChange={this.handleChange}
      />
    );
  };

  customHeaderOptions = () => {
    return (
      <CustomHeaderContainer>
        {this.renderSelectsCompanies()}
      </CustomHeaderContainer>
    );
  };

  // Renderiza a tabela de produtos
  renderTable = () => {
    const { lang } = this.props;
    const { companies } = this.state;
    const rows = [
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: lang.name,
      },
      {
        id: "cnpj",
        numeric: false,
        disablePadding: false,
        label: lang.cnpj,
      },
      {
        id: "dtCreated",
        numeric: false,
        disablePadding: false,
        label: lang.createdAt,
      },
      {
        id: "del",
        numeric: false,
        disablePadding: false,
        label: lang.deleted,
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        showAddButton
        customHeaderOptions={() => this.customHeaderOptions()}
        onAddEvent={() => this.setState({ sideModalOpen: true })}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        handleSelectAllClick={(e) => {
          if (e.target.checked) {
            this.setState((state) => ({
              selected: state.itemList.map((n) => n.id),
            }));
            return;
          }
          this.setState({ selected: [] });
        }}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={companies.map((c) => ({
          id: c.id,
          display: c.display,
          dtCreated: new Date(c.dtCreated)
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("/"),
          description: c.description,
          document: c.document,
          email: c.email,
          name: c.name,
          phoneNumber: c.phoneNumber,
          whatsApp: c.whatsApp,
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell component="th" scope="row" padding="none">
                {n.name.length > 20 ? `${n.name.substr(0, 20)}...` : n.name}
              </TableCell>
              <TableCell>{n.document}</TableCell>

              <TableCell>{n.dtCreated}</TableCell>
              <TableCell>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    this.openDeleteModal(n.id);
                  }}
                  aria-label="Delete"
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </>
          );
        }}
      />
    );
  };

  // Renderiza a modal de deletar os items.
  openDeleteModal = (id) => {
    this.setState({
      idDelete: id,
      sideModalOpen: false,
      deleteCompaniesModalOpen: true,
    });
  };

  /**
   * Método para deletar o item na base de dados.
   */
  deleteItem = async () => {
    this.setState({ sideModalOpen: false });
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      let { idDelete } = this.state;

      if (idDelete) {
        this.setState({ openDeleteLoadingModal: true });
        await api.delete(
          `${PROFILE_API_BASE + PROFILE_LOCATION}/${
            idDelete + PLACEID_LOCATION
          }/remove?${QUERY_ACCESS + userToken}`,
          {
            idDelete,
          }
        );
        this.setState({ openDeleteLoadingModal: false });
        idDelete = undefined;
        if (idDelete) {
          await this.loadPlacesCompanies(idDelete);
        } else {
          await this.setState({ companies: [] });
        }
      }
    } catch (error) {
      console.log("caiu no catch", error);
      const errorObj = { ...error };
      this.setState({
        openDeleteLoadingModal: false,
      });
      let message = "";
      message = fetchErrors(
        errorObj.response.status,
        errorObj.response.data.content,
        "excluir a configuração!"
      );
      this.setState({
        mensagemError: message,
        openDeleteErrorModal: true,
        openLoadingModal: false,
        deleteCategoryModalOpen: false,
        openDeleteLoadingModal: false,
      });
    }
  };

  /**
   *
   * @param {Object} item Objeto do item do produto.
   */
  onClickItemEdition = async (companies) => {
    const { selected } = this.state;
    await this.setState({
      selected: selected.filter((s) => s !== companies.id),
      sideModalOpen: true,
      id: companies.id,
      display: companies.display,
      description: companies.description,
      document: companies.document,
      email: companies.email,
      name: companies.name,
      phoneNumber: companies.phoneNumber,
      whatsApp: companies.whatsApp,
    });
  };

  // Renderiza a modal de criar produtos
  renderSideModal = () => {
    const { lang } = this.props;
    const { sideModalOpen } = this.state;
    return (
      <CustomSideModal
        open={sideModalOpen}
        handleClose={this.handleCloseModal}
        title={!this.state.id ? lang.sideModalTitle : lang.sideModalTitleEdit}
        confirmationAction={this.validateFormValues}
        confirmationText={lang.save}
        cancelationAction={this.handleCloseModal}
        cancelationText={lang.cancel}
      >
        <Grid container>
          <Grid item xs={12}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  placesShowTooltip: false,
                  placesError: false,
                })
              }
              open={this.state.placesShowTooltip}
              title={this.state.placesTooltipMessage}
            >
              <TextField
                className="input-margin width-95"
                required
                label={lang.place}
                error={this.state.placesError}
                value={this.state.visibleValue}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  companiesShowTooltip: false,
                  companiesError: false,
                })
              }
              open={this.state.companiesShowTooltip}
              title={this.state.companiesTooltipMessage}
            >
              <FormControl fullWidth>
                <TextField
                  select
                  margin="dense"
                  variant="outlined"
                  label="Estabelecimentos"
                  required
                  error={this.state.companiesError}
                  onChange={this.handleChangeCompanies}
                  value={this.state.companiesvisibleValue}
                >
                  {this.state.profilesList.map((profile) => (
                    <MenuItem
                      key={profile.id}
                      value={profile.name}
                      id={profile.id}
                    >
                      {profile.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </CustomTooltip>
          </Grid>
        </Grid>
      </CustomSideModal>
    );
  };

  // Método realizado ao fechar a modal de adição do item.
  handleCloseModal = () => {
    this.setState({
      sideModalOpen: false,
      id: undefined,
      companiesName: "",
      companiesError: false,
      companiesShowTooltip: false,
      companiesTooltipMessage: "",
      placesName: undefined,
      placesError: false,
      placesShowTooltip: false,
      placesTooltipMessage: "",
      isEnable: true,
      visibleValue: "",
      selectedID: undefined,
      mensagemError: "",
      // Variavel para controlar modal de deletar o item
      deleteCompaniesModalOpen: false,
      delete: false,
      openDeleteErrorModal: false,
      openDeleteLoadingModal: false,
      idDelete: undefined,
      openLoadItem: false,
      isLoading: false,
      companieselectedID: undefined,
      companiesvisibleValue: "",
    });
  };

  // Método realizado para envio dos dados do formulário para o back
  handleModalSubmit = async () => {
    this.setState({ openLoadingModal: true });
    const configRes = await this.registerLink();
    if (configRes) {
      this.setState({
        openLoadingModal: false,
        openSuccessModal: true,
        sideModalOpen: false,
      });

      this.handleCloseModal();
    } else {
      this.setState({ openLoadingModal: false, openErrorModal: true });
    }
  };

  /**
   * Método para cadastrar o vinculo do cnpj.
   */
  registerLink = async () => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const pId = this.state.selectedID;
      const id = this.state.companieselectedID;

      const response = await api.put(
        `${PROFILE_API_BASE + PROFILE_LOCATION}/${
          id + PLACEID_LOCATION
        }/${pId}/add?${QUERY_ACCESS + userToken}`,
        {
          id,
          pId,
        }
      );
      await this.loadPlacesCompanies(pId);
      return response;
    } catch (error) {
      console.log(error);
      const errorObj = { ...error };
      console.log({ ...error });
      let msg = "";
      if (this.state.id) {
        msg = "editar os vinculos!";
      } else {
        msg = "cadastrar os vinculos!";
      }
      let message = "";
      message = fetchErrors(
        errorObj.response.status,
        errorObj.response.data.content,
        msg
      );
      this.setState({
        mensagemError: message,
      });
      return false;
    }
  };

  // Método para validar os campos do formulário
  validateFormValues = () => {
    const { selectedID, companieselectedID } = this.state;
    const { lang } = this.props;

    let data = {};
    if (!selectedID) {
      data = {
        ...data,
        placesShowTooltip: true,
        placesTooltipMessage: !selectedID
          ? lang.placeRequiredError
          : lang.placeInvalidError,
        placesError: true,
      };
    }
    if (!companieselectedID) {
      data = {
        ...data,
        companiesShowTooltip: true,
        companiesTooltipMessage: !companieselectedID
          ? lang.companiesRequiredError
          : lang.companiesInvalidError,
        companiesError: true,
      };
    }

    if (Object.keys(data).length > 0) {
      this.setState(data);
    } else {
      this.handleModalSubmit();
    }
  };

  render() {
    const { lang } = this.props;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <CustomModal
          open={this.state.openLoadingModal}
          onClose={() => this.setState({ openLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">
              {!this.state.id
                ? lang.registerCompaniesLoading
                : lang.editCompaniesLoading}
            </Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomConfirmationModal
          title={
            !this.state.id
              ? lang.registerSuccessTitle
              : lang.registerSuccessTitle
          }
          open={this.state.openSuccessModal}
          subtitle={
            !this.state.id
              ? lang.registerSuccessSubTitle
              : lang.editSuccessSubTitle
          }
          description={
            !this.state.id
              ? lang.registerSuccessDescription
              : lang.editSuccessDescription
          }
          confirmationText={lang.registerSuccessConfirmationText}
          confirmationAction={() => this.setState({ openSuccessModal: false })}
          onClose={() => this.setState({ openSuccessModal: false })}
        />

        <CustomConfirmationModal
          title={!this.state.id ? lang.registerErrorTitle : lang.editErrorTitle}
          open={this.state.openErrorModal}
          subtitle={
            !this.state.id ? lang.registerErrorSubTitle : lang.editErrorSubTitle
          }
          description={
            !this.state.id
              ? lang.registerErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
              : lang.editErrorDescription.replace(
                  "@MSG",
                  this.state.mensagemError
                )
          }
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() => this.setState({ openErrorModal: false })}
          onClose={() => this.setState({ openErrorModal: false })}
        />

        {/* Modal de confirmação para deletar categorias */}
        <CustomConfirmationModal
          title={lang.deleteCompaniesModalTitle}
          description={lang.deletarCompaniesModalDescription}
          open={this.state.deleteCompaniesModalOpen}
          confirmationText={lang.deleteCompaniesConfirmationText}
          confirmationAction={async () => this.deleteItem()}
          cancelationText={lang.cancelationText}
          cancelationAction={() =>
            this.setState({ deleteCompaniesModalOpen: false })
          }
          onClose={() => this.setState({ deleteCompaniesModalOpen: false })}
        />

        <CustomConfirmationModal
          title={lang.deleteErrorTitle}
          open={this.state.openDeleteErrorModal}
          subtitle={lang.deleteErrorSubTitle}
          description={lang.deleteErrorDescription.replace(
            "@MSG",
            this.state.mensagemError
          )}
          confirmationText={lang.deleteErrorConfirmationText}
          confirmationAction={() =>
            this.setState({ openDeleteErrorModal: false })
          }
          onClose={() => this.setState({ openDeleteErrorModal: false })}
        />

        {this.renderTable()}
        {this.renderSideModal()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(LinkPlacesCompanies);
