import { CHANGE_DATA } from "./types";

/**
 * Metodo que coloca dados no store compartilhado da aplicacao, assim,
 * fica mais facil o acesso de dados.
 *
 * @param {*} data Dado que sera inserido no redux para que a aplicacao toda tenha acesso.
 * @param {String} key chave para guardar os dados.
 */
export const changeData = (key, value) => dispatch => {
  try {
    dispatch({ type: CHANGE_DATA, payload: { value, key } });
  } catch (error) {
    console.log(`changeData: ${error}`);
  }
};
