// Bibliotecas.
import React, { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import api from "./utils/API";
// Componentes.
import CustomRoute from "./components/CustomRoute";
import lang from "./config/lang";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Order from "./pages/Order";
import OrderPanel from "./pages/OrderPanel";
import Products from "./pages/Products";
import Companies from "./pages/Companies";
import Places from "./pages/Places";
import Category from "./pages/Category";
import ItemProduct from "./pages/ItemProduct";
import BankAccount from "./pages/BankAccount";
import UsersPDV from "./pages/UserPDV";
import OrderList from "./pages/OrderList";
import Settings from "./pages/Settings";
import StoreOrder from "./pages/StoreOrder";
import SettingsItems from "./pages/SettingsItems";
import LinkPlacesCompanies from "./pages/LinkPlacesCompanies";
import UserList from "./pages/UserList";
import AllOrder from "./pages/AllOrder";
import ListProductsInPreparation from "./pages/ListProductsInPreparation";
import Message from "./pages/Message";

// Funções e dados.
import { storeUserData, logUserOut, storeUserRoles } from "./redux/actions";
import {
  USER_ID,
  USER_AUTH_TOKEN,
  USER_AUTH_TOKEN_EXPIRATION,
} from "./static/LocalStorageTags";
import { getItem } from "./utils/localStorage";
import { loadPermissionUser } from "./utils/permission";
import { USER_API_BASE, USERS_LOCATION, QUERY_ACCESS } from "./static/URL";

// Componente de rotas.
class Routes extends Component {
  state = { loading: true, userId: undefined, token: undefined };

  componentDidMount() {
    // Ao iniciar o componente de rotas, coloca o watcher de dados do firebase.
    // firebase.auth().onAuthStateChanged(this.onAuthStateChanged);
    this.onInit();
  }

  /**
   * Método realizado ao iniciar componente de rotas.
   */
  onInit = async () => {
    // Valida a sessao do usuario.
    // if (user) {
    // Recupera dados do usuário no storage.
    const userId = getItem(USER_ID);
    const token = getItem(USER_AUTH_TOKEN);
    const exp = getItem(USER_AUTH_TOKEN_EXPIRATION);
    // Valida se os dados estao validos.
    if (
      userId &&
      token &&
      exp &&
      new Date(parseInt(exp, 10)).getTime() > new Date().getTime()
    ) {
      // Guarda dados no estado
      await this.setState({ userId, token });
      // Realiza processo de login
      await this.onLoginSuccess();
    } else {
      await this.props.logUserOut();
    }
    // Define a variavel de carregamento como falsa, agora disponibilizando as rotas.
    this.setState({ loading: false });
  };

  /**
   * Método realizado ao conseguir logar com sucesso.
   */
  onLoginSuccess = async () => {
    try {
      // Recupera dados do usuário.
      const token = getItem(USER_AUTH_TOKEN);
      const userId = getItem(USER_ID);
      const { data: user } = await api.get(
        `${USER_API_BASE + USERS_LOCATION}/${userId}?${QUERY_ACCESS + token}`
      );

      // Busca as permissoes do token.
      const permissions = await loadPermissionUser(token);
      // TODO: RECUPERAR ROLE QUE VEM DO TOKEN, E INSERI-LA NO ARRAY DO USUARIO
      this.props.storeUserData(user);
      this.props.storeUserRoles(permissions);
    } catch (error) {
      console.log({ ...error });
      // Define a variavel de carregamento como falsa, agora disponibilizando as rotas.
      this.setState({ loading: false });
    }
  };

  render() {
    // Retorna o componente de acordo com a variavel de carregamento.
    return this.state.loading ? null : (
      <BrowserRouter>
        <Switch>
          {/* Rotas abertas. */}
          <CustomRoute path="/login" Component={Login} lang={lang.loginPage} />
          <CustomRoute
            path="/register"
            Component={Register}
            lang={lang.registerPage}
          />
          {/* Rotas privadas. */}
          <CustomRoute
            path="/dashboard"
            privateRoute
            Component={Dashboard}
            dataToCheck={this.props.user.user}
            permissions={this.props.user.permissions}
            lang={lang.dashPage}
          />
          <CustomRoute
            path="/category"
            privateRoute
            Component={Category}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.categoryPage}
          />
          <CustomRoute
            path="/products"
            privateRoute
            Component={Products}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.productsPage}
          />
          <CustomRoute
            path="/users"
            privateRoute
            Component={UsersPDV}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.userPDVPage}
          />
          <CustomRoute
            path="/items"
            privateRoute
            Component={ItemProduct}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.itemProductPage}
          />
          <CustomRoute
            path="/accounts"
            privateRoute
            Component={BankAccount}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.bankAccountPage}
          />
          <CustomRoute
            path="/companies"
            privateRoute
            Component={Companies}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.companiesPage}
          />
          <CustomRoute
            path="/places"
            privateRoute
            Component={Places}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.placesPage}
          />
          <CustomRoute
            path="/orderPanel"
            privateRoute
            Component={OrderPanel}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.orderPanelPage}
          />
          <CustomRoute
            path="/orderList"
            privateRoute
            Component={OrderList}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.orderListPage}
          />
          <CustomRoute
            path="/storeOrder"
            privateRoute
            Component={StoreOrder}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.storeOrderPage}
          />
          <CustomRoute
            path="/settings"
            privateRoute
            Component={Settings}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.settingsPage}
          />
          <CustomRoute
            path="/settingsItems"
            privateRoute
            Component={SettingsItems}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.settingsItemsPage}
          />
          <CustomRoute
            path="/linkPlacesCompanies"
            privateRoute
            Component={LinkPlacesCompanies}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.linkPlacesCompaniesPage}
          />
          <CustomRoute
            path="/listUser"
            privateRoute
            Component={UserList}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.userListPage}
          />
          <CustomRoute
            path="/allOrder"
            privateRoute
            Component={AllOrder}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.allOrderPage}
          />
          <CustomRoute
            path="/listProductsInPreparation"
            privateRoute
            Component={ListProductsInPreparation}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.listProductsInPreparationPage}
          />
          <CustomRoute
            path="/message"
            privateRoute
            Component={Message}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.messagePage}
          />

          <CustomRoute
            path="/"
            privateRoute
            Component={Order}
            permissions={this.props.user.permissions}
            dataToCheck={this.props.user.user}
            lang={lang.orderPage}
          />
        </Switch>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, {
  storeUserData,
  logUserOut,
  storeUserRoles,
})(Routes);
