import React, { Component } from "react";
import { Input, InputLabel, FormControl } from "@material-ui/core";
import MaskedInput from "react-text-mask";

class CustomMaskedInput extends Component {
  InputMaskPhone = props => {
    const { inputRef, ...other } = props;

    return (
      <MaskedInput
        {...other}
        ref={ref => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={this.props.mask}
        placeholder={this.props.label}
        required={this.props.required || false}
      />
    );
  };

  render() {
    return (
      <FormControl
        fullWidth={this.props.fullWidth}
        className={this.props.formControllClassname}
        style={this.props.style}
      >
        <InputLabel htmlFor={this.props.id}>
          {this.props.label}
          {this.props.required ? "*" : null}
        </InputLabel>
        <Input
          className={this.props.className}
          value={this.props.value}
          onChange={e => this.props.onChange(e.target.value)}
          id={this.props.id}
          inputComponent={this.InputMaskPhone}
          required={this.props.required || false}
          error={this.props.error || false}
          inputProps={this.props.inputProps}
        />
      </FormControl>
    );
  }
}

export default CustomMaskedInput;
