import React, { Component } from "react";
import lang from "../../config/lang";

// Recursos visuais
import {
  CardContainer,
  StyledAvatar,
  StyledProgress,
  StyledSpan,
} from "./styles.css";

export default class CustomCrudCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageStatus: "loading",
    };
  }

  render() {
    const { img, title, description } = this.props;
    const { imageStatus } = this.state;
    return (
      <CardContainer onClick={this.props.onClick} className="shadow-2">
        <div className="flex-column width-100">
          <StyledAvatar
            imgProps={{
              onLoad: () => this.setState({ imageStatus: "loaded" }),
            }}
            alt=""
            src={img}
            className="product-avatar"
            imgstatus={imageStatus}
          />
          {imageStatus === "loading" && (
            <StyledProgress size={150} thickness={1} />
          )}
          <StyledSpan>
            <b>{lang.crudCard.name}:</b>{" "}
            {title.length > 25 ? `${title.substr(0, 25)}...` : title}
          </StyledSpan>
          <StyledSpan>
            <b>{lang.crudCard.description}:</b>{" "}
            {description.length > 110
              ? `${description.substr(0, 110)}...`
              : description}
          </StyledSpan>
        </div>
      </CardContainer>
    );
  }
}
