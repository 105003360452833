// Bibliotecas.
import React, { Component } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import { connect } from "react-redux";
// Funções
import { changeData } from "../../../redux/actions";
import { checkCEP } from "../../../utils/data";
import { CEP_MASK, STATE_MASK } from "../../../utils/masks";
// Recursos visuais
import "../style.css";
import { ButtonContainer } from "./styles.css";
// Componentes.
import CustomMaskedInput from "../../../components/CustomMaskedInput";
import CustomTooltip from "../../../components/CustomTooltip";

// Passo do formulário para cadastrar os dados de endereço da empresa.
class Step5 extends Component {
  state = {
    streetShowTooltip: false,
    streetTooltipMessage: "",
    streetError: false,
    numberShowTooltip: false,
    numberTooltipMessage: "",
    numberError: false,
    neighborhoodShowTooltip: false,
    neighborhoodTooltipMessage: "",
    neighborhoodError: false,
    zipCodeShowTooltip: false,
    zipCodeTooltipMessage: "",
    zipCodeError: false,
    cityShowTooltip: false,
    cityTooltipMessage: "",
    cityError: false,
    stateShowTooltip: false,
    stateTooltipMessage: "",
    stateError: false
  };

  /**
   * Método realizado ao inserir dados no cep.
   */
  onChangeCEP = async zipCode => {
    // Realiza ação no estado compartilhado
    this.props.changeData("zipCode", zipCode);
    // Cria regex para validar.
    const regex = new RegExp(/\d{5}-\d{3}/g);
    // Testa se o CEP esta no formato do regex.
    if (zipCode && regex.test(zipCode)) {
      // Se sim, realiza a chamada para a API da viacep, para completar os dados do cadastro.
      const res = await axios.get(`https://viacep.com.br/ws/${zipCode}/json`);
      // Recupera os dados mais interessantes.
      const { bairro, localidade, logradouro, uf } = res.data;
      if (bairro && localidade && logradouro && uf) {
        // Define os dados no estado compartilhado.
        this.props.changeData("state", uf);
        this.props.changeData("city", localidade);
        this.props.changeData("street", logradouro);
        this.props.changeData("neighborhood", bairro);
      }
    }
  };

  /**
   * Método que valida os dados cadastrados.
   */
  checkStep5Data = () => {
    let data = {};
    if (!this.props.street || this.props.street.trim().length === 0) {
      data = {
        ...data,
        streetShowTooltip: true,
        streetTooltipMessage: !this.props.street
          ? this.props.lang.streetRequired
          : this.props.lang.streetInvalid,
        streetError: true
      };
    }
    if (
      !this.props.number ||
      this.props.number.trim().length === 0 ||
      isNaN(this.props.number)
    ) {
      data = {
        ...data,
        numberShowTooltip: true,
        numberTooltipMessage: !this.props.number
          ? this.props.lang.numberRequired
          : this.props.lang.numberInvalid,
        numberError: true
      };
    }
    if (
      !this.props.neighborhood ||
      this.props.neighborhood.trim().length === 0
    ) {
      data = {
        ...data,
        neighborhoodShowTooltip: true,
        neighborhoodTooltipMessage: !this.props.neighborhood
          ? this.props.lang.neighborhoodRequired
          : this.props.lang.neighborhoodInvalid,
        neighborhoodError: true
      };
    }
    if (
      !this.props.zipCode ||
      this.props.zipCode.trim().length === 0 ||
      !checkCEP(this.props.zipCode)
    ) {
      data = {
        ...data,
        zipCodeShowTooltip: true,
        zipCodeTooltipMessage: !this.props.zipCode
          ? this.props.lang.zipCodeRequired
          : this.props.lang.zipCodeInvalid,
        zipCodeError: true
      };
    }
    if (!this.props.city || this.props.city.trim().length === 0) {
      data = {
        ...data,
        cityShowTooltip: true,
        cityTooltipMessage: !this.props.city
          ? this.props.lang.cityRequired
          : this.props.lang.cityInvalid,
        cityError: true
      };
    }
    if (
      !this.props.state ||
      this.props.state.trim().length === 0 ||
      this.props.state.trim().length > 2
    ) {
      data = {
        ...data,
        stateShowTooltip: true,
        stateTooltipMessage: !this.props.state
          ? this.props.lang.stateRequired
          : this.props.lang.stateInvalid,
        stateError: true
      };
    }
    if (Object.keys(data).length > 0) {
      this.setState(data);
    } else {
      this.props.onSuccess();
    }
  };

  render() {
    return (
      <div
        className={`flex-column
        input-div-container
        justify-center
        shadow-2
        register-container`}
      >
        <div className="flex-row justify-center align-items-center address-title">
          <Typography variant="h6" gutterBottom>
            {this.props.lang.address}
          </Typography>
        </div>
        <CustomTooltip
          onClose={() =>
            this.setState({
              zipCodeShowTooltip: false,
              zipCodeError: false
            })
          }
          open={this.state.zipCodeShowTooltip}
          title={this.state.zipCodeTooltipMessage}
        >
          <CustomMaskedInput
            formControllClassname="input-margin"
            required
            value={this.props.zipCode}
            onChange={zipCode => this.onChangeCEP(zipCode)}
            id="zipCode"
            label={this.props.lang.zipCode}
            error={this.state.zipCodeError}
            mask={CEP_MASK}
          />
        </CustomTooltip>
        <div className="flex-row justify-center align-items-center">
          <CustomTooltip
            onClose={() =>
              this.setState({
                streetShowTooltip: false,
                streetError: false
              })
            }
            open={this.state.streetShowTooltip}
            title={this.state.streetTooltipMessage}
          >
            <TextField
              className="input-margin"
              required
              id="street"
              error={this.state.streetError}
              label={this.props.lang.street}
              value={this.props.street}
              onChange={e => this.props.changeData("street", e.target.value)}
            />
          </CustomTooltip>
          <CustomTooltip
            onClose={() =>
              this.setState({
                numberShowTooltip: false,
                numberError: false
              })
            }
            open={this.state.numberShowTooltip}
            title={this.state.numberTooltipMessage}
          >
            <TextField
              className="input-margin"
              required
              id="number"
              label={this.props.lang.number}
              error={this.state.numberError}
              value={this.props.number}
              onChange={e => this.props.changeData("number", e.target.value)}
            />
          </CustomTooltip>
        </div>
        <CustomTooltip
          onClose={() =>
            this.setState({
              neighborhoodShowTooltip: false,
              neighborhoodError: false
            })
          }
          open={this.state.neighborhoodShowTooltip}
          title={this.state.neighborhoodTooltipMessage}
        >
          <TextField
            className="input-margin"
            required
            id="neighborhood"
            label={this.props.lang.neighborhood}
            error={this.state.neighborhoodError}
            value={this.props.neighborhood}
            onChange={e =>
              this.props.changeData("neighborhood", e.target.value)
            }
          />
        </CustomTooltip>

        <TextField
          className="input-margin"
          id="complement"
          label={this.props.lang.complement}
          value={this.props.complement}
          onChange={e => this.props.changeData("complement", e.target.value)}
        />
        <div className="flex-row justify-center align-items-center">
          <CustomTooltip
            onClose={() =>
              this.setState({
                cityShowTooltip: false,
                cityError: false
              })
            }
            open={this.state.cityShowTooltip}
            title={this.state.cityTooltipMessage}
          >
            <TextField
              className="input-margin"
              required
              error={this.state.cityError}
              id="city"
              label={this.props.lang.city}
              value={this.props.city}
              onChange={e => this.props.changeData("city", e.target.value)}
            />
          </CustomTooltip>
          <CustomTooltip
            onClose={() =>
              this.setState({
                stateShowTooltip: false,
                stateError: false
              })
            }
            open={this.state.stateShowTooltip}
            title={this.state.stateTooltipMessage}
          >
            <CustomMaskedInput
              formControllClassname="input-margin"
              required
              value={this.props.state}
              error={this.state.stateError}
              onChange={state => this.props.changeData("state", state)}
              id="state"
              label={this.props.lang.state}
              mask={STATE_MASK}
            />
          </CustomTooltip>
        </div>
        <ButtonContainer>
          <Button
            variant="contained"
            color="primary"
            onClick={this.checkStep5Data}
          >
            {this.props.lang.continue}
          </Button>
          <Button onClick={this.props.back} className="back-button">
            {this.props.lang.goBack}
          </Button>
        </ButtonContainer>
      </div>
    );
  }
}

function mapStateToProps({ register }) {
  try {
    return {
      ...register
    };
  } catch (error) {
    console.log(`Register - mapStateToProps: ${error}`);
    return {};
  }
}

export default connect(mapStateToProps, { changeData })(Step5);
