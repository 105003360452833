import firebase from "firebase/app";
import "firebase/database";
import {
  FETCH_ORDERS_SUCCESS,
  SHOW_SNACKBAR,
  CLOSE_SNACKBAR,
  SHOW_LOADER,
  CLOSE_LOADER,
} from "./types";

/**
 * Método que busca os pedidos do perfil.
 *
 * @param {String} profile id do perfil.
 */
export const fetchProfileOrders = (profile) => {
  console.log("profile", profile);
  return (dispatch) => {
    firebase
      .database()
      .ref(`/orders/${profile}/`)
      .on("value", (snapshot) => {
        dispatch({
          type: FETCH_ORDERS_SUCCESS,
          payload: snapshot.val() ? Object.values(snapshot.val()) : [],
        });
      });
  };
};

/**
 * Metodo para mostrar uma snackbar.
 *
 * @param {String} message mensagem para snackbar
 * @param {String} variant variante da snackbar
 */
export const showSnackbar = (message, variant) => (dispatch) => {
  dispatch({ type: SHOW_SNACKBAR, payload: { message, variant } });
};

/**
 * Metodo para fechar uma snackbar.
 */
export const closeSnackbar = () => (dispatch) => {
  dispatch({ type: CLOSE_SNACKBAR });
};

/**
 * Metodo para mostrar o loader.
 *
 * @param {String} message mensagem para snackbar
 * @param {String} variant variante da snackbar
 */
export const showLoader = (orderId) => (dispatch) => {
  dispatch({ type: SHOW_LOADER, payload: { orderId } });
};

/**
 * Metodo para fechar o loader.
 */
export const closeLoader = () => (dispatch) => {
  dispatch({ type: CLOSE_LOADER });
};
