// Bibliotecas.
import React, { Component } from "react";
import { Typography } from "@material-ui/core";
// Recursos visuais.
import { ListContainer, Container } from "./styles.css";
// Componentes.
import Card from "../Card";

class List extends Component {
  render() {
    const { data, index: listIndex, title, done, lang } = this.props;
    return (
      <Container data={data}>
        <header
          title={title}
          className="header-style shadow-1 system-primary-background"
        >
          <Typography className="list-header-title" variant="subtitle1">
            {title}
          </Typography>
          <Typography variant="caption" className="list-subtitle">
            {data.length}
          </Typography>
        </header>
        <ListContainer done={done} data={data}>
          <ul>
            {data.map((item, i) => (
              <Card
                lang={lang}
                key={item.id}
                data={item}
                index={i}
                listIndex={listIndex}
                length={data.length}
              />
            ))}
          </ul>
        </ListContainer>
      </Container>
    );
  }
}

export default List;
