/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import { TableCell } from "@material-ui/core";
import "firebase/auth";

// Funções e dados estaticos.
import { fetchProfileOrders } from "../../redux/actions";
// Componentes.
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
// Recursos visuais.

class ListProductsInPreparation extends Component {
  state = {
    // Table

    open: false,
    selected: [],
    order: "desc",
    orderBy: "id",
    page: 0,
    rowsPerPage: 10,
    // SideModal
    sideModalOpen: false,
    openLoadCategory: false,
    orders: [],
    newOrders: [],
    // SideModal
    originalOrders: [],
    selectedOrder: {},
    checkOrderInfoModalOpen: false,
    products: [],
  };

  async componentDidMount() {
    const profile = this.props.user.selectedProfile;
    if (profile) {
      this.setState({ orders: [] });
      // Pegar empresas do usuário.
      this.props.fetchProfileOrders(profile.id);
    }
  }

  /*
   * Método que carega todas categorias cadastradas.
   */
  // eslint-disable-next-line react/sort-comp
  loadProdutcs = async (orders) => {
    const novoArray = orders.filter((val) => {
      return val.orderStatus.index === 9;
    });

    const products = novoArray.reduce((total, amount) => {
      amount.itemList.forEach((items) => {
        total.push(items);
      });
      return total;
    }, []);

    const count = products.reduce((objeto, elementoAtual) => {
      const grupo = elementoAtual.prodId;
      // eslint-disable-next-line no-prototype-builtins
      if (!objeto.hasOwnProperty(grupo)) {
        objeto[grupo] = [];
      }
      objeto[grupo].push(elementoAtual);
      return objeto;
    }, {});

    const keys = Object.keys(count);
    const productsList = [];
    for (let i = 0; i < keys.length; i++) {
      const productsListName = keys[i];
      let qtda = 0;
      let produtoName;
      let id = 0;

      for (let j = 0; j < count[productsListName].length; j++) {
        const screen = count[productsListName][j];
        // eslint-disable-next-line eqeqeq
        qtda += screen.quantity;
        produtoName = screen.prodName;
        id = screen.id;
      }

      productsList[i] = {
        quantity: qtda,
        prodName: produtoName,
        id,
      };
    }
    await this.setState({
      products: productsList,
    });
  };

  // eslint-disable-next-line consistent-return
  async UNSAFE_componentWillReceiveProps(nextProps) {
    try {
      await this.setState({
        orders: nextProps.orders,
        originalOrders: nextProps.orders,
      });
      this.loadProdutcs(nextProps.orders);
      this.filterOrders();
      // Valida se o id do perfil selecionado no redux esta vazio.
      if (
        !nextProps.user.selectedProfile ||
        !nextProps.user.selectedProfile.id ||
        !this.props.user.selectedProfile ||
        !this.props.user.selectedProfile.id
      ) {
        this.setState({ orders: [], originalOrders: [], products: [] });
      }
      // Caso nao esteja, valida a seleção.
      else if (
        nextProps.user.selectedProfile &&
        nextProps.user.selectedProfile.id &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id &&
        this.props.user.selectedProfile.id !== nextProps.user.selectedProfile.id
      ) {
        this.setState({
          orders: [],
          products: [],
        });
        this.props.fetchProfileOrders(nextProps.user.selectedProfile.id);
      }
    } catch (error) {
      console.log(`Order - componentWillReceiveProps${error}`);
    }
  }

  // Renderiza a tabela de categorias
  renderTable = () => {
    const { lang } = this.props;
    const { products } = this.state;
    const rows = [
      {
        id: "product",
        numeric: false,
        disablePadding: false,
        label: lang.product,
      },
      {
        id: "quantidade",
        numeric: false,
        disablePadding: false,
        label: lang.quantity,
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        onAddEvent={() => this.setState({ sideModalOpen: false })}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        onClick={() => console.log("")}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={products.map((o) => ({
          prodName: o.prodName,
          quantity: o.quantity,
          id: o.id,
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell component="th" scope="row">
                {n.prodName}
              </TableCell>
              <TableCell component="th" scope="row">
                {n.quantity}
              </TableCell>
            </>
          );
        }}
      />
    );
  };

  render() {
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        {this.renderTable()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user, order }) => {
  // eslint-disable-next-line no-undef
  let orders =
    order && Array.isArray(order.orders)
      ? order.orders.filter((val) => {
          return val !== undefined;
        })
      : [];
  if (orders) {
    // eslint-disable-next-line no-undef
    orders = _.sortBy(orders, ["id"]);
  }
  return { user, orders };
};

export default connect(mapStateToProps, {
  fetchProfileOrders,
})(ListProductsInPreparation);
