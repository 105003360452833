// Dados estáticos.
import { QUERY_ACCESS, PAYMENT_API_BASE, BANK_LOCATION } from "../static/URL";
// Bibliotecas de utilidade.
import api from "../utils/API";

/**
 * Método para carregar os bancos.
 *
 * @param {String} token  de autenticação
 */
export const loadBanks = async (token) => {
  try {
    const { data: banksList } = await api.get(
      `${PAYMENT_API_BASE + BANK_LOCATION}?${QUERY_ACCESS + token}`
    );
    return banksList;
  } catch (error) {
    console.log({ ...error });
    return [];
  }
};
