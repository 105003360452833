// Bibliotecas
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  // Badge
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {
  PowerSettingsNew,
  // Notifications
  Person,
} from "@material-ui/icons";
import { connect } from "react-redux";
import clsx from "clsx";
// Ações
import {
  logUserOut,
  selectProfile,
  logUserType,
  fetchProfileOrders,
} from "../../redux/actions";
import { fetchUserProfiles } from "../../functions/profile";
import { getItem, setItem } from "../../utils/localStorage";
// Dados estaticos
import {
  USER_ID,
  USER_AUTH_TOKEN,
  USER_SELECTED_PROFILE,
  USER_TYPE_LOGIN,
} from "../../static/LocalStorageTags";
import lang from "../../config/lang";
// Recursos visuais.
import { Logo, appbarStyle, ButtonContainer } from "./styles.css";

const styles = {
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 20,
  },
  title: {
    flexGrow: 1,
    color: "white",
    marginRight: 10,
  },
  picture: {
    borderRadius: 30,
    height: 40,
  },
};

class CustomAppBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      anchorEl2: null,
      profiles: [],
      selectedProfile: undefined,
    };
  }

  async componentDidMount() {
    await this.getProfileProcess();
  }

  getProfileProcess = async () => {
    const userId = getItem(USER_ID);
    const token = getItem(USER_AUTH_TOKEN);
    const profiles = await fetchUserProfiles(userId, token);
    const savedSelectedProfile = getItem(USER_SELECTED_PROFILE);
    let profileToSelect;
    if (savedSelectedProfile) {
      const profileObj = JSON.parse(savedSelectedProfile);
      profileToSelect = profileObj;
    } else if (profiles && profiles.length > 0) {
      const [profileInArray] = profiles;
      profileToSelect = profileInArray;
    }
    console.log("selecionando perfil");
    this.props.selectProfile(profileToSelect);
    this.props.fetchProfileOrders(profileToSelect.id);
    setItem(USER_SELECTED_PROFILE, JSON.stringify(profileToSelect));
    this.setState({ profiles });
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenu2 = (event) => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleClose2 = () => {
    this.setState({ anchorEl2: null });
  };

  /**
   * Método realizado ao deslogar usuário.
   */
  onLogout = async () => {
    await this.props.logUserOut();
    this.props.history.replace("/login");
  };

  /**
   * Método que valida e retorna nome do perfil selecionado.
   */
  renderProfileName = () => {
    if (
      this.props.user &&
      this.props.user.selectedProfile &&
      this.props.user.selectedProfile.display
    ) {
      return this.props.user.selectedProfile.display;
    }
    return lang.appBar.noProfileSelected;
  };

  /**
   * Método que valida e retorna o nome do usuário logado.
   */
  renderUserName = () => {
    if (getItem(USER_TYPE_LOGIN) === "email") {
      return "PDV";
    }
    if (this.props.user && this.props.user.user && this.props.user.user.name) {
      return this.props.user.user.name;
    }
    return "";
  };

  /**
   * Método que valida o tipo de login.
   */
  getTypeLogin = () => {
    if (getItem(USER_TYPE_LOGIN) === "email") {
      return "https://firebasestorage.googleapis.com/v0/b/furafilla-client.appspot.com/o/logos%2Ffurafilla%2Ffurafilla_icon_pink%403x.png?alt=media&token=5539202d-0872-4c6b-a017-036b0838ec84";
    }
    if (this.imageAvailable()) {
      return this.props.user.user.fbUrlImage;
    }
    return "https://firebasestorage.googleapis.com/v0/b/furafilla-client.appspot.com/o/logos%2Ffurafilla%2F685-6851196_person-icon-grey-hd-png-download.png?alt=media&token=6fa4d1e8-3930-4398-a980-fac763e7dd81";
  };

  /**
   * Método que retorna condição para validar se imagem do perfil está disponível.
   */
  imageAvailable = () => {
    return (
      this.props.user && this.props.user.user && this.props.user.user.fbUrlImage
    );
  };

  render() {
    const { classes } = this.props;

    const open2 = Boolean(this.state.anchorEl);
    const open3 = Boolean(this.state.anchorEl2);
    return (
      <AppBar
        position="fixed"
        style={appbarStyle}
        className={clsx(this.props.classesDrawer.appBar, "", {
          [this.props.classesDrawer.appBarShift]: this.props.open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={this.props.handleDrawerOpen}
            edge="start"
            className={clsx(this.props.classesDrawer.menuButton, {
              [this.props.classesDrawer.hide]: this.props.open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h5"
            noWrap
            title={this.props.title}
            className={classes.title}
          >
            {this.props.title}
          </Typography>

          <div>
            <ButtonContainer>
              <IconButton
                aria-label="current user profile"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={this.handleMenu2}
                color="inherit"
              >
                {this.props.user.selectedProfile && (
                  <Logo
                    src={this.props.user.selectedProfile.imageUrl}
                    alt="user"
                  />
                )}
                <Typography
                  variant="subtitle1"
                  noWrap
                  className={`${classes.title} profile-name`}
                >
                  {this.renderProfileName()}
                </Typography>
              </IconButton>
            </ButtonContainer>
            <Menu
              id="menu-appbar"
              anchorEl={this.state.anchorEl2}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              open={open3}
              onClose={this.handleClose2}
            >
              <MenuItem
                onClick={async () => {
                  this.props.selectProfile(undefined);
                  this.handleClose2();
                }}
              >
                <ListItemText primary={lang.appBar.noProfileSelected} />
              </MenuItem>
              {this.state.profiles &&
                this.state.profiles.map((profile) => (
                  <MenuItem
                    selected={
                      this.props.user.selectedProfile &&
                      this.props.user.selectedProfile.id === profile.id
                    }
                    key={profile.id}
                    onClick={() => {
                      this.props.selectProfile(profile);
                      setItem(USER_SELECTED_PROFILE, JSON.stringify(profile));
                      this.handleClose2();
                    }}
                  >
                    <Logo src={profile.imageUrl} alt="profile" />
                    <ListItemText primary={profile.display} />
                  </MenuItem>
                ))}
            </Menu>
          </div>

          <div>
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
            >
              <img
                className={classes.picture}
                src={this.getTypeLogin()}
                alt="user"
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={this.state.anchorEl}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              keepMounted
              open={open2}
              onClose={this.handleClose}
            >
              {/* <MenuItem>
                <ListItemIcon>
                  <Badge badgeContent={3} max={100} color="error">
                    <Notifications />
                  </Badge>
                </ListItemIcon>
                <ListItemText primary="Notificações" />
              </MenuItem>
              <MenuItem>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText primary="Meu perfil" />
              </MenuItem> */}
              <MenuItem
                onClick={async () => {
                  console.log("Usuário");
                }}
              >
                <ListItemIcon>
                  <Person />
                </ListItemIcon>
                <ListItemText primary={this.renderUserName()} />
              </MenuItem>
              <MenuItem
                onClick={async () => {
                  await this.onLogout();
                }}
              >
                <ListItemIcon>
                  <PowerSettingsNew />
                </ListItemIcon>
                <ListItemText primary={lang.appBar.logout} />
              </MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps, {
  logUserOut,
  selectProfile,
  logUserType,
  fetchProfileOrders,
})(withStyles(styles)(CustomAppBar));
