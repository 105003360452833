// Bibliotecas.
import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Typography } from "@material-ui/core";
import { Search, Fullscreen, FullscreenExit } from "@material-ui/icons";
// COmponentes.
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import Board from "./Board";
import CustomSnackbar from "../../components/CustomSnackbar";
import OrderScanModal from "./OrderScanModal";
import OrderInfoModal from "./Card/OrderInfoModal";
import CustomFabButtom from "../../components/CustomFabButtom";
// Recursos visuais.
import { CardContainer, CardListContainer, Container } from "./style.css";
// Ações.
import {
  fetchProfileOrders,
  closeSnackbar,
  showLoader,
} from "../../redux/actions";
// Dados estaticos
import {
  PAYMENT_MADE,
  states,
  WAITING_APPROVAL,
} from "../../static/orderStates";
import { printOrder, changeOrderState } from "../../functions/order";
import { getItem } from "../../utils/localStorage";
import { USER_AUTH_TOKEN } from "../../static/LocalStorageTags";
import audio from "../../assets/swiftly.mp3";

const notification = new Audio(audio);

class Order extends Component {
  state = {
    orders: [],
    orderScanModalOpen: false,
    checkOrderInfoModalOpen: false,
    selectedOrder: {},
    fullScreen: false,
    timeoutStarted: false,
    token: "",
    notified: [],
    browseNotification: false,
  };

  componentDidMount() {
    const profile = this.props.user.selectedProfile;
    if (profile) {
      // Pegar empresas do usuário.
      this.setState({ orders: [] });
      this.props.fetchProfileOrders(profile.id);
    }

    const token = getItem(USER_AUTH_TOKEN);
    if (token) {
      this.setState({ token });
    }

    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification");
    } else {
      Notification.requestPermission();
    }
  }

  /**
   *
   * @param {Object} nextProps Proximo objeto de props do componente.
   */
  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(nextProps) {
    try {
      this.setState({ orders: nextProps.orders });
      if (
        Array.isArray(nextProps.orders) &&
        nextProps.orders.length > 0 &&
        !this.state.timeoutStarted
      ) {
        console.log("Vou iniciar os interval");
        await this.setState({ timeoutStarted: true });
        await this.onTimeoutCheck();
        this.setState({
          browseNotification: true,
        });
      }
      // Valida se o id do perfil selecionado no redux esta vazio.
      if (
        !nextProps.user.selectedProfile ||
        !nextProps.user.selectedProfile.id ||
        !this.props.user.selectedProfile ||
        !this.props.user.selectedProfile.id
      ) {
        this.setState({ orders: [] });
      }
      // Caso nao esteja, valida a seleção.
      else if (
        nextProps.user.selectedProfile &&
        nextProps.user.selectedProfile.id &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id &&
        this.props.user.selectedProfile.id !== nextProps.user.selectedProfile.id
      ) {
        this.setState({ orders: [] });
        this.props.fetchProfileOrders(nextProps.user.selectedProfile.id);
      }
    } catch (error) {
      console.log(`Order - componentWillReceiveProps${error}`);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  showNotification = () => {
    const options = {
      icon: "https://furafilla.com.br/favicon.ico",
      body: `${this.props.user.selectedProfile.display} chegou novo pedido no FuraFilla!`,
      dir: "ltr",
    };

    this.setState({
      browseNotification: false,
    });
    // eslint-disable-next-line no-new
    new Notification("FuraFilla", options);
    // eslint-disable-next-line no-new
  };

  onTimeoutCheck = async () => {
    if (Array.isArray(this.state.orders) && this.state.orders.length > 0) {
      const hasWaitingOrders =
        this.state.orders.filter(
          (o) =>
            o.orderStatus &&
            o.orderStatus.index &&
            o.orderStatus.index === WAITING_APPROVAL.index
        ).length > 0;

      const paidOrders = this.state.orders.filter(
        (o) =>
          o.orderStatus &&
          o.orderStatus.index &&
          o.orderStatus.index === PAYMENT_MADE.index
      );
      clearTimeout();
      if (paidOrders && paidOrders.length > 0) {
        for (let i = 0; i < paidOrders.length; i++) {
          const order = paidOrders[i];
          // eslint-disable-next-line no-await-in-loop
          await this.automaticPreparing({ order });
        }
      }

      if (hasWaitingOrders) {
        notification.play();
      }

      const isDefaultBehaviour = paidOrders.length === 0 && !hasWaitingOrders;
      if (isDefaultBehaviour) {
        // setTimeout(this.onTimeoutCheck, 10000);
        setTimeout(this.onTimeoutCheck, 2000);
      } else {
        // setTimeout(this.onTimeoutCheck, 3000);
        setTimeout(this.onTimeoutCheck, 2000);
      }
    }
  };

  automaticPreparing = async ({ order }) => {
    try {
      console.log("Pedido chegou para enviar para preparo", order.id);

      const response = await changeOrderState(
        order.id,
        order.profileId,
        states.PREPARING.index,
        this.state.token,
        null
      );
      if (response) {
        printOrder(order);
      }
    } catch (automaticPreparingError) {
      console.log(automaticPreparingError);
    }
  };

  checkIfOrderWasNotified = ({ notifiedObj }) => {
    try {
      return this.state.notified.filter(
        (o) => o.id === notifiedObj.id && o.state === notifiedObj.state
      ).length;
    } catch (checkIfOrderWasNotifiedError) {
      console.log(checkIfOrderWasNotifiedError);
    }
    return true;
  };

  checkListToNotify = async ({ state, list }) => {
    try {
      if (Array.isArray(list)) {
        list.forEach((order) => {
          const objToNotify = { id: order.id, state };
          if (
            order.orderStatus &&
            order.orderStatus.index &&
            order.orderStatus.index === state &&
            !this.checkIfOrderWasNotified({
              notifiedObj: objToNotify,
            })
          ) {
            this.showNotification();
            notification.play();
            this.state.notified.push(objToNotify);
          }
        });
      }
    } catch (checkListToNotifyError) {
      console.log(checkListToNotifyError);
    }
  };

  /**
   * Método para renderizar o totalizador de pedidos finalizados/retirados.
   */
  completeAndWithdrawnTotalOrders = () => {
    const { lang } = this.props;
    const { orders } = this.state;
    return (
      <CardContainer
        className={`system-primary-background
        shadow-2
        flex-row
        justify-space-between
        align-items-center`}
      >
        <Typography>{lang.withdrawnAndComplete}: </Typography>
        <Typography variant="h5">
          {
            orders.filter(
              (order) =>
                order.orderStatus &&
                (order.orderStatus.index === states.ORDER_COMPLETED.index ||
                  order.orderStatus.index === states.ORDER_WITHDRAWN.index)
            ).length
          }
        </Typography>
      </CardContainer>
    );
  };

  /**
   * Método para renderizar o totalizador de pedidos que estao processando o pagamento.
   */
  processingPaymentOrders = () => {
    const { lang } = this.props;
    const { orders } = this.state;
    return (
      <CardContainer
        className={`system-primary-background
        shadow-2
        flex-row
        justify-space-between
        align-items-center`}
      >
        <Typography>{lang.processingPayment}: </Typography>
        <Typography variant="h5">
          {
            orders.filter(
              (order) =>
                order.orderStatus &&
                order.orderStatus.index &&
                order.orderStatus.index === states.PROCESSING_PAYMENT.index
            ).length
          }
        </Typography>
      </CardContainer>
    );
  };

  /**
   * Método para renderizar o totalizador de pedidos que estao aceitos.
   */
  acceptedOrders = () => {
    const { lang } = this.props;
    const { orders } = this.state;
    return (
      <CardContainer
        className={`system-primary-background
        shadow-2
        flex-row
        justify-space-between
        align-items-center`}
      >
        <Typography>{lang.accepted}: </Typography>
        <Typography variant="h5">
          {
            orders.filter(
              (order) =>
                order.orderStatus &&
                order.orderStatus.index &&
                order.orderStatus.index === states.ACCEPT.index
            ).length
          }
        </Typography>
      </CardContainer>
    );
  };

  /**
   * Método para renderizar o totalizador de pedidos que estao no estado de retirar no balcao.
   * takeaway - lolla
   */
  takeawayOrders = () => {
    const { lang } = this.props;
    const { orders } = this.state;
    return (
      <CardContainer
        className={`system-primary-background
        shadow-2
        flex-row
        justify-space-between
        align-items-center`}
      >
        <Typography>{lang.takeaway}: </Typography>
        <Typography variant="h5">
          {
            orders.filter(
              (order) =>
                order.orderStatus &&
                order.orderStatus.index &&
                order.orderStatus.index === states.REMOVE_AT_COUNTER.index
            ).length
          }
        </Typography>
      </CardContainer>
    );
  };

  /**
   * Método para renderizar os cards totalizadores no Header.
   */
  renderHeaderCardList = () => {
    return (
      <>
        {this.processingPaymentOrders()}
        {this.takeawayOrders()}
        {this.completeAndWithdrawnTotalOrders()}
      </>
    );
  };

  /**
   * Metodo para renderizar a snackbar que sera utilizada para qualquer momento de
   * ação do sistema.
   */
  renderSnackbar = () => {
    return (
      <>
        <CustomSnackbar
          open={this.props.order.open}
          onClose={this.props.closeSnackbar}
          message={this.props.order.message}
          variant={this.props.order.variant}
        />
      </>
    );
  };

  /**
   * Método realizado ao escanear/receber valor do pedido com sucesso.
   *
   * @param {Object} result objeto com valores de perfil/pedido.
   */
  onSuccess = ({ orderId = 0 }) => {
    const { orders } = this.state;
    const [order] = orders.filter((orderItem) => orderItem.id === orderId);
    if (order) {
      this.setState({
        selectedOrder: order,
        checkOrderInfoModalOpen: true,
        orderScanModalOpen: false,
      });
    } else {
      throw new Error("Pedido nao encontrado.");
    }
  };

  renderComponents = () => {
    const { lang } = this.props;
    const {
      orders,
      orderScanModalOpen,
      checkOrderInfoModalOpen,
      selectedOrder,
    } = this.state;
    return (
      <Container id="screen" fullScreen={this.state.fullScreen}>
        <CardListContainer>{this.renderHeaderCardList()}</CardListContainer>
        <Board data={orders} lang={lang} />
        {this.renderSnackbar()}
        <CustomFabButtom
          color="primary"
          onClick={() => this.setState({ orderScanModalOpen: true })}
        >
          <Search />
        </CustomFabButtom>
        <CustomFabButtom
          color="primary"
          screenOnFullScreen={this.state.fullScreen}
          onClick={async () => {
            await this.setState({ fullScreen: !this.state.fullScreen });
          }}
          btnOrderPosition={2}
        >
          {this.state.fullScreen ? <FullscreenExit /> : <Fullscreen />}
        </CustomFabButtom>
        <OrderScanModal
          open={orderScanModalOpen}
          lang={lang}
          onClose={() => this.setState({ orderScanModalOpen: false })}
          onSuccess={(result) => this.onSuccess(result)}
        />
        {/* Modal de visualização dos dados do pedido */}
        <OrderInfoModal
          lang={lang}
          open={checkOrderInfoModalOpen}
          selectedOrder={selectedOrder}
          onClose={() => {
            this.setState({
              selectedOrder: {},
              checkOrderInfoModalOpen: false,
            });
          }}
          startLoading={() =>
            this.props.showLoader(this.state.selectedOrder.id)
          }
        />
      </Container>
    );
  };

  render() {
    this.checkListToNotify({
      state: states.WAITING_APPROVAL.index,
      list: this.state.orders,
    });
    return !this.state.fullScreen ? (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        {this.renderComponents()}
      </CustomDrawerContainer>
    ) : (
      this.renderComponents()
    );
  }
}

const mapStateToProps = ({ user, order }) => {
  let orders =
    order && Array.isArray(order.orders)
      ? order.orders.filter((val) => {
          return val !== undefined;
        })
      : [];
  if (orders) {
    // eslint-disable-next-line no-undef
    orders = _.sortBy(orders, ["dtCreated"]);
  }
  return { user, orders, order };
};

export default connect(mapStateToProps, {
  fetchProfileOrders,
  closeSnackbar,
  showLoader,
})(Order);
