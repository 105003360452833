export const productsPage = {
  title: "Produtos",
  product: "Produto",
  description: "Descrição",
  complements: "Complementos",
  category: "Categoria",
  price: "Preço",
  createdAt: "Criado em",
  sideModalTitle: "Adicionar Produto",
  sideModalTitleEdit: "Editar Produto",
  isStock: "Possui estoque",
  isControlSale: "Possui controle de vendas",
  isCustomizable: "É customizavel",
  forAdult: "Para maiores de idade",
  toGift: "Para presente",
  toSchedule: "Agendamento",
  save: "Salvar",
  cancel: "Cancelar",
  dropZoneLabel: "Arraste a imagem do produto ou clique aqui",
  nameRequiredError: "Nome do produto é obrigatório!",
  nameInvalidError: "Nome do produto inválido!",
  descriptionRequiredError: "Descrição é obrigatória!",
  descriptionInvalidError: "Descrição inválida!",
  priceRequiredError: "Preço é obrigatório!",
  priceInvalidError: "Preço inválido!",
  registerProductLoading: "Estamos cadastrando seu novo produto",
  editProductLoading: "Estamos editando seu produto",
  registerErrorTitle: "Erro",
  registerErrorSubTitle: "Não foi possível cadastrar o produto!",
  registerItemProductErrorSubTitle:
    "Não foi possível cadastrar o item no produto!",
  editErrorSubTitle: "Não foi possível editar o produto!",
  registerErrorDescription: "@MSG",
  registerErrorConfirmationText: "OK",
  registerSuccessTitle: "Sucesso",
  registerSuccessSubTitle: "Produto cadastrado com sucesso!",
  editSuccessSubTitle: "Produto editado com sucesso!",
  registerSuccessDescription:
    "Agora o seu produto já está disponível no sistema.",
  registerSuccessConfirmationText: "OK",
  enable: "Ativo",
  editErrorDescription: "@MSG",
  delete: "Deletar",
  deleteErrorTitle: "Erro",
  deleteErrorSubTitle: "Não foi possível deletar o item!",
  deleteErrorDescription: "@MSG",
  deleteErrorConfirmationText: "OK",
};
