// Bibliotecas
import React, { Component } from "react";
// Componentes
import Section from "./Section";
// Funções e dados estaticos.
import { states } from "../../../static/orderStates";
// Recursos visuais
import { Container, Divider } from "./styles.css";

export default class Board extends Component {
  componentDidMount() {}

  render() {
    const { orders, lang } = this.props;
    return (
      <Container className="flex-row align-items-center justify-space-between">
        <Section
          orders={orders.filter(
            order => order.orderStatus.index === states.PREPARING.index
          )}
          title={states.PREPARING.label}
        />
        <Divider className="system-primary-background" />
        <Section
          orders={orders.filter(
            order => order.orderStatus.index === states.ORDER_IS_READY.index
          )}
          title={lang.ready}
        />
      </Container>
    );
  }
}
