// Query de acesso na url.
export const QUERY_ACCESS = "access_token=";
// URL base das apis de usuario.
export const USER_API_BASE = "/user/api/rs/v.01";
// URL base das apis de perfil.
export const PROFILE_API_BASE = "/profile/api/rs/v.01";
// URL base das apis de pedido.
export const ORDER_API_BASE = "/order/api/rs/v.01";
// URL base das apis de conta/pagamento.
export const PAYMENT_API_BASE = "/account/api/rs/v.01";
// Local da api de login.
export const LOGIN_LOCATION = "/login";
// Local da api de usuarios.
export const USERS_LOCATION = "/users";
// Local da api de perfis.
export const PROFILE_LOCATION = "/profiles";
// Local da api de locais.
export const PLACE_LOCATION = "/places";
// local da api de produtos.
export const PRODUCTS_LOCATION = "/products";
// Local da api de pedidos.
export const ORDER_LOCATION = "/orders";
// Local da chamada de notificação
export const NOTIFY_LOCATION = "/notify";
// Local da api de formas de pagamento.
export const PAYMENT_METHODS_LOCATION = "/payment/methods";
// Local da api de loja-restaurante (para comandas).
export const STORE_LOCATION = "/store";
// Local da api de registrar comandas.
export const STORE_REQUEST_LOCATION = "/request";
// local do usuário em qualquer api
export const ME_LOCATION = "/me";
// local da api de categorias.
export const CATEGORY_LOCATION = "/category";
// local da api de item do produto
export const ITEM_PRODUCT_LOCATION = "/items";
// local da api dos bancos
export const BANK_LOCATION = "/bank";
// local da api das contas
export const ACCOUNT_LOCATION = "/account";
// Local da api da configurações da conta
export const SETTINGSTORE_LOCATION = "/config";
// local da api das contas por profile
export const ACCOUNT_PROFILE_LOCATION = "/account/profile";
// Local da api de perfil PDV
export const PROFILESTORE_LOCATION = "/profile";
// Local da api da configuracao
export const CONFIG_LOCATION = "/config";
// Url da api padrão na amazon
export const URL_AMAZON =
  "https://uvjxplzijc.execute-api.sa-east-1.amazonaws.com";
// Api de order na amazon
export const ORDER_AMAZON = "/orders/api/v.01";
// Local da api de locais por empresa
export const PLACEID_LOCATION = "/place";
// Local para enviar mensagem
export const NOTIFICATIONS = "/notifications";
// Local para titulo da mensagem
export const TITLE = "title=";
// Local para mensagem
export const MESSAGE = "message=";
