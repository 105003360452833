// Bibliotecas.
import React, { Component } from "react";
// Recursos visuais.
import { Container } from "./styles";
// Componentes.
import List from "../List";
// Dados estaticos.
import { states } from "../../../static/orderStates";
import audio from "../../../assets/got-it-done-613.mp3";

const notification = new Audio(audio);

class Board extends Component {
  state = { notified: [] };

  checkIfOrderWasNotified = ({ notifiedObj }) => {
    try {
      return this.state.notified.filter(
        (o) => o.id === notifiedObj.id && o.state === notifiedObj.state
      ).length;
    } catch (checkIfOrderWasNotifiedError) {
      console.log(checkIfOrderWasNotifiedError);
    }
    return true;
  };

  checkListToNotify = async ({ state, list }) => {
    try {
      if (Array.isArray(list)) {
        list.forEach((order) => {
          const objToNotify = { id: order.id, state };
          if (
            order.orderStatus &&
            order.orderStatus.index &&
            order.orderStatus.index === state &&
            !this.checkIfOrderWasNotified({
              notifiedObj: objToNotify,
            })
          ) {
            notification.play();
            this.state.notified.push(objToNotify);
          }
        });
      }
    } catch (checkListToNotifyError) {
      console.log(checkListToNotifyError);
    }
  };

  render() {
    const { data, lang } = this.props;
    // this.checkListToNotify({
    //   state: states.WAITING_APPROVAL.index,
    //   list: data,
    // });
    return (
      <Container>
        {/* Aguardando aprovação */}
        <List
          lang={lang}
          index={states.WAITING_APPROVAL.index}
          title={states.WAITING_APPROVAL.label}
          data={data.filter(
            (item) =>
              item.orderStatus &&
              item.orderStatus.index &&
              item.orderStatus.index === states.WAITING_APPROVAL.index
          )}
        />
        {/* Pedido aceito */}
        {/* <List
          lang={lang}
          index={states.ACCEPT.index}
          title={states.ACCEPT.label}
          data={data.filter(
            item => item.orderStatus && item.orderStatus.index && item.orderStatus.index === states.ACCEPT.index
          )}
        /> */}
        {/* Processando pagamento */}
        {/* <List
          lang={lang}
          index={states.PROCESSING_PAYMENT.index}
          title={states.PROCESSING_PAYMENT.label}
          data={data.filter(
            item =>
              item.orderStatus && item.orderStatus.index && item.orderStatus.index === states.PROCESSING_PAYMENT.index ||
              item.orderStatus && item.orderStatus.index && item.orderStatus.index === states.ACCEPT.index
          )}
        /> */}
        {/* Pagamento realizado */}
        <List
          lang={lang}
          index={states.PAYMENT_MADE.index}
          title={states.PAYMENT_MADE.label}
          data={data.filter(
            (item) =>
              item.orderStatus &&
              item.orderStatus.index &&
              item.orderStatus.index === states.PAYMENT_MADE.index
          )}
        />

        {/* Preparando */}
        <List
          lang={lang}
          index={states.PREPARING.index}
          title={states.PREPARING.label}
          data={data.filter(
            (item) =>
              item.orderStatus &&
              item.orderStatus.index &&
              item.orderStatus.index === states.PREPARING.index
          )}
        />

        {/* Pedido pronto */}
        <List
          lang={lang}
          index={states.ORDER_IS_READY.index}
          title={states.ORDER_IS_READY.label}
          data={data.filter(
            (item) =>
              item.orderStatus &&
              item.orderStatus.index &&
              item.orderStatus.index === states.ORDER_IS_READY.index
          )}
        />

        {/* Pedido Retirado */}
        {/* <List
          lang={lang}
          index={states.ORDER_WITHDRAWN.index}
          title={states.ORDER_WITHDRAWN.label}
          data={data.filter(
            item => item.orderStatus && item.orderStatus.index && item.orderStatus.index === states.ORDER_WITHDRAWN.index
          )}
        /> */}

        {/* Pedido Finalizado */}
        {/* <List
          lang={lang}
          index={states.ORDER_COMPLETED.index}
          title={lang.withdrawnAndComplete}
          data={data.filter(
            item =>
              item.orderStatus && item.orderStatus.index && item.orderStatus.index === states.ORDER_COMPLETED.index ||
              item.orderStatus && item.orderStatus.index && item.orderStatus.index === states.ORDER_WITHDRAWN.index
          )}
          done
        /> */}
      </Container>
    );
  }
}

export default Board;
