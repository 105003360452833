import styled from "styled-components";

export const Container = styled.div`
  width: 98%;
  height: 100%;
`;

export const Divider = styled.div`
  height: 100%;
  width: 5px;
`;
