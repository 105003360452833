// Biblioteca
import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
// Componentes
import { Grid, Button } from "@material-ui/core";
// icones
import { Fullscreen, FullscreenExit } from "@material-ui/icons";
import { DatePicker, MuiPickersUtilsProvider } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import isWithinInterval from "date-fns/isWithinInterval";

// Componentes
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomFabButtom from "../../components/CustomFabButtom";
import Card from "./Card";
// Recursos visuais
import { Container, FiltersContainer } from "./styles.css";
// Ações.
import { fetchProfileOrders } from "../../redux/actions";
// Dados estaticos
import { states } from "../../static/orderStates";

console.log(navigator.language, navigator.language.split(/[-_]/)[0]);

// eslint-disable-next-line import/no-dynamic-require
const { default: locale } = require(`date-fns/locale/${
  navigator.language ||
  navigator.language.split(/[-_]/)[0] ||
  navigator.userLanguage.split(/[-_]/)[0] ||
  "pt-BR"
}`);

class Dashboard extends Component {
  state = {
    orders: [],
    originalOrders: [],
    fullScreen: false,
    dateInitFilter: null,
    dateEndFilter: null,
  };

  componentDidMount() {
    const dateInitFilter = new Date();
    dateInitFilter.setHours(0);
    dateInitFilter.setMinutes(1);
    dateInitFilter.setSeconds(1);
    const dateEndFilter = new Date();
    dateEndFilter.setHours(23);
    dateEndFilter.setMinutes(59);
    dateEndFilter.setSeconds(59);
    this.setState({ dateInitFilter, dateEndFilter });
    const profile = this.props.user.selectedProfile;
    if (profile) {
      // Pegar empresas do usuário.
      this.setState({ orders: [] });
      this.props.fetchProfileOrders(profile.id);
    }
  }

  /**
   *
   * @param {Object} nextProps Proximo objeto de props do componente.
   */
  // eslint-disable-next-line camelcase
  async UNSAFE_componentWillReceiveProps(nextProps) {
    try {
      console.log(nextProps.orders);
      await this.setState({
        orders: nextProps.orders,
        originalOrders: nextProps.orders,
      });
      this.filterOrders();
      // Valida se o id do perfil selecionado no redux esta vazio.
      if (
        !nextProps.user.selectedProfile ||
        !nextProps.user.selectedProfile.id ||
        !this.props.user.selectedProfile ||
        !this.props.user.selectedProfile.id
      ) {
        console.log("id do perfil vazio");
        this.setState({
          orders: [],
          originalOrders: [],
        });
      }
      // Caso nao esteja, valida a seleção.
      else if (
        nextProps.user.selectedProfile &&
        nextProps.user.selectedProfile.id &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id &&
        this.props.user.selectedProfile.id !== nextProps.user.selectedProfile.id
      ) {
        console.log("id diferente ou vazio");
        const dateInitFilter = new Date();
        dateInitFilter.setHours(0);
        dateInitFilter.setMinutes(1);
        dateInitFilter.setSeconds(1);
        const dateEndFilter = new Date();
        dateEndFilter.setHours(23);
        dateEndFilter.setMinutes(59);
        dateEndFilter.setSeconds(59);
        this.setState({
          orders: [],
          originalOrders: [],
          dateInitFilter,
          dateEndFilter,
        });
        this.props.fetchProfileOrders(nextProps.user.selectedProfile.id);
      }
    } catch (error) {
      console.log(`Dashboard - componentWillReceiveProps${error}`);
    }
  }

  filterOrders = () => {
    console.log("filtrando");
    let filteredOrders = this.state.originalOrders;
    if (
      this.state.dateInitFilter &&
      this.state.dateInitFilter instanceof Date
    ) {
      let endDate = new Date();
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      if (
        this.state.dateEndFilter &&
        this.state.dateEndFilter instanceof Date
      ) {
        endDate = this.state.dateEndFilter;
      }
      console.log(this.state.dateInitFilter, endDate);
      filteredOrders = filteredOrders.filter((o) =>
        isWithinInterval(new Date(o.dtCreated), {
          start: this.state.dateInitFilter,
          end: endDate,
        })
      );
    }

    if (Array.isArray(filteredOrders)) {
      this.setState({ orders: filteredOrders });
    }
  };

  renderComponents = () => {
    const { lang } = this.props;
    const { orders } = this.state;

    // Lista de pedidos que ja foram pagos.
    const paidOrders = orders.filter(
      (order) =>
        order.orderStatus &&
        order.orderStatus.index &&
        order.orderStatus.index >= states.PAYMENT_MADE.index &&
        order.orderStatus.index !== states.REFUSED.index &&
        order.orderStatus.index !== states.PAYMENT_REFUSED.index
    );

    // Variavel que calcula o ticket medio.
    let totalAverage = 0.0;
    let totalValue = 0.0;
    if (paidOrders && paidOrders.length > 0) {
      totalAverage =
        paidOrders.reduce(
          (accOrder, currentOrder) => accOrder + currentOrder.totalPrice,
          0
        ) / paidOrders.length;

      // Variável que calcula o preco total de todos os pedidos.
      totalValue = paidOrders.reduce(
        (accOrder, currentOrder) => accOrder + currentOrder.totalPrice,
        0
      );
    }

    // Variável que calcula a quantidade de produtos vendidos.
    let soldProducts = 0;
    paidOrders.forEach((order) =>
      order.itemList.forEach((item) => (soldProducts += item.quantity))
    );

    // Variável que calcula a quantidade de usuários distintos.
    const distinctUsers = new Set(
      orders.reduce((acc, cur) => [...acc, cur.userId], [])
    ).size;

    // Variável que calcula a quantidade de pedidos em andamento.
    const inProgressOrders = orders.filter(
      (order) =>
        order.orderStatus &&
        order.orderStatus.index &&
        (order.orderStatus.index === states.ACCEPT.index ||
          order.orderStatus.index === states.PROCESSING_PAYMENT.index ||
          order.orderStatus.index === states.PAYMENT_MADE.index ||
          order.orderStatus.index === states.PREPARING.index ||
          order.orderStatus.index === states.ORDER_IS_READY.index)
    ).length;

    // Variavel que calcula a quantidade de pedidos para ir no balcao (takeaway - lolla)
    const takeawayOrders = orders.filter(
      (order) =>
        order.orderStatus &&
        order.orderStatus.index &&
        order.orderStatus.index === states.REMOVE_AT_COUNTER.index
    ).length;

    const rejectedOrders = orders.filter(
      (order) =>
        order.orderStatus &&
        order.orderStatus.index &&
        order.orderStatus.index === states.REFUSED.index
    ).length;

    return (
      <Container id="screen" fullScreen={this.state.fullScreen}>
        <FiltersContainer>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <DatePicker
              autoOk
              format="dd/MM/yyyy"
              className="filter"
              maxDate={this.state.dateEndFilter || new Date()}
              margin="normal"
              label={lang.initDateFilter}
              value={this.state.dateInitFilter}
              onChange={async (dateInitFilter) => {
                dateInitFilter.setHours(0);
                dateInitFilter.setMinutes(1);
                dateInitFilter.setSeconds(1);
                await this.setState({ dateInitFilter });
              }}
            />

            <DatePicker
              autoOk
              format="dd/MM/yyyy"
              className="filter"
              maxDate={new Date()}
              minDate={this.state.dateInitFilter}
              margin="normal"
              label={lang.endDateFilter}
              value={this.state.dateEndFilter}
              onChange={async (dateEndFilter) => {
                dateEndFilter.setHours(23);
                dateEndFilter.setMinutes(59);
                dateEndFilter.setSeconds(59);
                await this.setState({ dateEndFilter });
              }}
            />
          </MuiPickersUtilsProvider>
          <Button
            variant="contained"
            color="primary"
            className="filter-clear-btn"
            onClick={() => this.filterOrders()}
          >
            {lang.searchFilters}
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="filter-clear-btn"
            onClick={async () => {
              await this.setState({
                dateInitFilter: null,
                dateEndFilter: null,
                orders: this.state.originalOrders,
              });
              this.filterOrders();
            }}
          >
            {lang.cleanFilters}
          </Button>
        </FiltersContainer>
        <Grid container>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card icon="place" title={lang.takeaway} value={takeawayOrders} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              icon="timer"
              title={lang.inProgressOrders}
              value={inProgressOrders}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              icon="close"
              title={lang.rejectedOrders}
              value={rejectedOrders}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              icon="check_circle_outline"
              title={lang.doneOrders}
              value={paidOrders.length || 0}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              icon="attach_money"
              finalizados
              title={lang.totalValue}
              value={totalValue
                .toFixed(2)
                .replace(".", ",")
                .replace(/\d(?=(\d{3})+\.)/g, "$&.")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              icon="attach_money"
              title={lang.totalAverage}
              value={totalAverage
                .toFixed(2)
                .replace(".", ",")
                .replace(/\d(?=(\d{3})+\.)/g, "$&.")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card icon="list" title={lang.soldProducts} value={soldProducts} />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Card
              icon="peopleAlt"
              title={lang.distinctUsers}
              value={distinctUsers}
            />
          </Grid>
        </Grid>
        <CustomFabButtom
          color="primary"
          screenOnFullScreen={this.state.fullScreen}
          onClick={async () => {
            await this.setState({ fullScreen: !this.state.fullScreen });
          }}
        >
          {this.state.fullScreen ? <FullscreenExit /> : <Fullscreen />}
        </CustomFabButtom>
      </Container>
    );
  };

  render() {
    return !this.state.fullScreen ? (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        {this.renderComponents()}
      </CustomDrawerContainer>
    ) : (
      this.renderComponents()
    );
  }
}

const mapStateToProps = ({ user, order }) => {
  let orders =
    order && Array.isArray(order.orders)
      ? order.orders.filter((val) => {
          return val !== undefined;
        })
      : [];
  if (orders) {
    // eslint-disable-next-line no-undef
    orders = _.sortBy(orders, ["dtCreated"]);
  }
  return { user, orders, order };
};

export default connect(mapStateToProps, {
  fetchProfileOrders,
})(Dashboard);
