import React, { Component } from "react";
import { Fab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// import { Container } from './styles';

const styles = (theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing.unit * 2,
    right: theme.spacing.unit * 2,
  },
});

class CustomFabButtom extends Component {
  render() {
    const {
      color,
      classes,
      onClick,
      children,
      horizontal,
      vertical,
      screenOnFullScreen,
      theme,
      btnOrderPosition,
    } = this.props;
    const style = {};
    style[`${horizontal || "right"}`] = `${
      theme.spacing.unit *
        (horizontal === "left" && !screenOnFullScreen ? 11 : 2) +
      btnOrderPosition * theme.spacing.unit * 5
    }px`;
    style[`${vertical || "bottom"}`] = `${
      theme.spacing.unit * (vertical === "top" && !screenOnFullScreen ? 11 : 2)
    }px`;
    return (
      <Fab
        id="fab-btn"
        className={classes.fab}
        style={{ ...style }}
        color={color || "primary"}
        onClick={onClick}
      >
        {children}
      </Fab>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CustomFabButtom);
