import React from "react";
import { Paper } from "./styles.css";

export default function PaperComponent(props) {
  return (
    <Paper quarter={props.quarter} threeQuarters={props.threeQuarters}>
      {props.children}
    </Paper>
  );
}
