// Tag do token do servidor para o usuário.
export const USER_ID = 'USER_ID';
// Tag do id do usuário para o servidor.
export const USER_AUTH_TOKEN = 'USER_AUTH_TOKEN';
// Tag para expiração do token.
export const USER_AUTH_TOKEN_EXPIRATION = 'USER_AUTH_TOKEN_EXPIRATION';
// Tag para empresa selecionada.
export const USER_SELECTED_PROFILE = 'USER_SELECTED_PROFILE';
// Tag para tipo de login.
export const USER_TYPE_LOGIN = 'USER_TYPE_LOGIN';
