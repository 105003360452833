import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Modal } from "@material-ui/core";
import { Container } from "./styles.css";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = theme => ({
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    // padding: theme.spacing.unit * 4,
    padding: `0px 0px ${theme.spacing.unit}px`,
    outline: "none",
    borderRadius: "10px"
  }
});

class SimpleModal extends React.Component {
  state = {
    open: false
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, style } = this.props;
    return (
      <div>
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.open}
          onClose={this.props.onClose}
          disableBackdropClick={this.props.disableBackdropClick}
          disableEscapeKeyDown={this.props.disableEscapeKeyDown}
        >
          <Container
            style={{ ...getModalStyle(), ...style }}
            className={classes.paper}
          >
            {this.props.children}
          </Container>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(SimpleModal);
