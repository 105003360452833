import styled from "styled-components";

export const ListColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  /* border-right: 1px solid rgba(0, 0, 0, 0.05); */
  /* padding: 0px 10px 0px 0px; */
  align-items: flex-start !important;

  .list-item {
    padding: 0;
    margin-top: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    margin-left: 5px;

    div:nth-child(odd) {
      background: rgba(103, 58, 183, 0.1);
      border-left: 1px solid rgba(103, 58, 183, 0.2);
    }

    span {
      margin-left: 3px;
    }

    div {
      width: 100%;
    }
  }

  .additional-item {
    margin-left: 25px !important;
  }

  .product-subtitle {
    margin-top: 10px;
  }
`;
