import {
  DASHBOARD_SCREEN,
  ORDER_SCREEN,
  ORDERPANEL_SCREEN,
  ORDERLIST_SCREEN,
  COMPANIES_SCREEN,
  CATEGORY_SCREEN,
  PRODUCTS_SCREEN,
  ITEMPRODUCTS_SCREEN,
  BANKACCOUNT_SCREEN,
  USERPDV_SCREEN,
  STORE_ORDER,
  // SETTINGS_SCREEN,
  LINKPLACESCOMPANIESCREEN,
  PLACES_SCREEN,
  SETTINGSITEMS_SCREEN,
  LISTUSER_SCREEN,
  ALLORDERCREEN,
  LISTPRODUCTSINPREPARATIONCREEN,
  MESSAGECREEN,
} from "../static/ScreenReferences";

/**
 * Método que retorna o menu por permissão de usuario.
 * @param {permissions} passa as regras da permissão.
 */
const ScreensPerPermissions = (permissions) => {
  let menus = {};
  let firstScreens = [];
  let secondaryScreens = [];
  let thirdScreens = [];
  menus = { ...menus, firstScreens };
  menus = { ...menus, secondaryScreens };
  menus = { ...menus, thirdScreens };

  try {
    if (permissions && permissions.length > 0) {
      for (let i = 0; i < permissions.length; i++) {
        if (permissions[i] === "storePdv") {
          firstScreens.push(ORDER_SCREEN, ORDERPANEL_SCREEN, ORDERLIST_SCREEN);
        }
        if (permissions[i] === "storeProducts") {
          secondaryScreens.push(PRODUCTS_SCREEN);
        }

        if (permissions[i] === "storeManager") {
          firstScreens.push(
            DASHBOARD_SCREEN,
            ORDER_SCREEN,
            ORDERPANEL_SCREEN,
            ORDERLIST_SCREEN,
            LISTPRODUCTSINPREPARATIONCREEN
          );
          secondaryScreens.push(
            COMPANIES_SCREEN,
            CATEGORY_SCREEN,
            PRODUCTS_SCREEN,
            ITEMPRODUCTS_SCREEN,
            USERPDV_SCREEN,
            SETTINGSITEMS_SCREEN
          );
        }

        if (permissions[i] === "store") {
          firstScreens.push(
            DASHBOARD_SCREEN,
            ORDER_SCREEN,
            ORDERPANEL_SCREEN,
            ORDERLIST_SCREEN,
            LISTPRODUCTSINPREPARATIONCREEN
          );
          secondaryScreens.push(
            COMPANIES_SCREEN,
            PLACES_SCREEN,
            LINKPLACESCOMPANIESCREEN,
            CATEGORY_SCREEN,
            PRODUCTS_SCREEN,
            ITEMPRODUCTS_SCREEN,
            BANKACCOUNT_SCREEN,
            USERPDV_SCREEN,
            STORE_ORDER,
            SETTINGSITEMS_SCREEN
          );
          thirdScreens.push(LISTUSER_SCREEN, ALLORDERCREEN, MESSAGECREEN);
        }

        firstScreens = Array.from(new Set(firstScreens));
        secondaryScreens = Array.from(new Set(secondaryScreens));
        thirdScreens = Array.from(new Set(thirdScreens));
        menus = { ...menus, firstScreens };
        menus = { ...menus, secondaryScreens };
        menus = { ...menus, thirdScreens };
      }
      if (!menus || menus === undefined || menus.length === 0) {
        return menus;
      }
      return menus;
    }
    return menus;
  } catch (error) {
    console.log({ ...error });
    return menus;
  }
};

export default ScreensPerPermissions;
