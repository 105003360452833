/* eslint-disable react/jsx-wrap-multilines */
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  TableCell,
  TextField,
  Grid,
  Typography,
  LinearProgress,
  IconButton,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import "firebase/auth";
import api from "../../utils/API";
// Funções e dados.
import {
  PROFILE_API_BASE,
  CATEGORY_LOCATION,
  PROFILE_LOCATION,
  QUERY_ACCESS,
} from "../../static/URL";
import {
  USER_SELECTED_PROFILE,
  USER_AUTH_TOKEN,
} from "../../static/LocalStorageTags";
import { getItem } from "../../utils/localStorage";
import { loadCategory } from "../../functions/category";

// Componentes.
import CustomTooltip from "../../components/CustomTooltip";
import CustomDrawerContainer from "../../components/CustomDrawerContainer";
import CustomTable from "../../components/CustomTable";
import CustomSideModal from "../../components/CustomSideModal";
import CustomConfirmationModal from "../../components/CustomConfirmationModal";
import CustomModal from "../../components/CustomModal";
import CustomNumberMaskedInput from "../../components/CustomNumberMaskedInput";

// Recursos visuais.
// import "./style.css.js";

class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLoadingModal: false,
      openErrorModal: false,
      openSuccessModal: false,
      // Table
      categories: [],
      open: false,
      selected: [],
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: 10,
      // SideModal
      sideModalOpen: false,
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      seq: "",
      seqError: false,
      seqhowTooltip: false,
      seqTooltipMessage: "",
      // Variavel para controlar modal de deletar a categoria
      deleteCategoryModalOpen: false,
      delete: false,
      openDeleteErrorModal: false,
      openDeleteLoadingModal: false,
      mensagemError: "",
      idDelete: undefined,
      openLoadCategory: false,
    };
  }

  componentDidMount() {
    this.loadCategories();
  }

  // eslint-disable-next-line consistent-return
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.user.selectedProfile) {
      return this.setState({ categories: [] });
    }
    if (
      (!this.props.user.selectedProfile && nextProps.user.selectedProfile) ||
      (nextProps.user.selectedProfile &&
        this.props.user.selectedProfile &&
        this.props.user.selectedProfile.id !==
          nextProps.user.selectedProfile.id)
    ) {
      this.loadCategories();
    }
  }

  /*
   * Método que carega todas categorias cadastradas.
   */
  loadCategories = async () => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const categories = await loadCategory(profileId, userToken);
      this.setState({ categories });
    } catch (error) {
      console.log({ ...error });
      this.setState({ categories: [] });
    }
  };

  // Retorna o UserProfile
  getUserProfile = async () => {
    const storageProfile = getItem(USER_SELECTED_PROFILE);
    if (storageProfile) {
      return JSON.parse(storageProfile).id;
    }
    const { user } = this.props;
    if (user && user.selectedProfile) {
      return user.selectedProfile.id;
    }
    const userToken = getItem(USER_AUTH_TOKEN);
    const { data: me } = await api.get(
      `${PROFILE_API_BASE + PROFILE_LOCATION}/me?${QUERY_ACCESS + userToken}`
    );
    const lastDataMe = me.sort(
      (a, b) => new Date(b.dtCreated) - new Date(a.dtCreated)
    );
    return lastDataMe[0].id;
  };

  // Renderiza a tabela de categorias
  renderTable = () => {
    const { lang } = this.props;
    const { categories } = this.state;
    const rows = [
      {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: lang.category,
      },
      {
        id: "seq",
        numeric: false,
        disablePadding: false,
        label: lang.sequence,
      },
      {
        id: "del",
        numeric: false,
        disablePadding: false,
        label: lang.delete,
      },
    ];
    return (
      <CustomTable
        title={lang.title}
        showAddButton
        onAddEvent={() => this.setState({ sideModalOpen: true })}
        order={this.state.order}
        orderBy={this.state.orderBy}
        page={this.state.page}
        rowsPerPage={this.state.rowsPerPage}
        selected={this.state.selected}
        onSelect={(newSelected) => this.setState({ selected: newSelected })}
        onClick={this.onClickCategoryEdition}
        handleChangeRowsPerPage={(event) => {
          this.setState({ rowsPerPage: event.target.value });
        }}
        handleChangePage={(event, page) => {
          this.setState({ page });
        }}
        handleRequestSort={(order, orderBy) => {
          this.setState({ order, orderBy });
        }}
        data={categories.map((c) => ({
          id: c.id,
          name: c.name,
          seq: c.seq,
        }))}
        rows={rows}
        tableRow={(n) => {
          return (
            <>
              <TableCell component="th" scope="row" padding="none">
                {n.name.length > 20 ? `${n.name.substr(0, 20)}...` : n.name}
              </TableCell>
              <TableCell>{n.seq}</TableCell>
              <TableCell>
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    this.openDeleteModal(n.id);
                  }}
                  aria-label="Delete"
                >
                  <Delete />
                </IconButton>
              </TableCell>
            </>
          );
        }}
      />
    );
  };

  // Renderiza a modal de deletar categorias
  openDeleteModal = (id) => {
    this.setState({
      idDelete: id,
      sideModalOpen: false,
      deleteCategoryModalOpen: true,
    });
  };

  /**
   * Método para cadastrar produto na base de dados.
   */
  deleteCategory = async () => {
    this.setState({ sideModalOpen: false });
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const { idDelete } = this.state;

      if (idDelete) {
        this.setState({ openDeleteLoadingModal: true });
        await api.delete(
          `${PROFILE_API_BASE + PROFILE_LOCATION}/${
            profileId + CATEGORY_LOCATION
          }/${idDelete}?${QUERY_ACCESS + userToken}`,
          {
            idDelete,
          }
        );
        await this.loadCategories();
        this.setState({ openDeleteLoadingModal: false });
      }
    } catch (error) {
      const errorObj = { ...error };
      this.setState({
        openDeleteLoadingModal: false,
      });
      let message = "Erro ao exluir categoria";
      if (errorObj.isAxiosError) {
        switch (errorObj.response.status) {
          case 400:
            message = "Requisição inválida.";
            break;
          case 401:
            message = "Acesso não autorizado!";
            break;
          case 422:
            message = errorObj.response.data.content;
            break;
          case 500:
            message = "Erro inesperado. Tente novamente em alguns minutos.";
            break;
          default:
            message = "Não foi possível excluir a categoria!";
            break;
        }
      }
      this.setState({
        mensagemError: message,
        openDeleteErrorModal: true,
        openLoadingModal: false,
        deleteCategoryModalOpen: false,
        openDeleteLoadingModal: false,
      });
    }
  };

  /**
   *
   * @param {Object} category Objeto da categoria.
   */
  onClickCategoryEdition = async (category) => {
    const { selected } = this.state;
    this.setState({
      selected: selected.filter((s) => s !== category.id),
      id: category.id,
      name: category.name,
      seq: category.seq,
      sideModalOpen: true,
    });
  };

  // Renderiza a modal de criar categorias
  renderSideModal = () => {
    const { lang } = this.props;
    const { sideModalOpen } = this.state;
    return (
      <CustomSideModal
        open={sideModalOpen}
        handleClose={this.handleCloseModal}
        title={!this.state.id ? lang.sideModalTitle : lang.sideModalTitleEdit}
        confirmationAction={this.validateFormValues}
        confirmationText={lang.save}
        cancelationAction={this.handleCloseModal}
        cancelationText={lang.cancel}
      >
        <Grid container>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  nameShowTooltip: false,
                  nameError: false,
                })
              }
              open={this.state.nameShowTooltip}
              title={this.state.nameTooltipMessage}
            >
              <TextField
                className="input-margin width-90"
                required
                label={lang.category}
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
              />
            </CustomTooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CustomTooltip
              onClose={() =>
                this.setState({
                  seqShowTooltip: false,
                  seqError: false,
                })
              }
              open={this.state.seqShowTooltip}
              title={this.state.seqTooltipMessage}
            >
              <CustomNumberMaskedInput
                className="width-90 input-margin "
                value={this.state.seq}
                onChange={(e) => this.setState({ seq: e })}
                id="seq"
                label={lang.sequence}
              />
            </CustomTooltip>
          </Grid>
        </Grid>
      </CustomSideModal>
    );
  };

  // Método realizado ao fechar a modal de adição de categorias.
  handleCloseModal = () => {
    this.setState({
      sideModalOpen: false,
      id: undefined,
      name: "",
      nameError: false,
      nameShowTooltip: false,
      nameTooltipMessage: "",
      seq: "",
      seqError: false,
      seqhowTooltip: false,
      seqTooltipMessage: "",
    });
  };

  // Método realizado para envio dos dados do formulário para o back
  handleModalSubmit = async () => {
    this.setState({ openLoadingModal: true });

    const categorytRes = await this.registerCategory();
    if (categorytRes) {
      this.setState({
        openLoadingModal: false,
        openSuccessModal: true,
        sideModalOpen: false,
      });
      await this.loadCategories();
      this.handleCloseModal();
    } else {
      console.log("erro ao cadastrar a categoria");
      this.setState({ openLoadingModal: false, openErrorModal: true });
    }
  };

  /**
   * Método para cadastrar produto na base de dados.
   */
  registerCategory = async () => {
    try {
      const userToken = getItem(USER_AUTH_TOKEN);
      const profileId = await this.getUserProfile();
      const { name, seq, id } = this.state;

      if (id) {
        const response = await api.put(
          `${PROFILE_API_BASE + PROFILE_LOCATION}/${
            profileId + CATEGORY_LOCATION
          }/${id}?${QUERY_ACCESS + userToken}`,
          {
            id,
            name,
            seq,
          }
        );
        return response;
      }
      const response = await api.post(
        `${PROFILE_API_BASE + PROFILE_LOCATION}/${
          profileId + CATEGORY_LOCATION
        }?${QUERY_ACCESS + userToken}`,
        {
          name,
          seq,
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      const errorObj = { ...error };
      console.log({ ...error });
      let message = "Não foi possível cadastrar a categoria!";
      if (errorObj.isAxiosError) {
        switch (errorObj.response.status) {
          case 400:
            message = "Solicitação inválida.";
            break;
          case 401:
            message = "Acesso não autorizado!";
            break;
          case 422:
            message = errorObj.response.data.content;
            break;
          case 404:
            message = "Categoria não encontrada para o perfil!";
            break;
          case 500:
            message = "Erro inesperado. Tente novamente em alguns minutos.";
            break;
          default:
            message = "Erro ao cadastrar categoria!";
            break;
        }
      }
      this.setState({
        mensagemError: message,
      });
      return false;
    }
  };

  // Método para validar os campos do formulário
  validateFormValues = () => {
    const { name } = this.state;
    const { lang } = this.props;
    let data = {};

    if (!name || name.trim().length === 0) {
      data = {
        ...data,
        nameShowTooltip: true,
        nameTooltipMessage: !name
          ? lang.nameRequiredError
          : lang.nameInvalidError,
        nameError: true,
      };
    }

    if (Object.keys(data).length > 0) {
      console.log("aqui erro", data);
      this.setState(data);
    } else {
      console.log("aqui");
      this.handleModalSubmit();
    }
  };

  render() {
    const { lang } = this.props;
    return (
      <CustomDrawerContainer
        history={this.props.history}
        name={this.props.name}
      >
        <CustomModal
          open={this.state.openLoadingModal}
          onClose={() => this.setState({ openLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">
              {!this.state.id
                ? lang.registerCategoryLoading
                : lang.editCategoryLoading}
            </Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomConfirmationModal
          title={
            !this.state.id
              ? lang.registerSuccessTitle
              : lang.registerSuccessTitle
          }
          open={this.state.openSuccessModal}
          subtitle={
            !this.state.id
              ? lang.registerSuccessSubTitle
              : lang.editSuccessSubTitle
          }
          description={
            !this.state.id
              ? lang.registerSuccessDescription
              : lang.editSuccessDescription
          }
          confirmationText={lang.registerSuccessConfirmationText}
          confirmationAction={() => this.setState({ openSuccessModal: false })}
          onClose={() => this.setState({ openSuccessModal: false })}
        />

        <CustomConfirmationModal
          title={!this.state.id ? lang.registerErrorTitle : lang.editErrorTitle}
          open={this.state.openErrorModal}
          subtitle={
            !this.state.id ? lang.registerErrorSubTitle : lang.editErrorSubTitle
          }
          description={
            !this.state.id
              ? lang.registerErrorDescription
              : lang.editErrorDescription
          }
          confirmationText={lang.registerErrorConfirmationText}
          confirmationAction={() => this.setState({ openErrorModal: false })}
          onClose={() => this.setState({ openErrorModal: false })}
        />

        {/* Modal de confirmação para deletar categorias */}
        <CustomConfirmationModal
          title={lang.deleteCategoryModalTitle}
          description={lang.deletarCategoryModalDescription}
          open={this.state.deleteCategoryModalOpen}
          confirmationText={lang.deleteCategoryConfirmationText}
          confirmationAction={async () => this.deleteCategory()}
          cancelationText={lang.cancelationText}
          cancelationAction={() =>
            this.setState({ deleteCategoryModalOpen: false })
          }
          onClose={() => this.setState({ deleteCategoryModalOpen: false })}
        />

        <CustomModal
          open={this.state.openDeleteLoadingModal}
          onClose={() => this.setState({ openDeleteLoadingModal: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">{lang.deleteCategoryLoading}</Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        <CustomConfirmationModal
          title={lang.deleteErrorTitle}
          open={this.state.openDeleteErrorModal}
          subtitle={lang.deleteErrorSubTitle}
          description={lang.deleteErrorDescription.replace(
            "@MSG",
            this.state.mensagemError
          )}
          confirmationText={lang.deleteErrorConfirmationText}
          confirmationAction={() =>
            this.setState({ openDeleteErrorModal: false })
          }
          onClose={() => this.setState({ openDeleteErrorModal: false })}
        />

        <CustomModal
          open={this.state.openLoadCategory}
          onClose={() => this.setState({ openLoadCategory: false })}
          disableBackdropClick
          disableEscapeKeyDown
        >
          <div style={{ padding: "20px" }}>
            <Typography variant="h6">{lang.categoryLoading}</Typography>
            <LinearProgress />
          </div>
        </CustomModal>

        {this.renderTable()}
        {this.renderSideModal()}
      </CustomDrawerContainer>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return { user };
};

export default connect(mapStateToProps)(Category);
