import { CHANGE_DATA } from "../actions/types";

const INITIAL_STATE = {
  name: undefined,
  phoneNumber: undefined,
  document: undefined,
  email: undefined,
  imageUrl: undefined,
  language: undefined,
  fbUrlImage: undefined,
  fbToken: undefined,
  fireIdStore: undefined,
  fbIdStore: undefined,
  // Dados da primeira empresa no estado.
  logoFile: undefined,
  profileName: "",
  display: "",
  profileDocument: "",
  description: "",
  profileEmail: "",
  profilePhoneNumber: "",
  whatsapp: "",
  street: "",
  number: "",
  neighborhood: "",
  complement: "",
  zipCode: "",
  city: "",
  state: ""
};

/**
 * Funcao realizada apos o 'Dispach' do redux.
 */
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CHANGE_DATA:
      const newReturn = { ...state };
      newReturn[action.payload.key] = action.payload.value;
      return newReturn;
    default:
      return state;
  }
}
