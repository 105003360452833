import styled from "styled-components";

export const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 10px;
  padding: 10px;

  .filter {
    margin-right: 10px;
    width: 200px;
  }

  .filter-clear-btn {
    background-color: #3b2e7d;
    margin-right: 10px;
  }
`;
