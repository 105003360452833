import styled from "styled-components";

export const Container = styled.div`
  width: 50%;
  height: 100%;
`;

export const TitleContainer = styled.div`
  margin-bottom: 20px;
`;

export const OrdersContainer = styled.div``;
