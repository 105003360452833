// Bibliotecas
import React, { Component } from "react";
import { Button, Select, MenuItem } from "@material-ui/core";
import { Check, Close, Notifications, Alarm } from "@material-ui/icons";
import { formatDistance } from "date-fns";
import ReactLoading from "react-loading";
import { connect } from "react-redux";
// Recusros visuais.
import { Container, Label } from "./styles.css";
// Dados estaticos
import { states } from "../../../static/orderStates";
import { USER_AUTH_TOKEN } from "../../../static/LocalStorageTags";
// Componentes.
import CustomConfirmationModal from "../../../components/CustomConfirmationModal";
import OrderInfoModal from "./OrderInfoModal";
import OrderWithdrawnModal from "./OrderWithdrawnModal";
// Funções.
import {
  changeOrderState,
  notifyClient,
  printOrder,
} from "../../../functions/order";
import { getItem } from "../../../utils/localStorage";
import { closeLoader } from "../../../redux/actions";
// Importa o 'locale' para o texto de formatação de data.
// eslint-disable-next-line import/no-dynamic-require

// eslint-disable-next-line import/no-dynamic-require
const { default: locale } = require(`date-fns/locale/${
  navigator.language ||
  navigator.language.split(/[-_]/)[0] ||
  navigator.userLanguage.split(/[-_]/)[0] ||
  "pt-BR"
}`);

class Card extends Component {
  state = {
    // Variavel para controlar modal de confirmação de pedido
    confirmOrderModalOpen: false,
    // Variavel para controlar modal de rejeição de pedido
    rejectOrderModalOpen: false,
    // Variavel para controlar a modal de iniciar o preparo do pedido.
    startPreparingModalOpen: false,
    // Variavel para controlar a modal de indicar que o pedido está pronto.
    orderReadyModalOpen: false,
    // Variavel para controlar a modal de retirada do pedido.
    orderWithdrawnModalOpen: false,
    // Variavel para controlar a modal de notificar o cliente sobre.
    notifyClientModalOpen: false,
    // Variavel para controlar a modal de resposta da notificação do cliente.
    notifyClientResponseModalOpen: false,
    // Variavel da mensagem de resposta da notificação.
    notifyClientResponse: "",
    // Variavel para controlar a modal de finalização do pedido.
    completeOrderModalOpen: false,
    // Variavel para controlar a modal de visualização do pedido
    checkOrderInfoModalOpen: false,
    selectedOrder: {},
    loading: false,
    token: "",
    orderETA: 0,
  };

  componentDidMount() {
    const token = getItem(USER_AUTH_TOKEN);
    if (token) {
      this.setState({ token });
    }
  }

  componentWillUnmount() {
    console.log("removendo");
    if (this.props.order.orderIdToLoad === this.props.data.id) {
      this.props.closeLoader();
    }
  }

  /**
   * Método que valida qual cor a label deverá retornar de acordo com o estado.
   */
  checkColor = (stateIndex) => {
    switch (stateIndex) {
      case states.ORDER_COMPLETED.index:
        return "lightgreen";
      case states.ORDER_IS_READY.index:
      case states.ORDER_WITHDRAWN.index:
        return "green";
      case states.PREPARING.index:
        return "orange";
      case states.PROCESSING_PAYMENT.index:
      case states.PAYMENT_MADE.index:
        return "yellow";
      case states.ACCEPT.index:
        return "lightblue";
      default:
        return "lightgrey";
    }
  };

  /**
   * Método que retornará quais botões devem aparecer quando estiver em estado de "Aguardando aprovação"
   */
  awaitingApprovalActions = (item) => {
    return (
      <>
        <Select
          value={this.state.orderETA}
          onChange={(e) => {
            this.setState({ orderETA: e.target.value });
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          inputProps={{
            name: "orderETA",
            id: "orderETA",
          }}
        >
          <MenuItem selected value={0}>
            <Alarm />
          </MenuItem>
          <MenuItem value={5}>5 minutos</MenuItem>
          <MenuItem value={10}>10 minutos</MenuItem>
          <MenuItem value={15}>15 minutos</MenuItem>
          <MenuItem value={20}>20 minutos</MenuItem>
          <MenuItem value={25}>25 minutos</MenuItem>
          <MenuItem value={30}>30 minutos</MenuItem>
          <MenuItem value={35}>35 minutos</MenuItem>
          <MenuItem value={40}>40 minutos</MenuItem>
          <MenuItem value={45}>45 minutos</MenuItem>
          <MenuItem value={50}>50 minutos</MenuItem>
          <MenuItem value={55}>55 minutos</MenuItem>
          <MenuItem value={60}>60 minutos</MenuItem>
        </Select>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              confirmOrderModalOpen: true,
              selectedOrder: item,
            });
          }}
        >
          <Check />
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className="reject-btn"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              rejectOrderModalOpen: true,
              selectedOrder: item,
            });
          }}
        >
          <Close />
        </Button>
      </>
    );
  };

  /**
   * Método que retornará quais botões devem aparecer quando estiver em estado de "Pagamento realizado"
   */
  paymentDoneActions = (item) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          this.setState({
            startPreparingModalOpen: true,
            selectedOrder: item,
          });
        }}
      >
        {this.props.lang.startPreparingLabel}
      </Button>
    );
  };

  /**
   * Método que retornará quais botões devem aparecer quando estiver em estado de "Preparando"
   */
  orderPreparingActions = (item) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          this.setState({
            orderReadyModalOpen: true,
            selectedOrder: item,
          });
        }}
      >
        {this.props.lang.orderReadyLabel}
      </Button>
    );
  };

  /**
   * Método que retornará quais botões devem aparecer quando estiver em estado de "Pedido pronto"
   */
  orderCompletedActions = (item) => {
    return (
      <>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              orderWithdrawnModalOpen: true,
              selectedOrder: item,
            });
          }}
        >
          {this.props.lang.withdrawnLabel}
        </Button>
        <Button
          variant="contained"
          color="default"
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              notifyClientModalOpen: true,
              selectedOrder: item,
            });
          }}
        >
          <Notifications />
        </Button>
      </>
    );
  };

  /**
   * Método que retornará quais botões devem aparecer quando estiver em estado de "Pedido retirado"
   */
  orderWithdrawnActions = (item) => {
    return (
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          this.setState({
            completeOrderModalOpen: true,
            selectedOrder: item,
          });
        }}
      >
        {this.props.lang.completeOrderLabel}
      </Button>
    );
  };

  /**
   * Método que validará quais ações devem ser renderizadas nos cards
   * dependendo do estado do pedido.
   */
  checkCardActions = (item) => {
    switch (item.orderStatus.index) {
      // Botões caso seja estado 'Aguardando aprovação'
      case states.WAITING_APPROVAL.index:
        return this.awaitingApprovalActions(item);
      case states.PAYMENT_MADE.index:
        return this.paymentDoneActions(item);
      case states.PREPARING.index:
        return this.orderPreparingActions(item);
      case states.ORDER_IS_READY.index:
        return this.orderCompletedActions(item);
      case states.ORDER_WITHDRAWN.index:
        return this.orderWithdrawnActions(item);
      // Os casos abaixo nao necessitam de ações nos cards.
      case states.PROCESSING_PAYMENT.index:
      case states.ORDER_COMPLETED.index:
      default:
        return true;
    }
  };

  /**
   * Método utilizado para renderizar as modais de confirmação para cada ação realizada.
   */
  renderActionModals = () => {
    const { lang } = this.props;
    return (
      <>
        {/* Modal de confirmação para aprovar pedido */}
        <CustomConfirmationModal
          title={lang.orderNumber.replace(
            "@orderId",
            this.state.selectedOrder.id
          )}
          subtitle={lang.confirmOrderModalTitle.replace(
            "@userName",
            this.state.selectedOrder.userName
          )}
          description={lang.confirmOrderModalDescription}
          open={this.state.confirmOrderModalOpen}
          confirmationText={lang.confirmOrderModalConfirmationText}
          confirmationAction={async () => {
            this.setState({ loading: true });
            changeOrderState(
              this.state.selectedOrder.id,
              this.state.selectedOrder.profileId,
              states.ACCEPT.index,
              this.state.token,
              this.state.orderETA
            );
          }}
          cancelationText={lang.cancelationText}
          cancelationAction={() => console.log("Cancelado")}
          onClose={() =>
            this.setState({ confirmOrderModalOpen: false, selectedOrder: {} })
          }
        />
        {/* Modal de confirmação para rejeitar pedido */}
        <CustomConfirmationModal
          title={lang.orderNumber.replace(
            "@orderId",
            this.state.selectedOrder.id
          )}
          subtitle={lang.rejectOrderModalTitle.replace(
            "@userName",
            this.state.selectedOrder.userName
          )}
          description={lang.rejectOrderModalDescription}
          open={this.state.rejectOrderModalOpen}
          confirmationText={lang.rejectOrderConfirmationText}
          confirmationAction={async () => {
            this.setState({ loading: true });
            changeOrderState(
              this.state.selectedOrder.id,
              this.state.selectedOrder.profileId,
              states.REFUSED.index,
              this.state.token,
              null
            );
          }}
          cancelationText={lang.cancelationText}
          cancelationAction={() => console.log("Cancelado")}
          onClose={() =>
            this.setState({ rejectOrderModalOpen: false, selectedOrder: {} })
          }
        />
        {/* Modal de confirmação para iniciar o preparo do pedido */}
        <CustomConfirmationModal
          title={lang.orderNumber.replace(
            "@orderId",
            this.state.selectedOrder.id
          )}
          subtitle={lang.startPreparingModalTitle.replace(
            "@userName",
            this.state.selectedOrder.userName
          )}
          description={lang.startPreparingModalDescription}
          open={this.state.startPreparingModalOpen}
          confirmationText={lang.startPreparingModalConfirmationText}
          confirmationAction={async () => {
            this.setState({ loading: true });
            const res = changeOrderState(
              this.state.selectedOrder.id,
              this.state.selectedOrder.profileId,
              states.PREPARING.index,
              this.state.token,
              null
            );
            if (res) {
              printOrder(this.state.selectedOrder);
            }
          }}
          cancelationText={lang.cancelationText}
          cancelationAction={() => console.log("Cancelado")}
          onClose={() =>
            this.setState({ startPreparingModalOpen: false, selectedOrder: {} })
          }
        />
        {/* Modal de confirmação para finalizar o preparo do pedido */}
        <CustomConfirmationModal
          title={lang.orderNumber.replace(
            "@orderId",
            this.state.selectedOrder.id
          )}
          subtitle={lang.orderReadyModalTitle.replace(
            "@userName",
            this.state.selectedOrder.userName
          )}
          description={lang.orderReadyModalDescription}
          open={this.state.orderReadyModalOpen}
          confirmationText={lang.orderReadyModalConfirmationText}
          confirmationAction={async () => {
            this.setState({ loading: true });
            changeOrderState(
              this.state.selectedOrder.id,
              this.state.selectedOrder.profileId,
              states.ORDER_IS_READY.index,
              this.state.token,
              null
            );
          }}
          cancelationText={lang.cancelationText}
          cancelationAction={() => console.log("Cancelado")}
          onClose={() =>
            this.setState({ orderReadyModalOpen: false, selectedOrder: {} })
          }
        />
        {/* Modal de confirmação para retirar o pedido */}
        {this.state.selectedOrder && (
          <OrderWithdrawnModal
            lang={lang}
            token={this.state.token}
            open={this.state.orderWithdrawnModalOpen}
            startLoading={() => this.setState({ loading: true })}
            selectedOrder={this.state.selectedOrder}
            onClose={() =>
              this.setState({
                orderWithdrawnModalOpen: false,
                selectedOrder: {},
              })
            }
          />
        )}
        {/* Modal de confirmação para retirar o pedido */}
        <CustomConfirmationModal
          title={lang.orderNumber.replace(
            "@orderId",
            this.state.selectedOrder.id
          )}
          subtitle={lang.notifyClientModalTitle.replace(
            "@userName",
            this.state.selectedOrder.userName
          )}
          description={lang.notifyClientModalDescription}
          open={this.state.notifyClientModalOpen}
          confirmationText={lang.notifyClientModalConfirmationText}
          confirmationAction={async () => {
            const { message } = await notifyClient(
              this.state.selectedOrder.id,
              this.state.token
            );
            this.setState({
              notifyClientModalOpen: false,
              notifyClientResponse: message,
              notifyClientResponseModalOpen: true,
            });
          }}
          cancelationText={lang.cancelationText}
          cancelationAction={() => console.log("Cancelado")}
          onClose={() => this.setState({ notifyClientModalOpen: false })}
        />
        {/* Modal com a resposta da notificação para o cliente. */}
        <CustomConfirmationModal
          title={lang.orderNumber.replace(
            "@orderId",
            this.state.selectedOrder.id
          )}
          subtitle={this.state.notifyClientResponse}
          open={this.state.notifyClientResponseModalOpen}
          confirmationText={lang.close}
          confirmationAction={async () => {
            this.setState({
              notifyClientResponseModalOpen: false,
              notifyClientResponse: "",
              selectedOrder: {},
            });
          }}
          cancelationAction={() => console.log("Cancelado")}
          onClose={() =>
            this.setState({
              notifyClientResponseModalOpen: false,
              notifyClientResponse: "",
              selectedOrder: {},
            })
          }
        />
        {/* Modal de confirmação para finalizar o pedido */}
        <CustomConfirmationModal
          title={lang.orderNumber.replace(
            "@orderId",
            this.state.selectedOrder.id
          )}
          subtitle={lang.completeOrderModalTitle.replace(
            "@userName",
            this.state.selectedOrder.userName
          )}
          description={lang.completeOrderModalDescription}
          open={this.state.completeOrderModalOpen}
          confirmationText={lang.completeOrderModalConfirmationText}
          confirmationAction={async () => {
            this.setState({ loading: true });
            changeOrderState(
              this.state.selectedOrder.id,
              this.state.selectedOrder.profileId,
              states.ORDER_COMPLETED.index,
              this.state.token,
              null
            );
          }}
          cancelationText={lang.cancelationText}
          cancelationAction={() => console.log("Cancelado")}
          onClose={() =>
            this.setState({ completeOrderModalOpen: false, selectedOrder: {} })
          }
        />
        {/* Modal de visualização dos dados do pedido */}
        <OrderInfoModal
          lang={lang}
          open={this.state.checkOrderInfoModalOpen}
          startLoading={() => this.setState({ loading: true })}
          selectedOrder={this.state.selectedOrder}
          onClose={() => {
            this.setState({
              selectedOrder: {},
              checkOrderInfoModalOpen: false,
            });
          }}
        />
      </>
    );
  };

  /**
   * Método para renderizar loader de tela.
   */
  renderLoader = (data) => {
    if (
      this.state.loading ||
      (this.props.order.loading &&
        this.props.order.orderIdToLoad === this.props.data.id) ||
      [
        (states.ACCEPT.index,
        states.PROCESSING_PAYMENT.index,
        states.ORDER_WITHDRAWN.index),
      ].includes(data.orderStatus.index)
    ) {
      return (
        <ReactLoading type="bars" color="#fff" height="30px" width="30px" />
      );
    }
    return true;
  };

  /**
   * Método que retorna o nome do cliente formatado.
   *
   * @param {String} name Nome do cliente.
   */
  renderClientName = (name) => {
    if (name.split(" ").length > 1) {
      const names = name.split(" ");
      return `${names[0]} ${names[names.length - 1]}`;
    }
    return name;
  };

  /**
   * Método para renderizar data (dd/MM/yy) a partir de um timestamp
   *
   * @param {Number} timestamp float com valor da data.
   */
  renderDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate() > 10 ? date.getDate() : `0${date.getDate()}`;
    const month =
      date.getMonth() + 1 > 10
        ? date.getMonth() + 1
        : `0${date.getMonth() + 1}`;
    return `${day}/${month}/${date.getFullYear()}`;
  };

  /**
   * Método para renderizar hora (hh:mm) a partir de um timestamp
   *
   * @param {Number} timestamp float com valor da data.
   */
  renderTime = (timestamp) => {
    const date = new Date(timestamp);
    const hour = date.getHours() > 10 ? date.getHours() : `0${date.getHours()}`;
    const minute =
      date.getMinutes() > 10 ? date.getMinutes() : `0${date.getMinutes()}`;
    return `${hour}:${minute}`;
  };

  /**
   * Método que renderiza o tempo de espera para pedidos que não estao completos
   * ou retirados
   *
   * @param {Object} data Objeto do pedido.
   */
  renderWaitingTime = (data) => {
    const { lang } = this.props;
    if (
      data.orderStatus.index !== states.ORDER_COMPLETED.index &&
      data.orderStatus.index !== states.ORDER_WITHDRAWN.index
    ) {
      return (
        <p>
          <strong>{lang.waitingTime}: </strong>
          {formatDistance(data.dtCreated, new Date(), {
            addSuffix: true,
            locale,
          })}
        </p>
      );
    }
    return true;
  };

  render() {
    const { data, lang } = this.props;
    return (
      <>
        <Container
          onClick={() => {
            this.setState({
              checkOrderInfoModalOpen: true,
              selectedOrder: data,
            });
          }}
        >
          <header>
            <Label color={this.checkColor(data.orderStatus.index)} />
            <strong className="order-title">
              {lang.order} #{data.id}
            </strong>
          </header>
          <div className="loader-header">{this.renderLoader(data)}</div>
          <p>
            <strong>{lang.client}: </strong>
            {this.renderClientName(data.userName)}
          </p>
          {this.renderWaitingTime(data)}
          {data.estimateTime && data.estimateTime > 0 ? (
            <p>
              <strong>{lang.estimateTime}: </strong>
              {data.estimateTime} {lang.minutes}
            </p>
          ) : (
            true
          )}
          {data.tableNumber && data.tableNumber.trim().length > 0 ? (
            <p>
              <strong>{lang.deliverOrderAt}: </strong>
              {data.tableNumber}
            </p>
          ) : (
            true
          )}
          <p>
            <strong>{lang.totalValue}: </strong>
            {lang.currencyType}
            {data.totalPrice
              .toFixed(2)
              .replace(".", ",")
              .replace(/\d(?=(\d{3})+\.)/g, "$&.")}
          </p>
          <div className="btn-container">
            {/* <Button
              variant="contained"
              color="default"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                this.setState({
                  checkOrderInfoModalOpen: true,
                  selectedOrder: data,
                });
              }}
            >
              <InfoOutlined />
            </Button> */}
            {this.checkCardActions(data)}
          </div>
        </Container>
        {this.renderActionModals()}
      </>
    );
  }
}

const mapStateToProps = ({ order }) => {
  return { order };
};

export default connect(mapStateToProps, { closeLoader })(Card);
