// // TODO: BUSCAR ESTADOS DA API QUANDO EXISTIR UMA API QUE DISPONIBILIZA ISSO.
// export const states = {
//   NONE: { index: -1, enum: "order.status.none", label: "Sem Estado" },
//   CREATED: { index: 0, enum: "order.status.created", label: "Criado" },
//   PENDENT: { index: 1, enum: "order.status.pendent", label: "Pendente" },
//   VALID: { index: 2, enum: "order.status.valid", label: "Valido" },
//   WAITING_APPROVAL: {
//     index: 3,
//     enum: "order.status.waiting.for.approval",
//     label: "Aguardando aprovação"
//   },
//   ACCEPT: { index: 5, enum: "order.status.accpet", label: "Pedido aceito" },
//   PROCESSING_PAYMENT: {
//     index: 6,
//     enum: "order.status.processing.payment",
//     label: "Processando pagamento"
//   },
//   PAYMENT_MADE: {
//     index: 7,
//     enum: "order.status.payment.made",
//     label: "Pagamento realizado"
//   },
//   PAYMENT_REFUSED: {
//     index: 8,
//     enum: "order.status.payment.refused",
//     label: "Pagamento não realizado"
//   },
//   PREPARING: { index: 9, enum: "order.status.preparing", label: "Preparando" },
//   ORDER_IS_READY: {
//     index: 10,
//     enum: "order.status.order.is.ready",
//     label: "Pedido pronto"
//   },
//   ORDER_WITHDRAWN: {
//     index: 11,
//     enum: "order.status.order.withdrawn",
//     label: "Pedido retirado"
//   },
//   ORDER_COMPLETED: {
//     index: 12,
//     enum: "order.status.order.completed",
//     label: "Pedido finalizado"
//   },
//   REFUSED: { index: 13, enum: "order.status.refused", label: "Recusado" },
//   SUCCESS: { index: 14, enum: "order.status.success", label: "Sucesso" }
// };

export const NONE = {
  index: -1,
  enum: "order.status.none",
  label: "Sem Estado",
  color: "grey",
};
export const CREATED = {
  index: 0,
  enum: "order.status.created",
  label: "Criado",
  color: "grey",
};
export const PENDENT = {
  index: 1,
  enum: "order.status.pendent",
  label: "Pendente",
  color: "grey",
};
export const VALID = {
  index: 2,
  enum: "order.status.valid",
  label: "Valido",
  color: "green",
};
export const WAITING_APPROVAL = {
  index: 3,
  enum: "order.status.waiting.for.approval",
  label: "Aguardando aprovação",
  color: "darkorange",
};
export const ACCEPT = {
  index: 5,
  enum: "order.status.accpet",
  label: "Pedido aceito",
  color: "green",
};
export const PROCESSING_PAYMENT = {
  index: 6,
  enum: "order.status.processing.payment",
  label: "Processando pagamento",
  color: "darkorange",
};
export const PAYMENT_MADE = {
  index: 7,
  enum: "order.status.payment.made",
  label: "Pagamento realizado",
  color: "green",
};
export const PAYMENT_REFUSED = {
  index: 8,
  enum: "order.status.payment.refused",
  label: "Pagamento não relizado",
  color: "red",
};
export const PREPARING = {
  index: 9,
  enum: "order.status.preparing",
  label: "Preparando",
  color: "orange",
};
export const ORDER_IS_READY = {
  index: 10,
  enum: "order.status.order.is.ready",
  label: "Pronto!",
  color: "green",
};
export const ORDER_WITHDRAWN = {
  index: 11,
  enum: "order.status.order.completed",
  label: "Pedido retirado",
  color: "green",
};
export const ORDER_COMPLETED = {
  index: 12,
  enum: "order.status.order.completed",
  label: "Pedido finalizado",
  color: "grey",
};
export const REFUSED = {
  index: 13,
  enum: "order.status.refused",
  label: "Recusado",
  color: "red",
};
export const SUCCESS = {
  index: 14,
  enum: "order.status.success",
  label: "Sucesso",
  color: "green",
};
export const REMOVE_AT_COUNTER = {
  index: 15,
  enum: "order.status.remove.at.counter",
  label: "Retirar no Balcão",
  color: "green",
};
export const states = {
  NONE,
  CREATED,
  PENDENT,
  VALID,
  WAITING_APPROVAL,
  ACCEPT,
  PROCESSING_PAYMENT,
  PAYMENT_MADE,
  PAYMENT_REFUSED,
  PREPARING,
  ORDER_IS_READY,
  ORDER_WITHDRAWN,
  ORDER_COMPLETED,
  REFUSED,
  SUCCESS,
  REMOVE_AT_COUNTER,
};
